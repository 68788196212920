import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import close from '../../images/design/close.svg';
import { t } from '../../services/i18n';

const GridChoice = ({ value, onChange, next, title, description, options, prev, onClose }) => (
  <div className="choice-container">
    { onClose && (
      <button type="button" onClick={onClose} className="close">
        <img src={close} alt="cross icon" />
      </button>
    ) }
    <h2>{ title }</h2>
    <p>{ description }</p>
    <div className="grid">
      { options.map((option) => (
        <label
          htmlFor={option.value}
          className={cn('option', { checked: value === option.value })}
          key={option.value}
        >
          <span>{ option.label }</span>
          <input
            type="radio"
            value={option.value}
            id={option.value}
            checked={value === option.value}
            onChange={({ target }) => {
              if (target.checked) onChange(option.value);
            }}
          />
        </label>
      )) }
    </div>
    <footer>
      <span>
        {
          !value
            ? t('onboarding.select-msg')
            : `'${options.find((o) => o.value === value).label}' ${t('onboarding.selected')}`
        }
      </span>
      <nav>
        { prev && (
          <button type="button" className="link" onClick={prev}>
            Previous
          </button>
        ) }
        <button type="button" onClick={next} disabled={!value} className="primary">
          { !value ? t('onboarding.select-btn') : t('common.continue-btn')}
        </button>
      </nav>
    </footer>
  </div>
);

GridChoice.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  prev: PropTypes.func,
  onClose: PropTypes.func
};

GridChoice.defaultProps = {
  value: '',
  options: [],
  prev: null,
  onClose: null
};

export default GridChoice;
