import React from 'react';
import radio from '../images/chapters/chapter7/MINUSCA_UN_staff_radio_guira_FM.jpg';
import twitter from '../images/chapters/chapter7/MINUSMA_twitter.png';
import technology from '../images/chapters/chapter7/technology_and_equipment_in_UNIFIL.jpg';
import resolution from '../images/chapters/chapter7/UNISFA_local_level_conflict_mediation_and_resolution.png';
import speech from '../images/chapters/chapter7/UNMISS_hate_speech.jpg';
import AudioPlayer from '../components/audio';
import audio from './audio';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 7: Communicating about POC',
  title: t('handbook.chapters.chapter7.title'),
  index: '7',
  color: 'un-blue',
  content: (
    <>
      <div className="grid-three un-blue">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            Strategic communication and influence is an important part of the POC concept, throughout all tiers. In alignment with their general communication strategies, missions should consider developing specific POC communication strategies that include dialogue, engagement, public information and advocacy in support of the mission’s strategic and political approach to POC.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            The primary aim of a POC communication strategy is to understand, mobilize and affect the intentions, perceptions and expectations of all relevant actors to enhance the protection of civilians. Effective strategic communications also help to uphold the mission’s reputation and credibility.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            Developing strategic communication approaches for POC begins with mapping and determining the targets, aims, types and available means of communication, as well as crafting Messaging for each target.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            Strategic communications on POC should be guided by mission leadership, with support from POC Advisers and in consultation with DPO staff at UN Headquarters. The Strategic Communication and Public Information section (SCPI) manages the mission’s communications plan and works closely with police and military Public Information Officers. All mission actors who engage with external actors on POC must be part of a coordinated approach to ensure consistent messaging.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            Strategic communications are managed jointly at UN Headquarters, mission headquarters and mission field offices.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            Strategic communication plans on POC should begin with mission start-up and should be updated regularly throughout the duration of the mission. Specific communications plans should be developed to accompany significant POC operations and will be necessary during crises.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '7.1',
      name: 'Strategic Communications',
      title: t('handbook.chapters.chapter7.1.title'),
      content: (
        <>
          <p>
            Missions are advised to proactively engage in strategic discussions with all stakeholders to enhance knowledge and understanding of POC, encourage actions to protect civilians and manage expectations. The aims of these discussions and communications are to:
          </p>
          <ul className="square un-blue">
            <li>
              Understand and affect the hostile intent and acts of perpetrators or potential perpetrators of violence against civilians, their supporters and spoilers;
            </li>
            <li>
              Identify, understand, mobilize and support key influencers (including from communities, civil society, host state authorities, humanitarians, regional actors, and the international community) to affect hostile intent, mobilize protection actors, enhance the resilience of communities at risk and establish a protective environment;
            </li>
            <li>
              Identify, monitor, and swiftly counter hate speech, disinformation, propaganda, rumours and incitement of violence that negatively affect the security and POC situation, particularly within civilian communities; and
            </li>
            <li>
              Proactively manage perceptions and expectations of the mission&apos;s POC mandate, capacity and actions, and communicate successes and challenges on POC.
            </li>
          </ul>
          <p>
            All missions are encouraged to develop specific POC communications strategies, as well as action plans (see e.g., Annex IV for a Crisis Communications Action Plan). The table below outlines key targets of a mission POC communication strategy, the strategic aims of communication with them, the type of communication and the mission components likely to be involved.
          </p>
          <h4>POC Communication Strategy Target Audiences</h4>
          <table className="examples fade-up">
            <thead>
              <tr>
                <td>Target Audience</td>
                <td>Strategic Aim</td>
                <td>Type of Communication</td>
                <td>Mission Section</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>Host state</strong></td>
                <td>
                  To promote and encourage primary responsibility to protect civilians
                </td>
                <td>
                  High-level support<br />
                  Sensitization<br />
                  Persuasion<br />
                  Public encouragement<br />
                  Denunciation
                </td>
                <td>
                  Mission Leadership Political Affairs Force<br />
                  Police<br />
                  Human Rights SCPI
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Perpetrators of violence against civilians: </strong>(Leaders and members of armed forces and groups, community-based militias and other armed actors)
                </td>
                <td>
                  <p>
                    To prevent, mitigate or stop violence against civilians
                  </p>
                  <p>
                    To communicate that those who commit serious crimes against civilians will be held accountable
                  </p>
                </td>
                <td>
                  Dialogue<br />
                  Persuasion<br />
                  Denunciation<br />
                  Deterrence
                </td>
                <td>
                  Mission Leadership Force<br />
                  Police<br />
                  Political Affairs DDR/CVR<br />
                  Human Rights Justice
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Individuals and institutions directly or indirectly contributing to violence against civilians</strong>
                </td>
                <td>
                  To stop support for groups or actions that threaten civilians
                </td>
                <td>
                  Dialogue<br />
                  Engagement<br />
                  Persuasion
                </td>
                <td>
                  Mission Leadership<br />
                  Political Affairs<br />
                  Potentially in close coordination with UN and other Special Envoys, and Security Council Sanctions Committees and Panels of Experts
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Other protection actors:</strong>
                  <ul className="square un-blue">
                    <li>Civilian communities</li>
                    <li>Civil society</li>
                    <li>Religious and community leaders</li>
                    <li>Regional organizations</li>
                    <li>Other international organizations or forces</li>
                    <li>International community</li>
                  </ul>
                </td>
                <td>
                  <p>
                    To support and encourage actions to protect civilians at risk
                  </p>
                  <p>
                    To communicate the mission&apos;s POC mandate
                  </p>
                </td>
                <td>
                  Dialogue<br />
                  Engagement<br />
                  Persuasion<br />
                  Expectation management
                </td>
                <td>
                  Mission Leadership<br />
                  Civil Affairs<br />
                  Political Affairs<br />
                  Human Rights<br />
                  Force<br />
                  Police<br />
                  SCPI
                </td>
              </tr>
              <tr>
                <td><strong>Communities at risk</strong></td>
                <td>
                  <p>
                    To reassure of the mission&apos;s intent to protect
                  </p>
                  <p>
                    To advise on possible courses of action and refer to other sources of assistance
                  </p>
                </td>
                <td>
                  Reassurance<br />
                  Provision of information<br />
                  Expectation management
                </td>
                <td>
                  Civil Affairs<br />
                  Human Rights<br />
                  Force<br />
                  Police<br />
                  SCPI
                </td>
              </tr>
              <tr>
                <td><strong>National and international media</strong></td>
                <td>
                  <p>
                    To provide neutral sources of information
                  </p>
                  <p>
                    To counter negative messaging
                  </p>
                  <p>
                    To manage expectations and safeguard reputation of mission
                  </p>
                </td>
                <td>
                  Provision of information<br />
                  Peace promotion<br />
                  Dissuasion of conflict<br />
                  Expectation management
                </td>
                <td>
                  Mission Leadership<br />
                  SCPI
                </td>
              </tr>
            </tbody>
          </table>
          <AudioPlayer
            src={audio[4].file}
            title={audio[4].title}
            subtitle={audio[4].subtitle}
            avatar={audio[4].avatar}
            speaker={audio[4].speaker}
            transcript={audio[4].transcript}
          />
          <div className="voices un-blue margin-bottom">
            <div className="avatar">
              <img alt={audio[4].speaker} src={audio[4].avatar} />
            </div>
            <h3>VOICES FROM THE FIELD</h3>
            <div className="content">
              <h4>Communicating with Different Actors</h4>
              <h5>Ameen Alabsi, MINUSCA MILOB (2014-2017)</h5>
              <div className="half">
                <div>
                  <p>
                    In 2014, I was appointed to work in the Central African Republic (CAR) as a Military Observer (MILOB) for MINUSCA. This was the first such duty for me outside of my home country in Yemen, and I found the conditions in CAR difficult at first. There were many dangerous environments in the commencement of the Mission, which made the position seem risky to me.
                  </p>
                  <p>
                    I was assigned along with six other MILOBS to the field office in Berberati. We formed the first MILOB team in MINUSCA and represented the UN for the communities on the ground there. My function was as an Intelligence Officer (G2), playing the crucial role of collecting information on threats to the population. Because the Mission was new and understaffed, I also ended up coordinating between MINUSCA forces and the local authorities, sometimes assisting in organizing reconciliation meetings to bring the Muslim and Christian communities together. At mission start-up I learned you may need to wear several different hats and fill roles creatively, as needed.
                  </p>
                </div>
                <div>
                  <p>
                    My position required that I was in communication with a variety of actors to gather information. I would regularly stop at villages while on patrol to speak to the population, but also met with religious leaders and armed groups. Each group required a different relationship and style of communication. With the population in a small village, for example, it was important to take note of their protection concerns and communicate that I was trying to understand situations from their perspective. It was about providing reassurance. That style of communication was very different from dealing with armed elements, where interactions were more about building trust and making sure they were used to our presence and understood our intentions. I recall that the work could be dangerous, especially with frequent joint patrols into areas of armed conflict, so building trust and good relations was essential. Having networks in place also allowed us to better understand the environment, plan properly and provide early warning where needed.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative margin-bottom">
            <img src={resolution} alt="UNISFA local level conflict mediation and resolution" />
            <div className="description">
              <p>
                An integrated team from UNISFA holds a meeting with a community. (2018)
              </p>
            </div>
            <p className="ref">UN Photo/Irene Kapanga</p>
          </div>
        </>
      )
    },
    {
      index: '7.2',
      name: 'Mapping the Communications Landscape',
      title: t('handbook.chapters.chapter7.2.title'),
      content: (
        <>
          <table className="examples margin-bottom toolkit">
            <thead>
              <tr>
                <td>TOOLKIT</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h4 className="color">
                    How to Design a Strategic Communications Strategy on POC
                  </h4>
                  <h5>What is the role of strategic communication on POC?</h5>
                  <p>
                    Strategic communication in peacekeeping operations is conceptualized, planned, implemented and evaluated in alignment with the mandates, strategic objectives and intended outcomes of a mission. Strategic communication activities function to support a mission’s reputation, outreach, and advocacy goals. Specific strategies for communicating about POC can contribute to POC mandate implementation and should be part of the mission’s comprehensive POC strategy.
                  </p>
                  <h4 className="un-blue">Checklist</h4>
                  <ul className="checklist">
                    <li>
                      When designing POC communication strategies, pay attention to armed actors and community perceptions.
                    </li>
                    <li>
                      Map the specific communication targets (armed groups, communities, host state, media, etc.).
                    </li>
                    <li>
                      Establish profiles and influence strategies for each relevant actor or group of actors.
                    </li>
                    <li>
                      Establish a timeline for both behind-the-scenes and public engagement or advocacy actions, including actions by all mission components and sections.
                    </li>
                    <li>
                      Review costs and benefits of different communication means and media available.
                    </li>
                    <li>
                      Hold regular meetings with community representatives and leaders (tribal, religious, political), as well as youth and women&apos;s groups, community-based organizations and national NGOs at all levels. Bear in mind that leaders may not represent the experiences or perceptions of all sections of the community.
                    </li>
                    <li>
                      Ensure that all mission actors engaging with armed actors are first trained in the POC concept.
                    </li>
                    <li>
                      Ensure that all mission actors engaging with armed actors are integrated into POC planning, strategizing and information sharing, so that messaging is standardized and engagement on POC is coordinated.
                    </li>
                  </ul>
                  <h4 className="un-blue">Reminders</h4>
                  <ul className="reminders">
                    <li>
                      Strategic communications should be planned pre-emptively with clearly identified goals.
                    </li>
                    <li>
                      Effective communication should benefit a mission&apos;s reputation, outreach abilities and advocacy goals.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="examples d-inline-table">
            <thead>
              <tr>
                <td>Example: Mission mapping of key communication targets for POC</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="no-padding">
                  <ul className="striped no-bullets">
                    <li>
                      International community (including troop and police contributing countries)
                    </li>
                    <li>
                      Host state government and its institutions (including state armed forces)
                    </li>
                    <li>The local population (nationally)</li>
                    <li>Population in the vicinity of the POC threats</li>
                    <li>The community affected by the POC threats</li>
                    <li>
                      The surviving civilian victims of violence, especially vulnerable victims
                    </li>
                    <li>
                      The instigators and perpetrators of violence against civilians
                    </li>
                    <li>
                      Political and proxy actors who may have influence with perpetrators
                    </li>
                    <li>Youth (potential recruits into armed groups)</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            The mission has a wide variety of channels of communications at its disposal. The efficacy of communications will depend on the degree of coordination of key messages through various media between UN Headquarters, mission headquarters and field offices. These potential channels of communication and associated activities include:
          </p>
          <ul className="square un-blue">
            <li>
              Mission social media platforms (Twitter, Facebook, Flickr, YouTube, Instagram, etc.);
            </li>
            <li>Press releases;</li>
            <li>
              Radio/TV programming (mission radio/local stations);
              <ul className="triangle">
                <li>
                  Radio/video statements from key staff (including civilian and uniformed mission leadership)
                </li>
                <li>Community focused programming to promote social cohesion</li>
                <li>Interviews with moderate community voices</li>
              </ul>
            </li>
            <li>Text message (immediate crisis only);</li>
            <li>Key leader engagements (operational and tactical);</li>
            <li>
              Local level engagements (tactical) (including via CIMIC officers and CLAs); and
            </li>
            <li>Print products (leaflets/handbills/posters).</li>
          </ul>
          <p>
            In addition to the above direct channels of communication, the mission may utilize other activities that can serve to support, augment and amplify themes and messages. These include:
          </p>
          <ul className="square un-blue">
            <li>Community outreach/sensitization training;</li>
            <li>Distribution of radios (longer term project);</li>
            <li>Promotion of mission call-in or SMS phone number (hotline);</li>
            <li>Assistance to and capacity building of local media; and</li>
            <li>
              Coordination with host state government and social media services (to counter disinformation).
            </li>
          </ul>
          <p>
            Finally, various mission components will also have their own communication strategies and channels of access. These component-level communication strategies should also reflect agreed POC messaging.
          </p>
          <div className="relative">
            <img src={radio} alt="MINUSCA UN staff radio guira FM" />
            <div className="description">
              <p>
                The DPO USG and MINUSCA’s SRSG use the radio station created by the Mission to promote peace and reconciliation. (2018)
              </p>
            </div>
            <p className="ref">UN Photo/MINUSCA</p>
          </div>
        </>
      )
    },
    {
      index: '7.3',
      name: 'Planning and Preparedness for Strategic Communications',
      title: t('handbook.chapters.chapter7.3.title'),
      content: (
        <>
          <p>
            The communication and influence strategy should set out key objectives. Each objective should include a success indicator or information on the intended effect.
          </p>

          <table className="examples">
            <thead>
              <tr>
                <td>Example: POC Communication Strategy objectives and success indicators</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="no-padding">
                  <table className="grid">
                    <thead>
                      <tr>
                        <td>Objective</td>
                        <td>Success Indicator</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          1. <strong>DETER</strong> the continuation or escalation of violence
                        </td>
                        <td>
                          <ol type="1">
                            <li>Armed groups reduce number of attacks on civilians</li>
                            <li>Reduction in the number of civilian casualties</li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          2. <strong>DISSUADE</strong> retaliatory attacks
                        </td>
                        <td>
                          <ol type="1">
                            <li>Number of attacks on [specific group] are reduced</li>
                            <li>Increased incidents of mediation between groups</li>
                            <li>
                              Communities in conflict are reported to be collaborating on humanitarian issues
                            </li>
                            <li>Reduction in the number of armed self-defence groups</li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          3. <strong>REASSURE</strong> the affected population that assistance and protection will arrive
                        </td>
                        <td>
                          <ol type="1">
                            <li>
                              Local population express feeling of security/safety (feel safe to travel to the market, to neighbouring villages, etc.)
                            </li>
                            <li>
                              Local population express belief in the mission&apos;s ability to protect them
                            </li>
                            <li>Decrease in criticism of UN operations</li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          4. <strong>INFORM</strong> the wider community of the measures that the mission is taking in response to the crisis
                        </td>
                        <td>
                          <ol type="1">
                            <li>
                              Increase in local population ability to describe the mission mandate accurately
                            </li>
                            <li>
                              Increase in local population ability to identify UN activities in support of POC
                            </li>
                            <li>
                              National media disseminates the mission&apos;s messages
                            </li>
                            <li>
                              Interviews and stories are accurately echoed in the international community
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          5. <strong>DISCOURAGE</strong> local population from joining armed groups
                        </td>
                        <td>
                          <ol type="1">
                            <li>Increase in DDR participants</li>
                            <li>Increase in denunciations of armed groups</li>
                            <li>Increase in support for civil society groups</li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          6. <strong>CLARIFY</strong> misinformation or disinformation
                        </td>
                        <td>
                          <ol type="1">
                            <li>Increase in accurate reporting in the media</li>
                            <li>
                              Decrease in incidents of mis/disinformation in the media
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          7. <strong>MAINTAIN</strong> mission credibility, including by managing expectations
                        </td>
                        <td>
                          <ol type="1">
                            <li>Media stories reflect progress mission has made</li>
                            <li>
                              Local populations increasingly voice support for the mission
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          8. <strong>PROMOTE</strong> societal cohesion
                        </td>
                        <td>
                          <ol type="1">
                            <li>Number of attacks on ethnic group A/B are reduced</li>
                            <li>
                              Communities A and B are reported to be collaborating on peaceful dispute resolution
                            </li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          9. <strong>COUNTER</strong> enduring narratives that exasperate violence
                        </td>
                        <td>
                          <ol type="1">
                            <li>
                              Increased reporting on ethnic/religious communities co-existing in peace
                            </li>
                          </ol>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            Once the POC communications strategy is developed, missions may consider the following actions:
          </p>
          <ul className="square un-blue">
            <li>
              Conducting regular analysis of perceptions and objectives of key stakeholders (including government, humanitarian community, armed groups, international media, national and community level or social media, civil society, population) to inform the approach to engagement.
            </li>
            <li>
              Establishing a UN communication group or equivalent to help align or coordinate public information action with the UN Country Team and liaise closely with the Protection Cluster and existing NGO advocacy forums to reinforce and coordinate communications on POC, as appropriate.
            </li>
            <li>
              Depending on the context, and particularly in times of POC crisis, pursuing joint communication efforts with the host government and other international actors, such as through joint press conferences or joint visits to the field with the press, and aligning press lines and messaging accordingly.
            </li>
            <li>
              Ensuring coordinated and standard messaging per communication target:
              <ul className="triangle un-blue">
                <li>
                  Agreeing standard press lines and talking points or creating a ‘frequently asked questions&apos; (FAQ) on POC, and ensuring all sections understand the mission&apos;s mandated POC tasks and responsibilities, strategy, priorities and limitations;
                </li>
                <li>
                  Using perception analysis, community opinion surveys and monitoring or use of social media to inform messages and communication strategies;
                </li>
                <li>
                  Designating components or sections as focal points for each target (e.g., the Public Information Office for media and social media, Political Affairs for political actors, DDR/Political Affairs for armed groups, Civil Affairs for communities and civil society, Human Rights for victims and witnesses). The focal point should not vet or prevent meetings by other components, but rather ensure coordinated engagement for a specific target and accompany meetings as applicable and appropriate;
                </li>
                <li>
                  Ensuring that senior managers and leaders, or a person designated by them, lead sensitive or important engagement efforts; and
                </li>
                <li>
                  Developing and distributing written documents with key messages (e.g., the POC strategy, a community sensitization booklet on POC, media booklets).
                </li>
              </ul>
            </li>
            <li>
              Investing in <strong>new technologies</strong>, particularly for the monitoring and use of social media, as armed actors and spoilers often use social media to implement swift and efficient influence campaigns.
            </li>
          </ul>
          <p>
            Moreover, the mission must:
          </p>
          <ul className="square un-blue">
            <li>
              Ensure <strong>coherence and consistency of assessments and figures </strong>used in public reporting related to POC (press release, Secretary-General reports, etc.) and
            </li>
            <li>
              Recognize that the mission may expose civilians to risk through engagement or by communicating information on specific individuals or communities to an audience that uses the information in a detrimental or way, including a state organ. If this is a possibility, it is necessary to develop specific <strong>risk assessments and prevention and mitigation measures </strong>on the protection of partners (humanitarians, civil society, community representatives, etc.) or other informants, witnesses and victims.
            </li>
          </ul>
          <p>
            The planning of communication and influence actions should take into account the appropriate timing, balancing and synchronization with other protection activities and their potential negative impact. For example:
          </p>
          <ul className="un-blue">
            <li>
              Denunciation through advocacy and public reporting related to human rights violations and impunity should be planned and sequenced so as not to jeopardize an ongoing mediation or reconciliation effort;
            </li>
            <li>
              Sharing detailed information on POC-related incidents or threats with certain actors or through public information may expose the identity of victims or witnesses and, indirectly, result in violence against civilians; and
            </li>
            <li>
              While engaging with armed groups and militias, a fine balance must be reached to ensure protection messages and red lines are clearly communicated while maintaining a positive engagement on political dialogue, DDR or CVR.
            </li>
          </ul>
          <p>
            Missions should consider developing a liaison matrix to identify who within the mission will lead on engagement with key leaders in various target groups. The matrix can include the activity or means of communicating, the key messages and related considerations. In designating lead components within the mission, the value of military-to-military and police-to-police dialogue should be emphasized. Uniformed personnel often respond best to those with similar backgrounds, training and experience. This can be much more effective than having civilian staff engage armed actors, but it is critical that POC messaging be well coordinated between uniformed and civilian components of the mission.
          </p>

          <div className="template margin-top">
            <h4>
              TEMPLATE – Liaison Matrix for planning and tracking POC communications
            </h4>
            <table className="grid">
              <thead>
                <tr>
                  <td width="33%">Target audience</td>
                  <td width="33%">Activity/means</td>
                  <td width="33%">Message</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>To whom the communication is aimed</td>
                  <td>
                    What activity or means of communication will the mission use to engage/communicate with the intended audience
                  </td>
                  <td>What key messages are being imparted</td>
                </tr>
              </tbody>
            </table>

            <table className="grid">
              <thead>
                <tr>
                  <td width="33%">Comment</td>
                  <td width="33%">Mission component</td>
                  <td width="33%">Release authority</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Include any related activities, risks or issues to consider when designing or conducting the activity or message
                  </td>
                  <td>Who will lead the communication</td>
                  <td>
                    Who in the mission will authorize the action or communication
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )
    },
    {
      index: '7.4',
      name: 'Lines of Communications',
      title: t('handbook.chapters.chapter7.4.title'),
      content: (
        <>
          <div className="sub-section 7-4-1">
            <h5>
              <span className="un-blue">7.4.1</span> Key messages
            </h5>

            <p>
              Depending on the target of a POC related message and whether the message is to be public or private, the message will vary. Below are examples of standard lines/topics which could be utilized:
            </p>
            <ul className="square un-blue">
              <li>
                Provide factual and impartial information on the POC context:
                <ul className="triangle un-blue">
                  <li>
                    Current or potential threats and risks and
                  </li>
                  <li>
                    Populations and areas at risk of violence, with messages on specific protected categories of civilians: refugees, internally displaced persons, women, children, older persons, persons with disabilities, humanitarian personnel, journalists, human rights defenders, peacekeepers, etc.
                  </li>
                </ul>
              </li>
              <li>
                Insist on the need to put an end to continuing violence against civilians as an immediate priority.
              </li>
              <li>
                Emphasize the primary obligation of the national authorities (or armed groups, when international humanitarian law is applicable) to protect civilians.<br />
                (<em>Note - Should government authorities be partial in their actions or government forces commit large-scale human rights violations, the mission may favour taking a visible distance.</em>)
              </li>
              <li>
                Highlight the role of other protection actors, including local communities, political leaders, donors, the international community and humanitarian and development actors.
              </li>
              <li>
                Condemn violations of international human rights law and international humanitarian law, incidents of physical violence against civilians, threats to civilians and all incitement to violence against civilians.
              </li>
              <li>
                Promote the fight against impunity by stressing the need to bring to justice, in accordance with applicable international law, individuals who incite violence or commit crimes, gross violations of international human rights law or serious breaches of international humanitarian law.
              </li>
              <li>
                Encourage authorities and state security forces to take a firm stance on impunity, both within their ranks and by taking actions to deter or arrest perpetrators of violence.
              </li>
              <li>
                Advocate a reward and punish (i.e., ‘carrot and stick&apos;) political approach to armed groups and spoilers, supported by the establishment of clear POC-related ‘red lines&apos; (e.g., attacks against civilians, conflict-related sexual violence, expansion of territory, occupation of schools and hospitals).
              </li>
              <li>
                Underline the importance of parties to the conflict committing to political settlements and actions to transform the conflict environment and address the root causes of instability.
              </li>
              <li>
                Acknowledge, express concern about and outline mitigating measures for risks to civilians posed by peacekeepers.
              </li>
              <li>
                Present the mission&apos;s POC mandate, plans, successes and challenges:
                <ul className="triangle un-blue">
                  <li>
                    Explain the mission&apos;s approach to POC, including the whole-of-mission mandate and emphasis on prevention. Take time to explain POC activities that may not be high-profile or obvious, such as support to the rule of law or dialogue and engagement activities.
                  </li>
                  <li>
                    Manage expectations. Focus on what the mission can do (prevent and stop strategic threats, etc.) and cannot do (prevent or intervene in every situation of violence against civilians, etc.). Highlight effects rather than a list of actions.
                  </li>
                  <li>
                    Welcome external support.
                  </li>
                  <li>
                    Recognize failures and limitations and explain what is being done to address them.
                  </li>
                  <li>
                    Showcase success stories.
                  </li>
                  <li>
                    Present a people-centred approach.
                  </li>
                  <li>
                    Provide concrete and recent examples of successful joint protection actions implemented with national and local authorities or with humanitarian and development partners.
                  </li>
                </ul>
              </li>
              <li>
                Share specific messages with communities:
                <ul className="triangle un-blue">
                  <li>
                    Appreciate their views, both to help secure civilian communities in the short term and to guide the mission&apos;s efforts to address the root causes of the threats.
                  </li>
                  <li>
                    Present the mission&apos;s early warning and alert mechanisms and/or staff dedicated to community engagement (e.g., CLAs).
                  </li>
                  <li>
                    Provide examples of possible mission POC actions (e.g., the intensification patrols along certain axes, or on market days or to secure access to fields and other livelihood activities).
                  </li>
                  <li>
                    Explain human rights, child- and women-protection activities and the activities of police, justice and corrections components.
                  </li>
                </ul>
              </li>
              <li>
                Respond, where necessary and appropriate, to media broadcasts spreading hate speech or inciting violence, attacks on civilians or atrocity crimes.
              </li>
            </ul>

            <div className="relative">
              <img src={speech} alt="UNMISS hate speech" />
              <div className="description">
                <p>
                  A South Sudanese youth activist uses public radio to advocate for responsible reporting by the media. (2017)
                </p>
              </div>
              <p className="ref">UN Photo/Isaac Billy</p>
            </div>
            <AudioPlayer
              src={audio[5].file}
              title={audio[5].title}
              subtitle={audio[5].subtitle}
              avatar={audio[5].avatar}
              speaker={audio[5].speaker}
              transcript={audio[5].transcript}
            />
            <div className="voices un-blue">
              <div className="avatar">
                <img alt={audio[5].speaker} src={audio[5].avatar} />
              </div>
              <h3>VOICES FROM THE FIELD</h3>
              <div className="content">
                <h4>Messaging on POC with Armed Actors and Local Communities</h4>
                <h5>Ally Said Babu, MONUSCO UNPOL (2018–)</h5>
                <div className="half">
                  <div>
                    <p>
                      I have been serving in MONUSCO since August 2018, which is about when the current Ebola outbreak began. Where I was deployed in North Kivu, Ebola and armed groups, such as the Mai Mai and the ADF, were the biggest threats to civilians. The armed groups were actually attacking the Ebola Treatment Centres and humanitarians providing medical assistance. It was difficult to collect information about the attacks, even afterwards, to try to understand who was perpetrating the attacks and why? The Mission prioritized collecting information in this situation, and due to my experience in the field and because I speak Swahili, I was assigned to this duty.
                    </p>
                    <p>
                      In March 2019, I was deployed to the town of Butembo, where there was a lot of insecurity and attacks by the Mai Mai against Ebola facilities and health workers. The first thing I did was build my network. I focused on establishing relationships with local authorities, community leaders, the Local Protection Committees created to deal with issues concerning security and with military personnel. On any given day, I would be visiting a hospital to interview a young man suspected of being Mai Mai, speaking to local leaders about the insecurity in the area, visiting the prison to talk to detainees and meeting with armed groups at night.
                    </p>
                    <p>
                      All the while, we were working in very challenging circumstances. In an Ebola outbreak you must always
                    </p>
                  </div>
                  <div>
                    <cite>
                      &quot;The armed groups were actually attacking the Ebola Treatment Centres and humanitarians providing medical assistance.&quot;
                    </cite>
                    <p>
                      carry hand sanitizer, be careful about shaking hands or other contact and abide by early UN curfews. The risks were high. Because armed groups would not agree to meet near town or during daylight, I had to be creative. I would remove my UNPOL uniform and travel by local motor taxi at night to meet armed groups.
                    </p>
                    <p>
                      Eventually, with a lot of effort and the cooperation of local civil society, local authorities and religious leaders, we were successful in negotiating the opening of a hospital that the Mai Mai had previously closed down. We were able to explain to them the importance of fighting Ebola. Attacks in other areas decreased as well.
                    </p>
                    <p>
                      Based on my good relationship with one particular armed group, they even accepted to come in from the forest where they hid, surrender their weapons and rejoin civilian society. I connected them with our MONUSCO DDR team, and they are no longer threatening the security of civilians in the area.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sub-section 7-4-2">
            <h5>
              <span className="un-blue">7.4.2</span> Countering misinformation and disinformation
            </h5>
            <p>
              Responding to misinformation and disinformation is an important communications activity. Failing to do so leaves an information vacuum where misunderstanding and false beliefs can develop. In many mission contexts, this may augment conflict and/or pose a challenge to mission credibility. Often the distribution of mis/disinformation can be a driver of conflict and may worsen the threats to civilians if not challenged.
            </p>

            <table className="odd-even">
              <thead>
                <tr>
                  <td>MISINFORMATION</td>
                  <td>DISINFORMATION</td>
                  <td>HATE SPEECH</td>
                  <td>CRITICISM</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    False or misleading information that is not necessarily intended to deceive the recipient.
                  </td>
                  <td>
                    False or misleading information that is specifically intended to deceive the recipient in support of malicious ends.
                  </td>
                  <td>
                    Statements that are intended to demean and brutalize another, often supporting or encouraging violent actions.
                  </td>
                  <td>
                    The expression of disapproval of someone or something on the basis of perceived faults or mistakes.
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="relative float-right margin-top margin-bottom">
              <img src={twitter} alt="MINUSMA Twitter" />
              <div className="description below">
                <p>
                  MINUSMA uses its Twitter account to directly refute disinformation. (2019)
                </p>
              </div>
              <p className="ref">UN/MINUSMA</p>
            </div>

            <p>
              The POC communication and influence strategy should distinguish between mis/disinformation that is about the mission and that which is intended to provoke violence against civilians. However, both are important and the options for response are similar. All cases of suspected misinformation and disinformation should be analysed as soon as possible to determine the appropriate response.
            </p>

            <h5 className="clear">Misinformation and disinformation response options</h5>
            <table className="title-left margin-top margin-bottom">
              <tbody>
                <tr>
                  <td>Directly Refute</td>
                  <td>
                    This approach contests the details of the issue explicitly. This technique should be used sparingly, and reserved for extreme situations, when the pressing need to address the issue outweighs the amount of time and effort required to do so. Additionally, beware of the risk of legitimising a source by responding to it.
                  </td>
                </tr>
                <tr>
                  <td>Indirectly Refute</td>
                  <td>
                    This is an attempt to counter the main issues without reference to the original claims, accomplished through clear and timely communication of core themes and messages.
                  </td>
                </tr>
                <tr>
                  <td>Restraint</td>
                  <td>
                    When using restraint, the mission does not respond and therefore does not ‘fuel the fire.’ Yet remaining silent must be balanced with the risk of allowing mis/disinformation to flourish in an information vacuum. Factors to consider include the gravity of the information/allegation, the reach of the message and the potential for the message to compound other negative messages.
                  </td>
                </tr>
                <tr>
                  <td>Restrictive Measures</td>
                  <td>
                    These are efforts to deny access to or stop the spread of the mis/disinformation. This may take many forms, ranging from engaging with media sources directly, the government and/or the media platforms, to ensure that disinformation — particularly hate speech — is minimized.
                  </td>
                </tr>
                <tr>
                  <td>Conditioning/Sensitization</td>
                  <td>
                    These are measures to educate potential recipients prior to exposure to false information. This may take the form of community outreach or information campaigns to caution the local population about the nature and content of potential disinformation. Conditioning and sensitization should be conducted in line with indirect refutations of known/pre-existing incidents of mis/disinformation.
                  </td>
                </tr>
                <tr>
                  <td>Expectations Management</td>
                  <td>
                    Ensuring that all stakeholders, particularly the local community, are aware of the mission’s POC mandate and its capacity to implement that mandate is critical. Failure to live up to unreasonable expectations can negatively affect the credibility of the mission. The mission should ensure that adequate human and material resources are provided to allow the mission to engage in proactive and ongoing expectations management.
                  </td>
                </tr>
                <tr>
                  <td>Themes to Avoid</td>
                  <td>
                    <ul className="square un-blue">
                      <li>
                        Messages that may imply that the mission is the primary provider of security;
                      </li>
                      <li>
                        Messages that would impugn the impartiality of the mission (e.g., favouring one ethnic group over another);
                      </li>
                      <li>
                        Only blaming one group for threats to/attacks on civilians when there is more than one entity perpetrating violence against civilians;
                      </li>
                      <li>
                        Speculations on the outcome of human rights or other investigations;
                      </li>
                      <li>
                        The use of inappropriate or non-culturally relevant approaches;
                      </li>
                      <li>
                        Comments on the national policy of UN troop contributing countries; and n Other non-UN operations.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="relative">
              <img src={technology} alt="Technology and equipment in UNIFIL" />
              <div className="description below">
                <p>
                  UNIFIL personnel work in an operations room supporting the Mission in establishing and maintaining situational awareness. (2016)
                </p>
              </div>
              <p className="ref">UN Photo/Pasqual Gorriz</p>
            </div>
          </div>
        </>
      )
    }
  ]
};
