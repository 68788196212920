import React, { useState } from 'react';
import cn from 'classnames';
import PageWrapper from '../components/wrapper';
import Header from './components/header';
import Progress from './components/progress';
import Suggestions from './components/suggestions';
import { t } from '../services/i18n';

const Profile = () => {
  const [active, setActive] = useState('progress');
  return (
    <PageWrapper page="profile">
      <div className="profile">
        <Header />
        <div className="tabs">
          <button
            type="button"
            className={cn({ active: active === 'progress' })}
            onClick={() => setActive('progress')}
          >
            {t('profile.tabs.progress.title')}
          </button>
          <button
            type="button"
            className={cn({ active: active === 'suggestions' })}
            onClick={() => setActive('suggestions')}
          >
            {t('profile.tabs.suggestions.title')}
          </button>
        </div>
        <div className="grid">
          <Progress active={active} />
          <Suggestions active={active} />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Profile;
