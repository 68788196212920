import React from 'react';
import ProcessChartImage from '../images/111.jpeg';
import AssessmentChartImage from '../images/annex_iv_assessment.png';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Annex IV: Crisis Communications Immediate Action Plan',
  title: t('handbook.annexes.iv.title'),
  annex: '4',
  color: 'un-orange',
  content: (
    <div className="main-content">
      <p>
        The following outlines the process to address specific PoC incidents. The intent the of this process is to ensure that the Mission is able to actively communicate accurate and timely information in response to a potential, occurring or recent POC violation.
      </p>
      <p><strong>Approach</strong></p>
      <p>
        PoC Crisis response communications can be divided into 5 distinct phases:
      </p>
      <div className="indent">
        <p>
          Phase 1. <strong>Initial Assessment.</strong> Determine the significance and the severity of the event in order to determine what, if any, response is required.
        </p>
        <p>
          Phase 2. <strong>First with the Truth.</strong> While the Mission&apos;s understanding of the situation may be developing, it is important to start communications as soon as feasibly possible in order to prevent an information vacuum which may be filled with mis/disinformation.
        </p>
        <p>
          Phase 3. <strong>Coordinated Response.</strong> As further information becomes available and the Mission is able to determine a more detailed and coordinated response to the event, communications may develop further activities that are specific to the situation and the planned response.
        </p>
        <p>
          Phase 4. <strong>Post-Event Assessment.</strong> Initial communications activities should be assessed in terms of their penetration within the designated audience, as well as the initial response to the communications efforts. Realignment of themes, messages and activities may occur at this time.
        </p>
        <p>
          Phase 5. <strong>Continuation of activities.</strong> While crisis communications are a distinct element within the overall communications plan, the associated activities must continue and be incorporated into the rhythm of preexisting communications efforts.
        </p>
      </div>
      <h5>Process</h5>
      <p>
        In line with the above phases approach to crisis communications, and in order to ensure that the Mission&apos;s communications remain coherent during and in the aftermath of a crisis, the following approach will be utilized.
      </p>
      <img src={ProcessChartImage} alt="process chart" />

      <p className="underline">Phase 1 – Initial Assessment</p>
      <p>
        Point of Entry: The Mission may become aware of a potential PoC Crisis through a variety of means, including, PoC representatives, RJOCs, MDSF, media monitoring (including social media) and operational reporting. Once sufficient information is available to suggest the possibility of a PoC crisis, key PoC personnel must be informed: These include:
      </p>
      <ol type="1">
        <li>The Senior Protection of Civilians Advisor</li>
        <li>The Force Commander or FCOS</li>
        <li>The Police Commissioner</li>
        <li>The Mission Spokesperson</li>
        <li>The Mission Chief of Staff</li>
      </ol>
      <p>
        IE Assessment: Once the key personnel are informed, a decision must be made to whether or not the Mission must respond in the information environment. The trigger criteria include:
      </p>
      <ol type="1">
        <li>Is this a PoC incidence?</li>
        <li>Is it a threat to the Mission&apos;s credibility?</li>
        <li>Is it likely to garner media attention?</li>
      </ol>
      <p>
        If the answer is yes to any of the trigger criteria, the event can be plotted on the following matrix to determine if a response is required or not:
      </p>
      <img src={AssessmentChartImage} alt="assessment chart" />
      <p>
        If a response is <strong>NOT </strong>required, the situation should be monitored, and events reported through normal reporting channels. Information concerning the event should be communicated to stakeholders in case the situation escalates and requires a response.
      </p>
      <p>
        If a response <strong>IS </strong>required, a crisis communication Working Group should be convened as soon as possible.
      </p>
      <p className="underline">Phase 2 – First with the Truth</p>
      <p>
        Crisis Communications Working Group (CCWG). The CCWG is the body that determines the appropriate Mission communications response to PoC Crisis issues and will include the pertinent communications stakeholders from the Mission and its constituent components. The initial meeting is indented to develop a common understand of the situation, determine the Mission&apos;s ability to respond in the information environment, and determine the appropriate initial (pre-approved) themes and messages.
      </p>
      <p>
        The initial meeting should occur as soon as possible after it has been determined that a Mission response is required. The duration of the meeting should be as short as possible to facilitate the timely conduct of crisis communications.
      </p>

      <table className="vertical with-border">
        <thead>
          <tr>
            <td>Attendance</td>
            <td>Input</td>
            <td>Output</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TBC</td>
            <td><ul><li>TBC</li></ul></td>
            <td>
              <ul>
                <li>
                  Designation of the lead spokesperson for communications. Of note, during the initial phases of a crisis, the Mission should speak with one voice until the details of the situation have become better defines and communication guidance and direction has been propagated to stakeholders.
                </li>
                <li>
                  Initial condemnation of the attack (initial pre-approved messages)
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="underline">Phase 3 – Coordinated Response</p>
      <p>
        Once the initial communications and associated activities have been coordinated and conducted, and additional information has been garnered on the event in question, a second Crisis Communications Working Group will be held. The purpose of the second CCWG is to refine messaging and activities based on new information and conduct more in-depth coordination of future activities. Additionally, participants may be required to coordinate and deconflict activities, or to capitalize on specific capabilities/personalities. The approach to the second meeting is much more deliberate and considered than the first meeting, as it will dictate a much more definitive response to the crisis.
      </p>
      <p className="underline">Phase 4 – Post-Event Assessment</p>
      <p>
        The situation must to continuously monitored and assessed post incident with a view to ensuring that the Mission&apos;s themes, message and activities are creating the desired effects. The measures of effectiveness articulated in the main body of this document may serve as the basis of a post-conflict assessment, while considering other information that may assist. In certain circumstances, this may necessitate the establishment of additional Crisis Communications Working Groups to realign themes, messages and activities, considering both an assessment of the Mission&apos;s communications as well as any additional information that becomes available.
      </p>
      <p className="underline">Phase 5 – Continuation of Activities</p>
      <p>
        As the overall situation develops, the lessons learned, including updates and changes to messages and activities, must be incorporated and reflected in the overall PoC response plan. Where applicable, a post-event working group should be set up in order to create a common understanding of the the Mission&apos;s response to the crisis, with an emphasis on the speed, coordination, efficacy of Mission communications efforts.
      </p>
    </div>
  )
};
