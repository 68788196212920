/* eslint-disable */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import templates from '../../templates';
import { filterSectionsByPersona } from '../../helpers';
import { getIndexToDisplay } from '../../helpers/index';
import cn from 'classnames';
import { t } from '../../services/i18n';

const Suggestions = ({ cache, active }) => {
  const [redirect, setRedirect] = useState(null);

  const suggestions = useMemo(() => {
    const keys = Object.keys(cache.progress);

    return filterSectionsByPersona(templates, cache.area, cache.role)
      .reduce((sections, chapter) => {
        // Ignore the chapters without sections (Annexes)
        if (!chapter.sections || !chapter.sections.length) return sections;
        return [
          ...sections,
          ...chapter.sections
            .filter((s) => !keys.some((k) => k === s.index))
            .map((s) => ({
              color: chapter.color,
              chapter: chapter.name,
              section: `${s.index} ${s.name}`,
              index: s.index,
              name: s.name,
              link: `/?chapter=${escape(chapter.name)}&section=${s.index}`
            }))
          ];
      }, []);
  }, [cache]);

  if (redirect) return <Redirect to={redirect} />

  return (
    <div className={cn('suggestions', { active: active === 'suggestions' })}>
      <div className="hero">
        <div>
          <h3>{t('profile.tabs.suggestions.title')}</h3>
          <h4>{t('profile.tabs.suggestions.subheading')}</h4>
        </div>
      </div>
      <ul>
        { suggestions.map((suggestion, key) => {
          return (
            <li
              className={suggestion.color}
              key={key}
              role="button"
              tabIndex={0}
              onClick={() => setRedirect(suggestion.link)}
            >
              <span>{ getIndexToDisplay(suggestion.index) } { suggestion.name }</span>
              <span>{ suggestion.chapter }</span>
            </li>
          );
        }) }
      </ul>
    </div>
  )
};

Suggestions.propTypes = {
  cache: PropTypes.shape({
    progress: PropTypes.object // eslint-disable-line
  }).isRequired,
  active: PropTypes.string
};

Suggestions.defaultProps = {
  active: ''
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps)(Suggestions);
