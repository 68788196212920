import { ADD_TO_HISTORY } from './types';

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_HISTORY:
      // TODO: Add this to localstorage so it can be
      // used after a refresh/reload
      return [...state || [], action.snapshot];
    default:
      return state;
  }
};
