import React from 'react';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Annex III: POC Indicators',
  title: t('handbook.annexes.iii.title'),
  annex: '3',
  color: 'un-orange',
  content: (
    <div className="main-content full">
      <table className="directory">
        <thead>
          <tr>
            <td>#</td>
            <td>Tier</td>
            <td>Indicator</td>
            <td>Category</td>
            <td>Possible Data Source</td>
            <td>
              Possible Component Responsible for Monitoring and Reporting
            </td>
            <td>Comments</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="7" className="subhead">
              <strong>
                Expected accomplishment A: Capability and intent of potential perpetrators to commit physical violence are reduced
              </strong>
            </td>
          </tr>
          <tr>
            <td>1.1.1</td>
            <td className="green">Tier I</td>
            <td>
              <p>
                Decrease in the number of violations of cease-fire agreements by conflict parties
              </p>
              <p>
                (at national and/or local level)
              </p>
            </td>
            <td>Situation</td>
            <td>SAGE/ Unite Aware - Incidents</td>
            <td>
              <p>
                Political Affairs Force JOC
              </p>
              <p>
                (Child Protection) (SWPA)
              </p>
            </td>
            <td>Disaggregated by region and perpetrator (armed groups / State security forces)</td>
          </tr>
          <tr>
            <td>1.2.1</td>
            <td className="purple">Tier II</td>
            <td>
              <p>
                Decrease in the number of recorded casualties (violent deaths and injuries) among civilians per 100,000 population in the country
              </p>
            </td>
            <td>Situation</td>
            <td>SAGE/ Unite Aware - Incidents</td>
            <td>
              <p>
                Human Rights Force JOC
              </p>
              <p>
                POC UNMAS
              </p>
            </td>
            <td>
              <p>
                Tailor to context, specific type of agreements Disaggregated by region, perpetrator, type of incident/tactic and victim profile (including age, gender and social group)
              </p>
              <p>
                Requires appropriate and dedicated resources to be measurable
              </p>
            </td>
          </tr>
          <tr>
            <td>1.2.2</td>
            <td className="purple">Tier II</td>
            <td>
              <p>
                Decrease in the number of civilian casualties attributed to land mines and other explosive remnants of war (ERW), including IEDs, per 100,000
              </p>
              <p>
                population in the country
              </p>
            </td>
            <td>Situation</td>
            <td>SAGE/ Unite Aware - Incidents</td>
            <td>
              <p>
                Human Rights JOC POC
              </p>
              <p>
                UNMAS
              </p>
            </td>
            <td>Disaggregated by region and victim profile</td>
          </tr>
          <tr>
            <td>1.2.3</td>
            <td className="purple">Tier II</td>
            <td>Decrease in the number of mass casualty incidents of physical violence against civilians</td>
            <td>Situation</td>
            <td>SAGE/ Unite Aware - Incidents</td>
            <td>
              <p>
                Human Rights Force JOC
              </p>
              <p>
                POC
              </p>
            </td>
            <td>
              <p>
                &apos;Mass casualty&apos; incidents are defined as incidents of 100 casualties or more
              </p>
              <p>
                Disaggregated by region, perpetrator and victim profile (e.g. if specific social group)
              </p>
            </td>
          </tr>
          <tr>
            <td>1.2.4</td>
            <td className="purple">Tier II</td>
            <td>
              Decrease in the number of recorded incidents of violations of IHL and IHRL related to the rights to life and physical integrity committed against the civilian population per 100,000 population in the country
            </td>
            <td>Situation</td>
            <td>SAGE/ Unite Aware - Incidents</td>
            <td>
              <p>
                Human Rights JOC
              </p>
              <p>
                Police / Force POC
              </p>
            </td>
            <td>
              Disaggregated by region, perpetrator and victim profile
            </td>
          </tr>
          <tr>
            <td>1.2.5</td>
            <td className="purple">Tier II</td>
            <td>
              Decrease in the number of recorded victims of conflict related sexual violence, per 100,000 population in the country
            </td>
            <td>Situation</td>
            <td>SAGE</td>
            <td>
              <p>
                WPAs
              </p>
              <p>
                Human Rights
              </p>
            </td>
            <td>
              <p>
                Disaggregated by region, perpetrator, type of violation and victim profile
              </p>
              <p>
                Data must be gathered in a safe and non- harmful manner by qualified personnel only
              </p>
              <p>
                Cooperation with experts from the HCT is critical
              </p>
            </td>
          </tr>
          <tr>
            <td>1.2.6</td>
            <td className="purple">Tier II</td>
            <td>
              <p>
                Decrease in the number of grave violations against children (differentiate between verified and unverified), specifically
              </p>
              <ul>
                <p>killing or maiming</p>
                <p>recruitment or use of children as soldiers</p>
                <p>sexual violence</p>
                <p>attacks against schools and hospitals</p>
                <p>denial of humanitarian access</p>
                <p>abduction of children</p>
              </ul>
            </td>
            <td>Situation</td>
            <td>MRM database</td>
            <td>
              <p>
                Child Protection Human Rights WPAs
              </p>
              <p>
                JOC
              </p>
              <p>
                MRM Taskforce
              </p>
            </td>
            <td>
              <p>
                Disaggregated by region, perpetrator, type of violation and victim profile
              </p>
              <p>
                In line with MRM database, disaggregation by verified and non- verified information is useful
              </p>
              <p>
                Data must be gathered in a safe and non- harmful manner by qualified personnel only Cooperation with experts from the HCT is critical in this context
              </p>
            </td>
          </tr>
          <tr>
            <td>1.2.7</td>
            <td className="purple">Tier II</td>
            <td>
              <p>
                Decrease in the recorded number of civilians killed as a result of inter- or intra-communal
              </p>
              <p>
                violence per 100,000 population in the country
              </p>
            </td>
            <td>Situation</td>
            <td>SAGE/ Unite Aware - Incidents</td>
            <td>Civil Affairs Human Rights JOC</td>
            <td>
              <p>
                Disaggregated by region, perpetrator and victim profile
              </p>
              <p>
                Define inter- and intra-communal violence at mission level
              </p>
            </td>
          </tr>
          <tr>
            <td>1.2.8</td>
            <td className="purple">Tier II</td>
            <td>
              Decrease in the number of incidents of inter- or intra-communal violence
            </td>
            <td>Situation</td>
            <td>SAGE/ Unite Aware - Incidents</td>
            <td>Civil Affairs Human Rights JOC</td>
            <td>
              <p>
                Disaggregated by region, social group and conflict trigger/source
              </p>
              <p>
                Include information about changes in frequency and scale of incidents
              </p>
              <p>
                Define inter- and intra- communal violence at mission level
              </p>
            </td>
          </tr>
          <tr>
            <td>1.2.9</td>
            <td className="purple">Tier II</td>
            <td>
              Decrease in area newly contaminated by landmines, improvised explosive devices (IED) or explosive remnants of war (ERW)
            </td>
            <td>Situation</td>
            <td>SAGE/ Unite Aware - Incidents</td>
            <td>UNMAS</td>
            <td>
              <p>
                Disaggregated by region and perpetrator (if possible)
              </p>
              <p>
                Area measured in km<sup>2</sup>
              </p>
              <p>
                &apos;Newly&apos; contaminated refers to contamination during the reporting period
              </p>
            </td>
          </tr>
          <tr>
            <td>1.2.10</td>
            <td className="purple">Tier II</td>
            <td>
              <p>
                Decrease in the number of new IDPs and refugees recorded, i.e.
              </p>
              <p>
                displaced during the reporting period, as well as decrease in their proportion of the total population
              </p>
            </td>
            <td>Situation</td>
            <td>
              Humanitarian Protection Cluster (incl. IOM, OCHA and UNHCR)
            </td>
            <td>
              ODSRSG/RC/HC<br />
              (HC supported by OCHA)
            </td>
            <td>
              Disaggregated by region (of origin/displacement), gender, age and social group (if data available)
            </td>
          </tr>
          <tr>
            <td>1.2.11</td>
            <td className="purple">Tier II</td>
            <td>
              Decrease in the total number of IDPs and refugees recorded, as well as decrease in their proportion of the total population
            </td>
            <td>Situation</td>
            <td>Humanitarian Protection Cluster</td>
            <td>
              ODSRSG/RC/HC (HCT: UNHCR, IOM)
            </td>
            <td>
              Disaggregated by region (of origin/displacement), gender and social group (if data available)
            </td>
          </tr>
          <tr>
            <td>1.3.1</td>
            <td className="yellow">Tier III</td>
            <td>
              Increase in the number of armed elements that voluntarily lay down their weapons
            </td>
            <td>Situation</td>
            <td />
            <td>DDR SSR</td>
            <td>
              Disaggregated by region and perpetrator, and where applicable, within displaced camps or settlements
            </td>
          </tr>
          <tr>
            <td>1.3.2</td>
            <td className="yellow">Tier III</td>
            <td>
              Increase in the number of children released from armed groups as well as from national security forces (distinguish between verified and unverified)
            </td>
            <td>Situation</td>
            <td>MRM database</td>
            <td>Child Protection DDR</td>
            <td>
              <p>
                Disaggregated by region and perpetrator
              </p>
              <p>
                In line with MRM database, disaggregation by verified and
                non-verified information is useful
              </p>
            </td>
          </tr>
          <tr>
            <td>1.3.3</td>
            <td className="yellow">Tier III</td>
            <td>
              Increase in the size of occupied territory from which armed groups withdraw (and number of [villages/communities] affected)
            </td>
            <td>Situation</td>
            <td />
            <td>Force JOC</td>
            <td>
              <p>
                Disaggregated by region and perpetrator
              </p>
              <p>
                Measured in km<sup>2</sup>
              </p>
              <p>
                Unit of analysis to be established on a context-specific basis
              </p>
              <p>
                Timetable of when to measure to be
                established on a context-specific basis
              </p>
            </td>
          </tr>
          <tr>
            <td>1.3.4</td>
            <td className="yellow">Tier III</td>
            <td>Increase in the number of schools and hospitals previously occupied by armed groups or armed forces that are vacated (distinguish between verified and unverified)</td>
            <td>Situation</td>
            <td>MRM database</td>
            <td>Child Protection Force</td>
            <td>
              <p>
                Disaggregated by region and perpetrator
              </p>
              <p>
                In line with MRM database, disaggregation by verified and non-verified information is useful
              </p>
            </td>
          </tr>
          <tr>
            <td>1.3.5</td>
            <td className="yellow">Tier III</td>
            <td>
              <p>
                Decrease in the total area contaminated (i.e. during the reporting period) by landmines and other explosive remnants of war (ERW), including IEDs
              </p>
            </td>
            <td>Situation</td>
            <td>SAGE</td>
            <td>UNMAS</td>
            <td>Disaggregated by region Area measured in km<sup>2</sup></td>
          </tr>
          <tr>
            <td>1.3.6</td>
            <td className="yellow">Tier III</td>
            <td>Decrease in the number and proportion of persons who think that armed groups have the intention and capabilities to carry out physical violence against civilian communities</td>
            <td>Perception</td>
            <td>Perception survey</td>
            <td>
              <p>
                Civil Affairs POC
              </p>
              <p>
                External Actors
              </p>
            </td>
            <td>
              <p>
                Disaggregated by region, gender and age
              </p>
            </td>
          </tr>
          <tr>
            <td>1.3.7</td>
            <td className="yellow">Tier III</td>
            <td>Increase in the proportion of girls and women interviewed who report feeling safe to leave their house alone during the day/night</td>
            <td>Perception</td>
            <td>Perception survey</td>
            <td>
              <p>
                Civil Affairs POC
              </p>
              <p>
                External Actors
              </p>
            </td>
            <td>Disaggregated by region and age</td>
          </tr>
          <tr>
            <td colSpan="7" className="subhead">
              <strong>
                Expected accomplishment B: Capacity of the host State to protect civilians is strengthened
              </strong>
            </td>
          </tr>
          <tr>
            <td>2.1.1</td>
            <td className="green">Tier I</td>
            <td>
              Increase in the number of [initiatives] to protect civilians that are being implemented by national and sub-national governments
            </td>
            <td>Capacity</td>
            <td />
            <td>
              <p>
                Civil Affairs Political Affairs Human Rights POC
              </p>
              <p>
                SSR
              </p>
              <p>
                RoL
              </p>
            </td>
            <td>
              <p>
                Disaggregated by entity responsible for initiative and level (national, regional, local)
              </p>
              <p>
                Indicate nature of &apos;initiatives&apos;, and consider formulating individual indicators for each type or level of initiative
              </p>
            </td>
          </tr>
          <tr>
            <td>2.1.2</td>
            <td className="green">Tier I</td>
            <td>
              <p>
                Increase in the number of mechanisms created and functionally operational to prevent and address conflict-related sexual violence (at community, local and national level)
              </p>
            </td>
            <td>Capacity</td>
            <td />
            <td>
              <p>
                WPAs
              </p>
              <p>
                Human Rights SSR
              </p>
            </td>
            <td>
              <p>
                Disaggregated by region and age (adult or juvenile)
              </p>
              <p>
                Indicate nature of established mechanisms, disaggregated by responsible entity (national, regional, local)
              </p>
            </td>
          </tr>
          <tr>
            <td>2.2.1</td>
            <td className="purple">Tier II</td>
            <td>
              Increase in the number and proportion of [counties] where effective alert systems are in place
            </td>
            <td>Capacity</td>
            <td />
            <td>Civil Affairs</td>
            <td>
              <p>
                Disaggregated by region
              </p>
              <p>
                Disaggregated by the nature of &apos;mechanism&apos;
              </p>
              <p>
                Mission to determine the appropriate unit of analysis (community, county, region)
              </p>
            </td>
          </tr>
          <tr>
            <td>2.2.2</td>
            <td className="purple">Tier II</td>
            <td>
              Increase in the number and proportion of effective rapid responses taken by host authorities following alerts of protection threats, i.e. where impending or ongoing violence was stopped
            </td>
            <td>Capacity</td>
            <td />
            <td>Force Police JOC POC</td>
            <td>
              <p>
                Disaggregated by response actor (and mission component)
              </p>
              <p>
                Include details about specific alert network put in place to ensure early warning
              </p>
            </td>
          </tr>
          <tr>
            <td>2.2.3</td>
            <td className="purple">Tier II</td>
            <td>Decrease in the average time it takes host authorities to respond to alerts of protection threats</td>
            <td>Capacity</td>
            <td />
            <td>Force Police JOC POC</td>
            <td>
              <p>
                Disaggregated by region, perpetrator and victim profile
              </p>
              <p>
                Reflect on factors that influenced the response time such as access (security and mobility related) or otherwise
              </p>
              <p>
                &apos;Response time&apos; defined as the time from the alert is received to the time action is taken OR the time from the alert is received to the identified threat is neutralized
              </p>
            </td>
          </tr>
          <tr>
            <td>2.2.4</td>
            <td className="purple">Tier II</td>
            <td>Increase in the number and proportion of violent attacks against civilians that did not result in civilian casualties following rapid response to early warning</td>
            <td>Capacity</td>
            <td />
            <td>Force Police JOC POC</td>
            <td>
              Disaggregated by response actor (and mission component), region, and targeted victim profile
            </td>
          </tr>
          <tr>
            <td>2.2.5</td>
            <td className="purple">Tier II</td>
            <td>Decrease in the number of incidents of vigilantism or mob violence</td>
            <td>Situation</td>
            <td>SAGE/ Unite Aware - Incidents</td>
            <td>Human Rights Police</td>
            <td>
              Disaggregated by region, perpetrator profile and victim profile
            </td>
          </tr>
          <tr>
            <td>2.2.6</td>
            <td className="purple">Tier II</td>
            <td>Increase in the area where landmines and explosive remnants of war (ERW) have been removed by host authorities</td>
            <td>Capacity</td>
            <td />
            <td>UNMAS</td>
            <td>
              Disaggregated by region with distinction between areas where contamination may pose a threat to civilians vs. where there is no threat
            </td>
          </tr>
          <tr>
            <td>2.3.1</td>
            <td className="yellow">Tier III</td>
            <td>
              Increase in the number and proportion of cases of violations of IHL and the rights to life and physical integrity (including conflict related sexual violence and grave violations against children) that are reported to national law enforcement agencies, and which result in an arrest and prosecution
            </td>
            <td>Capacity</td>
            <td>National records</td>
            <td>
              <p>
                Human Rights Police SSR
              </p>
              <p>
                Justice Child Protection
              </p>
              <p>
                WPAs
              </p>
            </td>
            <td>
              <p>
                Disaggregated by region, perpetrator and victim profile
              </p>
              <p>
                National records need to be verified to the extent possible
              </p>
            </td>
          </tr>
          <tr>
            <td>2.3.2</td>
            <td className="yellow">Tier III</td>
            <td>
              Increase in the proportion of persons who express increased trust in the host Government to address security concerns, by 100,000 population in the country
            </td>
            <td>Perception</td>
            <td>Perception survey</td>
            <td>Civil Affairs POC</td>
            <td>Disaggregated by region and profile of respondents</td>
          </tr>
          <tr>
            <td>2.3.3</td>
            <td className="yellow">Tier III</td>
            <td>
              Increase in the proportion of members of the national security and defence forces that are vetted according to international standards and the number of perpetrators of IHL or IHRL violations that are effectively excluded from service
            </td>
            <td>Capacity</td>
            <td>National records</td>
            <td>Human Rights SSR</td>
            <td>
              <p>
                Disaggregated by region of deployment, social group and gender
              </p>
              <p>
                National records need to be verified to the extent possible
              </p>
            </td>
          </tr>
          <tr>
            <td>2.3.4</td>
            <td className="yellow">Tier III</td>
            <td>
              <p>
                Increase in the number of
              </p>
              <p>
                corrective actions taken by the host State in response to violations of IHL and IHRL committed by national security and defence forces
              </p>
            </td>
            <td>Capacity</td>
            <td>National records</td>
            <td>
              <p>
                Human Rights SSR
              </p>
              <p>
                Justice Police Force
              </p>
            </td>
            <td>
              <p>
                Disaggregated by region and perpetrator
              </p>
              <p>
                Include details of action taken, and indicate number of (i) arrests,
              </p>
              <ol type="i">
                <li>
                  investigations,
                </li>
                <li>
                  prosecutions,
                </li>
                <li>
                  condemnations,
                </li>
                <li>
                  sanctions or other disciplinary measures etc.
                </li>
              </ol>
              <p>
                National records need to be verified to the extent possible
              </p>
            </td>
          </tr>
          <tr>
            <td>2.3.5</td>
            <td className="yellow">Tier III</td>
            <td>Increase in the number and proportion of members of national security and defence forces trained in explosive hazard mitigation and management</td>
            <td>Capacity</td>
            <td>National records</td>
            <td>
              <p>
                UNMAS
              </p>
              <p>
                Police SSR
              </p>
            </td>
            <td>
              <p>
                Disaggregated by region of deployment and gender
              </p>
              <p>
                National records need to be verified to the extent possible
              </p>
            </td>
          </tr>
          <tr>
            <td>2.3.6</td>
            <td className="yellow">Tier III</td>
            <td>
              <p>
                Effective implementation of a strategic plan to improve operational and institutional capacity in the security sector, including the existence of functioning civilian oversight mechanisms
              </p>
            </td>
            <td>Capacity</td>
            <td />
            <td>
              <p>
                Political Affairs SSR
              </p>
              <p>
                Human Rights
              </p>
            </td>
            <td>Include details of plans and mechanisms</td>
          </tr>
          <tr>
            <td>2.3.7</td>
            <td className="yellow">Tier III</td>
            <td>
              Effective implementation of a mechanism to prevent the recruitment of children in the national security and defence forces
            </td>
            <td>Capacity</td>
            <td />
            <td>
              <p>
                Child Protection Human Rights Force
              </p>
              <p>
                Police SSR
              </p>
            </td>
            <td>Include details of mechanism</td>
          </tr>
          <tr>
            <td>2.3.8</td>
            <td className="yellow">Tier III</td>
            <td>
              Necessary elements are in place for building the capacity of national security and defence forces to protect civilians, including curricula, institutional architecture and training capacity
            </td>
            <td>Capacity</td>
            <td />
            <td>Human Rights Police Force SSR</td>
            <td>Include details of system</td>
          </tr>
          <tr>
            <td>2.3.9</td>
            <td className="yellow">Tier III</td>
            <td>Increase in the number of international human rights instruments ratified by the host State</td>
            <td>Capacity</td>
            <td>National records</td>
            <td>Human Rights</td>
            <td>
              <p>
                Include details of specific treaties
              </p>
              <p>
                National records need to be verified to the extent possible
              </p>
            </td>
          </tr>
          <tr>
            <td>2.3.10</td>
            <td className="yellow">Tier III</td>
            <td>
              Increase in the percentage of Universal Periodic Review (UPR) and treaty body recommendations that have been fully implemented and are enforced
            </td>
            <td>Capacity</td>
            <td>National records</td>
            <td>Human Rights</td>
            <td>
              <p>
                Disaggregated by region and the gender of beneficiaries
              </p>
              <p>
                Specify recommendations implemented
              </p>
              <p>
                National records need to be verified to the extent possible
              </p>
            </td>
          </tr>
          <tr>
            <td>2.3.11</td>
            <td className="yellow">Tier III</td>
            <td>Increase in the number of national laws that are implemented and enforced in line with international child rights standards and treaties</td>
            <td>Capacity</td>
            <td>National records</td>
            <td>Child Protection Human Rights Justice</td>
            <td>
              <p>
                Include details of national laws in question as well as the applied international standards and treaties
              </p>
              <p>
                National records need to be verified to the extent possible
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="7" className="subhead">
              <strong>
                Expected accomplishment C: Community level capacities to prevent and mitigate physical violence are enhanced
              </strong>
            </td>
          </tr>
          <tr>
            <td>3.1.1</td>
            <td className="green">Tier I</td>
            <td>
              Increase in the number and proportion of disputes that are referred by victims to dispute resolution mechanisms (traditional/informal or institutionalized/formal), and which are thereby settled peacefully
            </td>
            <td>Capacity/ Perception</td>
            <td>Perception survey?</td>
            <td>
              <p>
                Human Rights Civil Affairs Police
              </p>
              <p>
                Justice SSR
              </p>
            </td>
            <td>
              <p>
                Disaggregated by region as well as the gender and social group of parties to the dispute
              </p>
              <p>
                Include details about the nature of the &apos;mechanism&apos; (traditional/informal or institutionalized/formal) and the nature of the dispute (land, housing, property, migration etc.)
              </p>
            </td>
          </tr>
          <tr>
            <td>3.1.2</td>
            <td className="green">Tier I</td>
            <td>
              Increase in the number and proportion of [counties] where effective dispute resolution mechanisms are in place (traditional/informal or institutionalized/formal)
            </td>
            <td>Capacity</td>
            <td />
            <td>
              <p>
                Human Rights Civil Affairs Justice
              </p>
              <p>
                SSR
              </p>
            </td>
            <td>
              <p>
                Disaggregated by region
              </p>
              <p>
                Disaggregated by the&apos; nature of &apos;mechanism
              </p>
            </td>
          </tr>
          <tr>
            <td>3.1.3</td>
            <td className="green">Tier I</td>
            <td>
              Increase in the number and proportion of [counties] where women make up at least 30% of active members in dispute resolution and/or justice mechanisms (traditional/informal or institutionalized/formal) and have appropriate influence over proceedings
            </td>
            <td>Capacity</td>
            <td>
              <p>
                Perception survey?
              </p>
            </td>
            <td>
              <p>
                Human Rights Civil Affairs Gender
              </p>
              <p>
                WPAs
              </p>
            </td>
            <td>
              <p>
                Disaggregated by region
              </p>
              <p>
                Disaggregated by the nature of &apos;mechanism&apos;
              </p>
            </td>
          </tr>
          <tr>
            <td>3.2.1</td>
            <td className="purple">Tier II</td>
            <td>
              Increase in the number and proportion of [counties] where effective community based early warning/alert systems are in place
            </td>
            <td>Capacity</td>
            <td />
            <td>Civil Affairs WPAs</td>
            <td>
              <p>
                Disaggregated by region as well as social group, age and gender of membership
              </p>
              <p>
                Include information on whether early warning mechanisms include early warning indicators on CRSV
              </p>
            </td>
          </tr>
          <tr>
            <td>3.2.2</td>
            <td className="purple">Tier II</td>
            <td>
              Increase in the number and proportion of local early warning mechanisms where women make up at least 30% of active members
            </td>
            <td>Capacity</td>
            <td>
              <p>
                Perception survey?
              </p>
            </td>
            <td>Civil Affairs Gender</td>
            <td>Disaggregated by region and the size of the population serviced by the committees</td>
          </tr>
          <tr>
            <td>3.2.3</td>
            <td className="purple">Tier II</td>
            <td>
              Increase in the proportion of people who report feeling safe per 100,000 population
            </td>
            <td>Perception</td>
            <td>Perception survey</td>
            <td>Civil Affairs External Actor</td>
            <td>
              <p>
                Disaggregated by gender and age (minors, youth, adults)
              </p>
            </td>
          </tr>
          <tr>
            <td>3.2.4</td>
            <td className="purple">Tier II</td>
            <td>
              Decrease in the number and proportion of inter- or intra-communal disputes that result in violence, per 100,000 people
            </td>
            <td>Situation</td>
            <td>Perception survey?</td>
            <td>Civil Affairs</td>
            <td>
              <p>
                Disaggregated by region
              </p>
              <p>
                Define inter- and intra-communal violence at mission level
              </p>
            </td>
          </tr>
          <tr>
            <td>3.2.5</td>
            <td className="purple">Tier II</td>
            <td>
              Increase in the number and proportion of people who express an enhanced level of trust toward members of [other social groups], per 100,000 people
            </td>
            <td>Perception</td>
            <td>Perception survey</td>
            <td>Civil Affairs External Actor</td>
            <td>
              <p>
                Relevant &apos;social groups&apos; to be defined on a context-specific basis
              </p>
              <p>
                Could be measured through a composite of indicators such as: <em>Proportion who feel comfortable having someone from another ethnic group marry someone from their households</em>
              </p>
            </td>
          </tr>
          <tr>
            <td>3.3.1</td>
            <td className="yellow">Tier III</td>
            <td>
              Increase in the number and proportion of [counties] where accessible, legitimate and human rights-based transitional justice mechanisms are in place
            </td>
            <td>Capacity</td>
            <td />
            <td>Civil Affairs Human Rights Justice</td>
            <td>
              <p>
                Disaggregated by region and social group
              </p>
              <p>
                Disaggregated by the nature of &apos;mechanism&apos;
              </p>
              <p>
                Mission to determine the appropriate unit of analysis (community, county, region)
              </p>
            </td>
          </tr>
          <tr>
            <td>3.3.2</td>
            <td className="yellow">Tier III</td>
            <td>Increase in the number of violations that are referred to and effectively addressed by transitional justice mechanisms</td>
            <td>Capacity</td>
            <td>Perception survey?</td>
            <td>Human Rights Civil Affairs Justice</td>
            <td>Disaggregated by region and victim profile</td>
          </tr>
          <tr>
            <td>3.3.3</td>
            <td className="yellow">Tier III</td>
            <td>
              Increase in the proportion of civilians who report good knowledge of, access to and trust in transitional justice mechanisms
            </td>
            <td>Perception</td>
            <td>Perception survey</td>
            <td>Human Rights Civil Affairs Justice</td>
            <td>Disaggregated by region and victim profile</td>
          </tr>
          <tr>
            <td>3.3.4</td>
            <td className="yellow">Tier III</td>
            <td>
              Increase in the proportion of daily activities that have resumed (e.g. markets that have re-opened; agricultural fields again being tended to; school enrollment)
            </td>
            <td>Situation</td>
            <td>SAGE/ Unite Aware - Incidents</td>
            <td>Civil Affairs</td>
            <td>
              <p>
                Disaggregated by region and social groups
              </p>
              <p>
                Missions to determine the most relevant measure of daily activities
              </p>
            </td>
          </tr>
          <tr>
            <td>3.3.5</td>
            <td className="yellow">Tier III</td>
            <td>
              Increase in the number and proportion of [counties] where the civilian population has received mine risk education (MRE)
            </td>
            <td>Capacity</td>
            <td>Protection Cluster</td>
            <td>UNMAS</td>
            <td>Disaggregated by region, social group, gender and age</td>
          </tr>
          <tr>
            <td>3.3.6</td>
            <td className="yellow">Tier III</td>
            <td>Increase in the number and proportion of refugees that have returned voluntarily following external displacement</td>
            <td>Situation</td>
            <td>UNHCR</td>
            <td>
              <p>
                ODSRSG/RC/HC
              </p>
              <p>
                (HC supported by OCHA)
              </p>
            </td>
            <td>Disaggregated by region, social group, gender and age</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
};
