import { SET_COOKIES } from './types';

const initialState = {
  enabled: !!localStorage.getItem('unp_cache'),
  chosen: !!localStorage.getItem('unp_cache')
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COOKIES:
      return { enabled: action.status, chosen: true };
    default:
      return state;
  }
};
