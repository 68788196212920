import i18n from 'i18n-js';
import { memoize } from 'lodash';
import English from './locale/en.json';
import French from './locale/fr.json';

const translationGetters = {
  en: () => English,
  fr: () => French
};

const getTranslation = memoize(
  (key, config) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key)
);

const translate = (key, config) => getTranslation(key, config);
const t = translate;

const setI18nConfig = (languageTag = 'en') => {
  // fallback if no available language fits
  i18n.defaultLocale = languageTag;
  i18n.locale = languageTag;
  i18n.fallbacks = true;

  // clear translation cache
  if (getTranslation.cache.clear) {
    getTranslation.cache.clear();
  }

  const getter = translationGetters[languageTag];
  // set i18n-js config
  i18n.translations = { [languageTag]: getter() };
  i18n.locale = languageTag;
};

export { translate, t, setI18nConfig };
