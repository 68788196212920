import React from 'react';
import destroyed from '../images/chapters/chapter1/MONUSCO_kItshanga_town_center_destoryed.jpg';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 1: Introducing POC',
  title: t('handbook.chapters.chapter1.title'),
  index: '1',
  color: 'un-blue',
  sections: [
    {
      index: '1.1',
      name: 'POC on the agenda of the Security Council',
      title: t('handbook.chapters.chapter1.1.title'),
      access: [{ role: 'civilian', area: 'leadership' }],
      content: (
        <>
          <p>
            The protection of civilians (POC) by United Nations (UN) peacekeeping operations is only one facet of the wider POC agenda at the UN. The protection of civilians in armed conflict has been on the agenda of the UN Security Council in its current form since 1999 when the Council adopted its first thematic resolution on the subject<sup>1</sup>, recognizing the protection of civilians as essential to the maintenance of international peace and security. Beginning with that first resolution, the Security Council has focused on complementary objectives: enhancing compliance with applicable international law and relevant Council decisions in the conduct of hostilities; facilitating access to humanitarian assistance; protecting forcibly displaced persons, women and children; providing protection through UN peace operations; and responding to violations through targeted measures and the promotion of accountability<sup>2</sup>.
          </p>
          <p>
            The approach of the Security Council to the protection of civilians in armed conflict has included: engaging duty bearers under international human rights law and international humanitarian law; developing and promoting compliance, monitoring and accountability mechanisms; and using the range of tools available in the UN system to support and promote the protection of civilians. The POC agenda also sits alongside complementary programmes and mandates such as the promotion and protection of human rights, children and armed conflict, and women, peace and security, including conflict-related sexual violence.
          </p>
          <p>
            The protection of civilians by UN peace operations is therefore part of the Security Council’s broader agenda on POC, which utilizes a range of different tools and approaches. The role of peace operations on POC is set out in both thematic and country-specific Security Council resolutions and is further defined and elaborated in the Department of Peace Operations (DPO) Policy on The Protection of Civilians in United Nations Peacekeeping<sup>3</sup> <span className="un-blue bold">A key distinguishing element of the POC mandate in peacekeeping is the authorization given to peacekeeping operations to use all necessary means, up to and including deadly force, to protect civilians under threat or imminent threat of physical violence<sup>4</sup></span>. In its approach to POC in peacekeeping, the Security Council has also mandated the protection of civilians by certain peacekeeping operations in situations where there is no active armed conflict, recognizing the preventive role that peace operations can play in the protection of civilians.
          </p>
          <p>
            In planning their approach to POC, it is therefore useful for peacekeeping operations to understand and to be aware of this broader context and to consider the need to complement and align with other ongoing UN approaches and activities that contribute to the implementation of the Security Council&apos;s POC agenda. These may include, for example: sanctions regimes; monitoring, reporting and accountability mechanisms; the provision of humanitarian assistance; protection activities by UN agencies, funds and programmes including protection of refugees and internally displaced persons; the protection and promotion of human rights; support for the rule of law; and political and peacebuilding activities.
          </p>
        </>
      )
    },
    {
      index: '1.2',
      name: 'POC in UN Peacekeeping',
      title: t('handbook.chapters.chapter1.2.title'),
      content: (
        <>
          <p>
            Since the first POC-mandated peacekeeping mission, the United Nations Assistance Mission in Sierra Leone (UNAMSIL) in 1999<sup>5</sup>, UN peacekeeping operations have been confronted with a broad range of threats to civilians and increasingly complex operational environments. As the nature of peacekeeping has evolved, so has the POC mandate and the tasks, activities, roles and responsibilities necessary to implement it. At the same time, the high expectations on missions to protect civilians present risks to the UN&apos;s credibility where these expectations are not met or managed effectively.
          </p>
          <div className="half">
            <div>
              <p>
                Initially faced with limited standardized guidance, specialized staff or dedicated tools for the protection of civilians, UN peacekeeping operations progressively developed their own approaches to POC — strategies, capacities, mechanisms and tools. Much of this has since been captured in policy, guidance and training, and is continuously developed and built upon through ongoing sharing of best practices and lessons learned. A continual learning process that captures mission-specific POC challenges, lessons learned and good practices is required to adapt to changing environments.
              </p>
              <p>
                Contemporary approaches to POC involve and rely on many other aspects of mission planning and other mandated activities. In particular, POC requires strategic political engagement and proactive and preventive action and relies upon effective integrated planning and coordination including with the host state, humanitarians, civil society and the affected communities. This contributes to ensuring a comprehensive approach.
              </p>
            </div>
            <div>
              <table className="striped bold">
                <thead>
                  <tr>
                    <th colSpan="2">UN Peacekeeping Operations with POC Mandates</th>
                  </tr>
                </thead>
                <tbody className="second-aligned-right">
                  <tr>
                    <td>UNAMSIL (Sierra Leone)</td>
                    <td>1999 - 2005</td>
                  </tr>
                  <tr>
                    <td>MONUC (DRC)</td>
                    <td>1999 - 2010</td>
                  </tr>
                  <tr>
                    <td>UNMIL (Liberia)</td>
                    <td>2003 - 2018</td>
                  </tr>
                  <tr>
                    <td>ONUB (Burundi)</td>
                    <td>2004 - 2006</td>
                  </tr>
                  <tr>
                    <td>UNOCI (Côte d’Ivoire)</td>
                    <td>2004 - 2017</td>
                  </tr>
                  <tr>
                    <td>MINUSTAH (Haiti)</td>
                    <td>2004 - 2017</td>
                  </tr>
                  <tr>
                    <td>UNMIS (Sudan)</td>
                    <td>2005 - 2011</td>
                  </tr>
                  <tr>
                    <td>UNIFIL (Lebanon)</td>
                    <td>2006 -</td>
                  </tr>
                  <tr>
                    <td>UNAMID (Darfur)</td>
                    <td>2007 -</td>
                  </tr>
                  <tr>
                    <td>MINURCAT (Chad)</td>
                    <td>2009 - 2010</td>
                  </tr>
                  <tr>
                    <td>MONUSCO (DRC)</td>
                    <td>2010 -</td>
                  </tr>
                  <tr>
                    <td>UNMISS (South Sudan)</td>
                    <td>2011 -</td>
                  </tr>
                  <tr>
                    <td>UNISFA (Abyei)</td>
                    <td>2011 -</td>
                  </tr>
                  <tr>
                    <td>MINUSMA (Mali)</td>
                    <td>2013 -</td>
                  </tr>
                  <tr>
                    <td>MINUSCA (CAR)</td>
                    <td>2014 -</td>
                  </tr>
                  <tr>
                    <td>MINUJUSTH (Haiti)</td>
                    <td>2017 - 2019</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className="un-blue bold">
            The protection of civilians mandate in UN peacekeeping is defined as: &apos;without prejudice to the primary responsibility of the host state, integrated and coordinated activities by all civilian and uniformed mission components to prevent, deter or respond to threats of physical violence against civilians, within the mission&apos;s capabilities and areas of deployment, through the use of all necessary means, up to and including deadly force.&apos;
          </p>
        </>
      )
    },
    {
      index: '1.3',
      name: 'Rationale, Scope and Purpose',
      title: t('handbook.chapters.chapter1.3.title'),
      content: (
        <>
          <p>
            This handbook complements and further builds upon official policy and guidelines on POC in UN peacekeeping (see references at the end of each chapter). It seeks to aid the operationalization of policy and doctrine and guide all peacekeeping personnel (civilian and uniformed) to effectively implement the mandate to protect civilians. The guidance provided here applies at the strategic, operational and tactical levels.
          </p>

          <section className="background">
            <p>
              The management of a peacekeeping operation at United Nations Headquarters and at the level of mission headquarters (the Head of Mission and Mission Leadership Team) is considered to be the <strong>strategic level </strong>of authority, command and control.
            </p>
            <p>
              The field-based management of a peacekeeping operation at the mission headquarters is considered to be the <strong>operational level</strong>. Depending on the mission configuration, operational level can also extend to the regional or sector level.
            </p>
            <p>
              The management of military, police and civilian operations below the level of mission headquarters, as well as the supervision of individual personnel, is considered to be at the <strong>tactical level </strong>and is exercised at various levels by subordinate commanders of military and police components and designated civilian heads at levels below the mission headquarters.
            </p>
          </section>

          <p>
            This handbook provides mission personnel with tools and techniques to interpret and implement POC mandates within the particular context of their missions:
          </p>

          <div className="flow">
            <div className="node">
              To promote the primary responsibility of the host state to protect civilians
            </div>
            <div className="line" />
            <div className="node">
              To enhance strategic and operational planning and coordination
            </div>
            <div className="line" />
            <div className="node">
              To contribute to ensuring readiness, and to shape and implement protection-sensitive tactical operations
            </div>
          </div>

          <p>
            Designed to acknowledge and address the multiplicity of operating contexts and challenges faced by peacekeeping operations, this handbook aims to provide concrete and modular response options for civilian, police and military peacekeepers to consider when analysing and addressing POC threats. It is based on the foundation that POC relies on integrated mission processes and systems, including analysis,<sup>6</sup> strategic decision-making, planning, communication, engagement and dialogue, operations, crisis management and training. This handbook emphasizes the whole-of-mission approach to POC, and thus is not structured around mission components or type of personnel. The objective is for mission personnel to understand the importance of working together using a range of tools and techniques to protect civilians.
          </p>
          <p>
            The handbook does not replace formal DPO doctrine, including existing policies, guidelines and standard operating procedures (SOPs). It consolidates existing good practices, methodologies, models, templates, checklists and other tools. The guidance and templates contained herein can be elaborated and adjusted as necessary to suit the particular context and needs of a mission.
          </p>
          <p>
            Finally, the handbook is designed as a self-reference tool, but it does not replace the Core Pre-Deployment Training, Comprehensive Protection of Civilians Training, scenario-based training or mentoring on POC.
          </p>
          <div className="relative">
            <img src={destroyed} alt="Kitshanga town center destroyed" />
            <div className="description">
              <p>
                Following clashes between government forces and a militia, a town in the Democratic Republic of the Congo (DRC) is destroyed, with around 90 people killed and more than 5,000 displaced. (2015)
              </p>
            </div>
            <p className="ref">UN Photo/Sylvain Liechti</p>
          </div>
        </>
      )
    },
    {
      index: '1.4',
      name: 'Content',
      title: t('handbook.chapters.chapter1.4.title'),
      content: (
        <>
          <p>
            This handbook is structured in six parts:
          </p>
          <p>
            <span className="boxed un-blue">Part I</span> <strong>(Introduction to the POC Context)</strong> provides guidance on how to interpret the POC mandate given to a mission by the UN Security Council, including key mandate language. It also identifies contextual and systemic constraints that may hamper missions in implementing their POC mandates and suggests how these constraints may be addressed, circumvented or overcome.
          </p>
          <p>
            <span className="boxed un-blue">Part II</span> <strong>(Leading, Planning, Coordination and Strategic Communication)</strong> provides guidance for mission leaders and managers on ensuring that the protection of civilians is at the heart of a mission&apos;s political strategy, providing strategic vision, deciding on resource allocation and mainstreaming POC in strategic planning and performance processes. It further suggests how to establish POC coordination mechanisms at all levels, both within a mission and with humanitarian actors and host authorities, for information sharing and analysis, planning, decision making, implementation and monitoring and reporting of POC activities. This part also provides guidance on how to develop a communication and influence plan on POC, which will include dialogue, engagement, public information and advocacy in support of a mission&apos;s political approach to POC.
          </p>
          <p>
            <span className="boxed un-blue">Part III</span> <strong>(Identifying and Prioritizing POC Threats and Risks)</strong> provides guidance for civilian (substantive and support), police and military components on how to identify and prioritize POC threats and risks for the purpose of POC planning and action. It suggests a process and factors to be taken into account for a mission to identify and prioritize POC threats and risks as well as tools to be used to facilitate that process. Missions can and should adapt these tools to meet their particular circumstances and requirements. This part also provides guidance for civilian, police and military components on how to engage with communities to understand protection threats faced by the population to inform the mission approach to POC and support local protection efforts.
          </p>
          <p>
            <span className="boxed un-blue">Part IV</span> <strong>(Operational Readiness and Response)</strong> provides guidance on how to integrate POC into mission operational planning, preparedness and coordination as well as the planning of security operations, including training and coordination with civilian, police and military components. It provides guidance and options on how peacekeeping missions can utilize and balance different options available to respond to threats to civilians.
          </p>
          <p>
            <span className="boxed un-blue">Part V</span> <strong>(Key Advice)</strong> provides guidance on how to ensure a gendered approach to POC, as well as recommendations for POC advisers on how to proactively advise and support mission leaders, including on preparedness, planning and coordination of activities and operations to protect civilians.
          </p>
          <p>
            <span className="boxed un-blue">Part VI</span> <strong>(Scenarios and Annexes)</strong> provides guidance on responding when civilians are threatened, with simple scenarios followed by a list of Do&apos;s and Don&apos;ts. The Annexes provide an acronym list, as well as templates, SOPs and terms of reference (TORs) for tools and mechanisms to facilitate implementation of the POC mandate, for missions to adapt to their own needs and resources.
          </p>
        </>
      )
    },
    {
      index: '1.5',
      name: 'Target Audience',
      title: t('handbook.chapters.chapter1.5.title'),
      content: (
        <>
          <p>
            Any and all personnel in UN peacekeeping operations should benefit from this handbook to understand the POC mandate and its implementation. Everyone in peacekeeping has a role in protecting civilians and is responsible for that protection. <strong className="un-blue">Each chapter should be relevant to all peacekeeping personnel</strong>, but may be particularly relevant to those serving in specific roles, as described in the &apos;who&apos; section at the beginning of each chapter.
          </p>
        </>
      )
    }
  ],
  footnotes: {
    content: (
      <ol type="1" className="refs">
        <li className="1-1">S/RES/1265 (1999).</li>
        <li className="1-1">
          For further detail see OCHA Occasional Policy Paper: ‘<em>Building a Culture of Protection: Twenty Years of Security Council Engagement on the Protection of Civilians</em>&apos;, May 2019. Available at: <a href="https://www.unocha.org/sites/unocha/files/Building%20a%20culture%20of%20protection.pdf">https://www.unocha.org/sites/unocha/files/Building%20a%20culture%20of%20protection.pdf.</a>
        </li>
        <li className="1-1">
          DPO Policy on the Protection of Civilians in United Nations Peacekeeping (2019.17).
        </li>
        <li className="1-1">
          Where this Handbook refers to &apos;the POC mandate&apos;, it means the general concept of the POC mandate in peacekeeping as set out by Security Council resolutions and DPO policy, and the specific mandate of an individual peacekeeping mission to protect civilians.
        </li>
        <li className="1-1">S/RES/1270 (1999).</li>
        <li className="1-3">
          This Handbook makes no principled distinction between ‘analysis&apos; and ‘assessment&apos;. Missions that do should consider in each instance which is required, and which mission actor is best placed to perform the function.
        </li>
      </ol>
    )
  }
};
