import React from 'react';
import mission from '../images/chapters/chapter14/joint_mission_MONUSCO.jpg';
import AudioPlayer from '../components/audio';
import audio from './audio';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 14: Advising on POC',
  title: t('handbook.chapters.chapter14.title'),
  index: '14',
  color: 'un-green',
  content: (
    <>
      <div className="grid-three un-green">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            Protection of Civilians Advisers should be deployed in all missions with POC mandates and with a direct reporting line to the Head of Mission.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            POC Advisers support the development of strategies and the mainstreaming of POC throughout the mission.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            Advising on POC entails proactively advising and supporting mission leaders, ensuring well-defined POC operations and impact, promoting internal and external cooperation and coordination, and continually building knowledge and skills.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            Advising on POC is undertaken by POC Advisers and Focal Points, in support of mission leadership.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            Advising on POC is primarily undertaken at mission headquarters, as well as in mission field offices.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            Advising on POC begins at mission start-up and continues throughout the mission’s lifecycle. This advice is particularly important when preparing the mission’s political and POC strategies and planning POC activities and operations, as well as when a mission is faced with a POC crisis.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '14.1',
      name: 'Advice and Support',
      title: t('handbook.chapters.chapter14.1.title'),
      content: (
        <>
          <p className="un-green bold">
            Proactively advise and support mission leaders, do not replace them.
          </p>
          <ul className="square un-green">
            <li>
              Remember the SRSG is ultimately responsible for and the owner of the POC strategy, not the POC Adviser. Act in support of mission leaders and managers, to guide and encourage.
            </li>
            <li>
              Wherever the POC Adviser is embedded, the Adviser&apos;s role is to support all mission leaders and managers towards POC success, maintaining a view to promoting the POC strategic approach.
            </li>
            <li>
              Focus on the mission&apos;s overall interest, not the interest of the pillar, office or unit alone.
            </li>
            <li>
              Align all actions with those of the designated POC lead amongst mission leadership. Regularly update and accompany the POC lead, and clear important moves and initiatives with the POC lead. This will provide more weight and momentum to POC actions.
            </li>
            <li>
              Liaise with internal and external high-level leaders in consultation with and with the consent of the POC lead. Do not risk jeopardizing relations with the POC lead and creating confusion by acting independently.
            </li>
            <li>
              Stay particularly connected to all special assistants, advisers and other staff working alongside mission leaders and managers, as well as those of external partners (ministries, embassies, UN agencies, etc.).
            </li>

            <li>
              Establish a liaison matrix with all leadership offices to clarify who within the mission will lead relations with each external actor, and therefore enable mission leaders to guide and control engagement.
            </li>
            <li>
              Prepare and regularly update standard and specific talking points for all key internal and external engagements on POC. Beyond the POC lead, also share these with other leaders and managers, preferably through their assistants and advisers.
            </li>
          </ul>
        </>
      )
    },
    {
      index: '14.2',
      name: 'Operations and Impact',
      title: t('handbook.chapters.chapter14.2.title'),
      content: (
        <>
          <p className="un-green bold">
            Focus on POC operations and impact, rather than process.
          </p>
          <ul className="square un-green">
            <li>
              Developing the POC strategy, contributing to strategic reporting and accomplishing or taking part in other processes as a member of the Office of the SRSG or the Office of the DSRSG will take time. The primary day-to-day focus of the POC Adviser should aim to support the POC lead on preparedness, planning and coordination of POC operations.
            </li>
            <li>
              Focus efforts on finding solutions to strategic POC threats (outcome and impact), while supporting tactical-level colleagues in addressing ongoing situations and more imminent threats, including through POC Officers and POC technical group members.
            </li>
            <li>
              Conduct regular visits to the field. This is the best way to stay connected with actualities and conditions; remember the purpose of POC and support operations. Encourage the POC lead to do the same, even for short visits, every few weeks. Involve, accompany or mobilize other mission leaders and managers, as well as host authorities and civil society/humanitarian actors, when possible.
            </li>
            <li>
              Support the bottom-up flow of both information and recommendations on situations; triangulate and share these with Protection Working Group (PWG) members, mission leaders and managers, as required. Provide feedback and share information with field offices on all POC-related topics — Heads of Offices are further removed from mission and UN headquarters and they need background information on the operational or strategic dynamics on POC to operate effectively. In turn, they will recognize the efforts and involve/share with the POC Adviser more.
            </li>
            <li>
              As much as possible, leverage process and non-kinetic activities (development of the strategy, high-level visits, SG report, etc.) in support of POC operational objectives.
            </li>
            <li>
              Make use of training sessions and workshops as opportunities for colleagues to improve operations, while providing them with on-the-job support and guidance, together with mission leaders, managers and/or PWG members. Formats can include:
              <ul className="triangle un-green">
                <li>
                  Exercises on real situations, including TTXs or workshops to develop or review tactical POC plans per threat; and
                </li>
                <li>
                  Training events that include a workshop aspect, to create a mutual-learning environment for/from trainees and improve guidance and processes (review tactical POC handbook, local POC-related operational plans, coordination and early warning mechanisms, etc.).
                </li>
              </ul>
            </li>
            <li>
              Ensure all programmatic and planning activities undertaken by the mission and other UN Country Team members are integrating POC indicators. Every UN programmatic section and agency could report periodically against those indicators in line with the Integrated Assessment and Planning Policy and Guidelines.
            </li>
            <li>
              Keep a log of POC actions and operations, failures and successes, to be shared as required.
            </li>
            <li>
              Promote joint civilian-police-military capacity or efforts to capture lessons learned from various POC operations and/or undertake AARs and capture best practices, which can be shared with UN Headquarters and other missions. They should also regularly be inserted into pre-deployment and in-mission training modules. As POC is most successful with a context-specific approach, rotating personnel and staff require a detailed knowledge of the terrain to ensure readiness.
            </li>
          </ul>
        </>
      )
    },
    {
      index: '14.3',
      name: 'Cooperation and Coordination',
      title: t('handbook.chapters.chapter14.3.title'),
      content: (
        <>
          <p className="un-green bold">
            Promote internal and external cooperation and coordination.
          </p>
          <ul className="square un-green">
            <li>
              Focus coordination actions and forums on the specific POC outcomes or impacts for which they are intended. Although the direct focus may be consensus building and support for the mission&apos;s POC actions, this will be better accomplished by stressing desired outcomes or impacts and proposing cooperation towards achieving the desired effect.
            </li>
            <li>
              Prioritize feedback and follow-up on commitments. Teamwork attitudes are contagious and generate success.
            </li>
            <li>
              Mobilize others to achieve POC success, which cannot be achieved alone. As much as possible, inform and involve mission leaders, managers, PWG members, field colleagues and partners in all endeavours.
            </li>
            <li>
              Reinforce appropriate processes and respect roles and responsibilities. Do not duplicate the responsibilities of others — rather support them and promote their leaders. Particularly, support JMAC to ensure POC analysis, support JOC to ensure the inclusion of POC-related developments in ad hoc and integrated reporting and the facilitation of coordinated operations, respect the roles of human rights, child protection, women&apos;s protection, police and justice colleagues in monitoring and investigating violations, etc.
            </li>
            <li>
              Dedicate time to the military and police, including the respective Chiefs of Staff, DCOS OPS, and planning, intelligence and operations officers. Assist them in understanding and liaising with civilian components, communities and other external actors, including through the liaison matrix.
            </li>
          </ul>

          <div className="relative">
            <img src={mission} alt="Joint mission MONUSCO" />
            <div className="description">
              <p>
                MONUSCO’s Force and Civil Affairs components undertake a joint mission to assess the security situation and engage the local administration, population and national police. (2019)
              </p>
            </div>
            <p className="ref">UN Photo/MONUSCO</p>
          </div>

          <ul className="square un-green">
            <li>
              Promote the link between mission headquarters and field offices:
              <ul className="triangle un-green">
                <li>
                  Do not decide on specific areas and situations for POC focus. Rather, support the responsibility of Heads of Offices and field commanders and ensure regular communication with them (through established and/or informal channels).
                </li>
                <li>
                  Promote and support direct engagement between field offices and mission leaders and managers (via VTCs, phone calls, field visits, Heads of Offices conferences, etc.).
                </li>
              </ul>
            </li>
            <li>
              Promote cooperation between mission and external actors.
              <ul className="triangle un-green">
                <li>
                  Become familiar with the relevant protection actors on the ground. Reach out to them and coordinate, to the extent appropriate, the sharing of protection analysis and, more broadly, information sharing.
                </li>
                <li>
                  To ensure sustainable impact, involve national civil society and host state actors, as appropriate, in regular internal meetings and trainings. Should the context warrant it, establish dedicated mechanisms or take part in their mechanisms (state security committee, joint operations centres, civil-military coordination forums, etc.). Conduct joint trainings with other actors for state security forces and relevant ministry staff.
                </li>
              </ul>
            </li>
          </ul>
          <AudioPlayer
            src={audio[10].file}
            title={audio[10].title}
            subtitle={audio[10].subtitle}
            avatar={audio[10].avatar}
            speaker={audio[10].speaker}
            transcript={audio[10].transcript}
          />
          <div className="voices un-green">
            <div className="avatar">
              <img alt={audio[10].speaker} src={audio[10].avatar} />
            </div>
            <h3>VOICES FROM THE FIELD</h3>
            <div className="content">
              <h4>Establishing POC in a Start-Up Mission</h4>
              <h5>Damian Lilly, UNMISS Senior Protection of Civilians Adviser (2012-2014)</h5>
              <div className="half">
                <div>
                  <p>
                    In January 2012, I joined UNMISS as the Senior Protection of Civilians (POC) Adviser to the SRSG. The mandate of UNMISS was only six-months old and the Mission was still in start-up phase when I arrived. The Mission was immediately thrown into a POC crisis with the outbreak of intercommunal violence in Jonglei state, and POC quickly became the priority of UNMISS. As the Senior POC Adviser, I was responsible for working with the different components and sections to establish the POC architecture of the Mission. Senior leadership set the tone by clearly articulating that protecting civilians was a key mission-wide responsibility.
                  </p>
                  <p>
                    I spent a significant amount of time with the civilian, police and military components explaining the POC operational concept and their respective roles to implement it. The POC Working Group provided the forum to translate this mandated task into an operational reality. I facilitated the process of developing UNMISS’ POC strategy, which prioritized those threats to civilians to which the Mission would respond with key interventions. We then rolled-out POC trainings for all relevant personnel in UNMISS bases across the country. I was also able to develop coordination mechanisms with humanitarian actors within the context of the Protection Cluster.
                  </p>
                  <p>
                    A key challenge we faced early on in the Mission was the scenario of civilians seeking protection at UNMISS bases. By 2012, it was a regular occurrence when violence
                  </p>
                </div>
                <div>
                  <cite>
                    &quot;By 2012, [civilians seeking protection at UNMISS bases] was a regular occurrence when violence broke out. Given the frequency of such instances, I was tasked by the SRSG with developing guidance for such situations.&quot;
                  </cite>
                  <p>
                    broke out. Given the frequency of such instances, I was tasked by the SRSG with developing guidance for such situations. This led to a contingency planning process for bases to prepare POC sites. With the outbreak of armed conflict in late 2013, the preparation of these POC sites proved crucial when civilians started arriving at UNMISS bases in large numbers across the country.
                  </p>
                  <p>
                    The key lesson I took from this experience was the importance of senior leadership setting POC as a priority. Additionally, it was crucial to work with all parts of the Mission to ensure that they knew their responsibilities in fulfilling the mandate and how their action contributed to a wider Mission goal. While UNMISS faced extraordinary operational challenges, it was important that this strategic vision was conveyed, and key systems put in place to implement the POC mandate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      index: '14.4',
      name: 'Continuous Learning',
      title: t('handbook.chapters.chapter14.4.title'),
      content: (
        <>
          <p className="un-green bold">
            Aim to improve the quality of work and build knowledge and skills.
          </p>
          <ul className="square un-green">
            <li>
              Seek feedback and inputs from mission leaders and managers on the support provided or required from the POC Adviser and POC team.
            </li>
            <li>
              Support and promote lessons learned, AARs, institutional memory processes and innovation.
            </li>
            <li>
              Build knowledge and skills, particularly of:
              <ul className="triangle un-green">
                <li>
                  Concepts and legal frameworks (IHL, IHRL, refugee law, IDP Guiding Principles, child protection, WPS, mass atrocity prevention, humanitarian protection, etc.);
                </li>
                <li>
                  Protection-related mechanisms at the strategic level: UN Security Council (POC, CAAC and WPS debates, the Informal Experts Group on POC, sanctions regimes and mechanisms), ICC, Human Rights up Front;
                </li>
                <li>
                  Humanitarian actors with a key role in protection;
                </li>
                <li>
                  DPO (and DOS) policies, guidelines and SOPs, as well as DPET&apos;s activities; and
                </li>
                <li>
                  Professional skills such as conflict resolution, planning, advocacy, capacity building, etc.
                </li>
              </ul>
            </li>
          </ul>
          <p className="bold">
            See Annex I for TORs of Mission Senior POC Advisers.
          </p>
        </>
      )
    }
  ]
};
