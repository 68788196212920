import React from 'react';
import PageWrapper from '../components/wrapper';
import Header from './components/header';
import android from '../images/design/android.png';
import ios from '../images/design/ios.png';
import desktop from '../images/design/desktop.png';

const About = () => (
  <PageWrapper page="about">
    <div className="about">
      <Header />
      <div className="row">
        <div className="row__item info">
          <p>All of the content in this app comes from the Protection of Civilians in United Nations Peacekeeping Handbook.</p>
          <div className="foreword">
            <h3>What is the purpose of this app?</h3>
            <p>
              This app intends to provide UN peacekeeping personnel with the most practical information and tools for implementing a protection
              of civilians mandate, based on the user’s general role within a peacekeeping mission. It is meant to make the content in the Protection
              of Civilians in United Nations Peacekeeping Handbook more accessible, interactive, and easy-to-reference.
            </p>
            <p>
              The app features reading content for 12 different types of peacekeeping personnel, based on personnel type (by civilian, police, or military)
              and work functionality (leadership, communications, planning, or general). At any time, a user can change the featured reading content by going
              to the user profile tab and inputting new information for personnel type and functionality.
            </p>
            <p>
              The app also contains the full version of the Handbook, Voices from the Field in audio format, and a resource tab where templates, toolkits,
              case studies, chapter summaries, and annexes can be quickly accessed.
            </p>
            <p>
              The app can be used online or offline on a smartphone, tablet, or computer. In areas of low bandwidth, users can choose to automatically
              eliminate photos from the text.
            </p>
            <h3>Who should use this app?</h3>
            <p>
              While this app was designed for UN civilian, police, and military personnel in peacekeeping missions, it has been made publicly available so that
              everyone interested in the role of peacekeepers in protecting civilians can make use of it. This could include UN diplomats, state politicians,
              foreign service officers, national military forces, local police, academic and policy researchers, crisis response teams, and humanitarian and
              community organizations.
            </p>
          </div>
          <div>
            <h3>How to install the app on your device?</h3>
            <p>If you are browsing using the app on your mobile device, you may be prompted if you want to add the app to your home screen.</p>
            <p>However, if you do not see an install prompt straight away, simply follow these steps to quickly install the app to your mobile device.</p>
            <p><strong>Installing on Android devices:</strong></p>
            <ol>
              <li>Tap the three-dot menu in the top-right corner</li>
              <li>Select `Add to home screen`.</li>
              <li>You may be asked to enter a name for the app before adding it to your home screen.</li>
              <li>The app will show up on your home screen like a normal mobile app.</li>
            </ol>
            <img className="mobile-screenshot" src={android} alt="Logo" />
            <p><strong>Installing on iOS devices:</strong></p>
            <ol>
              <li>Tap the ‘Share’ icon at the bottom of the screen.</li>
              <li>Scroll down the list and select `Add to home screen`.</li>
              <li>You may be asked to enter a name for the app before adding it to your home screen.</li>
              <li>The app will show up on your home screen like a normal mobile app.</li>
            </ol>
            <img className="mobile-screenshot" src={ios} alt="Logo" />
            <p><strong>Installing on desktop browsers (PC and macOS):</strong></p>
            <ol>
              <li>On most desktop browsers there will be a small down arrow icon at the top-right area of the address bar</li>
              <li>Click the down arrow icon</li>
              <li>You will be asked to install the app, click `Install`</li>
              <li>The app will be installed and should open automatically</li>
            </ol>
            <img className="desktop-screenshot" src={desktop} alt="Logo" />
          </div>
          <div className="content">
            <h3>Acknowledgements</h3>
            <p>
              Development of the progressive web application by Komodo Digital and the Protecting Civilians and Human Security Program at the Stimson Center.
            </p>
            <p>
              The publication of the original Handbook was made possible through the generous support of the Government of Sweden’s Folke Bernadotte Academy, the
              Government of the United Kingdom, and the Government of Canada.
            </p>
            <p>
              Acknowledgement is due to the consultants who developed the Handbook, Baptiste Martin and Tara Lyle.
              Design and layout of the Handbook by Frank DiCostanzo/ddacommunications.com.
            </p>
            <p>
              The Protection of Civilians in United Nations Peacekeeping Handbook was produced by the Protection of Civilians Team, Policy and Best Practices Service,
              Policy, Evaluation and Training Division, United Nations Department of Peace Operations, in consultation with Protection of Civilians Advisers, Officers
              and Focal Points in MINUJUSTH, MINUSCA, MINUSMA, MONUSCO, UNAMID, UNIFIL, UNISFA and UNMISS, and partners at UN Headquarters.
            </p>
            <p>
              Acknowledgement is due to the consultants who developed the Handbook, Baptiste Martin and Tara Lyle, and to those who translated it into French,
              Mariam Bagayako and Paterne Aime Petipe.
            </p>
            <p>
              Design and layout of the Handbook by Frank DiCostanzo/ddacommunications.com.
            </p>
            <p>
              All photos have been taken from official UN photos.
            </p>
            <div className="copyright">
              <p>Copyright &copy; United Nations {new Date().getFullYear()}</p>
              <p>All rights reserved</p>
            </div>
          </div>
        </div>
        <div className="row__item feedback">
          <h3>Feedback</h3>
          <p>
            The Policy, Evaluation and Training Division in the UN Department of Peace Operations welcomes feedback and questions about the app.
            Feedback provided may be used to make future improvements.
          </p>
          <p>
            Get in touch:  <a className="email" href="mailto:pbps-poc-unhq@un.org">PBPS-POC-UNHQ@un.org</a>
          </p>
        </div>
      </div>
    </div>
  </PageWrapper>
);

export default About;
