import React from 'react';
import meeting from '../images/chapters/chapter10/UNAMID_womens_meeting.jpg';
import unpol from '../images/chapters/chapter10/UNPOL_MINURCAT.jpg';
import AudioPlayer from '../components/audio';
import audio from './audio';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 10: Working with Communities on POC',
  title: t('handbook.chapters.chapter10.title'),
  index: '10',
  color: 'un-orange',
  content: (
    <>
      <div className="grid-three un-orange">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            Peacekeeping operations should take a people-centred approach to POC, that understands the protection needs of communities based on their own perceptions and analysis of the threats and necessary responses. The response to physical threats to civilians must take into account and support existing community self-protection mechanisms, as appropriate.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            Peacekeeping operations are deployed for a finite period and are not themselves a sustainable solution to conflict. They can, however, support sustainable solutions and durable peace by supporting and building local capacity and addressing root causes of conflict. Engagement with the civilian population is also vital for mission situational awareness.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            Community engagement for POC takes place through regular and routine activities by all mission components as well as dedicated activities that seek to understand protection threats faced by the population. When engaging with the population on POC, particular efforts should be made to engage with all community members (including women, youth, minority groups, different religious and ethnic communities, people with disabilities, older persons, etc.) and not just community leaders. All community engagement must be undertaken with a ‘do-no-harm’ approach. The mission should also consider the roles of other protection actors conducting community engagement, so as not to create duplication or confusion.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            Civilian, police and military components will all engage with civilians as part of their regular work, usually at the tactical level. Civilian sections, especially Civil Affairs and Human Rights, will have a particular role to play in community engagement, as will CIMIC Officers and Individual Police Officers engaged in community-oriented policing activities.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            Community engagement is primarily conducted at mission field offices, though some may be directed from mission headquarters.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            Community engagement should begin early for a mission to understand the environment and threats to civilians and should continue regularly throughout the lifecycle of a mission.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '10.1',
      name: 'Community Engagement',
      title: t('handbook.chapters.chapter10.1.title'),
      content: (
        <>
          <p>
            Community engagement should be the cornerstone of all POC efforts. Missions must engage communities from the earliest stages of mission planning and start-up, throughout the lifecycle of the mission, to drawdown and closure. Community engagement should not be left only to civilian components like Civil Affairs, Human Rights, DDR/CVR and Strategic Communications and Public Information; uniformed components also have a role to play. Without adequate national staff, including Community Liaison Assistants, efforts to engage communities will be extremely difficult. The establishment of adequate national staff posts, and the recruitment and retention of strong national colleagues, including women, will determine the mission&apos;s success with community engagement.
          </p>

          <p>
            Peacekeeping operations engage with communities for several distinct, yet inter-related reasons. These can include:
          </p>
          <ul className="square un-orange">
            <li>
              Situational awareness and understanding;
            </li>
            <li>
              Understanding the perceptions of threats faced by communities and vulnerable populations, and their protection needs;
            </li>
            <li>
              Understanding communities&apos; means of self-protection, conflict resolution and reconciliation techniques;
            </li>
            <li>
              Gathering information on potential threats faced by peacekeepers;
            </li>
            <li>
              Awareness raising and sensitization on the mission mandate;
            </li>
            <li>
              Understanding perceptions that communities have of the national, state and local actors and institutions responsible for and involved in protecting their rights and resolving conflicts;
            </li>
            <li>
              Understanding perceptions that communities have of the mission and encouraging mission acceptance by the local population; and
            </li>
            <li>
              Implementing projects, such as Quick Impact Projects (QIPs), and undertaking community-based activities.
            </li>
          </ul>

          <p>
            For more on these topics, see chapter 7 (Communicating about POC), chapter 8 (Identifying and Prioritizing POC Threats and Risks), chapter 9 (Using Tools for POC), chapter 11 (Operational Planning for POC) and chapter 12 (Responding to POC Threats).
          </p>
          <p>
            For engagement with communities to be effective, missions must be clear and transparent about their objectives. Each engagement will have a specific purpose or purposes and these will involve different approaches and activities, though these may overlap. Effective community engagement also requires sustained physical proximity, or, at minimum, sustained communications; one-off or ad hoc engagements have limited impact. Missions must also be mindful that community engagement is a bilateral activity, relying on two-way communication (i.e., it is a dialogue). Activities should focus as much on listening to communities as spreading messages. While community engagement is a powerful tool in the protection of civilians, it must not only be viewed as a means to an end. It also cannot occur in a vacuum, and must be coupled with engagement with duty bearers, such as perpetrators, potential perpetrators and local and national authorities.
          </p>
          <p>
            While community engagement by all mission components is to be encouraged, it must be undertaken carefully, as there are several risks involved. Engagement with the peacekeeping mission may stigmatize or even endanger communities, where an armed actor considers such engagement to be a threat. Further, engagement by peacekeeping personnel to provide protection to a community can undermine communities&apos; own capacities for self-protection. Networks and mechanisms established or supported by international actors may not be sustainable. The provision or expectation of protection by the mission may also create perverse incentives for communities, for example to stay in a place that is dangerous. Finally, supporting community self-protection approaches or mechanisms can expose communities to risk. Missions should carefully consider all these risks before engaging communities.
          </p>
          <p>
            Community engagement activities can be conducted across all three tiers (protection through dialogue and engagement, provision of physical protection and establishment of a protective environment) and along all four phases (prevention, pre-emption, response and consolidation) of POC. Mission activities engaging communities may include:
          </p>
          <ul className="square un-orange">
            <li>
              Establishing Community Alert Networks and other early warning and alert systems;
            </li>
            <li>
              Supporting reconciliation and conflict management at the local level;
            </li>
            <li>
              Supporting community engagement and advocacy with potential perpetrators;
            </li>
            <li>
              Supporting community monitoring and reporting on violations of human rights and IHL;
            </li>
            <li>
              Supporting other local initiatives to prevent and respond to threats to civilians;
            </li>
            <li>
              Supporting community-led physical protection mechanisms such as flight to a safer area;
            </li>
            <li>
              Supporting the restoration of state authority, including building confidence between local communities and state authorities;
            </li>
            <li>
              Supporting witness and victim protection measures before, during and after investigations and prosecutions of serious crimes, as well as the provision of psycho-social support;
            </li>
            <li>
              Planning and implementing quick impact projects (QIPs);
            </li>
            <li>
              Sensitizing on the reintegration of ex-combatants to restore a climate of confidence between communities;
            </li>
            <li>
              Using CVR projects to provide vocational training for youth at risk to prevent them from joining armed groups; and
            </li>
            <li>
              Gathering information from affected communities on which areas may be contaminated by explosive hazards and working with the communities to develop programmes that prioritize areas for clearance and effectively reach populations with risk education.
            </li>
          </ul>
          <h5>
            Ensuring that community engagement is culturally and gender sensitive
          </h5>
          <p>
            Community engagement should be conducted in a manner sensitive to gender, diversity and culture. Local traditions and customs may not themselves be sensitive to gender and diversity, so a balance must be struck among these considerations. The following recommendations should be considered to ensure that interactions respect these principles:
          </p>
          <ul className="square un-orange">
            <li>
              In consulting local communities and gathering information on perceptions, needs and concerns, it may be necessary to undertake additional separate consultations with different individuals, groups or identities (e.g., youth, elders, women, informal community leaders). This can allow those whose voices are often excluded from the public realm to be heard. It may also be prudent to match interlocutors with community members, as for example, women may prefer to talk to other women, whether uniformed or civilian.
            </li>
          </ul>
          <div className="relative">
            <img src={meeting} alt="UNAMID Women's meeting" />
            <div className="description">
              <p>
                UNAMID organizes a women’s meeting aimed at encouraging women to spread the message of peace to their families and communities. (2017)
              </p>
            </div>
            <p className="ref">UN Photo/Mohamad Almahady</p>
          </div>
          <div className="half">
            <ul className="square un-orange">
              <li>
                While respecting and working with local structures and representatives, be careful about exacerbating power dynamics already at play within a community. Take care in selecting the mission&apos;s interlocutor from the community and how they are perceived by the entire community, including women and vulnerable groups.
              </li>
              <li>
                Data gathered from community engagement should be disaggregated according to age, gender, ethnicity, religion, etc., as and when relevant to the context, conflict or planned intervention.
              </li>
              <li>
                In conducting a conflict analysis, those engaging with communities should consider how diversity and gender identity have featured in the conflict and develop an understanding of the experiences, priorities and grievances of different groups.
              </li>
              <li>
                When implementing reconciliation and conflict resolution programmes or activities, consider traditional conflict mitigation and management mechanisms.
              </li>
              <li>
                In supporting the development of political space and the restoration and extension of state authority, it is important to support the participation of women and cultural/ethnic minorities in civil administration, including their appointment as community or government leaders, as much as possible.
              </li>
              <li>
                Protection of civilians analysis should consider how issues of gender and diversity interact with risk and vulnerability and think about the role different groups play in early warning and prevention.
              </li>
              <li>
                It is useful to map organizations or networks that represent minorities or marginalized groups and assess the key issues on which they are engaged, the role they play in decision making and the level of influence or capacity they have.
              </li>
              <li>
                In the implementation of QIPs, missions should consider supporting projects that feature marginalized groups as beneficiaries or implementing partners, as one aspect of building confidence in the mission, mandate and peace process. Projects themselves should, where possible, be sensitive to gender and diversity issues.
              </li>
            </ul>
            <div className="to-do un-orange">
              <div className="do">
                <header>What to DO</header>
                <p>
                  Ensure the safety and security of interlocutors.
                </p>
                <p>
                  Know the mission mandate.
                </p>
                <p>Listen.</p>
                <p>
                  Respect dignity and confidentiality.
                </p>
                <p>
                  Obtain informed consent.
                </p>
                <p>Think of creative community outreach options.</p>
                <p>
                  Report information received to the appropriate mission components and mission POC coordination mechanisms.
                </p>
                <p>
                  Collect disaggregated data/information that is sensitive to the different experiences of women, girls, men and boys.
                </p>
                <p>
                  Know referral arrangements for survivors of human rights violations, including sexual violence, to appropriate sources of support within and outside the mission, including specific mechanisms for children.
                </p>
                <p>
                  Work with local NGOs and communities but understand that ‘civil society&apos; is not always representative of the whole community.
                </p>
                <p>
                  Consider possible consequences of actions for local communities.
                </p>
                <p>
                  Work together as civilian and uniformed components for a coordinated approach to community engagement.
                </p>
              </div>
              <div className="dont">
                <header>What NOT to do</header>
                <p>
                  DO NOT impose mission resources for protection where communities are effectively managing their own protection.
                </p>
                <p>
                  DO NOT impose subjective (personal) judgements regarding communities&apos; needs.
                </p>
                <p>
                  DO NOT engage in activities that may harm local communities.
                </p>
                <p>
                  DO NOT interview or investigate victims and witnesses unless mandated and appropriately trained.
                </p>
                <p>
                  DO NOT share details of victims of human rights violations with authorities.
                </p>
                <p>DO NOT take pictures of child victims.</p>
                <p>
                  DO NOT act on behalf of victims without informed consent.
                </p>
                <p>
                  DO NOT be discouraged if help is not immediate, or the mission is unable to help.
                </p>
                <p>DO NOT casually spend time with children.</p>
                <p>DO NOT give money, food or employment to children.</p>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      index: '10.2',
      name: 'Do No Harm',
      title: t('handbook.chapters.chapter10.2.title'),
      content: (
        <>
          <p>
            <strong className="un-orange">Peacekeeping operations should be particularly aware of the potential risks to civilians caused by their own actions, including from the impact of their military and/or police operations or activities, as a result of mission deployment and placement, or in the form of reprisals against those engaging or cooperating with the mission. Missions must take actions to mitigate these risks. </strong>When interacting with civilians, missions should follow a do-no-harm approach, including undertaking a risk assessment of the effects of the mission&apos;s actions on the community.
          </p>
          <p>
            Harm may include direct harm through conduct and discipline incidents, including sexual exploitation and abuse, or civilian harm resulting from a security operation. Even operations that are aimed at protecting civilians may result in unintended harm through, for example, the displacement of civilians or armed groups and resulting consequences, including reprisals against civilians.
          </p>
          <p>
            Harm can also be indirect, due to proximity to the mission, or following the deployment or redeployment of a base, community engagement, the use of key infrastructure (transport, public services including schools, hospitals, water sources, etc.), local recruitment, the choice of contractors, a car accident or staff behaviour (cultural insensitivity, etc.).
          </p>
          <p>
            Particular attention should be paid to how and where meetings are organized, ensuring a safe space for persons in vulnerable situations or at-risk, ensuring informed consent, maintaining confidentiality of information shared by partners, and taking extra precaution in the collection, storage and reporting of information. <strong className="un-orange">All reported allegations of intimidation and reprisals for cooperation with the mission should be properly documented and reported through established internal channels.</strong>
          </p>
          <p>
            Before engaging with communities or individuals, missions should conduct risk assessments and implement risk mitigation measures. Possible risk mitigation measures to ensure adequate protection of sources and interlocutors may include:
          </p>
          <ul className="square un-orange">
            <li>
              Assess the level of threat and risk of harm to the informant and other cooperating persons before, during and after establishing contact with them. If an assessment concludes that the safety of the cooperating individual(s) cannot be ensured or there is insufficient information to make an informed determination about risks to their safety, do not seek information from or engagement with the individual(s).
            </li>
            <li>
              Choose the most appropriate and safest method to establish contact with the interlocutor. For example, determine if contact should be established directly or through a third party/intermediary.
            </li>
            <li>
              Consider minimizing the interlocutor&apos;s exposure. For instance, establish direct contact in a manner that may seem coincidental.
            </li>
            <li>
              Consider developing secure methods of communication to keep in touch.
            </li>
            <li>
              Choose the safest place to obtain/gather the information. For example, determine if the interaction with the source should be visible or discreet. Protect his/her identity.
            </li>
            <li>
              Guarantee confidentiality and security of information. If there is a risk of endangering the informant(s), information should not be disclosed or should only be disclosed in a manner that removes the risk (i.e., providing information on a general pattern without revealing specific details; ensuring that only trusted intermediaries are used). In individual protection cases, the requirement of confidentiality also includes the confidentiality of information on the protective measures taken, including any support given by partners external to the field presence.
            </li>
            <li>Ensure informed consent.</li>
            <li>
              Always take into account the knowledge, views and needs of cooperating persons by involving them in the risk and threat assessment and the choice of measures to be taken to ensure their safety.
            </li>

            <li>
              Do not raise expectations. Upon establishing contact and before proceeding, inform interlocutors of the limitations in guaranteeing protection.
            </li>
            <li>
              Consider providing the persons with useful contacts in the community that could offer different types of assistance (e.g., protection networks) and/or of local authorities, if these can be trusted, in case there is a risk of reprisals as a result of the interaction.
            </li>
            <li>
              Map the capacity and/or commitment of the local authorities to respond to protection concerns, to contribute to informed decisions.
            </li>
            <li>
              Make use of the resources available in the community by facilitating contact with local protection networks.
            </li>
            <li>
              Only share information on personal details of sources, victims and witnesses in line with relevant information sharing and reporting protocols (consult human rights and child protection personnel for further guidance). Identifying information can include photos and names, but also ages, locations and descriptions, especially in remote areas or where the details are unique.
            </li>
          </ul>
          <p className="un-orange bold">
            Where peacekeeping personnel are unsure about whether engagement can be conducted safely, or where they consider that the mitigating measures listed above might prove insufficient, peacekeeping personnel should refrain from engaging with local communities, sources and interlocutors.
          </p>
          <div className="relative margin-top">
            <img src={unpol} alt="UNPOL MINURCAT" />
            <div className="description">
              <p>
                MINURCAT UNPOL and the Chadian national police engage with a community member. (2009)
              </p>
            </div>
            <p className="ref">UN Photo/MINURCAT</p>
          </div>
        </>
      )
    },
    {
      index: '10.3',
      name: 'Community-oriented Policing',
      title: t('handbook.chapters.chapter10.3.title'),
      content: (
        <>
          <p>
            In addition to other community-based tools such as CLAs and CANs (see chapter 9), community-oriented policing (CoP) is a specific tool aimed at designing more resilient, sustainable protection strategies to ensure the improvement and quality of lives of the people in the longer term.
          </p>
          <p>
            CoP is the consultative systematic use of partnerships and problem-solving techniques to proactively address the immediate conditions that give rise to public safety issues such as crime, social disorder and fear of crime, while at the same time helping to restore and nurture public acceptance of state services, especially policing. Utilizing CoP, UNPOL leverages community partnerships to prevent and detect crime as well as build a protective environment for communities.
          </p>
          <p>
            In a post-conflict situation, state police services are not usually omnipresent. CoP therefore prioritizes educating people about measures they may take to protect themselves, such as avoiding high-crime areas, securing their housing, land and/or property and establishing neighbourhood watch/crime awareness programmes.
          </p>
          <p>
            CoP is a mutually beneficial strategy – police build greater trust, accessibility and respect, while at the same time reducing the fear of security forces that may grip a society in a conflict or post-conflict context. CoP provides state police with better access to information on crime and improved moral support and respect for their activities, while communities start to understand and appreciate state services better, thereby improving the state&apos;s legitimacy and governance.
          </p>
          <p>
            CoP can also be used to support community dialogue and efforts to strengthen intercommunal relationships and facilitate stability and reconciliation, thereby reducing longer term protection issues.
          </p>
          <p className="un-orange bold">
            The four core elements to any community-oriented policing strategy are:
          </p>
          <ul className="square color bold">
            <li>
              Consulting with communities,
            </li>
            <li>
              Responding to community needs,
            </li>
            <li>
              Mobilizing communities, and
            </li>
            <li>
              Solving recurrent problems.
            </li>
          </ul>
          <p>
            In consultation and coordination with other mission components, UNPOL should consult and engage with traditional community leaders as well as the communities themselves to ascertain their protection needs. UNPOLs need to ensure that such interactions and engagements are undertaken in a safe and respectful manner so as not to expose women, men, girls and boys to additional threats, risks or harm. UNPOL, together with local communities, can then design their strategies to respond to the protection needs identified. Most often, if the protection concerns are localized, and include non-military threats, UNPOL may be able to respond, including through the leveraging of host state police capacities. While high visibility and effective response may be effective deterents, UNPOL may also train community members to patrol the communities or to keep watch to supplement the deterrent presence of the police. Undertaking coordinated and regular awareness programmes on sexual and gender-based violence, as well as gender equality in the context of human rights and gender mainstreaming in the community, can lead to a reduction in gender-based crimes. Similarly, promoting awareness about rights and duties of individuals and creating community-police partnerships can contribute to preventing conflicts and improving the overall security situation.
          </p>
          <p>
            Beyond any short-term physical protection requirements, CoP can support and mobilize communities to establish or re-establish structures or mechanisms – like CANs or neighbourhood watch/crime prevention schemes — that more systematically address longer term and recurrent protection issues.
          </p>
          <AudioPlayer
            src={audio[6].file}
            title={audio[6].title}
            subtitle={audio[6].subtitle}
            avatar={audio[6].avatar}
            speaker={audio[6].speaker}
            transcript={audio[6].transcript}
          />
          <div className="voices un-navy">
            <div className="avatar">
              <img alt={audio[6].speaker} src={audio[6].avatar} />
            </div>
            <h3>VOICES FROM THE FIELD</h3>
            <div className="content">
              <h4>Community-Oriented Policing</h4>
              <h5>Lulu Masunga, UNAMID UNPOL (2017-2020)</h5>
              <div className="half">
                <div>
                  <p>
                    The majority of my work in UNAMID has been interacting with communities inside of internally displaced persons (IDP) sites in Central Darfur. In addition to daily foot patrols conducted inside the sites, I’ve led a number of sensitization efforts and capacity building projects, including setting up English classes. The goal of community-oriented policing in this sort of setting is to maintain stability, and often that requires developing a good relationship with community leadership so that you can combat criminality and keep the environment organized.
                  </p>
                  <p>
                    Community-oriented policing requires a close relationship between the police and the population, so that we as police can understand what threats the community is facing. Community-oriented policing is also especially important when there are spontaneous demonstrations from the population. When that happens, it’s necessary to calm the situation as quickly as possible, or it can quickly escalate and become dangerous. In the past, I’ve been able to calm demonstrations by communicating directly with authority figures from the population with whom I’ve already developed good relations. Your investment in relationship building can be really beneficial in these situations.
                  </p>
                  <p>
                    Outside of public demonstrations, there are several recurrent challenges I’ve faced in my role in UNAMID, which include:
                  </p>
                </div>
                <div>
                  <ul className="square">
                    <li>
                      <strong>Language barriers</strong> – Communication is the most important tool for the police inside of IDP sites, but we haven’t always had a great supply of Sudanese Language Assistants available to help us interact with communities. Another obstacle I’ve found is that some Language Assistants don’t always seem to tell the full truth of the community’s experience, either due to cultural issues or because they disagree with an opinion.
                    </li>
                    <li>
                      <strong>Expectation management</strong> – The majority of the IDPs we work with are not familiar with the purpose of the mission or the role we’re playing for their protection. Often their expectation is that we’ll bring them money or material assistance. It’s especially important to manage these expectations because they can have direct impact on the willingness of communities to share important information.
                    </li>
                    <li>
                      <strong>Patrolling challenges</strong> – Normally in order to conduct a patrol, there should be an armed military escort present. Given the harsh conditions in Darfur, however, vehicles often break down and poor roads can make it almost impossible to reach certain far-off locations. We’ve also found that when we conduct a patrol to an IDP site, we’re often informed that criminal elements fled the site as soon as they saw our vehicles coming.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
  ]
};
