import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import PageWrapper from './wrapper';
import WarningIcon from '../images/design/warning.svg';

const NoMatch = () => {
  const [stage, setStage] = useState('intro');

  if (stage === 'redirect') return <Redirect to="/welcome" />;

  return (
    <PageWrapper page="four-oh-four">
      <div className="four-oh-four">
        <div className="container">
          <div className="info">
            <img src={WarningIcon} alt="Warning" />
            <h2>The page you&lsquo;re looking for can&lsquo;t be found</h2>
            <p>
              We can&lsquo;t find the page you&lsquo;re looking for. Try
              searching via the &lsquo;Search&lsquo; in the main navigation.
            </p>
            <p>You can also navigate back to the &lsquo;Handbook&lsquo; via the button below.</p>
          </div>

          <button
            type="button"
            className="goto"
            onClick={() => setStage('redirect')}
          >
            Back to Handbook
          </button>
        </div>
      </div>
    </PageWrapper>
  );
};

NoMatch.propTypes = {
};

NoMatch.defaultProps = {
};

export default NoMatch;
