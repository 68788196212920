import React from 'react';
import { t } from '../../services/i18n';

const Header = () => (
  <>
    <header>
      <div className="title">
        <h2>{t('about.header.title')}</h2>
        <h4>{t('about.header.subtitle')}</h4>
      </div>
    </header>
  </>
);

export default Header;
