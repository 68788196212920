import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Intro from './stages/intro';
import GridChoice from './stages/gridChoice';
import roles from '../config/roles';
import actions from '../redux/actions';
import logo from '../images/design/header-logo.png';
import CookieBanner from '../components/cookie';
import templates from '../templates';
import resources from '../templates/resources';

const Onboarding = ({ updateCache, cache }) => {
  const [stage, setStage] = useState('intro');

  const roleKeys = Object.keys(roles);
  const roleOptions = roleKeys.map((k) => ({ label: roles[k].label, value: k }));

  if (stage === 'continue') return <Redirect to="/" />;

  return (
    <>
      <div className="onboard">
        <div>
          <header>
            <img src={logo} alt="UN logo" />
            <span>
              The Protection of Civilians in United
              Nations Peacekeeping Handbook
            </span>
          </header>
          { stage === 'intro' && <Intro goToNext={() => setStage('role')} /> }
          { stage === 'role' && (
            <GridChoice
              next={() => setStage('area')}
              value={cache.role}
              onChange={(role) => {
                const roleLabel = roleOptions.filter((r) => r.value === role);
                if (roleLabel.length > 0) {
                  updateCache({ role, roleLabel: roleLabel[0].label });
                }
              }}
              title="Select your role in peacekeeping"
              description="What you select will tailor the content to you. You can always change your role in the user profile section."
              options={roleOptions}
            />
          ) }
          { stage === 'area' && (
            <GridChoice
              next={() => {
                // Reset the presently stored chapter value incase an invalid one has been cached
                // Set to index 1 because instructions from Stimson say should jump to chapter
                // 1 skipping POC fundamentals
                updateCache({ chapter: templates[1].name, resource: resources[1].name });
                // Redirect user
                setStage('continue');
              }}
              value={cache.area}
              onChange={(area) => {
                const areaLabel = roles[cache.role].areas.filter((r) => r.value === area);
                if (areaLabel.length > 0) {
                  updateCache({ area, areaLabel: areaLabel[0].label });
                }
              }}
              title="Select the area of your role in peacekeeping"
              description="What you select will tailor the content to you. You can always change your role in the user profile section."
              options={cache.role && roles[cache.role].areas}
              prev={() => setStage('role')}
            />
          ) }
        </div>
      </div>
      <CookieBanner />
    </>
  );
};

Onboarding.propTypes = {
  cache: PropTypes.shape({
    role: PropTypes.string,
    area: PropTypes.string
  }),
  updateCache: PropTypes.func.isRequired
};

Onboarding.defaultProps = {
  cache: {}
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps, {
  updateCache: actions.updateCache
})(Onboarding);
