import React from 'react';
import actionPlan from '../images/chapters/chapter2/case_study_on_shabunda_action_plan.jpg';
import capacityBuilding from '../images/chapters/chapter2/MINUSTAH_capacity_building_of_state_forces.png';
import patrol from '../images/chapters/chapter2/MONUSCO_patrol.jpg';
import disarmament from '../images/chapters/chapter2/UNMIL_disarmament_and_rehabilitation.jpg';
import meeting from '../images/chapters/chapter2/UNMIS_UNMIS_SLA_Combatants_attend_meeting_of_head_of_UNMIS.jpg';
import gates from '../images/chapters/chapter2/UNMISS_opening_the_gates.jpg';
import tierI from '../images/chapters/chapter2/UNMISS_tier_i.jpg';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 2: Understanding POC',
  title: t('handbook.chapters.chapter2.title'),
  index: '2',
  color: 'un-green',
  content: (
    <>
      <div className="grid-three un-green">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            POC is a priority mandate of most UN multidimensional peacekeeping operations since 1999 and is high on the political agenda of the United Nations Security Council (UNSC). In order to implement POC, the specific POC mandate of the mission must be interpreted and understood.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            While there is a core of standardized terminology, the specific language used by the Security Council when mandating POC in peacekeeping has evolved over the years and may vary between peacekeeping operations. To translate the mandate into strategic and operational goals and specific tasks and responsibilities, and to manage expectations of the mission, a clear and unified understanding of the requirements and limits of the POC mandate — in the specific context of the mission — is necessary.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            The country-specific mandate is the starting point for each mission to develop its strategic and operational approach to POC, in line with DPO policy. It starts with preparing a conflict analysis and developing key mission documentation including: the mission concept, rules of engagement (ROE) and directives on the use of force (DUF), military and police concept of operations (CONOPS) and a POC strategy. The agreed understanding of the requirements of the mandate should then flow through mission planning processes, training, resource allocation and activities.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            Developing a shared understanding of the POC mandate is the overall responsibility of mission leadership, in consultation with DPO and other relevant departments at UN Headquarters, and with support from the mission Legal Adviser and POC Adviser. The interpretation of the POC mandate should be shared among all mission components and with external stakeholders, including local communities, host governments, armed actors, Member States, humanitarian partners and the local and international media.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            Interpreting the POC mandate is done jointly between UN Headquarters in New York and mission headquarters, while operationalizing the mandate occurs at mission headquarters and in mission field offices.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            Consideration of a mission’s POC mandate and approach is critical at mission start-up. It is also necessary when there is a change in mandate or a significant change in the situation on the ground, such as a crisis. All new peacekeeping personnel should familiarize themselves with the POC mandate and approach of the mission upon onboarding.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '2.1',
      name: 'The Scope of the POC Mandate in UN Peacekeeping',
      title: t('handbook.chapters.chapter2.1.title'),
      content: (
        <>
          <p>
            There are multiple legal, policy and operational issues to consider when interpreting an individual mission&apos;s POC mandate. The DPO POC Policy provides the conceptual framework, standard definitions, guiding principles, key considerations and the operational concept for the interpretation and implementation of the POC mandate. As a first step, all peacekeeping personnel should be aware of and understand the essential components of the POC mandate as set out in the DPO POC Policy.
          </p>
          <p>
            Within country-specific Security Council resolutions, POC mandates can be broad and open or can be more prescriptive. Increasingly, the Security Council has included more detailed guidance on the strategic direction and tasks that a particular mission should undertake as part of the POC mandate. Any changes to the mandate language should be examined for their legal, policy and operational implications.
          </p>

          <table className="examples">
            <thead>
              <tr>
                <th width="40%">Example of <em>broad </em>mandate:</th>
                <th>Example of <em>prescriptive </em>mandate:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p className="bold">
                    UNIFIL mandate language on POC (S/RES/1701 (2006)):
                  </p>
                  <p>
                    12. Acting in support of a request from the Government of Lebanon to deploy an international force to assist it to exercise its authority throughout the territory, <strong>authorizes UNIFIL to take all necessary action in areas of deployment of its forces and as it deems within its capabilities</strong>, to ensure that its area of operations is not utilized for hostile activities of any kind, to resist attempts by forceful means to prevent it from discharging its duties under the mandate of the Security Council, and to protect United Nations personnel, facilities, installations and equipment, ensure the security and freedom of movement of United Nations personnel, humanitarian workers and, <strong>without prejudice to the responsibility of the Government of Lebanon, to protect civilians under imminent threat of physical violence . . .</strong>
                  </p>
                </td>
                <td>
                  <p className="p">
                    MINUSMA mandate language on POC (S/RES/2480 (2019)):
                  </p>
                  <p>
                    <strong>28 (c) Protection of civilians</strong>
                  </p>
                  <ol type="i">
                    <li>
                      <strong>To protect, without prejudice to the primary responsibility of the Malian authorities, civilians under threat of physical violence;</strong>
                    </li>
                    <li>
                      <strong>In support of the Malian authorities, to take active steps to anticipate, deter and effectively respond to threats to the civilian population, notably in the North and Center of Mali, through a comprehensive and integrated approach, and, in this regard:</strong>
                      <ul className="square un-green">
                        <li>
                          <strong>to strengthen early warning and to increase efforts to monitor and document violations of international humanitarian law and violations and abuses of international human rights law;</strong>
                        </li>
                        <li>
                          <strong>to strengthen community engagement and protection mechanisms, including interaction with civilians, community outreach, reconciliation, mediation, support to the resolution of local and intercommunal conflicts and public information;</strong>
                        </li>
                        <li>
                          <strong>to take mobile, flexible, robust and proactive steps to protect civilians, including through the deployment of ground and air assets, as available, in high risk areas where civilians are most at risk;</strong>
                        </li>
                        <li>
                          <strong>to mitigate the risk to civilians before, during and after any military or police operation, including by tracking, preventing, minimizing, and addressing civilian harm resulting from the mission&apos;s operations;</strong>
                        </li>
                        <li>
                          <strong>to prevent the return of active armed elements to key population centres and other areas where civilians are at risk, engaging in direct operations pursuant only to serious and credible threats . . .</strong>
                        </li>
                      </ul>
                    </li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flow-hide 2-1-1">
            <h5>
              <span className="un-green">2.1.1</span> Interpreting mandate language
            </h5>
            <table className="boxed-table un-green">
              <tbody>
                <tr>
                  <td>
                    <span className="border un-green">‘Imminent&apos; threats</span>
                  </td>
                  <td>
                    Early mandate language on POC required the protection of civilians under &lsquo;imminent threat of physical violence.&rsquo; In most multidimensional missions, the word &lsquo;imminent&rsquo; has since been removed and the mandate now requires the protection of civilians under &lsquo;threat of physical violence.&rsquo; This language change clarified that a mission can and should take proactive and preventive action to protect civilians under threat and that these actions can include all necessary means (including the use of deadly force). Those missions which still have &lsquo;imminent&rsquo; threat within the POC mandate language are still expected to take a proactive and preventive approach to POC, but short of using force when the threat is not imminent.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-green">
                      In support of the host state
                    </span>
                  </td>
                  <td>
                    As the host state always bears the primary responsibility to protect civilians, most country-specific mandates include explicit language that the protection of civilians is without prejudice to the primary responsibility of the host state, so that the mission is not expected to substitute for the role of the host state in protecting the population. There may also be language in the mandate on certain tasks being in support of the host state (see, e.g., MINUSMA mandate language above). This highlights the importance of supporting the protection efforts and capacity of the host state, though it does not diminish the authorization for the mission to act independently to protect civilians, in particular, when the host state is unwilling or unable to do so. In situations where the host state has a history of failing to protect civilians or is itself a threat to civilians, the mandate language has sometimes not referenced support to the protection efforts of the host state. For example, when the UNMISS mandate was renewed in 2014, following the outbreak of war in South Sudan, the mandate changed to exclude language on support to the host state, including on protection<sup>7</sup>.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-green">A priority mandate</span>
                  </td>
                  <td>
                    Most mandates are explicit that the protection of civilians is a priority for the mission in terms of its allocation of capabilities and resources. However, even where this is not stated in the country-specific mandate, the Security Council has been clear that where mandated, the protection of civilians is a priority unless the relevant Security Council resolution stipulates otherwise<sup>8</sup>.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-green">
                      All necessary means/all necessary action
                    </span>
                  </td>
                  <td>
                    The mandate to protect civilians has always been accompanied by an authorization to use all necessary means or actions. This is the language used by the Security Council to authorize the full range of measures available to the mission, up to and including the use of deadly force.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-green">
                      Within capabilities and areas of deployment
                    </span>
                  </td>
                  <td>
                    The mandate to protect civilians is normally specified to be within the capabilities and areas of deployment of the mission. This limitation recognizes that peacekeeping operations have limited capacity and footprint and cannot protect everyone, everywhere, at all times. However, missions should consider the various threats to civilians when determining the mission footprint and deployment of resources and should be prepared to redeploy resources as necessary to respond to serious threats to civilians.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flow-hide 2-1-2">
            <h5>
              <span className="un-green">2.1.2</span>Who is a civilian?
            </h5>
            <div className="half">
              <div>
                <h5>Defining Civilians</h5>
                <p>
                  Defining &apos;civilians&apos; and distinguishing them from combatants is a feature of international humanitarian law (IHL), which specifically applies to situations of armed conflict. However, the term &apos;civilians&apos; as used in POC mandates is also applied in contexts unrelated to armed conflict. A principal aim of IHL is to ensure that parties to conflict respect and protect those who are not or are no longer directly participating in hostilities, such as civilians. Under IHL, therefore, the main purpose of defining civilians is directly related to the obligation of those engaged in conflict to protect civilians from harm. Under IHL, civilians are generally defined as persons who are not members of the armed forces or of organized armed groups.
                </p>
                <p>
                  For the specific purposes of the POC mandate in peacekeeping, the DPO POC Policy sets out who is considered a civilian:
                </p>
                <p className="un-green bold">
                  For the purposes of the protection of civilians mandate in peacekeeping, everyone is to be considered a civilian, except persons falling in one of the following categories:
                </p>
                <ul className="square un-green color">
                  <li className="un-green bold">
                    members of armed forces;
                  </li>
                  <li className="un-green bold">
                    members of organized armed groups who have continuous combat functions; and
                  </li>
                  <li className="un-green bold">
                    civilians directly participating in hostilities, for such time as they do so.
                  </li>
                </ul>
                <p className="un-green bold">
                  In case of doubt whether a person is a civilian, that person shall be considered a civilian.
                </p>
                <p>
                  Everyone who is not excluded, as stated above, falls within the scope of the POC mandate. They may be preventively and proactively protected by a peacekeeping operation from threats of physical violence. Where a peacekeeping operation is deployed in a non-armed conflict situation, everyone except members of armed forces are civilians within the scope of the POC mandate.
                </p>
                <p>
                  In some situations, such as community-based violence or criminal gang activity, the perpetrators or potential perpetrators of violence against civilians will be other civilians. Where this is the case, the mission must make an operational decision on how to apply the POC mandate, with support from Headquarters, as required.
                </p>
              </div>
              <div>
                <div className="background border margin-bottom">
                  When international humanitarian law applies to United Nations forces, they must comply with it, including the principles of distinction, proportionality and precaution. This obligation is independent of the POC mandate. Guidance on whether international humanitarian law applies to a specific peacekeeping operation will be developed on a case-by-case basis and as required by the Office of Legal Affairs, in consultation with DPO and the relevant mission. See also chapter 11.4 on protection of civilians during military operations.
                </div>
                <div className="relative">
                  <img src={meeting} alt="Combatants" />
                  <div className="description">
                    <p>
                      Combatants from an<br />
                      armed group in Sudan. (2006)
                    </p>
                  </div>
                  <p className="ref">UN Photo/Fred Noy</p>
                </div>
              </div>
            </div>
            <table className="examples striped margin-top">
              <thead>
                <tr>
                  <td>
                    Examples: POC mandate application when civilians are perpetrators of violence against other civilians
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where two armed groups or two armed communities are violently clashing, the mission would not normally be expected to intervene under the POC mandate to protect those participating in the clashes but would be expected to protect other civilians affected by those clashes.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where intercommunal violence involves attacks against civilians, rather than direct attacks against an armed adversary, the mission should intervene to protect.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where an armed mob attacks a group of unarmed civilians, the mission should intervene to protect.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where organized criminal entities are preying on the local civilian population, the mission should intervene to protect.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where a physically violent crime, unrelated to armed conflict, occurs in the presence or vicinity of peacekeepers, the mission should intervene to protect the victims of the crime. However, the mission cannot be expected to intervene or address all ordinary crime without an executive policing mandate.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where an individual on the premises of a UN peacekeeping operation (such as in a POC site in UNMISS) is committing crimes or causing violence to others, the mission should intervene to protect. However, as the individual is still a civilian, and if he/she is on UN premises to avoid harm from an armed conflict, the mission must still protect the individual. Where expelling him/her or handing him/her over to local government authorities would endanger him/her, the mission must respect the principle of non-refoulement<sup>9</sup>.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <h4>Distinguishing Civilians</h4>
            <p>
              In developing an understanding of the scope of the POC mandate, key mission documents on POC at strategic, operational and tactical levels should clarify who is a civilian that a peacekeeping operation may seek to protect where it has the capacity to do so. In distinguishing civilians, the mission should:
            </p>
            <ul className="square un-green">
              <li>
                Identify armed forces or organized armed groups operating in the mission area, members of which would not normally be civilians protected under the POC mandate, bearing in mind that:
                <ul className="triangle un-green">
                  <li>
                    The level of &lsquo;organization&rsquo; of an armed group can be difficult to appreciate but the mission can consider factors such as the group&apos;s structure and extent of its hierarchy, its control over a geographical area and its self-identification as an armed group.
                  </li>
                  <li>
                    Membership in an armed group or state security force may be difficult to ascertain but the mission can take into account factors including whether the individual(s) concerned are wearing a uniform or distinctive clothing or signs, carrying a weapon or taking active part in hostilities or attacks on civilians. However, absence of uniforms or signs is not definitive, as members of armed groups or state security forces may display no visible signs revealing their status.
                  </li>
                  <li>
                    Where an armed group has both a military and a political wing, persons involved only in the political wing would normally be considered civilians. Where political actors of any affiliation endorse orders for violence, they remain civilians, but it may not be advisable to exercise the POC mandate to protect them.
                  </li>
                  <li>
                    Dependents of armed forces or an armed group would normally be considered to be civilians to the extent that they do not directly participate in hostilities.
                  </li>
                  <li>
                    A member of an armed force or armed group can become a civilian when he/she disengages from active duty or their fighting function and reintegrate into civilian life.
                  </li>
                </ul>
              </li>
              <li>
                Recall that civilians who directly participate in hostilities are excluded from protection for such time as they do so. This may include civilians in self-defence groups. However, once they cease to engage in violence they should be protected.
              </li>
              <li>
                Consider that possession of a weapon does not in itself prevent a civilian from being considered a civilian, especially in contexts where it may be tradition to carry a weapon (whether guns, machetes, knives, spears, etc.).
              </li>
              <li>
                Remember that, in case of doubt, the individual or group of individuals shall be considered civilian and protected as civilians until determined otherwise.
              </li>
            </ul>
            <div className="relative">
              <img src={disarmament} alt="Disarmament and Rehabilitation" />
              <div className="description">
                <p>
                  More than 2,000 former combatants turn in their weapons to UNMIL at the start of a disarmament and rehabilitation campaign to stabilize the country. (2003)
                </p>
              </div>
              <p className="ref">UN Photo/Shima Roy</p>
            </div>
            <p>
              Once the mission has identified those individuals or groups falling within the scope of the POC mandate, and determined that the POC mandate applies to protecting them, then the mission must prioritize among threats and civilians at risk, based on the prevention and response capabilities available (see chapter 8) as well as other relevant circumstances.
            </p>
            <div className="case-study un-green">
              <h3>CASE STUDY</h3>
              <h4>Opening the gates in South Sudan - Who to let in?</h4>
              <div className="content">
                <div className="relative">
                  <img src={gates} alt="Opening the gates" />
                  <div className="description">
                    <p>
                      Tens of thousands of civilians seek refuge at UNMISS bases due to outbreaks of violence across the country. (2013)
                    </p>
                  </div>
                  <p className="ref">UN Photo/UNMISS</p>
                </div>
                <p>
                  In December 2013, when violence broke out in Juba, and then quickly spread to other areas of South Sudan, thousands of people fled to UNMISS compounds seeking refuge. They were men, women and children of all ethnicities. In the immediate moment, and the initial days to follow, the mission had no time or capacity to assess who was coming in to the compounds. Two simple rules were established: no uniforms and no weapons. Anyone willing to abide by those rules was determined to merit protection. The reality of this was that many fighters arrived at the gates, laid down their guns, and changed clothes. Piles of weapons and fatigues grew at the gates, with the mission safely storing the former. This approach drew strong criticism from the government, particularly once fighting had died down and it was suspected that many former fighters were in the POC sites. However, UNMISS stood by its policy that these individuals, even if they were former fighters, should be protected once they ceased to directly participate in hostilities by effectively disengaging from their fighting function. This demonstrates the operational challenges in the field to determine who falls within the POC mandate.
                </p>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      index: '2.2',
      name: 'The Operational Concept for POC in UN Peacekeeping',
      title: t('handbook.chapters.chapter2.2.title'),
      content: (
        <>
          <p>
            The operational concept for the protection of civilians in United Nations peacekeeping is set out in the DPO POC Policy. In accordance with the POC Policy, protection of civilians in peacekeeping is implemented through three tiers, namely, protection through dialogue and engagement, provision of physical protection and establishment of a protective environment, as outlined below.
          </p>

          <table className="columns">
            <tbody>
              <tr>
                <td><img src={tierI} alt="protection through dialogue" /></td>
                <td>
                  <img src={patrol} alt="provision of physical protection" />
                </td>
                <td>
                  <img
                    src={capacityBuilding}
                    alt="establishment of a protective environment"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="un-green title">Tier I:</p>
                  <p className="subtitle">
                    Protection through dialogue and engagement
                  </p>
                  <p>
                    Tier I activities include active, structured and regular dialogue with perpetrators or potential perpetrators of violence against civilians; conflict resolution and mediation between parties to the conflict; advocating with the host government, its security institutions and other relevant actors to intervene to protect civilians; local conflict resolution and social cohesion activities; strategic communication; investigation; advocacy; reconciliation initiatives; reporting on human rights and protection concerns; and other initiatives that seek to protect civilians through communications, dialogue and direct or indirect engagement.
                  </p>
                </td>
                <td>
                  <p className="un-green title">Tier II:</p>
                  <p className="subtitle">Provision of physical protection</p>
                  <p>
                    Tier II encompasses activities by all mission components to physically protect civilians, whether through protective presence, interpositioning, the threat or use of force, or facilitating safe passage or refuge. Notably, Tier II includes activities by uniformed components involving the show or use of force to prevent, deter, pre-empt and respond to threats to civilians. However, civilian mission components can also act as a protective presence through their regular, visible and direct engagement with civilian populations at risk.
                  </p>
                </td>
                <td>
                  <p className="un-green title">Tier III:</p>
                  <p className="subtitle">
                    Establishment of a protective environment
                  </p>
                  <p>
                    Tier III activities are frequently programmatic in nature and designed with committed resources for peacebuilding and conflict prevention/resolution objectives. Sometimes presented as separate mandated tasks under country-specific resolutions, activities under Tier III help create a protective environment for civilians, prevent the (re-)emergence of threats of physical violence, support the legitimacy of the host state and its capacity to protect civilians, and support the (re-)establishment of the rule of law and criminal justice chain.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            The three tiers are mutually accommodating and reinforcing and are implemented simultaneously and strategically in accordance with the mission mandate, mission phase and the circumstances on the ground. There is no inherent hierarchy or sequencing between the tiers.
          </p>
          <p>
            The protection of civilians requires actions with both short-term and long-term outlook, based on an analysis of the environment, the phase of the conflict, if any, and the mission&apos;s lifecycle, as well as the nature of the threat. The strategic approach to POC, and the three tiers, are therefore implemented along four phases:
          </p>

          <div className="grid-four">
            <div className="box un-green">
              <header>Prevention</header>
              <p>
                where <i><strong>no clear threat</strong></i> to civilians has been identified (longer term)
              </p>
            </div>
            <div className="box un-green">
              <header>Pre-emption</header>
              <p>
                where <i><strong>likely threats</strong></i> are identified and attacks against civilians are <i><strong>anticipated</strong></i> (short term)
              </p>
            </div>
            <div className="box un-green">
              <header>Response</header>
              <p>
                where attacks against civilians are <i><strong>imminent</strong></i> or <i><strong>occurring</strong></i> (short term)
              </p>
            </div>
            <div className="box un-green">
              <header>Consolidation</header>
              <p>
                where violence against civilians is <i><strong>subsiding</strong></i> (longer term)
              </p>
            </div>
          </div>

          <div className="start-third margin-top">
            <p>
              Similar to the tiers, these phases are not mutually exclusive and can overlap. There can be phases to individual threats in specific places and at specific times, and there can be phases to entire conflicts. The same action by the mission can potentially address various phases simultaneously.
            </p>
            <div className="horizontal-flow">
              <div>
                <header>Prevention</header>
                <p>Threat is latent (risk)</p>
              </div>
              <div className="border-dashed un-green" />
              <div>
                <header>Pre-emption</header>
                <p>Threat is identified and likely or actual</p>
                <footer>Response</footer>
              </div>
              <div className="border-dashed un-green" />
              <div>
                <header>Consolidation</header>
                <p>Threat has been mitigated/eliminated</p>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      index: '2.3',
      name: 'Relating POC in Peacekeeping To Other Protection Mandates',
      title: t('handbook.chapters.chapter2.3.title'),
      content: (
        <>
          <p>
            The protection of civilians in peacekeeping is linked to other UN protection mandates both within and beyond the peacekeeping operation.
          </p>
          <div className="flow-hide 2-3-1">
            <h5>
              <span className="un-green">2.3.1</span>Within the peacekeeping operation
            </h5>
            <p>
              The POC mandate in UN peacekeeping is grounded in international law, including international humanitarian law, international human rights law and international refugee law, and reflects the desire of the Security Council to protect civilians from harm. As such, the POC mandate in peacekeeping is also linked to other protection mandates given to peacekeeping missions by the Security Council, in particular those on human rights, children and armed conflict (CAAC), conflict-related sexual violence (CRSV) and rule of law. The implementation of the POC mandate should support and reinforce the Women, Peace and Security (WPS) agenda. Interpreting the scope of the POC mandate therefore requires consideration of the interaction and complementarity of these other protection mandates.
            </p>
            <table className="examples underborder">
              <thead>
                <tr>
                  <td colSpan="2">Other Peacekeeping Protection Mandates</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bold">Human Rights</td>
                  <td>
                    <ul className="triangle">
                      <li>
                        Protection and prevention based on law, advocacy and capacity-building
                      </li>
                      <li>
                        Addresses everyone and all human rights, not only physical integrity
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="bold">Children and Armed Conflict (CAAC)</td>
                  <td>
                    <ul className="triangle">
                      <li>Focuses on children</li>
                      <li>
                        Built around the six grave violations (killing and maiming, recruitment or use, sexual violence, abduction, attacks against schools or hospitals, denial of humanitarian access)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="bold">Conflict-Related Sexual Violence (CRSV)</td>
                  <td>
                    <ul className="triangle">
                      <li>Focuses on women, girls, boys and men</li>
                      <li>
                        Requires a conflict-nexus, does not include all sexual and gender based violence (SGBV)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="bold">Rule of Law</td>
                  <td>
                    <ul className="triangle">
                      <li>
                        Protection and prevention based on support to criminal justice institutions (police, justice and corrections)
                      </li>
                      <li>
                        Focuses on combatting impunity through investigations and prosecutions
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              In understanding how the POC mandate mainstreams and relates to other peacekeeping protection mandates, missions should take into account:
            </p>
            <ul className="square un-green">
              <li>
                The Human Rights up Front initiative, a system-wide approach to identifying and preventing serious violations of international human rights and humanitarian law.
              </li>
              <li>
                The integration of human rights components within the mission structure and their role in monitoring and reporting on violations, early warning, capacity building and technical assistance to the host state.
              </li>
              <li>
                Specific initiatives under the CAAC and CRSV mandates such as their monitoring and reporting systems (Monitoring and Reporting Mechanism [MRM] and Monitoring, Analysis and Reporting Arrangements [MARA], respectively) and the process of listing persistent violators in reports of the Secretary-General.
              </li>
            </ul>
            <p>
              To ensure coherence and complementarity between the mission&apos;s protection mandates, there should be close coordination and cooperation between mission advisers and experts on the various protection mandates, including POC, child protection, women&apos;s protection, gender, police, justice, corrections and human rights personnel (including where child protection and women&apos;s protection are consolidated within human rights). These protection mandates should be seen as mutually reinforcing and supportive and efforts should be made to find ways to achieve mandated goals in an efficient way through coordination and cooperation.
            </p>
            <div className="case-study un-green">
              <h3>CASE STUDY</h3>
              <h4>Shabunda Action Plan in DRC</h4>
              <div className="content">
                <p>
                  From November 2017, an increasing number of attacks against civilians by armed groups in several villages in Kigulube, in Shabunda territory, South Kivu, were reported. These attacks involved rapes, gang rapes, abductions, recruitments of children, forced labour, cruel, inhuman and degrading treatment, forced displacement and looting. To address the situation, MONUSCO, jointly with UN agencies and the Protection Cluster, developed a comprehensive &apos;Shabunda Action Plan&apos;, which was launched in 2019. The plan addressed:
                </p>
                <p>
                  1) restoration of state authority through increased national police and armed forces presence and capacity; 2) fight against impunity through technical and logistical support to military justice, investigation and prosecution of grave human rights abuses; 3) preventive engagement with armed group commanders on a roadmap to end CRSV, recruitment of child soldiers and other grave violations of child rights; and 4) community engagement, including with women and youth associations, to establish early warning mechanisms and protection networks and to promote social cohesion.
                </p>
                <p className="un-green bold large">
                  As a result of the Shabunda Action Plan…there was a significant decrease of CRSV in Shabunda in the first six months of 2019.
                </p>
                <p>
                  As a result, additional national security actors were deployed to the area. MONUSCO ensured that the officers deployed were trained in human rights, CRSV, child protection and POC. Support was also provided to the Congolese military justice system in investigations. A protection plan for victims/survivors and witnesses was developed by MONUSCO, UNHCR (through INTERSOS), and the Taskforce for Justice, composed of both national and international non-governmental organizations (NGOs). On 26 March 2019, &apos;Kokodikko,&apos; the commander of the armed group Raia Mutomboki, and his deputy were arrested by the national armed forces. Other combatants from the same faction surrendered following the arrest. Mobile court sessions began in September 2019. MONUSCO also sought to engage with the faction and other armed actors in the area to sensitize them to protection concerns. MONUSCO further worked to sensitize community mediators and civilians from Shabunda, with links to Raia Mutomboki, to pass advocacy messages on the need to end child recruitment and sexual violence. As a result of these combined efforts, the armed group was effectively neutralized.
                </p>
                <div className="relative">
                  <img src={actionPlan} alt="Shabunda action plan" />
                  <div className="description">
                    <p>
                      MONUSCO launches several Joint Protection missions in response to massive sexual violence and human rights violations documented in Shabunda territory of South Kivu. (2019)
                    </p>
                  </div>
                  <p className="ref">UN Photo/MONUSCO</p>
                </div>
                <p>
                  The Mission also supported local capacity building and leadership through structured community dialogue to promote social cohesion enabling participants to work together to map conflicts and agree on remedial actions. MONUSCO strengthened its community-based early warning mechanisms, conducted engagement activities, and set up a communication network composed of women, youth and state security representatives. As a result of the Shabunda Action Plan, in addition to the progress towards accountability, there was a significant decrease of CRSV in Shabunda in the first six months of 2019.
                </p>
                <p>
                  MONUSCO and other UN actors continue to work on other identified conflict drivers for a more sustainable impact of local actions, including advocacy for increased and continued presence of state authority as well as improving the road infrastructure and communication network along the Walungu-Kigulube axis. These improvements are crucial for humanitarian and security force access to Kigulube as well as for economic development.
                </p>
              </div>
            </div>
          </div>
          <div className="flow-hide 2-3-2">
            <h5>
              <span className="un-green">2.3.2</span>Other UN protection mandates beyond peacekeeping<sup>10</sup>
            </h5>
            <p>
              Different UN actors deployed to the same country or situation will have different mandates and roles with regard to the protection of civilians, and protection more broadly, and may use different definitions or work under different parameters. To identify complementarities and areas for coordination and cooperation (see chapter 6), it is therefore useful to understand their mandates and roles. It may also be necessary to distinguish the mandates of the different actors for the benefit of host state authorities, armed actors and local communities, who may perceive all UN entities to be the same.
            </p>
            <table className="striped bordered un-green">
              <thead>
                <tr>
                  <td>UN ACTOR</td>
                  <td>PROTECTION ROLE</td>
                  <td>SOURCE OF THE MANDATE</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>UNHCR</td>
                  <td>
                    Refugees and Internally Displaced Persons (IDPs) and lead agency for the Protection Cluster
                  </td>
                  <td>GA Resolution A/RES/428(V) (1950)</td>
                </tr>
                <tr>
                  <td>UNICEF</td>
                  <td>Children and adolescents</td>
                  <td>GA Resolution A/RES/57(I) (2002)</td>
                </tr>
                <tr>
                  <td>OCHA</td>
                  <td>Coordination of humanitarian action</td>
                  <td>GA Resolution A/RES/46/182 (1991)</td>
                </tr>
                <tr>
                  <td>WFP</td>
                  <td>Food as an aid to economic and social development</td>
                  <td>GA Resolution A/RES/1714(XVI) (1961)</td>
                </tr>
                <tr>
                  <td>OHCHR</td>
                  <td>All human rights of all people</td>
                  <td>GA Resolution A/RES/48/141 (1993)</td>
                </tr>
                <tr>
                  <td>UN Women</td>
                  <td>
                    Gender equality, empowerment and the advancement of women and girls
                  </td>
                  <td>GA Resolution A/RES/64/289 (2010)</td>
                </tr>
                <tr>
                  <td>SRSG CAAC</td>
                  <td>
                    Protection and well-being of children affected by armed conflict and CAAC as a peace and security issue
                  </td>
                  <td>
                    GA Resolution A/RES/51/77 (1997) &amp; SC Resolution S/RES/1261 (1999)
                  </td>
                </tr>
                <tr>
                  <td>SRSG SVC</td>
                  <td>
                    Conflict-related sexual violence (CRSV) as a peace and security issue
                  </td>
                  <td>SC Resolution S/RES/1888 (2009)</td>
                </tr>
                <tr>
                  <td>TOE-RoL SVC</td>
                  <td>
                    Criminal accountability for CRSV through investigations and prosecutions
                  </td>
                  <td>SC Resolution S/RES/1888 (2009)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )
    }
  ],
  footnotes: {
    content: (
      <ol type="1" start="7" className="refs">
        <li className="2-1-1">
          S/RES/2155 (2014). Following the Revised Agreement on the Resolution of Conflict on the Situation in South Sudan (R-ARCSS), subsequent UNMISS mandates have reinserted language on support to the state. See, e.g., S/RES/2459 (2019).
        </li>
        <li className="2-1-1">S/RES/1894 (2009) OP 19.</li>
        <li className="2-1-2">
          The principle of non-refoulement, reflected in different bodies of international law, protects a person from being transferred from one authority to another when there are substantial grounds for believing that the person would be at risk of being subjected to irreparable harm, including persecution, torture, ill-treatment, or other serious human rights violations.
        </li>
        <li className="2-3-2">
          Some protection mandates apply both within and beyond peacekeeping and are therefore presented twice in these sections. For example, the Office of the High Commissioner for Human Rights (OHCHR) is both integrated within peacekeeping operations and has a mandate beyond peacekeeping operations.
        </li>
      </ol>
    )
  }
};
