import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Redirect } from 'react-router-dom';
import PageWrapper from '../components/wrapper';
import Sidebar from './components/sidebar';
import Content from './components/content';
import Audio from './components/audio';
import Resource from './components/resource';
import access from '../config/access';
import { getChapterIndexFromName } from '../helpers';
import CustomStyles from './components/customStyles';

const hasConfig = (role, area, tab) => tab === 'recommended' && !!access[`${role}-${area}`];

const Book = ({ location, cache }) => {
  const chapterIndex = getChapterIndexFromName(cache.chapter);

  if (chapterIndex === 'no-index-available') {
    return (
      <Redirect to={{ pathname: '/404', state: { from: location } }} />
    );
  }

  return (
    <PageWrapper page="handbook">
      <CustomStyles area={cache.area} role={cache.role} tab={cache.tab} />
      <div className="handbook">
        <Sidebar />
        <div
          className={cn('content', chapterIndex, {
            closed: cache.showSidebar,
            'has-flow': hasConfig(cache.role, cache.area, cache.tab)
          })}
        >
          { cache.tab && cache.tab !== 'audio' && cache.tab !== 'resources' && (
            <Content location={location} />
          ) }
          { cache.tab && cache.tab === 'audio' && (
            <Audio />
          ) }
          { cache.tab && cache.tab === 'resources' && (
            <Resource location={location} />
          ) }
        </div>
      </div>
    </PageWrapper>
  );
};

Book.propTypes = {
  cache: PropTypes.shape({
    chapter: PropTypes.string,
    tab: PropTypes.string,
    showSidebar: PropTypes.bool,
    role: PropTypes.string,
    area: PropTypes.string
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.string
  }).isRequired
};

export default Book;
