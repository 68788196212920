import React from 'react';
import joint from '../images/chapters/chapter12/joint_MONUSCO_FARDC_operation.jpg';
import assesment from '../images/chapters/chapter12/MINUJUSTH_strategic_assesment.jpg';
import justice from '../images/chapters/chapter12/MINUSMA_justice_and_reconciliation.jpg';
import tailored from '../images/chapters/chapter12/MONUSCO_tailored_armed_group_strategies.jpg';
import support from '../images/chapters/chapter12/prosecution_support_cells.jpg';
import firewood from '../images/chapters/chapter12/UNMISS_firewood_patrol.jpg';
import AudioPlayer from '../components/audio';
import audio from './audio';
import marcellaFavrettoAvatar from '../images/avatars/Marcella_Favretto.png';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 12: Responding to POC Threats',
  title: t('handbook.chapters.chapter12.title'),
  index: '12',
  color: 'un-red',
  content: (
    <>
      <div className="grid-three un-green">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            Once threats to civilians have been identified, analysed and prioritized, a peacekeeping operation must identify strategic response activities using, as appropriate, the full range of tools and capabilities available.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            Determining the appropriate POC response in a coordinated and structured way at the strategic, operational and tactical levels ensures that all relevant mission components are engaged and understand their roles and responsibilities. It is also important to ensure that the tools of different mission components are mutually reinforcing in response to threats to civilians. An organized strategic response also allows for easier and more consistent communication with relevant stakeholders including host states, communities at risk and humanitarian actors.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            Across the four response phases, missions can adopt various approaches and activities to respond to threats to civilians. Depending on the threats and context, different responses may be appropriate. Missions should utilize and balance the different options available.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            Responses should be planned at the strategic, operational and tactical levels, as appropriate, and can engage all civilian, police and military personnel. Mission leadership should oversee and advise on the overall strategic approach of the mission, with support from POC Advisers.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            Responses to POC threats should be undertaken at mission field offices and mission headquarters, with support from UN Headquarters as necessary, depending on the imminence, impact and type of threat.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            Missions respond to threats to civilians continually throughout the lifecycle of a mission along the four response phases.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '12.1',
      name: 'Comprehensive and Political Approach to POC',
      title: t('handbook.chapters.chapter12.1.title'),
      content: (
        <>
          <p>
            In accordance with the DPO POC Policy, a peacekeeping operation should take a comprehensive approach to the protection of civilians. This involves jointly planned and coordinated action between uniformed and civilian components.
          </p>
          <p>
            A comprehensive approach to POC relies on a detailed threat assessment (see chapter 8), which includes analysis of the intent, interests, resources and capabilities of perpetrators, the related risks to civilians and possible opportunities to affect hostile intent. The mission will then jointly develop response strategies that identify and integrate civilian, police and military responses appropriate to the nature of the threat and which address both immediate protection needs and the root causes of the threat to civilians. While the overall mission POC
            strategy and identification of strategic priorities will be developed at the strategic level, the detailed response to specific threats will often be developed at operational or tactical level under the leadership of the head of field office and sector commander.
          </p>
          <p>
            In their comprehensive approach, peacekeeping missions are recommended to take a political approach to POC — that is, one which strategically aligns POC with the broader political context and aims of the mission. A political approach to POC should aim to:
          </p>
          <ul className="square un-red">
            <li>
              Understand and reduce/negate the hostile intent and capacity of perpetrators of violence against civilians;
            </li>
            <li>
              Mobilize influencers and other protection actors; and
            </li>
            <li>
              Manage perceptions and expectations from the population.
            </li>
          </ul>
          <p>
            This approach may require a range of activities under the three tiers of the POC operational concept in accordance with the type and imminence of the threat.
          </p>

          <div className="case-study margin-top">
            <h3>Case Study</h3>
            <h4>MONUSCO Tailored Strategies for Speciﬁc Armed Groups</h4>
            <div className="content">
              <div className="half">
                <div>
                  <p>
                    In MONUSCO, tailored strategies for dealing with armed groups have been developed that respond to the complex and diverse environment throughout the country. These strategies identify the role of different mission components, in line with broader mission analysis, of the advantages, disadvantages and challenges of engaging with the particular armed group or using a specific approach. Based on political engagement at all levels, the strategies aim to provide MONUSCO with a more effective way of clarifying and operationalizing protection priorities and prioritizing the primacy of politics.
                  </p>
                  <p>
                    One example is the strategy implemented in Ituri regarding the Patriotic Resistance Front in Ituri (FRPI). The process has been driven largely from the bottom up by MONUSCO’s Stabilization and Support Unit (SSU), aligning the work of all sections through a community-based approach. A key element of the strategy has been to link MONUSCO’s military operations and civilian activities with political initiatives at the local, provincial and national levels.
                  </p>
                </div>
                <div>
                  <div className="relative">
                    <img src={tailored} alt="MONUSCO tailored armed group strategies" />
                    <div className="description">
                      <p>
                        MONUSCO’s DSRSG meets with the DRC’s armed forces to discuss the development of a multi-faceted strategy for the neutralization of armed groups. (2016
                      </p>
                    </div>
                    <p className="ref">UN Photo/Michael Ali</p>
                  </div>
                  <p>
                    At the local level, MONUSCO staff worked with civil society and government partners to engage the armed group in dialogue. Based on the dialogue, local government officials created a roadmap for demobilizing the FRPI. Political officials at higher levels within the Mission identified and engaged high-level political key influencers and spoilers with links to local communities and the conflict to ensure national and regional political buy-in. Further efforts are underway to embed protection analysis and threat data within these tailored strategies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      index: '12.2',
      name: 'POC Along the Lifecycle of a Mission',
      title: t('handbook.chapters.chapter12.2.title'),
      content: (
        <>
          <p>
            Peacekeeping missions operate in dynamic conflict and political environments. Threats to civilians will vary and change over time and this requires balancing efforts and mandated tasks over the lifecycle of a mission, adjusted to the specific threat at hand. Should a mission face a high level of instability, protecting civilians may require securing communities at risk, facilitating their access to humanitarian assistance and mitigating or stopping ongoing hostile acts as a priority. Should a mission operate in a context where violence has subsided or no clear threat to civilians is identified, its POC efforts can focus on preventing and eliminating POC threats sustainably by addressing root causes and/or preventing the relapse of violence through environment building (i.e., stabilization or peacebuilding activities). Often missions will face different conflict environments within the same country and will need to tailor their approach to different threats in different geographical areas, based on customized local protection plans. At all times, missions must ensure full respect for international human rights law, and where applicable, international humanitarian law, and must implement the Human Rights Due Diligence Policy. They must also mitigate possible harm to civilians posed by the mission&apos;s presence or actions.
          </p>
          <p>
            Peacekeeping operations are necessarily temporary measures. They will eventually go through transition processes resulting from the drawdown or withdrawal of the peace operation, or a transition from a peacekeeping operation to a smaller peacekeeping mission, special political mission or UN Country Team (UNCT). In many cases, a transition will mark progress away from conflict and towards sustaining peace. In such situations, host state authorities will have been supported and given the capacity to effectively assume their primary protection responsibilities. In other cases, the transition may not coincide with such success. Here it will be necessary for the mission to articulate the protection concerns that will remain following the mission&apos;s drawdown or departure, in order to inform the Security Council of the impact. In any transition, the mission will need to coordinate with other protection actors to determine what protection functions of the mission may be continued via other means, and where there may be gaps.
          </p>
          <div className="relative margin-top">
            <img src={assesment} alt="MINUJUSTH strategic assessment" />
            <div className="description">
              <p>
                A Strategic Assesment Mission meets with MINUJUSTH personnel and the UNCT to discuss different options available for the UN’s continued presence in the country. (2019)
              </p>
            </div>
            <p className="ref">UN Photo/Leonora Baumann</p>
          </div>
        </>
      )
    },
    {
      index: '12.3',
      name: 'POC Phases and Potential Actions',
      title: t('handbook.chapters.chapter12.3.title'),
      content: (
        <>
          <p>
            <strong className="un-red">Actions by the mission to address threats to civilians are implemented along four phases: (i) prevention,(ii) pre-emption, (iii) response and (iv) consolidation to either eliminate a threat or mitigate the risk to civilians. These phases do not necessarily occur in sequential order and may be undertaken simultaneously or independently. </strong>Each phase involves actions across all three tiers of the POC operational concept (protection through dialogue and engagement, provision of physical protection and establishment of a protective environment).
          </p>
          <p>
            Activities and objectives undertaken along these phases will vary according to the specific content of each country-specific mandate and nature of each threat. The mission approach may apply to a specific geographic area and can be framed around core protection objectives. Short term actions will focus on putting a stop to violence and limiting the effects of violence on civilians while longer term action will focus on preventing violence and promoting accountability of perpetrators.
          </p>
          <h5>Prevention and consolidation phases</h5>
          <p>
            In a context or area where the overall security environment is stable and where threats to civilians are distant, unlikely or subsiding, a mission must focus on further stabilizing the situation, addressing the root causes of the conflict and leading the way to sustainable peace. Efforts to these ends can include:
          </p>
          <ul className="square un-red">
            <li>
              Preventing the resumption of conflict, including threats to civilians, through monitoring of and support to the peace process;
            </li>
            <li>
              Securing the voluntary, safe, dignified and sustainable return of IDPs and refugees;
            </li>
            <li>
              Enhancing community resilience, through support to humanitarian, early-recovery or development assistance;
            </li>
            <li>
              Supporting the efforts of civil society and national human rights institutions in performing their monitoring roles to prevent violations and ensure respect for human rights;
            </li>
            <li>
              Conducting stabilization and peacebuilding activities, including to durably eliminate and mitigate potential threats to civilians; and
            </li>
            <li>
              Building the capacity of the host state in support of the protection of civilians and the rule of law.
            </li>
          </ul>
          <h5>Pre-emption and response phases</h5>
          <p>
            In a context or area where the peace process is disrupted, or there is no peace to keep; where violence against civilians is frequent or highly likely; where the state lacks capacity or willingness to protect populations; and/or where elements of the state security forces themselves pose a threat to civilians, a mission must address the immediate causes of the conflict and focus on restoring security, in priority areas, for the civilian population. Efforts to these ends encompass:
          </p>
          <ul className="square un-red">
            <li>
              Deterring and mitigating threats to civilians, including through public messaging, direct engagement and credible deterrence, supported as required by security operations;
            </li>
            <li>
              Securing communities at risk, key civilian assets, services and livelihoods;
            </li>
            <li>Facilitating access to humanitarian assistance; and</li>
            <li>
              Conducting early stabilization activities in prioritized POC areas.
            </li>
          </ul>
          <h5>Prevention:</h5>
          <p>
            To prevent violence by non-state armed groups or state security forces, intercommunal violence, serious crimes or other situations that may lead to violence against civilians, there are many direct and indirect supportive actions that a mission can take, including:
          </p>
          <ul className="square un-red">
            <li>
              Promoting human rights, including gender equality, women&apos;s and children&apos;s rights, by:
              <ul className="triangle un-red">
                <li>conducting public information campaigns;</li>
                <li>
                  training state security forces on human rights and IHL; and
                </li>
                <li>
                  training local communities and civil society on monitoring human rights.
                </li>
              </ul>
            </li>
            <li>
              Supporting the extension of the state and its authority across its territory and along its borders, by:
              <ul className="triangle un-red">
                <li>
                  promoting good governance, respect for human rights and the rule of law, particularly the criminal justice chain and prison security.
                </li>
              </ul>
            </li>
            <li>
              Ensuring a visible presence of UN military and police components, particularly in areas at risk where the state security forces are not present, by:
              <ul className="triangle un-red">
                <li>
                  assuring the population of the mission&apos;s intent to protect them from physical violence; and
                </li>
                <li>establishing community engagement and alert mechanisms.</li>
              </ul>
            </li>
            <li>
              Monitoring and investigating violations of international human rights, humanitarian and refugee law, including conflict-related sexual violence and grave violations against children, by:
              <ul className="triangle un-red">
                <li>
                  advocating for respect for international human rights and humanitarian law with potential perpetrators; and
                </li>
                <li>
                  seeking accountability for violations at the national and international levels.
                </li>
              </ul>
            </li>
            <li>
              Mitigating political, economic, identity, community or housing, land or property-related conflicts that may escalate into physical violence, by:
              <ul className="triangle un-red">
                <li>the provision of good offices;</li>
                <li>
                  supporting the establishment of conflict resolution or statutory, traditional or transitional justice mechanisms; and
                </li>
                <li>alerting the national authorities of those risks.</li>
              </ul>
            </li>
            <li>
              Monitoring and addressing vulnerabilities, by:
              <ul className="triangle un-red">
                <li>
                  supporting access to justice for vulnerable and marginalized groups;
                </li>
                <li>
                  disseminating information on rights and potential risks (including explosive ordnance and other explosive hazard risk education);
                </li>
                <li>
                  supporting community-based protection mechanisms, such as traditional justice mechanisms;
                </li>
                <li>
                  building capacity of civil society, particularly women&apos;s organizations, and media; and
                </li>
                <li>
                  supporting and securing as necessary the provision of humanitarian assistance.
                </li>
              </ul>
            </li>
          </ul>

          <div className="relative">
            <img src={firewood} alt="UNMISS firewood patrol" />
            <div className="description">
              <p>
                UNMISS Force patrols a road near a POC site in an area where women have reported cases of harassment and abuse while collecting firewood. (2015)
              </p>
            </div>
            <p className="ref">UN Photo/UNMISS</p>
          </div>

          <ul className="square un-red">
            <li>
              Marking, fencing and clearing areas contaminated by explosive ordnance, developing improvised explosive- device threat-mitigation strategies, and ensuring safe and secure weapons and ammunition storage and management.
            </li>
            <li>
              Ensuring the inclusion of and subsequent implementation of international human rights, child protection, women&apos;s protection and gender standards in peace agreements.
            </li>
            <li>
              Monitoring, controlling and addressing illegal trafficking and the presence of small arms.
            </li>
            <li>
              Supporting the establishment and protection of key infrastructure, including roads, transport and communications in remote and inaccessible areas.
            </li>
            <li>
              Coordinating and cooperating with humanitarian and development actors to promote income-generation activities for youth in areas where conflict may arise.
            </li>
            <li>
              Consulting women and promoting their participation in the design and implementation of gender-sensitive activities to respond to their protection needs.
            </li>
            <li>
              Strengthening capacity of the state and civil society to promote gender equality and women&apos;s rights through their empowerment.
            </li>
            <li>
              Supporting local peace agreements and ceasefires.
            </li>
          </ul>
          <p>
            The mission can also take action towards preventing violations of international human rights law, international humanitarian law and international refugee law, as well as to prevent and minimize collateral damage and other direct or indirect lawful harm caused by state security forces, UN and other international security forces, as applicable, by:
          </p>
          <ul className="square un-red">
            <li>
              Ensuring that relevant national, UN and other international security forces are sensitized to, and receive adequate training on applicable international human rights law and international humanitarian law standards, as well as criminal responsibility of public servants, including security sector officers, for human rights violations and the commission of crimes;
            </li>
            <li>
              Supporting national authorities in establishing or strengthening the criminal justice chain (including military justice) to investigate, prosecute and adjudicate individuals responsible for serious crimes and human rights violations; and
            </li>
            <li>
              Establishing monitoring, reporting, control and accountability mechanisms to track, prevent and address violations of international humanitarian, human rights and refugee law and all other casualties caused by state and international security forces.
            </li>
          </ul>
          <h5>Pre-emption:</h5>
          <p>
            When likely threats are identified and attacks against civilians are anticipated, proactive measures are required to mitigate or eliminate the threats before violence occurs. This requires deterring a party or person(s) from committing acts of violence against civilians, or affecting their capacity to do so, including through the threat or use of force. <strong className="un-red">Activities under the prevention phase should continue to be implemented, particularly in areas under threat.</strong>
          </p>
          <p>
            To ensure a sustainable impact, pre-emption activities should preferably, and as appropriate, be conducted in support of state authorities, by mobilizing or persuading them to act. Measures may include:
          </p>
          <ul className="square un-red">
            <li>
              Public information on POC and human rights reporting, as well as proactive engagement and advocacy with potential perpetrators of violence and third parties that may positively influence the actions of perpetrators. Engagement will aim at sensitizing them on their obligations under international human rights law, international humanitarian law and international refugee law and about existing accountability mechanisms.
            </li>
            <li>
              Increased political engagement and use of good offices functions to mediate, calm tensions and engage local, national and international intermediaries and influencers who may de-escalate a rise in tensions.
            </li>
            <li>
              Actions to mobilize, persuade and support state authorities to extend their presence and ensure rule of law and public order in the area(s) under threat, particularly by applying the required accountability, conflict resolution and reconciliation mechanisms.
            </li>
            <li>
              Security operations, whether conducted unilaterally, in coordination, or jointly with the host authorities. These can entail credible deterrence actions or engaging in offensive operations to prevent violence against civilians. Commanders should, where appropriate, consider the alternatives to the use of force. Those may include (but are not limited to) deception, psychological methods, negotiation, rapid deployments and reinforced military and/or police presence and patrolling, the protection of key sites, facilities (including prisons), areas or goods, cordon and search operations, counter improvised explosive device (IED) activities, interposition and show of force and/or the manoeuvre of larger forces to demonstrate resolve. To enhance impact, all security operations should be implemented in support of and/or in close coordination with judicial or non-judicial accountability processes conflict-resolution processes and disarmament, demobilization and reintegration (DDR) and/or security sector reform (SSR) mechanisms.
            </li>
            <li>
              Proactive engagement, advocacy and support to relevant state and international institutions to help them identify, take action against and/or apprehend members/personnel who have perpetrated violence against civilians. Particularly, the mission will aim to reinforce prevention mechanisms in the area under threat and ensure relevant state authorities investigate and take action to punish perpetrators in areas most affected by human rights violations or instances of disproportionate collateral damage.
            </li>
            <li>
              Consistent implementation of the Human Rights Due Diligence Policy on United Nations support to non-UN security forces (HRDDP), which will further strengthen the POC mandate implementation and provide peacekeeping operations with leverage to influence behaviour and establish harm-mitigation measures.
            </li>
          </ul>
          <h5>Response:</h5>
          <p>
            Whenever physical violence against civilians has materialized, missions should aim immediately to stop aggressors from conducting hostile acts.
          </p>
          <p>
            To ensure a sustainable impact, activities should preferably, and as appropriate, be conducted in support of state authorities, by mobilizing or persuading them to act. Measures, <strong>in addition to those already described in the prevention and pre-emption phase, </strong>may include:
          </p>
          <ul className="square un-red">
            <li>
              Urgent engagement with parties to the conflict at local and national level, calling for a halt to hostilities, and use of intermediaries and influencers to promote peace.
            </li>
            <li>
              Actions to further mobilize, persuade and support state authorities to extend their presence and ensure human rights, rule of law and public order in the area(s) affected by violence, particularly by applying the required conflict resolution, judicial, DDR or SSR mechanisms and by providing any additional training or expert advice, as required.
            </li>
            <li>
              Direct action targeted at perpetrators to affect their intent or capacity, including through security operations and the gradual use of force to stop ongoing violence or control and disperse assemblies of potential perpetrators.
            </li>
            <li>
              Should the mission lack the capacity or political space to stop aggressors, the provision of direct physical security to civilians at risk or accompaniment and provision of security to their movement to more stable areas, with due regard for their expressed wishes.
            </li>
            <li>
              Use of force, in accordance with the military ROE and the police DUF, including to apprehend and temporarily detain hostile persons or groups and, where appropriate, hand them over to the national authorities<sup>35</sup>.
            </li>
            <li>
              Actions to collect, register and preserve evidence of crimes against humanity, war crimes, genocide, other serious crimes, including sexual violence, as well as grave violations of human rights, for subsequent criminal investigation and prosecution of alleged perpetrators at national or international level.
            </li>
          </ul>

          <div className="voices un-red">
            <div className="avatar">
              <img alt="Marcella Favretto" src={marcellaFavrettoAvatar} />
            </div>
            <h3>VOICES FROM THE FIELD</h3>
            <div className="content">
              <h4>Negotiating Release of Tortured Detainees</h4>
              <h5>Marcella Favretto, UNAMSIL Human Rights Officer (2000-2003)</h5>
              <div className="half">
                <div>
                  <p>
                    In 2000, I was deployed as Human Rights Officer to UNAMSIL. At that time, the POC mandate was relatively new and almost invisible. One day, together with other human rights colleagues and peacekeeping troops, I went on a field visit to Makeni, headquarters of the rebel group the Revolutionary United Front (RUF), six months before their disarmament. It was the first time civilian peacekeepers were deployed in this area where many human rights violations had occurred and continued, including mass enslavement and rape of women and girls, killings and enforced disappearances.
                  </p>
                  <cite>
                    &quot;We realized immediately how dire the situation was, as detained members of the RUF were subject to torture and inhumane treatment.&quot;
                  </cite>
                  <p>
                    When we reached Makeni, we approached the RUF headquarters and could hear the screams of a man being tortured. We realized immediately how dire the situation was, as detained members of the RUF were
                  </p>
                </div>
                <div>
                  <p>
                    subject to torture and inhumane treatment. We tried to intervene to obtain the release of the man, but we were threatened. We immediately went to the Commander of the UN battalion deployed to the area and informed him we needed troops to return to Makeni to show force and try to get the detainees released. We were all aware that the credibility of the UN was at stake as the civilian population expected us to protect them.
                  </p>
                  <p>
                    Together with the UN troops, we returned to the RUF headquarters and negotiated for the release of the detainees, including the man who was being tortured. The involvement of the UN military was fundamental not only to showing force, but also for providing immediate medical care to the torture victim. In the following months, we engaged the community who provided us with information on more detention centres and cases of torture. We continued negotiating the release of detainees from these centres, as well as the release of enslaved women who we helped transport back to their homes.
                  </p>
                  <p>
                    We were only three civilian staff deployed to this region, but working together with the military component, who had the will to enforce the mandate, we were able to make a difference and protect civilians.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h5>Consolidation:</h5>
          <p>
            When violence against civilians is subsiding, the mission will support the progressive return to stability and normalcy. These activities will be conducted, as required, in support of or in close coordination with host authorities and the UN Country Team and other partners.
          </p>
          <p>
            Specifically, the mission may, inter alia:
          </p>
          <ul className="square un-red">
            <li>
              Contribute to creating the conditions conducive to the voluntary, safe, dignified and sustainable return or local integration of refugees and IDPs, in coordination with national authorities and humanitarian partners.
            </li>
            <li>
              Support the reintegration of ex-combatants, and of children formerly associated with armed forces or groups.
            </li>
            <li>
              Support, through ensuring conducive security conditions, the provision of civilian-led humanitarian, rehabilitation and/or recovery assistance and promote the compensation of victims of violence, as applicable.
            </li>
            <li>
              Accompany the implementation of local peace and reconciliation processes, including supporting women&apos;s leadership and participation, as well as through statutory, traditional or transitional justice mechanisms.
            </li>
            <li>
              Take steps to fight impunity and further restore or extend the authority of the state and the rule of law, including accountability for human rights violations and transitional justice.
            </li>
          </ul>
          <AudioPlayer
            src={audio[7].file}
            title={audio[7].title}
            subtitle={audio[7].subtitle}
            avatar={audio[7].avatar}
            speaker={audio[7].speaker}
            transcript={audio[7].transcript}
          />
          <div className="voices un-red">
            <div className="avatar">
              <img alt={audio[7].speaker} src={audio[7].avatar} />
            </div>
            <h3>VOICES FROM THE FIELD</h3>
            <div className="content">
              <h4>Experience of Opening the Gates</h4>
              <h5>Kirsten Young, UNMISS Protection of Civilians Officer, Bentiu (2012-2014)</h5>
              <div className="half">
                <div>
                  <p>
                    I was deployed as POC officer in Unity State, when the crisis in South Sudan erupted in mid-December 2013. In Bentiu, we didn’t expect the scale of the crisis. While we’d made many of the necessary preparations for a crisis based on the extensive UN guidance on POC, in retrospect I often think about what more we could have done better in those first weeks for civilians seeking protection. One surprise was that while our planning had focused on the South Sudanese civilian population, they were not the only ones who came. Humanitarians and foreign nationals likewise sought protection at the UNMISS base.
                  </p>
                  <p>
                    As of the early evening of 20 December, I was OiC State Coordinator responsible for handling the crisis. By then, thousands of civilians had arrived at our base for protection. I held an open air “town hall” to inform UN personnel what we knew so far and what our preparations were. There were many people I didn’t recognize and I found out that they were humanitarian or NGO partners based in town who had come to our base for protection. In the days that followed, we did a number of extraction missions for such partners, refugees, contractors, and foreign nationals who had businesses or charities in town. This meant that the humanitarian actors to whom we were supposed to handover over responsibility for the IDPs within 72
                  </p>
                </div>
                <div>
                  <cite>
                    &quot;One surprise was that while our planning had focused on the South Sudanese civilian population, they were not the only ones who came.&quot;
                  </cite>
                  <p>
                    hours were now on our base and in the process of evacuation. UNMISS was left to fill the void.
                  </p>
                  <p>
                    In the morning of 21 December, while at the gate surveying the situation, an older Eritrean man approached to talk to me, and I realized how many foreign nationals had entered the POC site that night. The diversity of the population meant that evacuations were a priority and by 5 January, we had evacuated 1,500 foreign nationals despite intimidation from the authorities and roadblocks. This was only possible due to the support of the Deputy Chief of Staff who was coordinating and advocating with the embassies in Juba, our Kenyan Senior Military Liaison Officer who was instrumental in getting the Kenyan Government’s support for flights and our Aviation/MovCon team who drove the evacuees to the airstrip and coordinated the passengers and assets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      index: '12.4',
      name: 'Use of Force for POC',
      title: t('handbook.chapters.chapter12.4.title'),
      content: (
        <>
          <p>
            Graduated force can be applied along a continuum of three broad levels beginning with physical authoritative presence, progressing to non-deadly force and finally the use of deadly force. Depending upon the nature of the threat, peacekeepers sometimes may not have enough time to embrace gradual application of force and may have to act immediately by resorting to deadly force to avoid greater harm. The authorization to use force without following graduated procedures, which is reflected in mission-specific ROE and DUF, applies where the attack or threat of an attack comes so unexpectedly that even a moment&apos;s delay could lead to the death of, or serious bodily injury to, oneself, other United Nations personnel or other persons who are under the protection of the peacekeeping mission.
          </p>
          <p>
            <strong className="un-red">Under the POC mandate, the use of proactive force is authorized against any source of physical violence as soon as a credible threat against civilians has been identified: The protection of civilians is not limited to defensive actions to secure civilians once an attack is underway.</strong> However, the use of force should be part of a comprehensive engagement that aims to affect the intent of a perpetrator through both persuasive and coercive means. Following a political approach to POC, missions should consider political and community-based approaches, and leverage security means to support and provide credibility to a robust comprehensive engagement. Nonetheless, missions should be prepared to use force to protect civilians where required, including through proactive military and police operations when this is considered to be the most appropriate and effective response.
          </p>
          <p>
            The use of force in furtherance of POC mandates may involve UN peace operations temporarily detaining individuals who pose a threat to civilians. The deprivation of liberty in such situations gives rise to significant legal and practice issues, however, as it is not part of established criminal law processes. As peacekeeping mandates have become more robust and the nature of the tasks increasingly complex, issues concerning detention within peace operations are arising more frequently and in circumstances that are ever more challenging<sup>36</sup>. In addition to implementing the DPKO-DFS Interim Standard Operating Procedures on Detention in United Nations Peace Operations (2010) — which sets out the procedures for handling, questioning, search, transfer, handover and/or release of detained persons — mission-specific guidance may be required to ensure that persons detained by United Nations personnel are treated in accordance with international norms and standards and to reduce the risks that may arise to the UN in connection with the detention of persons by peace operations.
          </p>
          <p>
            When deciding on the balancing of civilian, police and military actions under the three tiers, and particularly when considering the use of robust measures against elements of state security forces or proxies, the mission will have several considerations. Beyond its legal authority and mandate to use force against any source of violence against civilians, a mission will need to take into account all the circumstances, including the operational consequences of any intervention.
          </p>
          <p>
            An early discussion between mission leaders and managers, as well as military and police planners, in consultation with civilian components and the POC Adviser, should clarify the general parameters:
          </p>
          <ul className="square un-red">
            <li>
              What should the military and police posture look like?
            </li>
            <li>
              What will be the POC circumstances and triggers, or red lines (both proactive and reactive) for the conduct of military or police operations?
            </li>
            <li>
              What is the adequate balancing and sequencing of political, security and environment-building POC action to effectively address the threats identified in the context, including violence committed by state security forces?
            </li>
            <li>
              What are the contingency plans, internal guidance documents and training exercises to be developed to ensure readiness to use security action with a POC lens?
            </li>
            <li>
              What are the relative roles of the police and the military for POC?
            </li>
            <li>
              What further actions will ensure adequate understanding, leadership, intent and accountability of contingents to ensure POC success?
            </li>
          </ul>
        </>
      )
    },
    {
      index: '12.5',
      name: 'Responding to Different Environments and Threats',
      title: t('handbook.chapters.chapter12.5.title'),
      content: (
        <>
          <div className="sub-section 12-5-1">
            <h5>
              <span className="un-red">12.5.1</span> Modes of protection action
            </h5>

            <p>
              The political and operational approach taken by the mission to POC, informed by the degree of willingness and capacities of the parties to protect civilians, determines what modes of actions are most appropriate at any point.
            </p>
            <table className="vertical with-border centered">
              <thead>
                <tr>
                  <td>
                    Action to ensure compliance and cooperation of perpetrators of violence
                  </td>
                  <td>
                    Action to support other protection actors including communities, civil society and local authorities
                  </td>
                  <td>
                    As a last resort, where responsible authorities are unwilling or unable to take appropriate action
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul className="square un-red">
                      <li>Robust engagement</li>
                      <li>Mobilization</li>
                      <li>Denunciation</li>
                      <li>Persuasion</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="square un-red">
                      <li>Capacity building (or sensitization)</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="square un-red">
                      <li>Substitution</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              This section sets out some standard threat categories and proposed response models. However, the approach of each mission will vary and be tailored to the specific operational theatre, either at the mission-level or for different areas of the country where the threat may vary. Many situations or risks of violence against civilians may not fall clearly under a specific threat category, or may overlap threat categories, and will require further joint analysis to identify the right triggers for effective POC action.
            </p>
          </div>
          <div className="sub-section 12-5-2">
            <h5>
              <span className="un-red">12.5.2</span> Political violence
            </h5>
            <p>
              Threats to civilians may be caused or exacerbated by the actions of national, regional or international political or economic<sup>37</sup> actors or groups who fuel conflict by organizing violence through state actors, non-state armed actors or community-based individuals or groups. This may take the form of arbitrary arrests or violent repression by state security forces, negative activities by traditional or new armed groups, or politically driven fuelling of intercommunal violence. In response, the mission can focus on curbing manipulation of conflicts by investigating and analysing the links between spoilers and actors of violence, denouncing such acts publicly and mobilizing influential national or international actors to apply pressure to stop or arrest perpetrators.
            </p>
            <p>
              Politically fuelled violence may be of concern before, during and after elections, anniversaries or milestones in a political/peace process. Most of these potential flash points can be predicted in advance and the mission will be able to implement comprehensive preventive activities and prepare contingency plans. Analysis should identify any potential links between electoral/political violence and other conflict issues such as community conflict, armed group activity or ethnic/religious violence. There are several possible foci for preventive activities, such as:
            </p>
            <ul className="square un-red">
              <li>
                Advocacy, training and mentoring of state security forces on methods/approaches to mitigate unrest or potential unrest without excessive use of force;
              </li>
              <li>
                Facilitating dialogue, engagement and conflict resolution between groups who may come into conflict;
              </li>
              <li>
                Engaging the mission&apos;s good offices function to facilitate the peace process and high-level political agreements; and
              </li>
              <li>
                Enhanced human rights monitoring and reporting.
              </li>
            </ul>
            <p>
              Contingency plans should identify key flash-point areas and issues, and plan for an escalation of response(s) should the situation deteriorate. This response should include actions by civilian and uniformed components. While it may be most appropriate to focus on dialogue and engagement with key leaders, the response plan should include the possibility of the use of force to respond to serious threats of violence against civilians.
            </p>

            <div className="toolkit">
              <h3>TOOLKIT</h3>
              <h4 className="un-red">
                How to Protect Civilians in an Election Context
              </h4>
              <div className="content">
                <h5>What to consider in an election context?</h5>
                <p>
                  Whether or not support to elections is within the peacekeeping mission mandate, local and national elections often take place in contexts where peace operations are deployed. During election periods, tensions can run high, and contentious political support groups may resort to forms of violence that threaten civilian populations. UNPOL will often be best placed within the mission to provide protection to civilians in such contexts, either directly or through support to state actors.
                </p>
                <h4 className="un-red">Checklist</h4>
                <ul className="checklist un-red">
                  <li>
                    Before and during an election period, remain abreast of political dynamics and seek forward-looking analyses. Consult Political Affairs, Electoral Affairs, Human Rights and JMAC.
                  </li>
                  <li>
                    Map critical dates, political leaders and priority zones for potential election-related tensions and violence.
                  </li>
                  <li>
                    Engage with authorities to advocate respect towards peaceful demonstrations and to restrict the use of lethal force. Provide capacity building to these ends, including human rights and public-order management training for national police, where appropriate.
                  </li>
                  <li>
                    Engage with local communities, and, where necessary, create Community Alert Networks (CANs). Build community capacity to raise election-related concerns with local authorities.
                  </li>
                  <li>
                    Where applicable, provide support to the government security plan and establish a show of presence (not a show of force).
                  </li>
                  <li>
                    Develop a network of mission staff, primarily Human Rights Officers, to deploy to potential hotspots and monitor the situation, including any election-related violence.
                  </li>
                  <li>
                    Conduct TTXs, including with Headquarters, to ensure preparedness for all potential scenarios.
                  </li>
                </ul>
                <h4 className="un-red">Reminders</h4>
                <ul className="reminders un-red">
                  <li>
                    Violence can erupt during the campaigning period, on voting day(s) or after results are announced.
                  </li>
                  <li>
                    Providing protection in an election context should be a police activity, not a military activity. Deploy FPUs where available. QRFs should be on standby as needed.
                  </li>
                  <li>
                    Election violence often occurs in an urban setting. The mission may need to adapt operations to reflect this, particularly where the mission’s usual POC activities and responses are in more rural areas.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="sub-section 12-5-3">
            <h5>
              <span className="un-red">12.5.3</span> Violence perpetrated by non-state armed groups
            </h5>
            <p>
              When confronted with threats or ongoing attacks against civilians by a non-state armed group (NSAG), to prevent or stop the violence a mission can focus on affecting the intent, presence and capacity of the NSAG. Immediate proactive engagement, advocacy and deterrence should be aimed at the leadership of the NSAG and its political or financial backers. This may be accompanied by actions to disarm, demobilize and reintegrate elements of the NSAG following a thorough vetting process, including through integration in state security forces and community violence reduction programmes, as applicable. Specific measures may be required to safeguard the mission&apos;s impartial stance and image. Security operations, in support of or in parallel to government action, may also be conducted to deter or put an end to hostile activity by the NSAG, including through the use of force, when and as required. The mission will investigate human rights abuses and may also detain, in support of or jointly with host authorities, key perpetrators of serious crimes, including leaders of the NSAGs.
            </p>
            <p>
              Motivational approaches that incentivize the cessation of violence, such as reward and punishment (i.e., ‘carrot and stick&apos;) may be beneficial. One option is to condition the benefits of a peace settlement — for instance DDR and SSR, including integration into state security forces — on a display of goodwill and immediate cessation of all forms of violence against civilians, particularly grave violations against women and children, and possibly handing over those responsible for such violations. Coercive methods can be applied in the form of public messaging or security
              operations. However, individual armed-group leaders, who have committed war crimes or mass atrocities, may not be eligible for peace dividends. In such a context, mobilizing strategic influencers and supporters of the group may elevate the level of pressure to deter further violence and accept unconditional rendition, or at least obtain the handover of the perpetrators of grave violations.
            </p>
            <p>
              The reward and punishment approach is, however, generally unfit to address asymmetric threats, in which case the mission may want to adopt a community-based approach. This involves building strategic partnerships with external actors — including local authorities, state security forces, media, civil society and humanitarian and development agencies — with an aim to ‘win the hearts and minds&apos; of local populations (i.e., to sway the popular opinion with emotional/intellectual appeals). The expected effects of this approach include a reinforcement and promotion of popular trust and support for the actions taken by the mission, through a greater use of and support to non-military solutions. Enhanced popular support and trust will in turn uproot the threat from local communities, as the latter will reduce their active or passive support to the group and possibly reject negative elements, as well as facilitate the reintegration of defectors and returnees.
            </p>
          </div>
          <div className="sub-section 12-5-4">
            <h5>
              <span className="un-red">12.5.4</span> Community-based violence
            </h5>
            <p>
              When confronted with localized threats of intercommunal violence, the mission may take action to promote community dialogue and reconciliation at the local level, in close coordination with host authorities (particularly local leaders), humanitarian organizations and other political or conflict resolution actors, including women and youth. Military and police components can support as required through presence, deterrence or more robust action.
            </p>
            <p>
              Should the mission be faced with self-defence groups, vigilantes, community-based militias, gangs or any other group of armed individuals that cannot be considered as a formal armed group under IHL (see chapter 2), the mission may aim to ensure close monitoring, sensitization and control of said groups. Missions can envisage taking active measures as required, such as security operations, community violence reduction programmes or other activities to ensure strict compliance with applicable international and domestic law.
            </p>
            <p>
              In all areas affected by community-based violence, the mission could deploy rule-of-law actors, including through support to national police, justice and corrections systems, to ensure law and order.
            </p>

            <div className="relative">
              <img src={justice} alt="MINUSMA justice and reconciliation" />
              <div className="description">
                <p>
                  MINUSMA conducts sensitization and education sessions on social cohesion, human rights and justice. (2018)
                </p>
              </div>
              <p className="ref">UN Photo/Gema Cortes</p>
            </div>
            <AudioPlayer
              src={audio[8].file}
              title={audio[8].title}
              subtitle={audio[8].subtitle}
              avatar={audio[8].avatar}
              speaker={audio[8].speaker}
              transcript={audio[8].transcript}
            />
            <div className="voices un-red">
              <div className="avatar">
                <img alt={audio[8].speaker} src={audio[8].avatar} />
              </div>
              <h3>VOICES FROM THE FIELD</h3>
              <div className="content">
                <h4>Intercommunal Dialogue to Address Communal Conflict</h4>
                <h5>Guang Cong, UNMISS Director of Civil Affairs Division (2016-2020)</h5>
                <div className="half">
                  <div>
                    <p>
                      In South Sudan, one of the leading sources of civilian casualties and displacement is local communal conflict. These conflicts are generally driven as a consequence of resource-based competition, deep-seated ethnic animosities and lack of enforcement of the rule of law. All these were further exacerbated by the political violence between the government and a main opposition party in 2013 and renewed fighting in 2016. In UNMISS Civil Affairs, we facilitate the prevention, mitigation and resolution of communal conflict, and support initiatives to promote reconciliation and social cohesion, including support to rapprochement and confidence-building events between the parties to the revitalized agreement of the resolution of conflict in South Sudan.
                    </p>
                    <p>
                      As Director of UNMISS Civil Affairs Division, I invoked a number of strategic approaches to overcome the challenges of limited resources and address the sustainability of our interventions. One approach I used was to address key drivers of the communal conflicts including conflict between pastoralists and farmers — a leading conflict driver at the subnational level that often claims more civilian lives than the ongoing armed conflict.
                    </p>
                    <p>
                      For instance, to regulate cattle migration between Western and Eastern Lakes, Gok, Amadi and Terekeka States, I initiated a dialogue process between the bordering communities of these states in February 2017.
                    </p>
                  </div>
                  <div>
                    <cite>
                      &quot;I invoked a number of strategic approaches to overcome the challenges of limited resources and address the sustainability of our interventions.&quot;
                    </cite>
                    <p>
                      As the initiative progressed, several key stakeholders from these states visited Aweil State, bordering Sudan, to witness the best practices from cattle migration regulatory mechanisms between the pastoralists from Sudan and the host communities from South Sudan. Subsequently, we held a series of conferences in various conflict hotspots, which led to the adoption of a five-state cattle migration agreement in August 2017 and established a Joint Border Peace Committee/Court (JBPC) consisting of traditional leaders. The cattle migration agreement and JBPC were both officially endorsed by the governors of each of the five states.
                    </p>
                    <p>
                      Recognizing the positive impact of this mechanism for intercommunal dialogue, I organized a follow up conference in Western Lakes States in March 2019 and extended the scope of the agreement to include Jonglei State, making it a six-state agreement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sub-section 12-5-5">
            <h5>
              <span className="un-red">12.5.5</span> Violence perpetrated by state security forces
            </h5>
            <p>
              When confronted with threats of or ongoing physical violence to civilians posed by state security forces and their operations, the mission may take the necessary unilateral, or joint with the host state, mitigating measures to stop violence and prevent further occurrences. The mission may consider activities including human rights monitoring and investigations, reporting, political engagement, capacity building or advocacy. The latter may include high-level political engagement to dissuade state security forces from using violence against civilians as a tactic, actions to promote accountability for violations of human rights and IHL and communications to the government that the mission will use force against government security actors to protect civilians.
            </p>
            <p>
              The mission can draw on available measures to promote accountability and combat impunity by identifying perpetrators of violations and enabling their prosecution by strengthening justice systems. The mission can also provide training on international humanitarian and human rights law, and other capacity building support (including, for instance, the development or dissemination of a Code of Conduct, ROE or DUF; effective command and control mechanisms; guidance to ensure civil-military coordination or mitigate collateral damage), in consultation, as appropriate, with the ICRC and the Protection Cluster.
            </p>
            <p>
              Particular efforts could be made to: sensitize state security forces on the need to ensure proactive transparency and public information for allegations of misconduct or breaches of international law; conduct independent investigations, supported by the mission, as needed, and shared with relevant domestic accountability mechanisms; communicate the results of investigations and, should the allegations be confirmed, ensure disciplinary sanctions or legal accountability and provide reparations to the victims or their families.
            </p>

            <div className="case-study un-red">
              <h3>CASE STUDY</h3>
              <h4>MONUSCO’s Prosecution Support Cells Programme</h4>
              <div className="content">
                <div className="half">
                  <div>
                    <p>
                      MONUSCO’s Prosecution Support Cells Programme in the DRC has operated as part of the Mission’s Justice Support Section since 2011 and has successfully contributed to the implementation of the Mission’s mandate to support national efforts to bring to justice the perpetrators of crimes fueling the conflict, including those committed by state security forces. This includes perpetrators of war crimes and crimes against humanity, including CRSV. Many emblematic cases that have been investigated, prosecuted and tried through the national military justice system have involved serious crimes committed against the civilian population, including mass murder, rape, kidnapping, slavery of women and girls, as well as cases of child recruitment. These have resulted in the trial and imprisonment of senior officers in the national army, as well as those from armed groups.
                    </p>
                    <p>
                      The programme combines the political leverage and technical and logistical support of MONUSCO with the programmatic support of UNDP, while also strengthening coordination and partnerships between a variety of UN and non-UN actors, including international non-government organizations (INGOs). Through the expertise provided by investigation
                    </p>
                  </div>
                  <div>
                    <div className="relative">
                      <img src={support} alt="Prosecution support cells" />
                      <div className="description">
                        <p>
                          MONUSCO supports an open air mobile court in DRC. (2017)
                        </p>
                      </div>
                      <p className="ref">UN Photo/MONUSCO</p>
                    </div>
                    <p>
                      and prosecution advisers, deployed as Government Provided Personnel (GPPs) from different countries, the programme incorporates support to the Congolese authorities for investigations, mobile hearings and trials conducted in remote and insecure areas where atrocities have been committed and where courts barely function or exist. With support of other partners, the programme also includes victim and witness support/assistance, particularly for cases of CRSV, and capacity building training.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <p>
              In situations where state security forces pose threats of physical violence to civilians, particularly where such violence is imminent or ongoing, the mission may also intervene to physically protect civilians. This can include assisting civilians to escape the threat, interposition between civilians and the threat, or the use of graduated force, depending upon the circumstances and balance of forces. There is a risk that such intervention may result in withdrawal of host state consent. <strong className="un-red">However, the mission has a responsibility and obligation to intervene and stop violence against civilians by state security forces, including through the use of force.</strong>
            </p>
          </div>
          <div className="sub-section 12-5-6">
            <h5>
              <span className="un-red">12.5.6</span> Violence perpetrated by international security forces
            </h5>
            <p>
              When confronted with threats of physical violence to civilians posed by international security forces and their operations, the mission can take mitigating actions similar to these used with state security forces, including monitoring, reporting, political engagement, advocacy, capacity building and technical assistance. The mission can identify perpetrators of violence and advocate for their prosecution through available measures to promote accountability and combat impunity, either in country or with the state of origin. Further, the mission can provide training on international humanitarian and human rights law and other capacity building support. All those actions will aim to address the causes and eliminate intended or unintended consequences of actions by international security forces. Such actions should be taken in close coordination with the ICRC and the Protection Cluster, as appropriate.
            </p>
            <p>
              For international forces, as in the case of state security forces, particular efforts should be made to sensitize them on the need to ensure proactive transparency and public information for allegations of misconduct or breaches of international law. This should entail conducting independent investigations, supported by the mission, as needed, and shared with relevant domestic accountability mechanisms. The results of investigations should be communicated and, should the allegations be confirmed, disciplinary sanctions or legal accountability ensured, including provision of reparations to the victims or their families.
            </p>
            <p>
              Missions can regularly engage state and international partners to align their goals and efforts and communicate concerns about possible harm caused by these international actors. As required, the mission may also support them to apply a POC lens to all plans and activities conducted to ensure their presence and actions do no harm but rather contribute to POC.
            </p>
            <p>
              The Human Rights Due Diligence Policy (HRDDP), applicable to most forms of support provided by the mission and other UN entities to non-UN security forces, may further strengthen the POC mandate and provide the mission with leverage to influence behaviour as it provides, inter alia, for the formalization of measures to reduce the risk that national or international security forces who are recipients of UN support commit grave human rights violations.
            </p>
            <p className="un-red bold">
              Specific efforts should also be made to identify and mitigate all risks posed to civilians by the presence and actions of the mission itself (see chapters 10.2 and 11.4).
            </p>

            <div className="relative margin-top">
              <img src={joint} alt="Joint MONUSCO FARDC operation" />
              <div className="description">
                <p>
                  MONUSCO supports the DRC armed forces in an operation against a rebel group. (2014)
                </p>
              </div>
              <p className="ref">UN Photo/Sylvain Liechti</p>
            </div>
          </div>
        </>
      )
    },
    {
      index: '12.6',
      name: 'Building National and Local Protection Capacities',
      title: t('handbook.chapters.chapter12.6.title'),
      content: (
        <>
          <p>
            As states have the primary responsibility to protect civilians, many mission activities are devoted to supporting host state authorities to fulfil their responsibility. Such activities can include security sector reform, as well as capacity building — of state security actors, criminal justice actors and national human rights commissions — on a wide array of topics and skills. A key aspect of this approach is strengthening of the rule of law through fighting impunity and strengthening criminal accountability for serious crimes. Capacity building of law enforcement and judicial actors includes strengthening or enhancing the capacity to undertake investigations, to establish and operationalize mobile courts or specialized criminal courts, to create witness- and victim-protection measures and programmes on compensation and rehabilitation of victims; and assisting in restoring, extending and strengthening justice and corrections state functions. Mission uniformed components often work together with state counterparts to support them with capacity building and training, help them plan and implement security operations according to international standards, and emphasize the need to prioritize the protection of civilians in their guidelines and operations. All support to state security forces must be in compliance with the HRDDP.
          </p>
          <AudioPlayer
            src={audio[9].file}
            title={audio[9].title}
            subtitle={audio[9].subtitle}
            avatar={audio[9].avatar}
            speaker={audio[9].speaker}
            transcript={audio[9].transcript}
          />
          <div className="voices un-red">
            <div className="avatar">
              <img alt={audio[9].speaker} src={audio[9].avatar} />
            </div>
            <h3>VOICES FROM THE FIELD</h3>
            <div className="content">
              <h4>Support to Mobile Courts</h4>
              <h5>
                Elena Ikumapayi, MINUSMA Associate Judicial Affairs Officer (2016-)
              </h5>
              <div className="half">
                <div>
                  <p>
                    I am part of the MINUSMA Justice and Corrections team in Gao, a region in northern Mali. We focus our efforts on advancing the peace process by supporting the effective restoration and extension of State authority and rule of law. Where rule of law institutions are weak, or the population does not trust the judiciary, communities are more prone to violence.
                  </p>
                  <p>
                    In some locations of Mali, there are limited or no rule of law institutions. Judges are often unwilling to remain in remote areas due to insecurity. For people in these areas, access to justice is both costly and dangerous, as they must travel far along insecure roads to reach the nearest available court. In early 2019, I helped organize a mobile court hearing to address this problem. In this location, the judge had been absent for seven years. MINUSMA provided logistical, advisory and security support to facilitate the judge’s visit.
                  </p>
                  <p>
                    On the first day, the judge heard eight cases, both civil and criminal, and resolved several other legal files. One of the civil cases involved a land dispute between two communities. The dispute had not yet turned violent, but tensions were high between the communities. The hearing and decision by the judge seemed to resolve the tensions. At the end of the hearing the parties agreed to be bound by the judge’s decision and expressed their intention not to appeal. When we followed up in the
                  </p>
                </div>
                <div>
                  <cite>
                    &quot;Some participants said that when justice is close to them, it makes them more willing to forgive.&quot;
                  </cite>
                  <p>
                    subsequent weeks, local authorities informed us that the decision seemed to be respected and there had been no further problems between the communities.
                  </p>
                  <p>
                    On the second day, the judge held an open dialogue with the population to sensitize them about the rule of law and the progressive return of state authorities. Until he could return to the area full time, he encouraged the population to contact him directly, particularly if they had information that would help in the fight against impunity. As we left the area, many people thanked us for facilitating the visit and mobile court, though it was clear they were hungry for more.
                  </p>
                  <p>
                    On this occasion, I witnessed first-hand the potential of a present justice system to diffuse possible sources of communal tension. Some participants said that when justice is close to them, it makes them more willing to forgive. By addressing resource-based drivers of conflict, justice may reduce the risk of violent POC incidents.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p>
            POC activities at the local level often include support to social cohesion and reconciliation activities; enhancing community protection capacities; and building capacity of civil society, journalists and human rights defenders. Activities at the local level must be implemented in coordination and consultation with local communities to address the different protection needs of women, men, girls and boys and to ensure their sustainability and effectiveness as POC tools.
          </p>
          <p>
            Care must be exercised in determining when it is appropriate to support community protection capacities. Such capacities, or self-protection measures, can be any activities undertaken by local communities to mitigate, deter or avoid threats, including threats of physical violence against civilians. These can include self-defence groups, neighbourhood watch or patrols, alert networks, advocacy with state authorities to seek protection, conflict resolution, adopting coping mechanisms such as only moving in groups, displacement, negotiation with perpetrators and cooperation with perpetrators. Communities may or may not view these activities as self-protection measures; in many instances, communities may just instinctively implement them as methods of survival. Self-protection mechanisms that involve the use of force, such as self-defence groups, may evolve into predatory entities, morphing from protectors into perpetrators of violence against civilians. Mission support to community protection capacities must, as a priority, consider the principle of do no harm, as well as the sustainability of the support and approach. In cases where support to community protection capacities is undertaken by NGOs, the mission should take care not to duplicate, but rather, to complement such efforts, as appropriate.
          </p>
        </>
      )
    }
  ],
  footnotes: {
    content: (
      <ol type="1" start="35" className="refs">
        <li className="12-3">
          DPKO-DFS Interim Standard Operating Procedures on Detention in United Nations Peace Operations (2010.6).
        </li>
        <li className="12-4">
          See DPKO-DFS Interim Standard Operating Procedures on Detention in United Nations Peace Operations (2010.6) and &apos;The review and evaluation of the implementation of the Interim Standard Operating Procedures on Detention in United Nations Peace Operations&apos; (2017.11).
        </li>
        <li className="12-5-2">For example, companies or organized crime groups.</li>
      </ol>
    )
  }
};
