import React from 'react';
import patrol from '../images/chapters/chapter13/UNPOL_patrol_timbuktu.jpg';
import protection from '../images/chapters/chapter13/womens_protection_networks.jpg';
import tawahBlamahFlomoAvatar from '../images/avatars/Tawah_Blamah_Flomo.png';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 13: Integrating Gender Equality and WPS in POC',
  title: t('handbook.chapters.chapter13.title'),
  index: '13',
  color: 'un-blue',
  content: (
    <>
      <div className="grid-three un-blue">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            Armed conflicts affect women, men, boys and girls in different ways. This means that operational plans and strategies in peacekeeping missions to protect civilians must be informed by an analysis of the different impacts of conflict on women, men, boys and girls, and must incorporate strategies to address these.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            Women, men, boys and girls may have access to different kinds of sources of information relating to POC. To obtain a holistic overview of the threats and risks to civilians, missions must draw on the perspectives of women, men, boys and girls. This will ensure that gender issues influence strategic plans on POC and continue to inform future planning and implementation of the POC mandate.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            POC initiatives should fully account for the specific gender dimensions of protection. Gender outcomes should be included in all POC plans, policies, analyses and reports. POC stakeholders must ensure that women fully participate in all decision-making processes and that the gender equality and WPS mandates are included across all three tiers of the mission’s POC initiatives and the POC strategy.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            Integrating gender equality and WPS mandates in POC applies to all mission personnel, civilian and uniformed, at all levels and ranks, as well as personnel at UN Headquarters.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            Integrating gender equality and WPS mandates in POC is undertaken at UN Headquarters, in mission headquarters and in mission field offices.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            Integrating gender equality and the WPS mandates must begin from the outset at mission start-up and continue throughout the lifecycle of the mission. Gender perspectives must be taken into account during the planning and execution of all POC activities and operations.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '13.1',
      name: 'POC, Gender Equality and the WPS Mandates',
      title: t('handbook.chapters.chapter13.1.title'),
      content: (
        <>
          <h5>Normative framework</h5>
          <p>
            Several Security Council resolutions on POC feature directives on gender integration, highlighting the need for specific protection provisions for women and specialized gender training. The Women, Peace and Security Resolutions 1325 (2000) and 2242 (2015) specifically identify the need for peacekeeping missions to integrate women&apos;s needs and gender perspectives into their work, while Resolution 2122 (2013) specifically calls on missions to address the security threats and protection challenges faced by women and girls in conflict and post-conflict settings.
          </p>
          <h5>DPO policies</h5>
          <p>
            Meaningful integration of the WPS priorities is a guiding principle of the DPO POC Policy. The 2018 Gender Policy also prioritizes gender integration in all aspects of POC. It notes the need for holistic gender and protection analysis that identifies the specific capabilities, roles and responsibilities of women, as well as the gender dimensions of risks and vulnerabilities because women and girls are the main targets of CRSV. The Gender Policy also calls on POC stakeholders to ensure women&apos;s full participation in all decision-making processes.
          </p>
        </>
      )
    },
    {
      index: '13.2',
      name: 'Gender Integration for More Effective Protection',
      title: t('handbook.chapters.chapter13.2.title'),
      content: (
        <>
          <p>
            The POC mandate extends to all civilians in the mission&apos;s area of responsibility, thus peacekeeping personnel must have a nuanced understanding of the full range of threats faced by the diverse members of the civilian population to respond adequately. This includes recognizing how gender shapes individuals&apos; vulnerability, their capacities to respond to threats and the various type of threats they may encounter. An inclusive approach to information gathering and analysis can help to broaden this contextual understanding and facilitate more responsive and effective interventions. A gender-sensitive approach also means recognizing the varied gendered roles in peace and conflict. Women and girls can be a driving force for violence, whether as active supporters or perpetrators of violence. They also play critical roles in de-escalating conflict and promoting security and stability.
          </p>
          <p>
            Without taking gender into consideration, POC activities may overlook certain forces underlying or driving threats, as well as valuable resources for strengthening local protection mechanisms.
          </p>

          <div className="voices un-blue">
            <div className="avatar">
              <img alt="Tawah Blamah Flomo" src={tawahBlamahFlomoAvatar} />
            </div>
            <h3>VOICES FROM THE FIELD</h3>
            <div className="content">
              <h4>Integrating a Gender Perspective into POC</h4>
              <h5>
                Tawah Blamah Flomo, UNMIL National Civil Affairs Officer (2008-2016)
              </h5>
              <div className="half">
                <div>
                  <p>
                    I was assigned to Margibi County as a Civil Affairs Officer. It was just around the time the Government was beginning efforts to decentralize government institutions. In time, government institutions became more visible in the county. But as I participated in meetings of local organizations, I saw that women and youth in the districts and townships did not know much about the functions of government institutions. In most instances, when violent crimes were committed, the perpetrators of violence were not apprehended because the victims did not know how to get justice. As a result, there was increasing gender-based violence because of the impunity, and also increasing mob violence as communities took matters into their own hands.
                  </p>
                  <p>
                    To improve the knowledge of citizens on the functions of government institutions, and particularly their role in protection of the population, I engaged the County Communication Bureau Officer from the Ministry of Information. To raise awareness, in 2010, we crafted a radio programme named “Local Government and You.” This radio programme was hosted by the Communication Officer and was held every Tuesday at 8:00PM. I listened every Tuesday to enable me to provide feedback to the host and gather information
                  </p>
                </div>
                <div>
                  <cite>
                    &quot;In most instances, when violent crimes were committed, the perpetrators of violence were not apprehended because the victims did not know how to get justice.&quot;
                  </cite>
                  <p>
                    for further awareness around the county. Some Ministries were repeat guests on the show due to high demand from women’s groups and citizens concerned with protection issues.
                  </p>
                  <p>
                    The radio programme brought relief to women who now understood the different steps and channels to report gender-based violence and rape cases. Women’s organizations started getting more vocal in engaging local authorities like the Gender Office, Ministry of Health, police, County Superintendent and the court on prolonged GBV cases where justice was stalled. I heard from women’s groups that they understood the different government actors and who to engage or advocate with due to the radio programme.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      index: '13.3',
      name: 'Guidance for Gender-responsive POC',
      title: t('handbook.chapters.chapter13.3.title'),
      content: (
        <>
          <p>
            Implementing the WPS mandate is a priority across all POC plans, policies, activities, analysis and reports. General gender integration guidance for all POC activities includes:
          </p>
          <ul className="square un-blue">
            <li>
              Ensure that situational awareness contains a nuanced understanding of gender. A gender perspective exposes differences in status and power and how these shape immediate needs. Threats to women and girls are often less visible than threats to men and boys but can impact the conflict, as a whole. Direct engagement with local people, including women and girls, is the most effective way to strengthen situational awareness.
            </li>
            <li>
              Prioritize women&apos;s meaningful participation in all programmes, not just ‘gender&apos; programmes, from planning through to implementation. Often engagement with women is tokenistic and fails to recognize the valuable contextual knowledge that women offer.
            </li>
            <li>
              Address women&apos;s and girls&apos; protection issues beyond CRSV. Women and girls face protection risks that extend well beyond sexual violence. In a conflict, hostilities can disproportionately affect women and girls when residential areas are impacted. Women political leaders, media personnel and human rights defenders often face targeted threats and attacks. Other protection risks include abduction and forced marriage, domestic violence, enforcement of dress codes, lack of access to sources of livelihood, denial of housing, land or property and restrictions of movement. Protection actors, including the human rights component and members of the Protection Cluster, may be best placed to address risks that extend beyond threats of physical violence.
            </li>
            <li>
              <strong className="un-blue">Gender integration is the responsibility of all peacekeeping personnel, not just female personnel.</strong> Women peacekeepers are not the only conduit for engaging with local women, nor are they primarily responsible for supporting gender integration. The WPS mandate is the responsibility of everyone, in the same way that the POC mandate applies to all personnel.
            </li>
          </ul>

          <div className="relative">
            <img src={patrol} alt="UNPOL patrol Timbuktu" />
            <div className="description">
              <p>
                MINUSMA carries out patrols to secure the population in the face of terrorist threats. (2017)
              </p>
            </div>
            <p className="ref">UN Photo/MINUSMA</p>
          </div>

          <p>
            Gender integration does not necessarily require new programmes or approaches; much can be easily incorporated into existing POC efforts. The following are strategies for each of the three POC tiers:
          </p>

          <table className="columns align-left">
            <tbody>
              <tr>
                <td width="33%">
                  <p className="title un-blue">Tier I:</p>
                  <p className="subtitle">Protection through dialogue and engagement</p>
                  <ul className="square un-blue">
                    <li>
                      Strengthen women’s involvement in mediation, dialogue and conflict resolution, as mediators, delegates in negotiating parties, etc.
                    </li>
                    <li>
                      Bolster women’s participation in early warning. Women can be valuable sources of information of early warning systems. Other measures, such as dial-in radio programmes for women to report threats and ensure the community is informed of risks, can help to foster women’s active participation and benefit the whole community.
                    </li>
                    <li>
                      Undertake public information campaigns to promote women&apos;s rights and help strengthen the capacity of state and civil society in promoting gender equality and women’s rights. For example, sensitization campaigns can be conducted on the types of protection issues women frequently face and strategies to decrease their likelihood of occurrence, such as promoting women’s participation in measures to combat sexual violence or the creation of mechanisms for the recourse to justice.
                    </li>
                    <li>
                      Engage with women and women’s organizations to understand better what specific protection issues women face in a given context. When local male authority figures speak on behalf of their communities, they often fail to address women’s issues. Women may be reluctant to share their perspectives in mixed-gender settings, especially if they are not normally expected/empowered to share their opinions publicly.
                    </li>
                  </ul>
                </td>
                <td width="33%">
                  <p className="title un-blue">Tier II:</p>
                  <p className="subtitle">Provision of physical protection</p>
                  <ul className="square un-blue">
                    <li>
                      Implement dedicated protection activities based on the needs of and risks faced by women and girls (for example, in response to CRSV early warning indicators). Working with women and girls, especially marginalized and vulnerable groups, will lead to a clear understanding of how threats are gendered and how best to design and implement effective protection mechanisms. Activities may include:
                      <ul className="triangle un-blue">
                        <li>
                          Creating safe spaces in camps for women and girls;
                        </li>
                        <li>
                          Tailoring protection activities to women’s and girls’ mobility patterns and economic activity (e.g., firewood patrols, water-route patrols, field and market patrols);
                        </li>
                        <li>
                          Providing a security umbrella for women’s rights defenders and organizations working on women’s rights or GBV; and
                        </li>
                        <li>
                          Establishing JMAC perpetrator profiling systems that include patterns of attacks against women and girls, as well as sex and age disaggregated tracking of civilian casualties.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Consider how engagement and advocacy with women and girl supporters and potential perpetrators of violence can contribute to efforts to prevent violence.
                    </li>
                  </ul>
                </td>
                <td width="33%">
                  <p className="title un-blue">Tier III:</p>
                  <p className="subtitle">Establishment of a protective environment</p>
                  <ul className="square un-blue">
                    <li>
                      Support gender integration and women’s participation and leadership in conflict prevention, conflict resolution and peacebuilding. Work across other functional components to ensure gender perspectives are addressed, for example, in the reintegration of ex-combatants as well as in the restoration of rule of law and accountability for victims and supporting host government efforts towards women’s inclusion in decision-making roles in post-conflict governance institutions.
                    </li>
                    <li>
                      Ensure that women’s and girls’ needs are considered in creating the conditions conducive to the voluntary, safe, dignified and sustainable or local integration of refugees and IDPs, in coordination with national authorities and humanitarian partners.
                    </li>
                    <li>
                      Build capacity of national authorities to promote and respect human rights, including women’s rights and prevent, respond, investigate and prosecute CRSV.
                    </li>
                    <li>
                      Ensure tailored victim- and witness-protection measures for female victims and witnesses, especially victims/survivors of CRSV.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            Gender must further be integrated along the four POC phases (prevention, pre-emption, response and consolidation) either to eliminate a threat or to mitigate the risk to civilians, including women and girls, associated with it.
          </p>

          <table className="checklist un-red">
            <thead>
              <tr>
                <td />
                <td>Checklist: Guiding Questions for Gender-Responsive POC</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="checked" />
                <td>
                  How have women, girls, men and boys been affected differently by the conflict and/or by displacement? Have women, girls, men and boys been affected by specific events such as the destruction of infrastructure and health-care systems, separation of families, etc?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  How do gender norms, roles and dynamics shape the vulnerabilities of women, girls, men and boys? Do these norms, roles and dynamics affect how women, girls, men and boys are targeted?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  What are the basic needs of women, girls, men and boys (e.g., food, health, shelter, water and sanitation, education) in the displaced and host populations?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  What are the different coping mechanisms currently used by women, girls, men and boys? What resources and support structures are they utilizing and are they sustainable?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  Are women and men equally involved in planning and implementing protection programmes?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  Do women, girls, men and boys all have access to early warning systems? Are they actively contributing to reporting? What barriers might be preventing their participation?
                </td>
              </tr>
            </tbody>
          </table>

          <div className="case-study un-blue">
            <h3>CASE STUDY</h3>
            <h4>UNAMID Women Protection Networks</h4>
            <div className="content">
              <div className="relative">
                <img src={protection} alt="Women's protection network" />
                <div className="description">
                  <p>
                    A UNAMID Gender Officer meets with women in the community. (2014)
                  </p>
                </div>
                <p className="ref">UN Photo/MINUSMA</p>
              </div>
              <p>
                UNAMID has established 48 Women Protection Networks in the IDP camps across the five Darfur states. The Women Protection Networks serve multiple roles:
              </p>
              <ul className="square un-blue">
                <li>
                  Receive timely information concerning women&apos;s protection needs to inform appropriate protection measures;
                </li>
                <li>
                  Raise awareness among IDPs on women&apos;s rights and the fight against sexual and gender-based violence in the IDP camps;
                </li>
                <li>Strengthen the strategies for the protection of women</li>
                <li>
                  Create a forum in which IDP women discuss their protection needs and share with the camp leadership and other protection actors for action; and
                </li>
                <li>
                  Ensure that mechanisms for referring gender-based violence victims/survivors to service providers are in place.
                </li>
              </ul>
              <p>
                Improved communication between IDP women and the camp leadership about women&apos;s protection concerns led to targeted patrols and escorts by the UN military in collaboration with the IDP women leaders. There was also an improved response to sexual gender-based violence issues, especially referrals, as these networks refer SGBV survivors to relevant service providers. Most importantly, Women Protection Networks are critical in signaling early warnings in and around the camps.
              </p>
              <p>
                Mainstreaming these networks into the Ministry of Health and Social Development would further help to ensure their sustainability, as well as improve cooperation with security forces to act on reported incidents.
              </p>
            </div>
          </div>
        </>
      )
    },
    {
      index: '13.4',
      name: 'WPS Indicators Related to POC',
      title: t('handbook.chapters.chapter13.4.title'),
      content: (
        <>
          <p>
            DPO has developed a set of indicators for the WPS mandate which also measure the results and impact of POC-specific mission actions. These indicators should be integrated together with other POC indicators in the relevant reporting and evaluation mechanisms of the mission, e.g., CPAS, RBB, etc.
          </p>
          <p>
            <strong>Core indicator </strong>(to be deployed across all missions)
          </p>
          <ul className="circle un-blue">
            <li>
              Number and percentage of formal local early warning mechanisms where women make up at least 30% of active members in the area of responsibility (AOR).
            </li>
            <li>
              Number of mission-led (meaning: time or financial resources committed) initiatives that are aimed at strengthening the capacities of women&apos;s civil society organizations working on conflict prevention.
            </li>
            <li>
              Number of gender-responsive operations by the UN military and/or police, or alternatively &apos;Female Engagement Teams,&apos; carried out to protect civilians.
            </li>
          </ul>
          <p>
            <strong>Potential elective indicators </strong>(intended for selective use, as applicable to each mission mandate)
          </p>
          <ul className="circle un-blue">
            <li>
              Percentage of IDPs and refugees who have returned in a voluntary, safe, dignified and sustainable manner (disaggregated by sex and age).
            </li>
            <li>
              Percentage of displaced people referred to UNHCR who have meaningfully accessed services as demonstrated by UNHCR registration and receipt of at least one form of UNHCR or partner assistance (e.g., shelter, cash, etc.) (disaggregated by sex and age).
            </li>
            <li>
              Number and percentage of women and girls receiving benefits through reparation programmes (disaggregated by type of benefits received).
            </li>
          </ul>
          <p>
            <strong>Reach indicators </strong>(encouraged as much as financial, staff time and security constraints allow)
          </p>
          <ul className="circle un-blue">
            <li>
              Percentage of people (disaggregated by sex) who report feeling safe leaving their homes during the day and night (general population survey).
            </li>
            <li>
              Percentage of people (disaggregated by sex) living in refugee or IDP camps, sites or settlements, who report feeling safe leaving their homes during the day and night.
            </li>
          </ul>

          <ol type="1" start="38" className="refs">
            <li>
              This chapter has incorporated Chapter Ten on Protection of Civilians from the DPO Gender Equality and Women, Peace and Security Resource Package.
            </li>
          </ol>
        </>
      )
    }
  ]
};
