import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actions from '../redux/actions';
import start from '../images/design/player/start.svg';
import end from '../images/design/player/end.svg';
import trackBack from '../images/design/player/track-back.svg';
import trackForward from '../images/design/player/track-forward.svg';
import play from '../images/design/player/play.svg';
import pause from '../images/design/player/pause.svg';
import { t } from '../services/i18n';

const AudioPlayer = ({ title, subtitle, src, cache, updateCache, avatar, speaker, transcript, languageSpoken }) => {
  const [audioState, setAudioState] = useState({ duration: 0, currentTime: 0 });
  const [loaded, setLoaded] = useState(false);
  const [playing, setPlaying] = useState(false);
  const audioContent = useRef();
  const done = useRef(false);

  const setCurrentTime = (num) => {
    audioContent.current.currentTime = num;
  };

  const incr = (num) => {
    audioContent.current.currentTime += num;
  };

  const decr = (num) => {
    audioContent.current.currentTime -= num;
  };

  const toggle = () => {
    if (playing) audioContent.current.pause();
    else audioContent.current.play();
  };

  const toTime = (num) => {
    const whole = Math.floor(num);
    return `${Math.floor(whole / 60)}:${String(whole % 60).padStart(2, '0')}`;
  };

  const onPlay = () => {
    updateCache({ playing: title });
    setPlaying(true);
  };

  const onPause = () => {
    updateCache({ playing: null });
    setPlaying(false);
  };

  const update = () => {
    setAudioState({
      duration: audioContent.current.duration,
      currentTime: audioContent.current.currentTime
    });

    if (!playing) return;

    // Update the cache, so we can see what's going on
    updateCache({
      audioProgress: {
        ...(cache.audioProgress || {}),
        [title]: (audioContent.current.currentTime / audioContent.current.duration) * 100
      }
    });
  };

  useEffect(() => {
    if (audioContent !== null) {
      setLoaded(true);
    }
  }, []);

  /* eslint-disable */
  /**
   *
   * @param currentAudioState
   * @param transcriptProp
   */
  const getTranscriptText = (currentAudioState, transcriptProp) => {
    if (!transcriptProp && !currentAudioState) {
      return;
    }

    const transcriptLineMatch = transcriptProp.find(transcriptLine => {
      return (currentAudioState.currentTime >= transcriptLine.start && currentAudioState.currentTime <= transcriptLine.end);
    })

    if (transcriptLineMatch) {
      return transcriptLineMatch.text;
    }
  }
  const transcriptText = useMemo(() => getTranscriptText(audioState, transcript), [audioState, transcript]);

  return (
    <div className="audio-player" id={title}>
      { transcript.length > 1 ? (<div className="row transcript-container"><p>{transcriptText}</p></div>) : null }
      <div className="row">
        {/* Audio Header */}
        <div className="audio-header">
          <div className="avatar">
            <img alt="Avatar" src={avatar} />
          </div>
          <div className="titles">
            <h3>{ title } { languageSpoken && `(${languageSpoken})` }</h3>
            { subtitle ? <h5>{ subtitle }</h5> : ''}
            <div className="speaker">{ speaker }</div>
          </div>
        </div>
        { loaded ? (
          /* Audio Buttons */
          <div className="buttons">
            <button
              type="button"
              onClick={() => setCurrentTime(0)}
              style={{ opacity: audioState.currentTime < 10 ? 0.3 : 1 }}
            >
              <img src={start} alt="back to start" />
            </button>
            <button type="button" onClick={() => decr(10)}>
              <img src={trackBack} alt="back 10 seconds" />
            </button>
            <button type="button" onClick={toggle}>
              { playing ? <img src={pause} alt="pause" /> : <img src={play} alt="play" /> }
            </button>
            <button type="button" onClick={() => incr(10)}>
              <img src={trackForward} alt="forward 10 seconds" />
            </button>
            <button
              type="button"
              onClick={() => setCurrentTime(audioContent.current.duration)}
              style={{ opacity: audioState.currentTime === audioState.duration ? 0.3 : 1 }}
            >
              <img src={end} alt="skip to the end" />
            </button>
          </div>
        ) : null }
      </div>
      <audio
        ref={audioContent}
        src={src}
        onTimeUpdate={update}
        onCanPlay={() => {
          // We want to do this only once
          if (done.current) return;
          done.current = true;

          // Set the progress to match cache
          if (cache.audioProgress && cache.audioProgress[title]) {
            audioContent.current.currentTime = cache.audioProgress[title];
          }
          update();
          setLoaded(true);
        }}
        onPlay={onPlay}
        onPause={onPause}
      >
        <track kind="captions" />
        {t('handbook.audio.not-supported')}
      </audio>
      { loaded ? (
        <div className="controls">
          <div className="track">
            <span>{ toTime(audioState.currentTime) }</span>
            <div>
              <div
                className="progress"
                style={{ width: `${(audioState.currentTime / audioState.duration) * 100}%` }}
              />
            </div>
            <span>{ toTime(audioState.duration) }</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

AudioPlayer.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.node.isRequired,
  transcript: PropTypes.array,
  subtitle: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  speaker: PropTypes.string.isRequired,
  languageSpoken: PropTypes.string,
  cache: PropTypes.shape({
    audioProgress: PropTypes.object // eslint-disable-line
  }),
  updateCache: PropTypes.func.isRequired
};

AudioPlayer.defaultProps = {
  cache: {},
  transcript: [],
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps, {
  updateCache: actions.updateCache
})(AudioPlayer);
