import pocFundamentals from './poc_fundamentals';
import chapter1 from './chapter1';
import chapter2 from './chapter2';
import chapter3 from './chapter3';
import chapter4 from './chapter4';
import chapter5 from './chapter5';
import chapter6 from './chapter6';
import chapter7 from './chapter7';
import chapter8 from './chapter8';
import chapter9 from './chapter9';
import chapter10 from './chapter10';
import chapter11 from './chapter11';
import chapter12 from './chapter12';
import chapter13 from './chapter13';
import chapter14 from './chapter14';
import chapter15 from './chapter15';
import annexi from './annex_i';
import annexii from './annex_ii';
import annexiii from './annex_iii';
import annexiv from './annex_iv';
import annexv from './annex_v';
import annexvi from './annex_vi';
import annexvii from './annex_vii';
import annexviii from './annex_viii';

export default [
  pocFundamentals,
  chapter1,
  chapter2,
  chapter3,
  chapter4,
  chapter5,
  chapter6,
  chapter7,
  chapter8,
  chapter9,
  chapter10,
  chapter11,
  chapter12,
  chapter13,
  chapter14,
  chapter15,
  annexi,
  annexii,
  annexiii,
  annexiv,
  annexv,
  annexvi,
  annexvii,
  annexviii,
];
