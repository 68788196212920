import React from 'react';
import folon from '../images/chapters/chapter5/operation_folon.jpg';
import strategies from '../images/chapters/chapter5/tailored_armed_groups_strategies.jpg';
import AudioPlayer from '../components/audio';
import audio from './audio';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 5: Strategic Planning for POC',
  title: t('handbook.chapters.chapter5.title'),
  index: '5',
  color: 'un-navy',
  content: (
    <>
      <div className="grid-three un-navy">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            A strategic approach to POC translates the intent of the Security Council into a realistic plan for the mission. Setting, strategic outcomes and objectives for POC success can serve as the basis for the sequencing and balancing of mandated tasks, responsibilities and resources as well as the workplans of mission components and of individual staff.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            A strategic approach to POC allows for stakeholders both within and outside of the mission to understand the POC objectives of the mission, for expectations to be managed, for there to be accountability towards progress and for setbacks to be identified and reported. It also allows for lessons to be learned and adjustments to be made for effective POC responses.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            The first step in a strategic approach to POC is to understand the context of violence against civilians. Then the mission should determine its strategic objectives related to POC, as well as a plan to achieve those objectives. This process should include identifying qualitative and quantitative indicators and determining a means to collect the relevant and necessary data against those indicators. The resulting analysis should be used to inform all relevant mission strategic documents and planning processes, in line with UN Policy on Integrated Assessment and Planning and the DPO POC Policy.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            Setting and prioritizing POC related objectives should be led by mission leadership with support from mission planners (civilian and uniformed) and POC Advisers. All mission components should identify their contributions to the mission’s objectives on POC.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            Strategic planning is conducted primarily at mission headquarters and in mission field offices.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            Planning for POC should be done at mission start-up and reviewed in response to a change in mandate or change in the situation on the ground (the context). The mission should also conduct regular assessments of performance, whether timed with the Comprehensive Performance Assessment System (CPAS) cycle, budget cycles and/or mandate renewals.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '5.1',
      name: 'POC Strategy',
      title: t('handbook.chapters.chapter5.1.title'),
      content: (
        <>
          <p>
            A peacekeeping mission with a protection of civilians mandate must have a comprehensive POC strategy. The POC strategy can either be a stand-alone document or integrated into the mission concept. The POC strategy does not, however, function in isolation. POC objectives, indicators and plans must be mainstreamed and integrated in all mission strategic planning processes, cycles and strategies.
          </p>
          <div className="sub-section 5-1-1">
            <h5>
              <span className="un-navy">5.1.1</span>Purpose
            </h5>
            <p>
              The mission&apos;s POC strategy will guide the mission&apos;s strategic approach to POC, enable monitoring and evaluation of progress and identification of challenges, and serve as a tool for strategic engagement with stakeholders, including the Security Council and troop- and police-contributing countries. The strategy must be supported by an action plan or workplan to be translated into action. Strategies specific to different regions or threats may also be developed in regional or field offices, led by Heads of Office.
            </p>
            <p>
              This section presents guidance on the key considerations and process for drafting and implementing a comprehensive POC strategy that will promote coherence in approach, minimize gaps, avoid duplication, support the effective and efficient use of mission resources and facilitate coordination.
            </p>
            <p>
              Purpose of a comprehensive POC strategy:
            </p>
            <ul className="square striped un-navy">
              <li>
                Establishing a benchmark of physical violence against civilians in the mission area;
              </li>
              <li>
                Identifying priority POC threats and the criteria for setting those priorities;
              </li>
              <li>
                Establishing strategic outcomes, objectives and indicators for the implementation of the POC mandate;
              </li>
              <li>
                Defining the mission approach to POC and key activities across the three tiers for different categories of threat;
              </li>
              <li>
                Defining clearly the roles and responsibilities of mission components in supporting the implementation of the POC mandate;
              </li>
              <li>
                Establishing a framework for mission component strategies and workplans to reflect agreed POC priorities;
              </li>
              <li>
                Establishing mechanisms for internal coordination and external engagement;
              </li>
              <li>
                Determining key documents, activities and processes to enable preparedness on POC; and
              </li>
              <li>
                Incorporating concise criteria and guidance to identify and set operational and tactical POC priorities and sequencing of tasks for addressing them.
              </li>
            </ul>
            <AudioPlayer
              src={audio[1].file}
              title={audio[1].title}
              subtitle={audio[1].subtitle}
              avatar={audio[1].avatar}
              speaker={audio[1].speaker}
              transcript={audio[1].transcript}
            />
            <div className="voices un-navy">
              <div className="avatar">
                <img alt={audio[1].speaker} src={audio[1].avatar} />
              </div>
              <h3>VOICES FROM THE FIELD</h3>
              <div className="content">
                <h4>Developing a POC Strategy</h4>
                <h5>UNMISS SRSG David Shearer (2017-2021)</h5>
                <cite>
                  &quot;When I arrived in the Mission, we felt there was a need for an overarching strategic plan guiding our work to protect civilians both within these sites and across the country.&quot;
                </cite>
                <div className="half">
                  <div>
                    <p>
                      The decision by the UN Mission in South Sudan to open its gates to hundreds of thousands of civilians as they fled for their lives in 2013 was one of the most noble and compassionate things the UN has ever done. Thousands of families are alive today because of that decision. But six years on, life in the camps is far from ideal: they provide the basic services needed for survival, but they are not a place where children should grow up. Yet up to 80 per cent of residents are women and children. Ninety per cent of those displaced by the conflict in South Sudan are living in other areas and we also have a duty to extend our protection to them. Meeting these protection needs and our work with humanitarian partners to help families to return and rebuild their lives when they feel confident to do so is what motivates me and all staff serving with UNMISS.
                    </p>
                    <p>
                      When I arrived in the Mission, we felt there was a need for an overarching strategic plan guiding our work to protect civilians both within these sites and across the country. Individual components — civilian, police and military — were all diligently carrying out their work but were operating without a collective focus on the outcome. There was an urgent need to look beyond POC sites, to the future, and also to re-evaluate whether our protection presence across the country was making a real impact.
                    </p>
                    <p>
                      We needed to ask ourselves what &apos;protection&apos; actually meant and how our activities should evolve given the changing political and security situation. Put simply, we needed to encapsulate the 16-page Security Council resolution into a simple user-friendly guide that all staff could understand and follow with commitment.
                    </p>
                    <p>
                      So, we brought together the heads of all the Mission&apos;s sections to brainstorm ways to clearly establish our objectives, the best methods of protection and how we could measure our impact. We held strategy sessions on weekends so that our team could focus away from the usual daily work pressures.
                    </p>
                  </div>
                  <div>
                    <p>
                      Section heads then consulted with their teams, feeding back ideas from staff on the front line so that the strategy would also reflect the reality of our work in the field. We boiled down our responsibilities into two fundamental areas: protecting civilians and building durable peace. We knew the more that we could build peace, the less we would need to do in terms of protecting civilians. But if peace failed to hold, we needed to be ready to step up protection efforts.
                    </p>
                    <p>
                      We then took our plan to the UN Country Team, the Humanitarian Country Team and the South Sudan NGO forum so that we could build a UN system-wide approach to the protection of civilians and ensure that UNMISS and humanitarians were working together in a coherent and focused way.
                    </p>
                    <p>
                      The joint strategy identified four key areas of work. First, to advance the state responsibility to protect civilians by engaging with, and influencing, key political actors to set the policy and legal framework to secure the safety of their own population. Second, to foster a positive protection environment, we needed to support conflict-resolution and mediation, proactively deploy forces as a deterrent against violence, and support the legal, security and judicial systems. The third area of focus was to directly assist survivors of violations and abuses by working to investigate and publicly report serious crimes, advocate for the perpetrators to face justice and ensure that humanitarian assistance reached those in need. Finally, in situations when authorities were unwilling or unable to protect their own people, UNMISS would secure civilians in protection sites and beyond.
                    </p>
                    <p>
                      This new strategy means our focus is now about impact rather than on output. In other words, what makes a real difference rather than simply undertaking a series of activities. Our approach across the Mission and UN-system in South Sudan is more coherent with an increase in joint planning and activities. There is a greater sense of shared responsibility and staff are more motivated because they can see and measure the results of the efforts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-section 5-1-2">
            <h5>
              <span className="un-navy">5.1.2</span>Drafting process
            </h5>
            <p>
              The process of drafting a POC strategy provides an opportunity to ensure a unified understanding of the requirements of the POC mandate, build consensus, establish priorities and expectations, and clarify roles and responsibilities. The consultation process is central to the development of the strategy. All relevant protection actors – humanitarian actors, the host authorities and the affected population – should be consulted on the development of the strategy, though their level of involvement will vary depending on the political and operational context.
            </p>
            <p>
              POC strategies are an important tool for senior mission leadership to ensure that the mission approach to POC aligns with and is supported by the broad strategic aims of the mission including the mission&apos;s political strategy. <strong className="un-navy">Mission leadership should therefore be closely engaged in both the development and implementation of comprehensive POC strategies </strong>(see chapter 4).
            </p>
            <p>
              Other UN entities in the mission area with protection roles or programmes should be actively involved in the development of the strategy. The precise relationship and level of coordination on POC will vary from context to context, and may be influenced by several considerations, including:
            </p>
            <ul className="square un-navy">
              <li>
                The specific integration arrangements in place between the mission and other UN entities;
              </li>
              <li>
                Any overlap between the POC mandate of the UN peacekeeping mission and the protection activities of other UN entities;
              </li>
              <li>
                The humanitarian coordination structure in place and presence of a Protection Cluster; and
              </li>
              <li>
                Plans for the peacekeeping mission to drawdown and/or withdraw, and any impact this may have on the ongoing protection work of UN entities.
              </li>
              <li>
                Senior mission leadership should work with the POC Adviser or Focal Point to draft the strategy, remaining engaged throughout the drafting process and leading and overseeing the implementation of the strategy.
              </li>
            </ul>
            <p>To ensure effective consultations, the following issues will be determined prior to commencing the drafting process:</p>
            <table className="boxed-table un-navy">
              <tbody>
                <tr>
                  <td>
                    <span className="border un-navy">Drafting office</span>
                  </td>
                  <td>
                    <ul className="square">
                      <li>
                        The POC Adviser, in consultation with others, will determine who should be consulted, what elements of the draft they will be consulted on, and the consultation process and methodology.
                      </li>
                      <li>
                        The POC Adviser should ensure close coordination with the mission&apos;s strategic planning cell who can ensure overall consistency amongst strategic planning processes and insertion of POC as a priority in other relevant plans and strategies.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-navy">Drafting timeframe</span>
                  </td>
                  <td>
                    <ul className="square">
                      <li>
                        While sufficient time should be invested in consultations and ensuring buy-in, the strategy should be a practical, action-oriented document and the mission should aim to finalize the drafting process within three months.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-navy">Consultation process</span>
                  </td>
                  <td>
                    <ul className="square">
                      <li>
                        The strategy drafting process should be facilitated through the convening of a working group/task force across relevant mission civilian and uniformed components, and include external protection partners, as appropriate. Existing POC coordination mechanisms provide potential forums for the consultation process. The consultations with various stakeholders are not necessarily sequential; they may occur simultaneously.
                        <ul className="triangle un-navy">
                          <li>
                            <strong>Security Council: </strong>While perhaps not undertaken as formal consultations, it is crucial that the mission understands the Security Council&apos;s vision and expectations on POC in advance of the strategy development. Likewise, the Council must understand the threats and risks to civilians in the mission area, the mission&apos;s capacity to address those risks and challenges the mission faces in doing so.
                          </li>
                          <li>
                            <strong>Troop and police contributing countries (T/PCCs): </strong>Early consultation at Headquarters with the T/PCCs during the drafting of the POC strategy will create a common understanding of the POC risks where troops and police are deployed. This consultation should ascertain possible concerns and caveats and ensure an understanding of the Council&apos;s intent vis-à-vis the mission&apos;s POC mandate.
                          </li>
                          <li>
                            <strong>UN Headquarters: </strong>The mission should seek strategic guidance and support from Headquarters when drafting the strategy, in particular regarding matters related to the Security Council mandate and the role of T/PCCs. The parameters of the POC strategy should be discussed with the regional desks and the Policy, Evaluation and Training Division (DPET) POC Team before drafting begins and should be shared in draft for comments with the Integrated Mission Task Force and the Office of Legal Affairs.
                          </li>
                          <li>
                            <strong>In mission: </strong>The preparation of the strategy should be consulted with senior leadership of the mission. The military, police, and relevant civilian substantive and support components must all be part of the consultation process. The mission Chief of Staff and mission planning officer should be involved to ensure the strategy is aligned with other mission planning documents. In settings where an integrated JOC and JMAC exist, these bodies should help develop appropriate analytical and mapping tools to inform the drafting process and provide appropriate situational awareness and reporting.
                          </li>
                          <li>
                            <strong>UN Country Team (UNCT): </strong>The UNCT should be consulted through the integrated mission planning team or equivalent.
                          </li>
                          <li>
                            <strong>Humanitarian and protection actors, including UN entities and NGOs: </strong>Consultation with these actors will facilitate effective coordination where appropriate, including with strategies of the Humanitarian Country Team, the Protection Cluster and its sub-working groups, including strategies on child protection, gender-based violence, housing, land and property-related conflicts.
                          </li>
                          <li>
                            <strong>Host government: </strong>Early engagement with the host government (or the relevant governments, in situations of inter-state conflict or disputed territories) serves several purposes. It provides an opportunity to remind the government of obligations under international law, to clarify government roles and responsibilities in relation to the mission&apos;s POC mandate, to assess the government&apos;s strengths and weaknesses vis-à-vis POC, to understand and manage the expectations of host state authorities and to explain the POC priorities identified by the mission and the local population. Consultations should include all relevant government agencies involved in POC, not solely law enforcement and security institutions. The political context in which the mission is deployed and the capacity and willingness of the host government to meet its obligations should be considered when determining the extent of consultation.
                          </li>
                          <li>
                            <strong>Non-state armed groups: </strong>Engaging with non-state armed groups on POC issues may be necessary, where appropriate and security permitting, but direct consultations on the strategy itself may not be required. Engagement presents an opportunity to inform armed groups of their obligations under international law and to understand and assess their potential role in protecting civilians. The mission must determine how best to balance the opportunities and risks of engagement with armed groups, and how it will work with other protection actors to this end.
                          </li>
                          <li>
                            <strong>Local population: </strong>Sustained dialogue with women and men from the local population is necessary to identify the threats they face, their vulnerabilities, and how the mission can support existing protection capacities. It also allows the mission to manage expectations of the mission&apos;s POC mandate. The local population&apos;s perception of the security situation should be one of the most important indicators in defining the success of the mission&apos;s role in providing protection. Consultations with the local population should include groups at risk (women, youth, older persons, persons with disabilities, the displaced and minorities) and key civil society groups (traditional authorities, religious actors, human rights organizations, refugee/IDP committees, women&apos;s groups, etc.), to take into account all perspectives on threats and possible       solutions. When consulting with the population, the mission can seek the advice of external protection partners who may have existing connections and trust established with the communities. All engagement with the local population must be in line with the do no harm principle (see chapter 10.2).
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-navy">Timeframe and length</span>
                  </td>
                  <td>
                    <ul className="square">
                      <li>
                        The strategy should normally cover a one-year timeframe, at which point the strategy should be reviewed to consider whether it needs to be revised. However, significant changes in the operating environment and/or in the mission&apos;s mandate should trigger a review of the strategy outside the usual review timeline.
                      </li>
                      <li>
                        The POC strategy should be focused and concise. Long and highly detailed strategies have been less effective, particularly those which are not action oriented.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-navy">Endorsement</span>
                  </td>
                  <td>
                    <ul className="square">
                      <li>
                        As an internal mission document, the final strategy should be endorsed by the head of mission and the UNCT and disseminated to all mission components so that they are aware of their responsibilities. Depending on political and/or operational implications, other partners, including partner organizations and the Protection Cluster, can be provided with a copy or a summary document.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="sub-section 5-1-3">
            <h5>
              <span className="un-navy">5.1.2</span> Drafting process
            </h5>

            <div className="half template-container">
              <div className="template margin-top">
                <h4>TEMPLATE – POC Strategy</h4>
                <ol type="A">
                  <li>
                    Purpose, scope and key principles
                    <ol type="1">
                      <li>Purpose</li>
                      <li>Scope</li>
                      <li>Key principles</li>
                    </ol>
                  </li>
                  <li>Strategic threat assessment</li>
                  <li>Strategic objectives, indicators and risks</li>
                  <li>POC response model and planned activities</li>
                  <li>
                    Analysis of mission capacities, resources and national caveats
                  </li>
                </ol>
                <p>
                  <strong>Annexes to POC strategy or separate SOPs:</strong>
                  <br />
                  Engagement and coordination mechanisms
                </p>
                <p>
                  <strong>Annexes to POC strategy or separate SOPs:</strong>
                  <br />
                  Preparedness activities
                </p>
              </div>
              <div>
                <p>
                  Based on this template, below is a suggested approach, including key elements to include:
                </p>
                <p className="un-navy bold">
                  A. Purpose, scope and key principles
                </p>
                <ol type="1">
                  <li>
                    <strong>Purpose: </strong>A succinct statement of the purpose and objectives of the strategy including any specific requirements under the mission-specific POC mandate.
                  </li>
                  <li>
                    <strong>Scope</strong>: This section should address:
                    <p>
                      <strong><em>Compliance: </em></strong>A clear statement that compliance is mandatory for all mission staff. It should also note those aspects that may require coordination with other UN entities.
                    </p>
                    <p>
                      <strong><em>Temporal and geographic scope: </em></strong>The timeframe of the POC strategy and the area to which it applies (normally the whole mission area).
                    </p>
                    <p>
                      <strong><em>References, alignment and existing strategies:</em></strong>The POC strategy does not exist in isolation and must be aligned and linked with other existing protection-related strategies and planning documents, both within the mission and externally. These should be listed here. Specific attention will be given to linking and complementing priorities identified by the host government and any existing protection strategies that may have been developed by the Protection Cluster and its sub-clusters. In integrated mission settings, the POC strategy should be explicitly linked to the Integrated Strategic Framework (ISF), which is designed to articulate the UN&apos;s strategic vision in a mission context and must include POC as a priority. POC strategies should also reflect and be aligned with the military and police CONOPS, ROE and DUF.
                    </p>
                    <p>
                      <strong><em>Review of the strategy: </em></strong>Date for review of the strategy linked with other mission planning processes (e.g., the CPAS/Results Based Budget) or as a response to changes in the mission mandate and/or operating context.
                    </p>
                  </li>
                  <li>
                    <strong>Key Principles</strong>: Brief reminder of the guiding principles from the DPO POC Policy, tailored to the mission&apos;s environment.
                  </li>
                </ol>
              </div>
            </div>
            <p className="un-navy bold">
              B. POC strategic threat assessment
            </p>
            <p>
              The mission&apos;s POC strategy must be based on a thorough analysis of the protection environment, taking into account: threats facing the population, the vulnerabilities of those populations, and the respective capacity and intent of the host state and other protection actors, including the populations themselves (see chapter 8).
            </p>
            <p>
              At mission start-up, often coordinated by the POC Adviser, a thorough assessment of the threats to civilians should be undertaken to establish a benchmark of the level and type of threat faced by civilians within the mission area. An initial threat assessment will enable the mission to set its objectives and indicators, identify risks and external factors and monitor progress made on the effective implementation of the POC mandate.
            </p>
            <p>
              The mission, usually via the POC coordination mechanism, should review the POC strategic threat assessment once a year along with the POC strategy, or whenever major changes occur in the strategic environment. It will inform and be aligned with the Mission Threat Assessment provided by the JMAC. The POC strategic threat assessment should:
            </p>
            <ul className="square un-navy">
              <li>
                identify and prioritize the major actual and potential strategic threats to civilians in the mission area including a detailed analysis of the source of each threat; and
              </li>
              <li>
                include specific populations at risk and their vulnerabilities, based on any characteristics of the civilians that make them susceptible to those threats (for example, displacement, age, race or ethnicity, religion, gender, disability), or certain geographical features or activities that expose them to threats.
              </li>
            </ul>
            <p>
              A summary of the threat assessment should be included in the POC strategy, identifying the priority strategic threats in the mission area.
            </p>
            <p>
              The POC threat assessment presented in the mission&apos;s POC strategy should be accompanied by a POC Threat Matrix (see chapter 8.3), which is updated regularly (weekly or monthly) and which provides a detailed overview of priority threats to be addressed by the mission at the operational and tactical levels, at any point in time.
            </p>
            <p className="un-navy bold">
              C. Priority strategic objectives, indicators and risks
            </p>
            <p>
              The POC strategy should set out the key priority objectives of the mission for the implementation of the POC mandate, both in the long term and for the period of the strategy. These could focus, for example, on reducing a threat posed by a specific perpetrator or in a specific geographical area, addressing a systemic underlying cause of threats to civilians or enhancing the capacity and willingness of the host state to protect civilians. The strategy should set out the main indicators of the achievement of these objectives and any potential risks or external factors that may impede their achievement, as well as mechanisms for monitoring and evaluating progress towards these objectives.
            </p>
            <p>
              While the mission will focus on achieving these objectives at the strategic level, it must also be able to respond to, prevent or mitigate other ongoing or emerging threats to civilians at the tactical level in line with the POC threat assessment.
            </p>
            <p>
              POC strategies should systematically elevate gender, child protection and the fight against CRSV to a strategic priority, with dedicated outcomes and impact indicators.
            </p>

            <p className="un-navy bold">
              D. POC response model and planned activities
            </p>
            <p>
              This section will present the broad approach of the mission to preventing, stopping or mitigating the POC threats and risks identified in the strategic threat assessment, across the three tiers of the POC Operational Concept (namely, protection through dialogue and engagement, provision of physical protection and establishment of a protective environment).
            </p>
            <p>
              Detailed activities to respond to each threat at any point in time will be presented in the POC Threat Matrix, which will ensure that missions have an agreed multidimensional response in place for each priority threat and enable them to elaborate the roles and responsibilities of different mission components. All components and sections must thereafter align their CONOPS and workplans to reach key POC objectives set out in the strategy. Detailed roles and responsibilities of each mission component, as well as integrated POC mechanisms and tools, should be directly inserted in operational plans.
            </p>
            <p>
              Given the variety of contexts in which missions carry out the POC mandate and the great variety in mission design and structure, there is no single way in which POC roles should be assigned. This section may articulate the general POC-related roles and responsibilities of the various mission actors, while the POC Threat Matrix should go into the details regarding who undertakes which POC activity at any point in time.
            </p>
            <p className="un-navy bold">
              E. Analysis of mission capacities, resources and national caveats
            </p>
            <p>
              This section should include an analysis of mission capacities and resources in relation to the identified POC risks, including how resources will be allocated and prioritized. This analysis enables the mission to manage expectations and must therefore go beyond a discussion of the physical, financial and human resources at the mission&apos;s disposal, and include a frank assessment of what is beyond the mission&apos;s protection capacity. It will also include an analysis of the impact of national caveats, as well as of the ability of mission personnel to undertake POC activities. For example, in situations where the contingents in a particular area do not possess the training or capacities required to address POC concerns of the local community, this should be noted. Alternative means of meeting those requirements should be identified and the necessary training coordinated, including with the support of Headquarters.
            </p>
            <p className="un-navy bold">
              Annexes to POC strategy or separate SOPs: Engagement and coordination mechanisms
            </p>
            <p>
              (Separate SOPs are generally preferred, so that the comprehensive POC strategy remains a short, actionable document.)
            </p>
            <p>
              Either in an annex or separate SOPs<sup>15</sup>, the mission should reflect how new or existing coordination mechanisms will be used to coordinate the implementation of the POC strategy within the mission (in mission headquarters, and at subnational levels), with humanitarian and protection actors, with the host authorities, with local communities and with non-state armed actors, as appropriate (see chapter 6 for more guidance on POC coordination mechanisms).
            </p>
            <ol type="1">
              <li>
                <strong>Within the mission:</strong>
                <ul className="square un-navy">
                  <li>
                    Outline coordination mechanisms at the strategic, operational and tactical levels. Participation must include military, police, civilian and mission support representatives. To ensure integration or coordination of activities with the UN Humanitarian Country Team, participation should include representatives from the humanitarian community, as appropriate (the UN Office for the Coordination of Humanitarian Affairs [OCHA], possibly accompanied by the Protection Cluster lead and other agencies, as relevant).
                  </li>
                  <li>
                    Determine how immediate POC activities will be coordinated during crises, including through relevant crisis coordination mechanisms (Crisis Management Teams, Crisis Centres, JOCs, Protection Working Group, etc.).
                  </li>
                  <li>
                    <strong>POC lead or coordinator </strong>– While the development of the mission&apos;s POC strategy is placed under the overall responsibility of the senior mission leadership, a designated lead amongst senior mission leaders can be appointed to oversee and guide daily POC mandate implementation and coordination mechanisms. Most missions place this responsibility in the hands of one of the Deputy Special Representatives of the Secretary-General (DSRSGs), though protection of civilians activities should not be confined to a single &apos;pillar&apos; of the mission.
                  </li>
                  <li>
                    <strong>Protection of Civilian Adviser(s) </strong>– Mission POC Advisers or focal points support and advise mission leaders in their efforts to manage the mission&apos;s POC activities and coordinate early warning, analysis and response, planning, reporting, monitoring and evaluation, and training tools and processes related to POC under the POC Action Plan.
                  </li>
                </ul>
              </li>
              <div className="relative margin-top margin-bottom">
                <img src={strategies} alt="Tailored armed groups strategies" />
                <div className="description">
                  <p>
                    MONUSCO personnel conduct an integrated Joint Protection Team mission. (2019)
                  </p>
                </div>
                <p className="ref">UN Photo/Jacob Lange</p>
              </div>
              <li>
                <strong>With humanitarian and protection actors, including UN entities and NGOs:</strong>
                <ul className="square un-navy">
                  <li>
                    Humanitarian and protection actors should be briefed, consulted and/or involved in all aspects of the mission&apos;s POC strategy, including sharing a common analysis of threats and vulnerabilities, and the development of response plans, advocacy or training.
                  </li>
                  <li>
                    Identify existing coordination mechanisms at all levels with the humanitarian community, including the Protection Cluster and the UNCT/HCT, as well as possibly the Civil-Military Coordination (CMCoord) working group.
                  </li>
                  <li>
                    Roles and responsibilities specific to the protection of civilians should be clearly articulated and understood between the mission and other protection actors. This is essential to facilitating effective and appropriate coordination and to distinguish between humanitarian, human rights, development, military, police, rule of law and political actions, some of which could lead to diminished space for humanitarian action. It will also clarify that humanitarian protection actors are not a substitute for mission action, as humanitarian action is likely to remain outside the scope of integration and is guided by humanitarian principles, not mission gaps or priorities.
                  </li>
                </ul>
              </li>
              <li>
                <strong>With the host authorities:</strong>
                <ul className="square un-navy">
                  <li>
                    Based on the mapping of the government&apos;s strengths and weaknesses vis-à-vis POC, including possible IHL and human rights violations perpetrated by elements of the host government, identify coordination or engagement mechanisms to be used to consult with the host authorities, at mission headquarters and subnational level. This should include at least Ministries of Defence, Interior and Justice.
                  </li>
                  <li>
                    This section will articulate how the mission will support the host state to assess POC threats and fulfil its primary POC responsibilities.
                  </li>
                  <li>
                    Transparency is required to foster confidence with government counterparts that the mission is there to act in support of the host authorities.
                  </li>
                </ul>
              </li>
              <li>
                <strong>With local communities:</strong>
                <ul className="square un-navy">
                  <li>
                    Identify mission mechanisms and approaches for engagement with local communities, establish joint early warning and information-sharing mechanisms.
                  </li>
                  <li>
                    Identify existing community protection mechanisms to be supported by the mission.
                  </li>
                  <li>
                    Engagement will not only involve the representatives of the population or traditional authorities, but also groups who are directly at risk.
                  </li>
                  <li>
                    The mission should be aware of possible risks in engaging with or assigning specific responsibilities to community actors and ensure that these risks are identified and mitigated.
                  </li>
                </ul>
              </li>
              <li>
                <strong>With non-state armed actors:</strong>
                <ul>
                  <li>
                    Present how the mission intends to engage with nonstate armed groups on POC, at the strategic, operational and tactical levels. Ensure a streamlined and clear engagement process is in place — sanctioned by the mission leadership — that is consistent with any existing mechanisms and contacts the mission may have.
                  </li>
                </ul>
              </li>
            </ol>

            <p className="un-navy bold">
              Annexes to POC strategy or separate SOPs: Preparedness activities
            </p>
            <ol type="1">
              <li>
                <strong>Information gathering, sharing and analysis</strong>
                <ul className="square un-navy">
                  <li>
                    Information management: This annex or SOP should identify mission-wide or integrated information collection, sharing and analysis processes. This should include mechanisms to gather, analyse and map data, and disseminate POC related information.
                  </li>
                  <li>
                    The annex or SOP should also set out the principles and mechanisms for sharing sensitive information to ensure the confidentiality and informed consent for the use of information related to victims, witnesses or sources to protect their privacy and security. These mechanisms should be consistent with existing guidance, including the DPO Policy on Peacekeeping-Intelligence, JMAC and JOC policies and guidelines, and the OHCHR-DPKO-DPA-DFS Policy on Human Rights in United Nations Peace Operations and Political Missions.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Early warning systems and crisis response</strong>
                <ul className="square un-navy">
                  <li>
                    Early warning: This annex or SOP should identify or establish mission early warning systems including what role external actors, including the host authorities and local population, will play in these mechanisms.
                  </li>
                  <li>
                    Crisis response: This annex or SOP should articulate the rapid decision-making process to address POC crises, involving the relevant components. It shall also clearly state how missions will consult and coordinate with external actors in a crisis, especially with key government stakeholders.
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </>
      )
    },
    {
      index: '5.2',
      name: 'POC Priority Objectives in CPAS',
      title: t('handbook.chapters.chapter5.2.title'),
      content: (
        <>
          <p>
            All peacekeeping operations are due to adopt the Comprehensive Performance Assessment System (CPAS) by July 2020. CPAS is a comprehensive, results-based reporting and performance-assessment tool to help mission leadership grapple with complex mandates and rapidly changing political landscapes and country contexts. It focuses on the most important strategic objectives (priority objectives) of a mission, as articulated by the SRSG, and tracks a mission&apos;s performance and impact against those. It helps mission leadership to manage and assess their operations better and provides concrete data to make evidence-based decisions and more effectively tell the story of the mission and the difference it is making.
          </p>
          <p>
            When initiating the CPAS process, missions conduct context mapping to identify drivers of change, as well as critical conditions related to how the knowledge, position or behaviour of a target population influence the drivers of change. The context mapping is then used to build the results framework, outlining the outputs, intended outcomes and intended impacts the mission aims to bring about to influence the drivers and critical conditions. A combination of quantitative and qualitative indicators is used to monitor progress and assess whether the mission is achieving the desired outcomes and impacts, and data against those indicators is collected to support analysis and reporting. The collected data is analysed and visualized through a dashboard that allows mission leadership to see where the mission is performing well and where it faces challenges, showing trends across the indicators over time.
          </p>
          <p>
            In missions where POC is a priority mandate, priority objectives related to POC, which are informed by the POC strategy, should be included in the CPAS. Doing so will help determine and define POC success across the mission. All relevant mission components will then be expected to develop their workplans and objectives to make progress towards these goals. Mission POC advisers and focal points should be included in the context mapping exercises as well as in the development of the CPAS results framework, in particular the development of POC indicators, to ensure that CPAS accurately captures the mission&apos;s impact on POC. The results captured by CPAS should in turn inform reviews and revisions of the POC strategy.
          </p>
          <table className="examples horizontal">
            <thead>
              <tr>
                <td colSpan="6">Example: CPAS results framework related to POC</td>
              </tr>
            </thead>
            <tbody>
              <tr className="subhead">
                <td><strong>DRIVERS OF CHANGE</strong></td>
                <td><strong>IMPACT INDICATOR</strong></td>
                <td><strong>CRITICAL CONDITIONS</strong></td>
                <td><strong>OUTCOME</strong></td>
                <td><strong>OUTCOME INDICATOR</strong></td>
                <td><strong>OUTPUTS</strong></td>
              </tr>
              <tr>
                <td>Threat posed by armed groups decreases</td>
                <td>
                  <p># of civilian casualties caused by armed groups</p>
                  <p># of illegal checkpoints operated by armed groups</p>
                </td>
                <td>Effective presence of national security and defence forces</td>
                <td>
                  <p>
                    Improved capacity of Government security and defence forces to respond to
                  </p>
                  <p>imminent threats against civilians</p>
                </td>
                <td>
                  <p>% of high risk areas with presence of government forces</p>
                  <p># of cases where government forces respond to alerts</p>
                </td>
                <td>
                  <p>
                    Daily patrols coordinated with government security forces across the territory
                  </p>
                  <p>Training of government security forces conducted</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Progress towards these objectives should be regularly monitored and reviewed by the mission. The results framework is updated on a regular basis (3-6 months depending on the mission) or whenever a significant change in context occurs. An in-depth analysis of the results should take place as part of the review of the POC strategy or regular reporting mechanisms.
          </p>
          <p>
            Once implemented, it is expected that data and analysis generated by CPAS will be used to draft and inform the Results-Based Budget (RBB), performance reports, Secretary-General reports, and other reporting to UN Headquarters, as well as communications with external stakeholders. As a result, CPAS can be used to communicate progress and achievements towards the implementation of the POC mandate.
          </p>
          <p>
            Strategic monitoring and evaluation of the mission&apos;s POC activities can be initiated in mission or at Headquarters. Security Council Resolution 1894 (2009) stressed the importance of including POC indicators to measure and review progress against agreed-upon benchmarks<sup>16</sup>. POC Advisers should be involved in the analysis of effectiveness when POC outputs are being assessed.
          </p>
        </>
      )
    },
    {
      index: '5.3',
      name: 'Mainstreaming POC in Strategic Planning',
      title: t('handbook.chapters.chapter5.3.title'),
      content: (
        <>
          <p>
            Once the mission has identified threats and risks to civilians and set priority objectives for the implementation of the POC mandate, it should then develop integrated prevention and response plans for each identified threat or risk. These plans should set clear objectives, expected outcomes and indicators of progress towards mitigating threats/risks. The mission should ensure overall consistency amongst strategic planning processes and insertion of POC as a priority in relevant plans and strategies, including the Mission Concept, ISF, RBB, and CPAS. All relevant mission components should incorporate POC objectives, benchmarks and outcomes into their section workplans at all levels. POC objectives should also be included in the individual workplans of all relevant staff.
          </p>

          <table className="checklist margin-bottom">
            <thead>
              <tr>
                <td />
                <td>Checklist: Applying a POC lens to mission planning</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2">
                  Missions with POC mandates should ensure that their strategic planning embodies the requirements of the POC mandate and facilitates its implementation (see e.g., Annex II for guidance on developing POC responsive projects). In practice, applying a POC lens to planning requires mission planners, in consultation with the POC Adviser, to answer the following questions at the outset:
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  Does the strategic objective, activity or programme being planned impact directly or indirectly on the protection of civilians?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  Is there an opportunity to enhance the protection of civilians?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  Is there a potential negative impact on the protection of civilians or the mission’s ability to protect civilians?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  Which objectives, milestones and indicators of achievement contribute to or should be incorporated to address threats to civilians?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  What is the intended POC effect of each planned activity?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  What are the POC risks associated with each planned activity?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  What are the mitigation measures in place to address those POC risks?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  What is the process to ensure continuous risk analysis as the plan unfolds?
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  What capacity and processes are in place for possible contingencies that may arise and pose risks to civilians?
                </td>
              </tr>
            </tbody>
          </table>

          <div className="case-study un-navy">
            <h3>CASE STUDY</h3>
            <h4>The role of MINUSMA SPU in Campaign ‘Folon I’ in central Mali</h4>
            <div className="content">
              <div className="relative">
                <img src={folon} alt="Operation Folon" />
                <div className="description">
                  <p>
                    UN peacekeepers patrol a town in central Mali. (2019)
                  </p>
                </div>
                <p className="ref">UN Photo/Gema Cortes</p>
              </div>
              <p>
                From 2018-19, MINUSMA reinforced the size, capacity, and skillset of its integrated Strategic Planning Unit (SPU). This enabled the SPU to focus on whole-of-mission planning, including through collaboration between POC advisers, military, police and other MINUSMA personnel. The expansion of the unit enabled more effective strategic planning as MINUSMA proceeded to implement its newly revised 2019 mandate, which included the strategic priority to support the Government of Mali in its efforts to develop and implement a politically led strategy for the Centre of Mali, including the protection of civilians.
              </p>
              <p>
                By August 2019, the SPU consisted of four civilian officials, one police official and one military official working to enhance mission-wide collaborative planning. The SPU established new forums for integrated strategic planning and ensured that resource allocations would reflect overall Mission objectives and strategic priorities instead of individual section goals.
              </p>
              <p>
                These improvements in integrated strategic planning helped MINUSMA to protect civilians more effectively in a coordinated manner when it launched a protection campaign in response to escalating violence against civilians in 2019 in central Mali. Together with colleagues from across the Mission&apos;s components and under the Special Adviser for the Centre, the SPU led the planning of the campaign, which was an integrated operation. Peacekeeping troops conducted operations to improve security, while facilitating the deployment of civilian personnel to carry out activities and engage in dialogue with community members. Before and during the campaign, MINUSMA communicated with development and humanitarian actors, in part to deconflict, when needed, the activities of humanitarian actors and peacekeepers.
              </p>
              <p>
                Going forward, stronger integrated planning will better link threat analysis and planning to operations. This will enable deterrence of violence as a result of improved early warning, an enhanced protective environment, and increased Mission efforts to avoid unintentionally harming civilians or prompting reprisals against civilians.
              </p>
            </div>
          </div>
          <h5>Transition planning</h5>
          <p>
            Strategic planning also includes transition planning, which is the responsibility of the entire senior leadership team under the leadership of the SRSG. It begins as soon as a mission is set up. Upon completion of the start-up phase, all missions, jointly with UNCTs, are required to develop an integrated transition calendar in collaboration with the host authorities and other national, regional and international partners. The transition calendar should indicate the timelines and key milestones for developing a detailed transition plan. The UN country and Headquarters leadership should also take steps within their respective mandates and comparative advantages to ensure that a detailed transition plan is articulated at least 24 months prior to the withdrawal or reconfiguration of the UN peace operation<sup>17</sup>. Throughout these transition processes, it will be critical to apply a POC lens.
          </p>
        </>
      )
    },
    {
      index: '5.4',
      name: 'Setting POC Indicators',
      title: t('handbook.chapters.chapter5.4.title'),
      content: (
        <>
          <p>
            Measuring the effectiveness of mission POC activities is challenging. The protection environment on the ground is normally influenced by many complex factors, some of which are outside the control of peacekeeping operations, and most of which are difficult to measure. However, selecting a meaningful set of indicators for POC can help the mission to:
          </p>
          <ul className="square un-navy">
            <li>
              Ensure relevant POC indicators within reporting frameworks (e.g., CPAS, RBB).
            </li>
            <li>
              Measure progress of workplans: Component, unit and team workplans should include POC indicators to measure the outcome of relevant POC activities. This will highlight areas of relative success and relative struggle for mission entities.
            </li>
            <li>
              Support the strategic collection of data: The framework of POC indicators will inform the mission strategic information priorities by establishing a set of data requirements for consistent information collection.
            </li>
            <li>
              Improve POC reporting: The indicators can serve as cornerstones of missions&apos; POC reports and thus provide a coherent reporting framework to keep Headquarters and the Security Council better informed of trends and challenges on the ground.
            </li>
            <li>
              Enhance shared understanding of POC priorities: By collaboratively identifying a set of POC indicators, missions will develop a shared understanding of POC priorities. The subsequent application of these indicators in planning documents and reporting mechanisms will reinforce this process.
            </li>
            <li>
              Facilitate comparison of POC implementation across missions: While the final sets of indicators and the precise terminology used will differ between peacekeeping operations, the overall progress made by missions can facilitate cross-mission comparisons and the identification of regional trends.
            </li>
          </ul>
          <h5>Selecting a balanced set of indicators</h5>
          <p>
            <strong className="un-navy">DPO has developed a set of indicators for monitoring the implementation of the POC mandate</strong>, which missions can use as a basis to develop their own mission-specific indicators, tailored to their specific context and mandate. The full list of indicators is available in Annex III. The indicators are grouped into three categories, each of which will have an impact on the prevention or mitigation of threats to civilians:
          </p>
          <ol type="1">
            <li>
              Capability and intent of potential perpetrators to commit violence are reduced;
            </li>
            <li>
              Capacity of the host state to protect civilians is strengthened; and
            </li>
            <li>
              Community level capacity to prevent and mitigate physical violence is strengthened.
            </li>
          </ol>
          <p>
            The list of POC indicators developed by DPO serves as a repository to assist missions. It provides a number of Specific, Measurable, Achievable, Relevant, Timebound (SMART) indicators that cover a wide range of POC-related issues, allowing missions to select those most suited to their particular contexts. In this process, it will be important to consider indicators that already exist for individual components that can be adopted, aligned and/or combined with POC indicators.
          </p>
          <p>
            One of the greatest challenges to measuring POC performance is the ability to collect accurate and relevant quantitative and qualitative data about the impact of activities. In addition, POC action is designed to be preventive, making it particularly difficult to measure the absence of incidents of physical violence to civilians that the mission has helped to prevent. To address this challenge, it is recommended to select diverse types of indicators in order to provide a comprehensive picture.
          </p>

          <h5>Types of indicators for POC:</h5>
          <table className="striped no-header">
            <tbody>
              <tr>
                <td>1</td>
                <td>CAPACITY INDICATORS</td>
                <td>
                  The capacity of armed actors, the host state or communities to carry out physical violence or to protect themselves from it can serve as proxy for the strength of their institutions and mechanisms.
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>PERCEPTION INDICATORS</td>
                <td>
                  Perceptions will primarily be measured through opinion surveys, which provide a subjective view of the protection situation.
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>SITUATION INDICATORS</td>
                <td>
                  This type reflects patterns and trends of violence in an objective and typically quantifiable way.
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Each of the above types of indicator alone may pose problems. Community perceptions are not always accurate. For instance, an overall improvement in the country&apos;s economic and political situation may lead to a generally positive perception but may not account for serious violence against civilians in one part of the country. By drawing on different types of information, a diverse set of indicators can provide a more accurate and holistic assessment of progress in protecting civilians.
          </p>
          <p>
            A final layer of distinction between indicators is the tier of mission action under the three tiers of the operational concept of POC (protection through dialogue and engagement, provision of physical protection and establishment of a protective environment). The selection of a balanced set of indicators from across the three tiers aligns with a whole-of-mission, comprehensive approach to POC.
          </p>
          <p>
            Finally, POC indicators should:
          </p>
          <ul className="square">
            <li>
              Be based on the mission threat analysis and POC strategy, as well as CPAS context mapping;
            </li>
            <li>Use disaggregated data;</li>
            <li>Be gender sensitive;</li>
            <li>
              Link to other actors, including the protection plans and priorities of the host state and UN Country Team;
            </li>
            <li>
              Be based on cooperation with humanitarian partners; and
            </li>
            <li>
              Remain flexible if the situation changes.
            </li>
          </ul>
        </>
      )
    },
    {
      index: '5.5',
      name: 'Knowledge Management and Organizational Learning',
      title: t('handbook.chapters.chapter5.5.title'),
      content: (
        <>
          <p>
            To improve performance, all missions should engage in organizational learning, including conducting AARs and lessons-learned studies<sup>18</sup>. For example, in consultation with the mission&apos;s Policy and Best Practices Officer (PBPO) or Best Practices focal point, the POC Adviser or Focal Point and any other relevant mission component may conduct regular reviews of protection mechanisms, processes and tools, or regular assessment of the impact of the mission&apos;s POC activities in the most affected areas (for example, an understanding of how patrolling is aligned to threats against or vulnerabilities of civilians). Further, critical issues with POC tools, challenges or crises that have occurred should be captured by carrying out lessons-learned studies and AARs and shared with Headquarters to support organizational learning across missions and inform future strategic planning and guidance<sup>19</sup>.
          </p>
          <p>
            To the extent possible, all studies on the mission&apos;s performance in protecting civilians should employ a holistic approach, considering and reflecting internal views, but also those of external protection actors such as affected communities, the host government and humanitarian actors.
          </p>
        </>
      )
    },
    {
      index: '5.6',
      name: 'Reporting',
      title: t('handbook.chapters.chapter5.6.title'),
      content: (
        <>
          <p>
            Peacekeeping operations are expected to produce comprehensive reporting, in close coordination with other protection actors, on the progress being made in the implementation of POC mandates. This includes various types of reports for distinct purposes and audiences.
          </p>
          <p>
            Reporting in-mission and to UN Headquarters is crucial for drawing the attention of senior UN leadership to the challenges facing the mission&apos;s implementation of the POC mandate and the actions necessary to address those challenges. Such reporting includes, but is not limited to, daily, weekly and monthly situation reports and code cables. Public human rights reports issued by missions can contribute to implementing the POC mandate by influencing armed actors and others in positions of power, while also informing the international community about the situation of civilians affected by violence. Finally, the mission is responsible for providing input into various reports prepared for the Security Council, TCCs/PCCs and other Member States to inform them of developments on the ground and how the mission is addressing them.
          </p>
          <p>
            Missions must ensure that POC is appropriately mainstreamed in mission reporting and that offices responsible for reporting have the required information. The following are some of the existing mission reporting requirements related to POC:
          </p>
          <ul className="square un-navy">
            <li>
              Secretary-General&apos;s country-specific reports;
            </li>
            <li>
              Secretary-General&apos;s report on the protection of civilians in armed conflict (every 12 months);
            </li>
            <li>
              Inputs to briefings to the Security Council Informal Expert Group on the Protection of Civilians;
            </li>
            <li>
              Public reports on the human rights situation released in accordance with the Joint Directive on public reporting on human rights by UN Peace Operations;
            </li>
            <li>
              Secretary-General&apos;s thematic and periodic reporting on CAAC; and
            </li>
            <li>
              Secretary-General&apos;s thematic and periodic reporting on CRSV.
            </li>
          </ul>
          <p>
            At the strategic level, the reporting system should ensure that the relevant UN documents, including the country-specific Secretary-General&apos;s reports, concisely articulate anticipated or actual threats to civilians, and detail the mission&apos;s joint preventive or responsive actions under overall security and stability objectives, the progress made over time and the challenges faced by the mission. In addition to information on POC activities undertaken by the mission, reports should include an analysis of the adequacy of the mandate and resources to protect civilians.
          </p>
        </>
      )
    }
  ],
  footnotes: {
    content: (
      <>
        <ol type="1" start="15" className="refs">
          <li className="5-1-3">
            An SOP provides a standing instruction on how to implement a specific task, process or activity, or how to achieve a desired result. The development and promulgation of mission-specific guidance should follow the process set out in the Guidelines for the Development of Mission-Specific Guidance, available at <a href="https://ppdb.un.org">https://ppdb.un.org</a>.
          </li>
          <li className="5-2">S/RES/1894 (1999) OP 27.</li>
          <li className="5-3">
            Secretary-General&apos;s Planning Directive for the development of consistent UN Transition processes (25 February 2019).
          </li>
          <li className="5-5">
            As set out in the 2015 DPKO-DFS Policy on Knowledge Sharing and Organizational Learning (2015.13).
          </li>
          <li className="5-5">
            The Policy and Practice Database (PPDB) is the central repository for official guidance as well as lessons learned and best practices products for field operations managed by DPO and DPPA (<a href="https://ppdb.un.org">https://ppdb.un.org</a>).
          </li>
        </ol>
      </>
    )
  }
};
