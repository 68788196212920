import {
  SET_CACHE,
  UPDATE_CACHE,
  REMOVE_FROM_CACHE,
  CLEAR_CACHE
} from './types';

/**
 * Sets the cache to the exact object passed (Replace)
 * @example
 * setCache({ role: 'civilian', area: 'planning' });
 * @param {Object} data - the data to be stored in the cache
 * @returns {Object} reducer object
 */
const setCache = (data) => (dispatch, getState) => {
  const { cookies } = getState();
  dispatch({ data, cookies: cookies.enabled, type: SET_CACHE });
};

/**
 * Updates a one or more properties in the cache (Replace Properties)
 * @example
 * updateCache({ role: 'police' });
 * @param {Object} data - contains the properties to be
 * merged with cache
 * @returns {Object} reducer object
 */
const updateCache = (data) => (dispatch, getState) => {
  const { cookies } = getState();
  dispatch({ data, cookies: cookies.enabled, type: UPDATE_CACHE });
};

/**
 * Removes a property from the cache (Delete)
 * @example
 * removeFromCache('role');
 * @param {String} name - the name of the property to remove
 * @returns {Object} reducer object
 */
const removeFromCache = (name) => (dispatch, getState) => {
  const { cookies } = getState();
  dispatch({ name, cookies: cookies.enabled, type: REMOVE_FROM_CACHE });
};

/**
 * Clears the cache completely of all properties
 * @example
 * clearCache();
 * @returns {Object} reducer object
 */
const clearCache = () => ({ type: CLEAR_CACHE });

export default {
  setCache,
  updateCache,
  removeFromCache,
  clearCache
};
