import React, { useMemo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import sanitizeHtml from 'sanitize-html';
import templates from '../../templates';
import actions from '../../redux/actions';
import { chapterProgress } from '../../helpers';
import menuElipsis from '../../images/design/menu_elipsis.svg';
import menuElipsisGrey from '../../images/design/menu_elipsis-grey.svg';
import handbook from '../../images/design/handbook-grey.svg';
import textIcon from '../../images/design/text-only.svg';
import { translate } from '../../services/i18n';
import GridContent from './gridContent';
import { getIndexToDisplay } from '../../helpers/index';

const Content = ({ cache, updateCache, location }) => { // eslint-disable-line
  const [showMenu, setShowMenu] = useState(false);
  const [textOnly, setTextOnly] = useState(false);
  const refs = useRef([]);
  const container = useRef();
  const progressRef = useRef();
  const filterRef = useRef();
  const chapter = useMemo(() => {
    if (!cache.chapter) return null;
    refs.current = [];
    return templates.find((t) => t.name === cache.chapter);
  }, [cache.chapter]);

  useEffect(() => {
    progressRef.current = cache.progress || {};
  }, [cache.progress]);

  useEffect(() => {
    // Scroll to the top if we are going to recommendations
    if (cache.tab === 'recommended') {
      container.current.scrollTo(0, 0);
    }
  }, [cache.tab]);

  const getVisible = () => {
    // Get the first visible section if one exists
    const visible = refs.current.find(({ r }) => {
      // Check if display is none and if it is ignore it
      if (window.getComputedStyle(r).display === 'none') return false;

      const { top } = r.getBoundingClientRect();
      const { height } = container.current.getBoundingClientRect();

      return top >= 0 && top <= height;
    });

    const { height: innerHeight, top: containerTop } = container.current.getBoundingClientRect();

    const allVisible = refs.current.reduce((all, ref) => { // eslint-disable-line
      const { top, height: refHeight } = ref.r.getBoundingClientRect();
      const bottom = (top + refHeight) - containerTop;

      if ((top >= 0 || bottom >= 0) && top <= innerHeight) {
        all.push(ref);
      }

      return all;
    }, []);

    const progress = { ...progressRef.current };
    refs.current.forEach(({ r, section }) => {
      // Check if display is none and if it is ignore it
      if (window.getComputedStyle(r).display === 'none') return;

      // If it's not visible don't update it...
      if (!allVisible.some((v) => v.section.name === section.name)) return;

      // Get the percentage viewed based on the scrolling
      const { height, top } = r.getBoundingClientRect();

      // Get the remaining amount of section left, taking into
      // consideration the nav bar
      const left = (height + (top - containerTop)) - innerHeight;

      // Get the percent by getting the amount left against the
      // total height
      const percent = 100 - ((left / height) * 100);

      // Don't change the progress if the stored progress is higher
      // Or the section is off screen
      if ((progress[section.index] && progress[section.index] > percent) || percent < 0) return;

      // Don't set a progress above 100...
      progress[section.index] = percent > 100 ? 100 : percent;
    });
    updateCache({ progress });

    if (!visible) return;

    // Only update the visible when a top is visible
    updateCache({ visible: visible.section.name });
  };

  useEffect(() => {
    const query = Object.fromEntries(new URLSearchParams(location.search));
    if (query.chapter) {
      // Change to the correct chapter
      updateCache({ chapter: query.chapter });

      if (query.section) {
        setTimeout(() => {
          const s = refs.current.find((r) => r.section.index === query.section);
          let foundMatch = false;

          /**
           * A search term must exist prior to performing a search and replace
           */
          if (cache.keywords) {
            const addMarks = (text, regex) => text.replace(regex, '<mark id="marked">$1</mark>');
            const regex = new RegExp(`(${sanitizeHtml(cache.keywords)})`, 'gi');
            /**
             * Matches areas a sub chapter has sub sections
             * @type {NodeListOf<Element>}
             */
            const elementsNodeList = document.querySelectorAll(`[data-name='${s.r.dataset.name}'] .main .sub-section`);
            /**
             * Matches area where main is the root element and the children are only paragraphs
             * @type {Element}
             */
            const element = document.querySelector(`[data-name='${s.r.dataset.name}'] .main`);

            if (elementsNodeList > 1) {
              elementsNodeList.forEach((el) => {
                if (regex.test(el.innerHTML) && !foundMatch) {
                  el.innerHTML = addMarks(el.innerHTML, regex);
                  document.getElementById('marked').scrollIntoView();

                  foundMatch = true;
                }
              });
            } else if (element) {
              if (regex.test(element.innerHTML) && !foundMatch) {
                element.innerHTML = addMarks(element.innerHTML, regex);
                foundMatch = true;
              }
            }
          }

          if (foundMatch) {
            document.getElementById('marked').scrollIntoView();
          } else {
            s.r.scrollIntoView();
          }
          setTimeout(() => {
            container?.current?.addEventListener('scroll', getVisible);
          }, 10);
        }, 10);
      } else {
        container.current.addEventListener('scroll', getVisible);
        container.current.scrollTop = 0;
      }
    } else container.current.addEventListener('scroll', getVisible);
  }, [location]);

  const progress = useMemo(() => {
    if (!chapter) return 0;
    return chapterProgress(chapter.name, cache);
  }, [chapter, cache.progress]);

  useEffect(() => {
    const handleClick = (e) => {
      if (filterRef.current && !filterRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const next = () => {
    const index = templates.findIndex((t) => t.name === chapter.name);
    if (index + 1 < templates.length) {
      updateCache({ chapter: templates[index + 1].name });
      container.current.scrollTop = 0;
    }
  };

  const hasNext = () => {
    const index = templates.findIndex((t) => t.name === chapter.name);
    return index + 1 < templates.length;
  };

  return (
    <>
      <header className={chapter.color}>
        {cache.keywords ? (
          <div className="search-back">
            <a href={`/search?keywords=${cache.keywords}`}><span>&lt;</span> Back to Search Results</a>
          </div>
        ) : null}
        <div>
          <div>
            <h1>{ chapter.name }</h1>
          </div>
          <div className="desktop-options">
            <button
              type="button"
              onClick={() => setTextOnly((t) => !t)}
            >
              <img src={textIcon} alt="text only icon" />
              <div>{ textOnly ? 'All Content' : 'Text Only' }</div>
            </button>
          </div>
          <div ref={filterRef} className="mobile-options">
            <button type="button" onClick={() => setShowMenu((s) => !s)}>
              <img src={showMenu ? menuElipsis : menuElipsisGrey} alt="show menu" />
            </button>
            { showMenu && (
              <div className="submenu">
                <button
                  type="button"
                  onClick={() => {
                    setShowMenu(false);
                    updateCache({ showSidebar: !cache.showSidebar });
                  }}
                >
                  <img src={handbook} alt="handbook icon" />  {translate('common.handbook-contents')}
                </button>
                <button
                  type="button"
                  onClick={() => setTextOnly((t) => !t)}
                >
                  <img src={textIcon} alt="text only icon" />
                  { textOnly ? translate('common.all-content') : translate('common.text-only') }
                </button>
              </div>
            ) }
          </div>
        </div>
        <div className="progress-container">
          <div className="progress" style={{ width: `${progress}%` }} />
        </div>
      </header>
      <div
        className={cn(chapter.color, 'scroller', { 'text-only': textOnly, 'search-back': cache.keywords?.length > 0 })}
        ref={container}
      >
        <GridContent tab={cache.tab} role={cache.role} area={cache.area} chapter={chapter} />
        { chapter && !chapter.sections && cache.tab === 'recommended' && chapter.content }
        { chapter && chapter.sections && chapter.sections.map((section, i) => (
          <div
            key={section.index}
            className={`section ${section.index.replace('.', '-')}`}
            data-name={section.name}
            ref={(r) => {
              // the refs are set to null on elements that are being removed
              // we want to ignore those
              if ((refs.current[i] && refs.current[i].r) || !r) return;
              refs.current[i] = { r, section };
            }}
          >
            <div className="track">
              <div className="title" id={section.index}>
                <span className="index">{ getIndexToDisplay(section.index) }</span>
                <h3>{ section.name }</h3>
              </div>
            </div>
            <div className="main">
              { section.content }
            </div>
          </div>
        )) }
        { chapter?.footnotes?.content ? (
          <div className="section section-footnotes">
            <div className="main">{chapter.footnotes.content}</div>
          </div>
        ) : null }
        { hasNext() && (
          <div className="continue">
            <button type="button" onClick={next}>
              {translate('common.continue-next-chapter')} &rsaquo;
            </button>
          </div>
        ) }
      </div>
    </>
  );
};

Content.propTypes = {
  cache: PropTypes.shape({
    chapter: PropTypes.string,
    visible: PropTypes.string,
    progress: PropTypes.object, // eslint-disable-line
    area: PropTypes.string,
    role: PropTypes.string,
    showSidebar: PropTypes.bool,
    tab: PropTypes.string,
    keywords: PropTypes.string
  }).isRequired,
  updateCache: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps, {
  updateCache: actions.updateCache
})(Content);
