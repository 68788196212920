import React from 'react';
import UNLogoImage from '../images/un_logo.jpeg';
import Template115Image from '../images/115.jpeg';
import Template116Image from '../images/116.jpeg';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Annex VI: SOP on Deploying Joint Protection Teams (JPTs)',
  title: t('handbook.annexes.vi.title'),
  annex: '6',
  color: 'un-orange',
  content: (
    <div className="main-content">
      <div className="text-image">
        <div className="bold">
          <p>United Nations</p>
          <p>
            Multidimensional Integrated Stabilization Mission in the Central African Republic (MINUSCA)
          </p>
          <p>Ref. MINUSCA 2019</p>
        </div>
        <div>
          <img src={UNLogoImage} alt="UN logo" />
        </div>
      </div>
      <div className="centered">
        <p><strong>Standard Operating Procedure</strong></p>
        <p className="bordered">Deploying Joint Protection<br />Teams (JPTs)</p>
        <p className="border-bottom">
          Approved by: SRSG Mankeur Ndiaye<br />
          Effective date: 1 May 2019<br />
          Contact: Protection of Civilians Unit<br />
          Review date: 1 May 2020
        </p>
        <p className="bordered full">
          <strong>
            MINUSCA STANDARD OPERATING PROCEDURE ON EARLY WARNING AND RAPID RESPONSE
          </strong>
        </p>
        <div className="contents-table">
          <p><strong>Contents:</strong></p>
          <ol type="A" className="bold">
            <li>Purpose</li>
            <li>Scope</li>
            <li>Rationale</li>
            <li>Key principles</li>
            <li>Procedures</li>
            <li>Roles and Responsibilities</li>
            <li>Terms and definitions</li>
            <li>References</li>
            <li>Contact</li>
            <li>History</li>
          </ol>
        </div>
      </div>
      <ol type="1">
        <h4>A. PURPOSE</h4>
        <li>
          The purpose of this Standard Operating Procedure (SOP) is to provide guidance on planning, implementing and evaluating Joint Protection Teams (JPTs). JPTs are ad hoc, multidisciplinary and cross-sectional teams, tasked to assess possible, potential, existing or continued protection of civilian (POC) threats in zones where the Mission does not have a permanent presence and to develop recommendations for senior Mission leadership on how to respond to these threats.
        </li>
        <h4>B. SCOPE</h4>
        <li>
          This SOP applies to all MINUSCA military, police and civilian personnel. This SOP does not apply to activities outside of the Mission&apos;s mandate or area of operations.
        </li>
        <h4>C. RATIONALE</h4>
        <li>
          MINUSCA adopted an action plan in February 2018 to implement the recommendations of the Special Investigation conducted by Brigadier General (Rtd) Ferdinand Amoussou (January 2018). Actions included the revision and adoption of a new POC Strategy for the Mission, which was adopted in April 2018 within which JPTs are a key tool. Experience in Central African Republic has shown that JPT Missions that are deployed in a timely manner constitute a critical element to the Mission&apos;s efforts to effectively prevent and respond to threats against the physical integrity of civilians as JPTs can contribute to improving early warnings (EW) and support decision-making for the Mission.
        </li>
        <li>
          JPTs are designed to help the Mission develop more timely and comprehensive analysis of POC threats in areas where MINUSCA is absent. They also seek to facilitate the rapid elaboration of responses to POC threats, with special consideration to threats affecting women, children and vulnerable populations. As opposed to single-section or single-purpose missions, JPTs allow a more comprehensive, whole-of-mission approach to implementing the POC mandate. JPTs are thus a critical tool for enhancing the Mission&apos;s capacity to anticipate, deter and effectively respond to serious and credible threats against the civilian population, as mandated by Security Council Resolution 2448 (2018). They are also one of the Mission&apos;s most important POC preventive tools<sup>1</sup>. Facilitating their effective and speedy deployment is thus a priority for all Mission leadership and staff.
        </li>
        <h4>D. KEY PRINCIPLES</h4>
        <li>
          The principles which guide the United Nations Peacekeeping protection approach are inscribed in the Department of Peacekeeping Operations (DPKO) and Department of Field Support (DFS) Policy on the Protection of Civilians (Ref. 2015.07) and MINUSCA&apos;s approach is aligned with this Policy. The Mission&apos;s approach is also guided by context-specific mandated tasks and principles as indicated below. (See Annex 1 for additional principles)
          <ol type="1">
            <li>
              Promote respect for Human Rights and International Humanitarian Law: All actions, tasks and activities of the Mission shall take into consideration principles of respect for human rights, international humanitarian law, and other relevant international norms.
            </li>
            <li>
              Gender: Strategies, activities and tasks must include a gender perspective, as mandated by United Nations Security Council Resolution (UNSCR) 1325/2000, and related resolutions.
            </li>
            <li>
              Confidentiality: All JPT mission reports, pictures, depositions and information are classified as confidential. A copy of all information from JPTs must always be sent to the POC Unit.
            </li>
            <li>
              Engaging with non-state armed groups: all JPT members must follow the SOPs on &apos;Engaging with non-state Actors&apos;.
            </li>
            <li>
              Awareness of environmental impact: All Mission personnel participating in JPT missions should lead by example in taking specific actions to demonstrate environmental awareness, sustainability management, recycling, and hazard and waste control, as well as follow the Environment Policy and Guidelines for MINUSCA, 2016.
            </li>
          </ol>
        </li>
        <h4>E. PROCEDURES</h4>
        <h4>DEPLOYMENT MODALITIES AND OBJECTIVES</h4>
        <li>
          JPTs are deployed on an as needed basis. JPTs can be part of Field Office&apos;s (FO) annual or monthly workplans, and also can be deployed on an emergency basis. All components and sections within an FO should include JPT missions in their monthly and annual workplans, when relevant, based on an assessment of POC needs, contingency plans, and security situation in their area of responsibility (AOR). Planning must be flexible enough to allow effective response to changes in the context and in the nature of POC threats.
        </li>
        <li>
          JPTs are to be implemented in zones where: (i) civilians are exposed to significant physical security risks/threats; possible or likely POC threat(s) have been identified via early warning POC coordination mechanisms; (iii) the Mission does not have a regular physical presence. JPTs can also be exceptionally deployed to Permanent Operating Bases (POBs) and Temporary Operating Bases (TOBs), when capacities on the ground are insufficient to assess threats against civilians.
        </li>
        <li>
          The more specific objectives of a JPT are to:
          <ol type="1">
            <li>
              Assess the risks, vulnerabilities, and exposure of civilians to threats against their physical integrity, with specific attention to women and girls;
            </li>
            <li>
              Assess local political and social dynamics, including those linked to armed group presence and activity, for protection planning purposes;
            </li>
            <li>
              Make recommendations for the Mission&apos;s preventive and protective responses to the threats identified against the physical integrity of civilians, with specific attention to women and girls;
            </li>
            <li>
              Make recommendations and provide information to the UNCT, humanitarian partners, national authorities and communities on risks, threats and vulnerabilities and how to respond to them, as needed;
            </li>
            <li>
              Contribute to establishing or supporting preventive protection structures, including but not limited to community protection plans (CPPs) and information exchange networks on protection between communities and the Mission;
            </li>
            <li>
              Work with all UN components including the Force, UNPOL, and humanitarian actors as needed, to identify protection needs, including protection against sexual violence and any other gender-based violence (GBV);
            </li>
            <li>
              Promote respect for human rights and international humanitarian law (IHL) by all parties to the conflict and all other violent actors.
            </li>
          </ol>
        </li>
        <li>
          In line with UNSCR 2448 (2018) that states that POC must be given priority in decisions about deployments and the allocation of resources (para.37), Mission Support will prioritize JPTs within operational exigencies. Only MEDEVAC, CASEVAC and military operations will have priority over JPTs<sup>2</sup>. Accordingly, Mission Support and all other related financial, administrative and support offices/sections/units must prioritize JPTs by:
          <ol type="1">
            <li>
              Responding to all JPT-related requests as a priority, understanding that the unpredictability and/or urgent nature of JPTs often means that some travel documents – except for MOPs – will not always be submitted in a timely manner.
            </li>
            <li>
              Assigning and making available requested resources, including logistical and flight arrangements.
            </li>
          </ol>
        </li>

        <h4>IMPLEMENTATION PHASES</h4>
        <li>
          JPTs are implemented in three phases: before, during and after deployment. The steps involved in each phase are specified below:
        </li>
        <h5>Before the mission</h5>
        <li>
          <u>Activating a JPT:</u> The decision to launch a planned or an emergency JPT can be made either at HQ or at Field Office level. At HQ level, the decision is made by the DSRSG-P, or the DSRSG-RC-HC, based on recommendations by the SMG-P, the Protection Working Group (PWG), the Human Rights Division (HRD), the Civil Affairs Section (CAS), or the Field Office Coordinator&apos;s Office. At the field level, the decision is made by the Head of Office (HoO) in close consultation with the local SMG-P and CAS.
        </li>
        <li>
          <u>Decisions to activate a JPT</u> must specify the JPT mission leader, the participating sections, the zone of deployment, and the main objective(s) of the mission. Also, the decisions must be directly and immediately communicated to (i) participating sections; (ii) the POC Unit at Bangui level; and (iii) Force Headquarters (FHQ) both at HQ and field level.
        </li>
        <li>
          <u>The JPT mission leader</u> is an international staff appointed by the POC unit and approved by the PWG when the JPT is being activated at Bangui Level, or by the HoO and approved by the local SMG-P when activated at the field.
        </li>
        <li>
          During the JPT, the JPT mission leader will serve under the oversight of the relevant HoO for JPTs decided at the FO level or by the FOC for JPTs decided at the HQ level. Although JPT members should consult Mission components as required, decision-making on the conduct of the JPT will be taken by the JPT mission leadership under the supervision of the relevant HoO or FOC, who will consult as required with Senior Mission Leadership.
        </li>
        <li>
          Selecting JPT participants: In addition to the &apos;JPT mission leader&apos;; JPTs need to be composed of at least two or more &apos;mission experts&apos;; one Force representative with substantive responsibilities; and one UNPOL representative with substantive responsibilities. JPT participants are international staff selected/appointed by Section Chiefs of participating units/divisions. National staff may be nominated to join JPTs the mission under agreement of PWG (Bangui) or SMG-P (FO). Military and UNPOL substantive participants from Bangui are appointed by the FC and the PC respectively, and by the Force and UNPOL sector commanders at field level (LFC and LPC).
        </li>
        <li>
          The following considerations need to be followed when selecting participants:
          <ol type="1">
            <li>
              The JPT mission leader and civilian mission experts must be international staff from substantive sections. They must come from at least three (3) of the following sections or units: CAS, HRD, POC Unit, Joint Mission Analysis Center (JMAC), Joint Operations Centre (JOC), Political Affairs Division (PAD), Women Protection Unit (WPU), Child Protection Unit (CPU), Security Sector Reform Section (SSR) or the Disarmament, Demobilization and Reintegration Section (DDR);
            </li>
            <li>
              Selection of participants will be based on the nature of the threats to be assessed and the objectives of the JPT to be achieved. Any JPT mission likely to directly or indirectly document IHL violations must ensure participation of staff able to compile a case file enabling judicial follow-up;
            </li>
            <li>
              POC Focal Points, as experts in POC, must be given priority in the composition of JPTs as &apos;mission experts&apos;. POC Focal Points can join JPT missions in zones falling under their AOR or any other AOR, as needed;
            </li>
            <li>
              Other PWG members, including OCHA or Protection Cluster members may participate where relevant;
            </li>
            <li>
              JPTs should have participants of both genders and aim for gender parity, where possible.
            </li>
          </ol>
        </li>
        <li>
          <u>Participation of GoCAR in JPTs:</u> GoCAR representatives, including members of the Central African Armed Forces (FACA), Internal Security Forces (ISF), or civilian authorities may be invited to participate in a JPT. Previous authorization is required from the local SMG-P or by the PWG Secretariat. Applying the principle of impartiality and observing the UN&apos;s Human Rights Due Diligence Policy (HRDDP) is mandatory in these cases. Covering expenses of GoCAR representatives will be decided on a case by case basis, after consultation with the DMS. In cases where a JPT has GoCAR participants, it will be referred to as a &apos;JPT+&apos; to distinguish it from a standard JPT.
        </li>
        <li>
          <u>Elaborating the Terms of Reference (TORs):</u> A JPT&apos;s TORs must be elaborated using the template in Annex 2. JPT mission leader has the responsibility to draft the TORs, which will be adopted by the PWG at Bangui level, and by the local SMG-P at field level. TORs serve as memos for Mission&apos;s administrative procedures.
        </li>
        <li>
          Arranging logistics, assets and equipment for JPTs: Each JPT participant is responsible for processing and obtaining all travel documents and permissions to participate in the JPT, including MOPs, security clearances and other travel documents/authorizations. Participants should consider requesting group MOPs/security clearances to expedite the process. The JPT Mission leader is responsible for coordinating with all JPT participants and for liaising with POC Focal Points, HoOs, and ODMS to arrange all additional JPT requirements and logistics. The JPT Mission leader may delegate this as appropriate, but remains responsible overall. ODMS and UNDSS must rapidly facilitate all the necessary permissions, logistics, equipment, and resources for JPT missions<sup>3</sup>.
        </li>
        <li>
          Pre-deployment planning and briefings: The JPT leader will convene JPT participants and coordinate with the HoO and UNDSS of the zone where the JPT mission will be implemented at least 48 hours ahead of departure in order to:
          <ol type="1">
            <li>Receive briefings on safety and security;</li>
            <li>
              Review objectives and goals; background and context; and estimated duration of the mission;
            </li>
            <li>Assign roles and responsibilities, including for reporting;</li>
            <li>Agree on mission agenda;</li>
            <li>
              Identify the local actors with whom the JPT will engage. Team members should ensure that meetings are planned with women and women groups/organization representatives to take into account their perspectives and needs.
            </li>
          </ol>
        </li>
        <li>
          <u>Coordination with the Mission Intelligence Coordination Mechanism (MICM):</u> When planning JPTs, the JPT Mission Leader is encouraged and authorized to task the MICM to receive strategic intelligence aimed at supporting and informing operational planning. This intelligence-sharing process will help ensure a whole-of-Mission approach and improve situational awareness to implement the JPT.
        </li>
        <li>
          <u>Coordination with UNDSS:</u> UNDSS is ultimately responsible for determining whether security conditions are appropriate for a JPT mission to take place. UNDSS may hold, stop or cancel any JPT mission at any time for security reasons.
        </li>
        <li>
          <u>Issuing orders/FRAGOS for military escorts to JPT missions:</u> If required per UNDSS road security classifications, once informed of the activation of a JPT, FHQ will issue orders/FRAGOS to escort the JPT mission. All JPT-related orders must indicate that the escort is an &apos;operation mission&apos;. They are therefore not subject to the limitations of other types of military missions. Orders must be flexible and specify that JPT itineraries can be adapted in the course of the mission based on decisions by the civilian mission leader in consultation with the Force escort leader.
        </li>
        <h4>During the deployment</h4>
        <li>
          <u>Security:</u> Every JPT member is responsible for his/her own security and health. JPT participants will join the JPT mission only if they are willing to follow all security instructions. The JPT leader has the faculty to hold, stop or cancel a JPT mission before the start of the JPT mission when logistics or the conditions of UN assets required for the mission, including UN vehicles and communications equipment, are not available, or do not meet the minimum UN security or safety standards. The JPT leader is also responsible for the following tasks:
          <ol type="1">
            <li>
              Regularly liaising with UNDSS, U2/G2, JOC, JMAC, PWG/SMG-P members, and HoO to obtain the most accurate and updated security, humanitarian and political information; and adapt the planning of the mission accordingly.
            </li>
            <li>
              Briefing JPT participants on security developments. S/he may delegate this responsibility to one of the JPT members.
            </li>
            <li>
              Organizing regular radio checks to confirm the whereabouts of each member of the team.
            </li>
            <li>
              Making sure that the team complies with the security restrictions established by UNDSS.
            </li>
          </ol>
        </li>
        <li>
          <u>MINUSCA military escorts:</u> If required per UNDSS road security classifications, the Force is responsible for providing security to the JPT mission once in the field and outside of MINUSCA premises. The assigned units are commanded by a &apos;military escort leader&apos;, who has the highest military authority in his/her team. The military escort leader is responsible for implementing the order/FRAGO and is therefore accountable for all decisions taken, including those related to POC. The military escort leader has the authority to stop, hold or cancel a JPT mission only for security reasons, and after consultation with the JPT leader and UNDSS.
        </li>
        <li>
          <u>Coordination between the JPT leader and escort leader:</u> The JPT leader and the military escort leader will coordinate before and during the implementation of the mission to agree on agendas including travel times, routine/security stops, and communication. MINUSCA military escorts cannot abandon or leave behind UN civilian staff in the field under any circumstances. Doing so must be reported to HQ/FHQ for investigation.
        </li>
        <li>
          <u>Communications:</u> Each JPT member will carry his/her own Tetra Radio handset. These – and the mobile VHF radios fitted in any UN vehicle - should be switched to the channel agreed with UNDSS and the Force escort leader to maintain contact with UN Base. UNDSS will be responsible for checking that Tetra Radios and radios in vehicles are functional. The JPT leader will be responsible for checking that JPT staff are trained and able to use such communication equipment. At least one person on the JPT must carry a satellite phone and have at least two contacts at the closest FO/TOB and two others at MINUSCA HQ.
        </li>
        <li>
          <u>Parallel activities during JPT missions:</u> Implementing the JPT&apos;s TORs is the priority for all JPT members. Only when time permits can JPT members conduct parallel activities, investigations or meetings related to the workplans of their respective components. In this case, they must inform and coordinate with the JPT leader. JPT members are not required to remain physically together at all times. They may split up to work on different tasks, whether related to JPT objectives or to parallel activities, based on previous agreement with the JPT leader, and according to security assessment from UNDSS and JPT mission leader.
        </li>
        <li>
          <u>Modifications to JPTs during the mission:</u> Given the nature of JPT missions and the evolution of threats against civilians, some JPT missions may have to be modified while they are being carried out. Modifications usually involve a change in timing or destinations. When a modification is required, the JPT leader will communicate with his/her Head of Section, the POC Unit at Bangui level, or the relevant HoOs, who will immediately transfer the request to ODMS, to issue immediate amendments to MOPs, TRIPs, and other security authorizations. Major modifications such as the extension of the JPT by a day or more, or significant deviations from the planned route require approval HQ and/or Chief Security Authority (CSA), according to current procedures. All authorizations may be given verbally but must be confirmed in writing as soon as possible. Decisions will be coordinated and shared with FHQ (U3, and U35), and the military escort leader, and copied to POC unit.
        </li>
        <li>
          <u>Protection of civilians in the course of a JPT:</u> If the JPT mission encounters a POC situation or is under attack, the military escort has the obligation to: (i) secure the safety of the UN staff according to their military capacity; (ii) proceed to the protection of civilians respecting the Rules of Engagement (ROE). If the military escort is asked to Medevac a civilian who is not part of the JPT mission, the decision to Medevac will be jointly made by the JPT leader and the military escort leader according to the Amendment to the SOP CASEVAC / MEDEVAC PLAN July 2016 (2018).
        </li>
        <li>
          <u>Post-mission de-briefing:</u> Within 24 hours of the JPT&apos;s return to base, the JPT leader will have a de-briefing meeting with all the participants of the JPT, to cross-check findings, agree on key messages and follow-up actions, and facilitate completion of the joint JPT report.
        </li>
        <li>
          Post-mission reporting:
          <ol type="1">
            <li>
              <strong>Summary report: </strong>Within <strong>48 hours </strong>of the return to base, the JPT leader will submit an advance summary report of no more than two pages to POC senior advisor with copy to the HoO, the DSRSG-P and the DSRSG-RC-HC for their comments. JPT participants and the POC Unit will be in copy.
            </li>
            <li>
              <strong>Final report: </strong>The final report must be submitted <strong>no later than one week </strong>after the circulation of the summary report. The JPT report should be sent to, the DSRSG-P, the DSRSG-RC-HC, relevant HoOs, local SMG-P members, JPT participants, and the PWG at Bangui level. A template JPT report is at Annex 3 indicating the required content of the final report. Report assessments and recommendations must always take into consideration the specific needs and the impact of the armed conflict on both genders, highlighting the specific impact on women and girls.
            </li>
            <li>
              <strong>Information sharing with POC coordination mechanisms: </strong>The local SMG-P and/or PWG in Bangui may invite the JPT mission leader to present the JPT findings and discuss possible recommendations with their respective members.<br />
              An emergency SMG-P/PWG may be called by the POC Unit for this purpose.
            </li>
          </ol>
        </li>
        <li>
          <u>JPT recommendations:</u> Before finalizing recommendations, JPT Mission Leaders must ensure that JPT recommendations are specific, achievable, realistic (consistent with mission realities), timely, and take into account Field Office and state institution capacities. JPT reports should clearly specify which Sections are responsible for implementing recommendations, distinguishing between Field Office and Bangui-level actions as appropriate. HoOs and the local SMG-P members will adopt and issue instructions based on JPT recommendations in their AOR. Recommendations requiring HQ-level decisions or actions can be referred to the POC Unit in Bangui for referral to the Mission Senior Leadership or PWG members as relevant.
        </li>
        <li>
          <u>Implementation, monitoring and evaluation:</u> All Field Offices, through the SMG-P, are responsible for maintaining records as to the number of JPTs conducted, for the purpose of year-end performance reporting. The local SMG-P is also responsible for tracking and following-up on the implementation of endorsed JPT recommendations concerning their AOR through a JPT Tracking Matrix, and periodically report on the same to the POC Unit. The POC Unit will consolidate JPT details, including findings and recommendations, from field level and will inform PWG, SMG-P (at Bangui and local level), on progress of implementation as requested. After Action Reviews (AAR) of JPTs will be conducted as and when needed and as decided by any JPT participant.
        </li>
        <li>
          <u>Confidentiality and external communications:</u> JPT members will not share their reports, findings, opinions and recommendations with media or any other external actors, except when receiving direct authorization from their section/unit director. All media and external inquiries must be referred to the Mission&apos;s Spokesperson.
        </li>
        <h4>F. ROLES AND RESPONSIBILITIES</h4>
        <li>
          <u>The JPT leader</u> is the highest civilian authority in the JPT mission. All JPT leaders must be fully aware of: (i) the Mission&apos;s POC mandate; (ii) the Mission&apos;s gender mandate and related gender issues; and (iii) security management.
        </li>
        <li>
          With the support of civilian and uniformed JPT members, the JPT leader will be responsible for:
          <ol type="1">
            <li>
              Mapping key civilian and military actors and the political, social and economic context, in the area(s) where a JPT is deployed;
            </li>
            <li>
              Analyzing POC threats, including ethnic, land, natural resources and other issues that may be the cause or lead to local conflicts, in cooperation with the local communities and local authorities, local humanitarian actors and civil society organizations;
            </li>
            <li>
              Liaising with humanitarian actors, local leaders and communities to contribute to the development or the implementation of community protection plans;
            </li>
            <li>
              Contributing advice and analysis to develop context-specific civil and military protection responses according to threats identified. These should be captured in the Sector/Office POC Flashpoint Matrix;
            </li>
            <li>
              Reporting protection concerns to the relevant coordination mechanisms in order to identify potential complementary protection activities where needed;
            </li>
            <li>
              Ensuring regular information sharing on protection issues;
            </li>
            <li>
              Ensuring clarity around and coordination of roles and responsibilities within the JPT team, including inter alia no duplication in interviews with victims/witnesses.
            </li>
            <li>
              Presenting the draft report and final report of the mission.
            </li>
          </ol>
          JPT leader has the authority to delegate these responsibilities to other JPT mission participants, according to their unit/division/section/agency of origin, after direct consultation with them. Detailed roles and responsibilities are presented in Annex 4.
        </li>
        <h4>G. TERMS AND DEFINITIONS</h4>
        <p>
          <strong>COMMUNITY LIAISON ASSISTANT (CLA)</strong><sup>4</sup>: A national staff member managed by CAS and collocated with military and police field commanders, to support interactions with local authorities, communities and other relevant actors in the field and perform a range of tasks, including information gathering, threat or needs assessments, contributing to MINUSCA&apos;s early warning system activities, local-level protection planning and coordination of and follow-up on field visits and MINUSCA patrols and operations, as well as strengthening the resilience of local communities against protection threats.
        </p>
        <p>
          <strong>EARLY WARNING: </strong>A serious and credible threat, whether from non-state armed actors (including but not limited to self-defense groups, armed groups, and criminal gangs) or State authorities, that risks threatening the physical integrity of civilians, including human rights violations and IHL infractions. Early Warnings differ from simple reporting such as flash, situational, or periodic reports which generally refer to physical violence, human rights violations and/or international humanitarian law infractions which have already taken place. Early Warning focuses primarily on gathering, sharing and responding to information on possible eventualities that have the potential to directly impact on civilians and their physical integrity. Each Early Warning requires an Early Response, which must be carried out/activated to prevent such violence to take place, via anticipation, prevention, and deterrence measures (including Urgent Temporary Measures - UTMs), and/or taking an effective response, including the use of force.
        </p>
        <p>
          <strong>RISK: </strong>The potential for the materialization of the threat of physical violence against an individual or a community. Risk analysis can be presented as an equation that takes into consideration two opposing forces: the threat assessment, vulnerability assessment and time exposure analysis against the crisis prevention and management capacities.
        </p>
        <div className="equation">
          <div>
            Risk =
          </div>
          <div>
            <div>Threat x Vulnerability x Time exposure</div>
            <div>Crisis management and Self-protection capacities</div>
          </div>
        </div>
        <p>
          <strong>THREAT: </strong>A plausible, possible or potential cause of an incident or accident that may result in a direct or indirect threat to the physical protection of civilians.
        </p>

        <h4>H. REFERENCES</h4>
        <h5>Normative or superior references</h5>

        <ul>
          <li>United Nations Security Council Resolution 2448 (2018)</li>
          <li>United Nations Security Council Resolution 2387 (2017)</li>
          <li>United Nations Security Council Resolution 1820 (2008)</li>
          <li>United Nations Security Council Resolution 1325 (2000)</li>
          <li>United Nations Security Council Resolution 1612 (2005)</li>
          <li>
            DPKO-DFS Policy on Protection of Civilians in Peacekeeping (2015)
          </li>
          <li>DPKO-DFS Peacekeeping Intelligence Policy (2017)</li>
          <li>MINUSCA Political Strategy (2018)</li>
          <li>MINUSCA Protection of Civilians (POC) Strategy (2018)</li>
          <li>MINUSCA SOP on Early Warning and Rapid Response (2018)</li>
          <li>MINUSCA SOP on Engaging with non-state actors (2017)</li>
        </ul>
        <h5>Related procedures or guidelines</h5>
        <ul>
          <li>
            MINUSCA amendment to the SOP CASEVAC / MEDIVAC plan (2018) July 2016
          </li>
          <li>MINUSCA Sequenced Priority Objectives Matrix (19 April 2018)</li>
          <li>
            Special Representative of the Secretary General&apos;s (SRSG) Directive (February 2018)
          </li>
          <li>Recommendations of the Amoussou Report (2018)</li>
          <li>
            OHCHR Policy on Engagement in Relation to de facto Authorities and non-State Armed Groups (23 February 2018)
          </li>
          <li>
            Framework of Analysis for Atrocity Crimes: A Tool for Prevention (United Nations, 2014)
          </li>
          <li>
            Framework for Cooperation between MINUSCA Force and Human Rights Division (April 2018)
          </li>
          <li>Environment Policy and Guidelines for MINUSCA (January 2016)</li>
        </ul>

        <h4>I. CONTACT</h4>
        <li>
          For additional information and/or training on this SOP, please contact MINUSCA Senior POC Advisor at: <a href="mailto:MINUSCA-POC-UNIT@un.org">MINUSCA-POC-UNIT@un.org</a>
        </li>
        <h4>J. HISTORY</h4>
        <li>
          In 2018 POC unit was tasked by DSRSG-P, following Amoussou&apos;s report, to lead a consultation process between MINUSCA&apos;s military, police, and civilian units/areas/divisions, and with UN humanitarian agencies, on the best way to implement protection mission in the field. Consultations took place during the first semester of 2018, and drafting process during the second part of the year. PWG was the main deliberative and consultative body, and results were presented for review to the SMG-P at Bangui level. DSRSG-P validated and approved the exercise and final text. This is the first issuance of an SOP for JPTs in MINUSCA. Previous SOP drafts were circulated in 2015 but never became final texts.
          <p className="bold">APPROVAL SIGNATURES:</p>
          <p>[PARFAIT ONANGA-ANYANGA SIGNATURE]</p>
          <p>APPROVAL SIGNATURE (SRSG):</p>
          <p>
            Parfait Onanga-Anyanga<br />
            Special Representative of the Secretary-General DATE OF APPROVAL:
          </p>
        </li>
      </ol>

      <h4>ANNEXES</h4>
      <h5>Annex 1: Additional Principles</h5>
      <ol type="1">
        <li>
          <u>Protection of children:</u> Principles and obligations relating to the protection of children (boys and girls below 18 years of age) are to be integrated and mainstreamed throughout tasking, operational decisions, processes and activities of the mission, taking into consideration UNSCR 1314 (2000), UNSCR 1612 (2005) and DPKO, DFS and DPA Policy on Child Protection in United Nations Peace Operations, 2017.
        </li>
        <li>
          <u>Accountability for failure to protect:</u> The &apos;Accountability for implementation of the protection of civilians mandates (addendum to 2015.17 DPKO-DFS policy on the protection of civilians)&apos; states that Missions with a POC mandate must evaluate and account for individual and collective performance. Moreover, failure to protect may constitute an act of misconduct.
        </li>
        <li>
          <u>The principles of ‘Do no harm&apos; and harm mitigation:</u> The Mission&apos;s approach to POC will be guided by periodical Peace and Conflict Impact Assessments (PCIA), to ensure that there is no negative impact on civilians, including through secondary effects. Due consideration will be given to identifying and mitigating all harm, i.e. lawful or unlawful negative consequences to the physical integrity, safety and security of civilians, in particular women and children, associated with actions by peacekeepers. The Mission shall also advocate for and support POC risk mitigation mechanisms for national and international security forces, with a specific focus on national forces including the implementation of the UN Human Rights Due Diligence Policy (HRDDP) on UN support to non-UN security forces. The do no harm principle must also extend to the protection of witnesses, sources and all other civilians that engage with the JPT missions.
        </li>
        <li>
          <u>Zero tolerance for sexual exploitation and abuse:</u> The POC strategy is built upon the strict respect for the UN&apos;s Zero Tolerance Policy, regarding sexual exploitation and abuse by UN personnel. The prevention and punishment of SEA is a priority consideration for all actions undertaken by the Mission, and an intrinsic part of the POC strategy.
        </li>
        <li>
          <u>IHL responsibility of all armed groups:</u> Under International Humanitarian Law (IHL), including but not limited to the Geneva Conventions of 1949, State and non-State parties to armed conflicts have a range of obligations designed to protect civilians, including the rules of distinction, proportionality and precaution in and against the effects of attack. Moreover, all parties have the obligation to allow and facilitate rapid and unimpeded passage of humanitarian relief for civilians in need. The Mission will pro-actively remind all parties of their obligations under IHL, including with respect to children, the prohibition of committing grave child rights violations, and call for serious violations of IHL, to be investigated and prosecuted, and refer where appropriate, for national or international judicial follow-up.
        </li>
      </ol>

      <h4>Annex 2: Template TORs for JPTs</h4>
      <img src={Template115Image} alt="Template" />

      <h4>Annex 2: Template TORs for JPTs</h4>
      <img src={Template116Image} alt="Template" />

      <h4>Annex 4: Additional Roles and responsibilities</h4>
      <ol type="1">
        <li>
          <u>Human Rights Division (HRD) officers, will in particular be responsible for:</u>
          <ul>
            <li>
              Identifying on-going and risks of human rights violations, including sexual violence, recommending preventive and responsive measures and contributing to the development of community protection plans;
            </li>
            <li>
              Assess the need to dispatch a human rights investigative team to the concerned area before a JPT (past human rights violations can signal a need for future protection) or after a JPT (because HRD finds out during a JPT that a lot of human rights work needs to be done in the same area)
            </li>
            <li>
              In cases of human rights violations being imminent or ongoing and requiring urgent and immediate action, advising on immediate protection measures in close coordination with other JPT members and MINUSCA field commanders in the area.
            </li>
            <li>
              Engaging on advocacy on specific human rights cases and situations with alleged perpetrators — when appropriate — and relevant authorities to ensure both the respect of human rights and appropriate action to put an end to violations;
            </li>
            <li>
              Identifying the nature, cause and patterns of human rights violations and consult with the local population, the local authorities and the community leaders to recommend measures to prevent or mitigate such threats in the medium/long term, including through the establishment of a protective environment and the strengthening of rule of law;
            </li>
            <li>
              When possible and relevant, supporting the referral of victim(s) of human rights violations to a competent local organization and/or to the relevant judicial authority in situ or upon return from the mission;
            </li>
            <li>
              In cooperation with the Women Protection Adviser and in support of UNICEF5, referring cases of sexual violence to competent local organizations responsible for the delivery of medical, psychosocial and legal assistance. In the absence of such organization, refer the case(s) to one of the partner NGOs with capacity and means to assist the victims. Refer the cases to justice upon completion of the mission if there is no legal representation in the locality of the JPT, if the victim consents;
            </li>
            <li>
              Establish procedures to ensure that CLAs, the field commanders and other MINUSCA actors promptly refer human rights cases and situations to HRD and CP for timely follow up including interviews and investigations; and
            </li>
            <li>
              Contributing to monitoring and evaluating the impact of the JPT, its recommendations, and the activities suggested.
            </li>
          </ul>
        </li>
        <li>
          <u>Women Protection Advisors will in particular be responsible for:</u>
          <ul>
            <li>
              Identifying the most vulnerable groups, communities and areas that may require protection measures (with particular regard to the prevention of sexual violence and gender based violence (SGBV));
            </li>
            <li>
              Ensuring that adequate referral for assistance is provided to victims of SV and that field staff, JPT members and other actors are familiar with the referral of SV victims for assistance;
            </li>
            <li>
              Collecting information on the response (medical, psychosocial, legal and reinsertion) provided to SV incidents and identify gaps, for further follow-up and action by relevant actors;
            </li>
            <li>
              Listing State local institutions and non-State institutions (CBOs, CSOs, NGOs) as well as assessing their capacities to respond to SV incidents, to provide advice on capacity-building requirements and to advocate with relevant actors to fill the identified gaps;
            </li>
            <li>
              Supporting the HRD officer(s) in collecting information on allegations of recent and past SV incidents in the area;
            </li>
            <li>
              Consulting local communities, MINUSCA bases, CLAs and relevant staff whether Early Warning Indicators on SV (EWIs) were observed and whether any community-based protection mechanisms on SV/Emergency Plans are in place in the area;
            </li>
            <li>
              Making recommendations for the Community Protection Plan and to the JPT findings to respond to SV threats, through proposing measures aimed at preventing SV incidents, closing protection gaps, and ensuring minimum service provision for victims of SV; and
            </li>
            <li>
              Following up on the JPT recommendations regarding SV and the suggested activities.
            </li>
          </ul>
        </li>
        <li>
          <u>Child Protection (CP) officers will in particular be responsible for (principles of ‘do no harm&apos;, confidentiality and anonymity are to be maintained and promoted at all times):</u>
          <ul>
            <li>
              Identifying POC threats concerning children as well as communities where children are at higher risk (boys and girls) of POC threats, particularly of recruitment and use by armed groups and forces, and at risk of rape or sexual violence, abduction, and killing and maiming;
            </li>
            <li>
              Recommending preventive and remedial measures for specific violations such as children used by forces and armed groups;
            </li>
            <li>
              Collecting information on allegations of grave child rights violations, notably the violations noted in UN Resolution 1612, by all parties to the conflict;
            </li>
            <li>
              Ensuring that children victims of grave violations such as maiming, recruitment and sexual violence, are interviewed by a CPO or CP partner, and are immediately referred to medical and psycho social support;
            </li>
            <li>
              Sensitize parties to the conflict on the release of children associated with armed groups and the prevention of recruitment, sexual violence, killing, maiming, denial of humanitarian assistance, targeting and use of schools, hospitals and other civilian localities
            </li>
            <li>
              Advocate with parties occupying schools and hospitals to vacate these civilian institutions immediately, and contact relevant partners to ensure that there are no IEDs before civilians can use the buildings once again;
            </li>
            <li>
              Follow-up with UNICEF and other child protection actors as needed, in particular for the referral of victims;
            </li>
            <li>
              Contributing to monitoring and evaluating the impact of the JPT, its recommendations, and the activities suggested; and
            </li>
            <li>
              Undertaking CP awareness raising activities when possible with the local community, community and women leaders.
            </li>
          </ul>
        </li>
        <li>
          <u>JOC staff contribute to the work of the JPT by:</u>
          <ul>
            <li>
              Maintaining the JPT and military escort leader briefed on relevant information for the implementation of the JPT both prior and during the mission.
            </li>
          </ul>
        </li>
        <li>
          <u>JMAC staff contribute to the work of the JPTs by:</u>
          <ul>
            <li>
              Carrying out in-depth analysis and research on conflict dynamics in the area of the JPT mission, including on armed group presence and activity, conflict drivers related to intercommunity and ethnic relations, land usage, and natural resource exploitation and their implications for protection, and sharing that information with the JPT mission leader.
            </li>
          </ul>
        </li>
        <li>
          <u>The Police component will in particular be responsible for:</u>
          <ul>
            <li>
              Assessing the security situation and POC threats;
            </li>
            <li>
              Assessing action by MINUSCA police field commanders, based on the POC Handbook for peacekeepers;
            </li>
            <li>
              Collecting, analyzing and reporting all relevant information pertaining to the restoration of state authority, with a focus on the presence, capability, activities, and structure of parallel police;
            </li>
            <li>
              Interacting with the National Police and Gendarmerie, local authorities and populations;
            </li>
            <li>
              Jointly with HRD and CP officers, following up on the HRV committed by local actors;
            </li>
            <li>
              Monitoring and assessing the deployments of CAR&apos;s police, and setting up indicators to measure their impact;
            </li>
            <li>
              In coordination with UNHCR and UNICEF, training the police officers responsible for securing IDP sites, and protecting women and children victims of sexual violence;
            </li>
            <li>
              Interacting with domestic police officers, and other representatives of the state in the framework of the preparation of local contingency plans, and in close coordination with the rest of the JPT team;
            </li>
            <li>
              Providing technical expertise in support to the activities of the JPT, including investigations and establishing early warning networks;
            </li>
            <li>
              Identifying the main economic actors, interests, and (illegal) commercial routes in the area of deployment of the JPT.
            </li>
          </ul>
        </li>
        <li>
          <u>The Military component, other than military escorts will in particular be responsible for:</u>
          <ul>
            <li>
              Providing technical expertise in support of the establishment of early warning networks and appropriate military prevention and response measures;
            </li>
            <li>
              Assessing action by MINUSCA military field commanders, based on the POC Handbook for peacekeepers;
            </li>
            <li>
              Collecting, analyzing and reporting all relevant information pertaining to the restoration of state authority, with a focus on the presence, capability, activities, and structure of the FACA;
            </li>
            <li>
              Interacting with the FACA, local authorities and populations;
            </li>
            <li>
              Jointly with HRD and CP officers, following up on the HRV committed by local actors;
            </li>
            <li>
              Monitoring and assessing the deployments, behavior and impact of the FACA;
            </li>
            <li>
              In coordination other JPT members, contribute to training the FACA as required;
            </li>
            <li>
              Interacting with state officials re. preparation of Community Protection Plans, in close coordination with the CLA and the rest of the JPT team;
            </li>
          </ul>
        </li>
        <li>
          <u>Civil Affairs staff, including Community Liaison Assistants (CLAs):</u>
          <ul>
            <li>
              Placed alongside field commanders in priority areas of deployment to act as intermediary between the force and the local community, authorities and other key actors. In the context of JPTs, CLAs are responsible to:
            </li>
            <li>
              Use their existing contacts and analysis in order to inform mission planning and protection response.
            </li>
            <li>
              Share the existing community protection plan for the AOR with relevant MINUSCA substantive sections participating in the JPT. During the JPT mission, JPT members will provide inputs to the plan, in agreement with the field Commander and the CLA.
            </li>
            <li>
              support and coordinate information sharing between MINUSCA, local authorities and communities and humanitarian actors on the JPT;
            </li>
            <li>
              Coordinate the establishment of communication networks and fora to increase POC results through interactions with local communities. This should refer to CLA guidelines where their responsibilities are clearly expressed
            </li>
          </ul>
        </li>
        <li>
          <u>Protection of Civilians Unit:</u>
          <ul>
            <li>
              Support PWG and or local SMG-Ps with the drafting of initial TORs for the JPT mission.
            </li>
            <li>
              Provide coordination support to the JPT mission leader before, during and after deployment of the mission.
            </li>
            <li>
              Provide specialized advice to the JPT mission leader on possible threats against the physical integrity of civilians and their communities and appropriate responses.
            </li>
            <li>
              Provide feedback to JPT mission reports.
            </li>
          </ul>
        </li>
      </ol>

      <ol type="1">
        <li>
          JPTs, which are under the lead of Field Offices or POC unit in Bangui, differ from Joint Assessment Missions (JAMs), which are under the lead of the Civil Affairs Section. While JPTs are preventive or mitigative measures in response to POC threats, JAMs seek to assess specific issues such as humanitarian, security, protection, development, human rights or other socio-political aspects in a specific area.
        </li>
        <li>
          JPTs need to be prioritized over all other Mission activities including, but not limited to, UNHQ and other international visits and deployment of staff.
        </li>
        <li>
          This includes, but is not limited to:
          <ul className="dash">
            <li>
              Prioritizing issuance of communication and any other related equipment from MINUSCA stock for JPT travel;
            </li>
            <li>
              Authorizing JPT members to carry up to 50 kilos of luggage/cargo on MINUSCA flights, when transporting: (including but not limited to) personal protective equipment, food, water, first aid kits, vehicle/road equipment, and/or communication equipment;
            </li>
            <li>
              Prioritizing JPT passengers and material/cargo over all other passengers and material/cargo, except for those related to MEDEVAC, CASEVAC and military operations;
            </li>
            <li>
              Facilitating with priority vehicles and their equipment at FO level, which meet UN security and safety standards for the duration of the JPT mission.
            </li>
          </ul>
        </li>
        <li>
          MINUSCA SOP on the Modalities of Collaboration between Mission Uniformed Components and Community Liaison Assistants (Ref. 2018.6).
        </li>
      </ol>
    </div>
  )
};
