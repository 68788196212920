import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

/* eslint-disable react/jsx-props-no-spreading */
const Page = ({ component: Component, cache, ...rest }) => {
  const render = (props) => {
    const { location } = props;

    // If either role or area aren't chosen and we aren't going for
    // onboarding anyway, redirect to onboarding at the appropriate
    // stage (based on what has already been chosen, handled in component)
    if (!cache.role || !cache.area) {
      return <Redirect to={{ pathname: '/welcome', state: { from: location } }} />;
    }

    return <Component {...props} cache={cache} />;
  };

  return <Route {...rest} render={render} />;
};

Page.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  cache: PropTypes.shape({
    role: PropTypes.string,
    area: PropTypes.string
  }).isRequired
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps)(Page);
