/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import templates from '../../templates';
import actions from '../../redux/actions';
import audio from '../../templates/audio';
import resources from '../../templates/resources';
import access from '../../config/access';
import { getIndexToDisplay } from '../../helpers/index';

import { ReactComponent as RecommendedIcon } from '../../images/design/recommended.svg';
import { ReactComponent as FullHandbookIcon } from '../../images/design/full-handbook.svg';
import { ReactComponent as VoicesIcon } from '../../images/design/voices.svg';
import { ReactComponent as ResourcesIcon } from '../../images/design/resources.svg';

const hasConfig = (role, area) => !!access[`${role}-${area}`];

const dashCount = (str) => str.split('').reduce((i, a) => (i === '-' ? a + 1 : a), 0);

const isHidden = (role, area, index) => {
  if (!hasConfig(role, area)) return false;

  const formatted = index.replace('.', '-');
  const count = dashCount(index);
  const config = access[`${role}-${area}`];
  const sectionVisibility = config.find((s) => s.section === formatted);
  const ruleExists = !!((sectionVisibility && sectionVisibility.section));

  if (count > 0) {
    const chap = formatted.match(/(\d+)-\d+/)[1];
    if (!ruleExists && config.some((c) => c.section === chap)) return false;
  }

  return !ruleExists;
};
const isChapterHidden = (role, area, sections, chapter) => {
  // POC Fundamentals should always be visible
  if (chapter.index === '0') return false;
  if (!hasConfig(role, area)) return false;
  const hasRule = access[`${role}-${area}`].some(
    (i) => (chapter.index && i.section === chapter.index.replace('.', '-')) || i.section === `annex-${chapter.annex}`
  );
  if (hasRule) return false;
  if (sections) return sections.every((s) => isHidden(role, area, s.index));
  return true;
};

const Sidebar = ({ cache, updateCache }) => {
  const [tab, setTab] = useState(cache.tab || 'recommended');
  const [collapsed, setCollapsed] = useState(false);

  const chapterProgress = (chapt) => {
    let { sections } = templates.find((c) => c.name === chapt);

    if (!sections) {
      return 0;
    }

    sections = sections.filter((section) => !isHidden(cache.role, cache.area, section.index));

    const target = sections.length * 100;

    return (sections.reduce((total, section) => {
      const progress = (cache.progress && cache.progress[section.index]) || 0;
      return total + progress;
    }, 0) / target) * 100;
  };

  useEffect(() => {
    // Set the cache to match the content type
    // so we can hide certain elements (audio)
    updateCache({ tab });
  }, [tab]);

  /**
   * Filter out the POC Fundamentals from the full Handbook while
   * still maintaining routing active.
   */
  const removedContent = ['POC Fundamentals'];
  const filteredHandbookTemplates = templates.filter((chapter) => !removedContent.includes(chapter.title));

  return (
    <aside className={cn({ open: cache.showSidebar })}>
      <header>
        <div className="title">
          Handbook Contents
          <button type="button" onClick={() => updateCache({ showSidebar: false })}>
            Close Menu
          </button>
        </div>
        <nav>
          <button
            type="button"
            className={cn({ active: tab === 'recommended' })}
            onClick={() => tab !== 'recommended' && setTab('recommended')}
            title="Recommended"
          >
            <RecommendedIcon title="Recommended" /> <div>Recommended</div>
          </button>
          <button
            type="button"
            className={cn({ active: tab === 'text' })}
            onClick={() => tab !== 'text' && setTab('text')}
            title="Full Handbook"
          >
            <FullHandbookIcon title="Full Handbook" /> <div>Handbook</div>
          </button>
          <button
            type="button"
            className={cn({ active: tab === 'audio' })}
            onClick={() => tab !== 'audio' && setTab('audio')}
            title="Voices from the Field"
          >
            <VoicesIcon title="Voices from the Field" /> <div>Audio</div>
          </button>
          <button
            type="button"
            className={cn({ active: tab === 'resources' })}
            onClick={() => tab !== 'resources' && setTab('resources')}
            title="Resources"
          >
            <ResourcesIcon title="Resources" /> <div>Resources</div>
          </button>
        </nav>
      </header>

      <div className="nav-container">
        { tab === 'recommended' && (
          <ul>
            { templates.map((chapter) => (
              <li
                key={chapter.name}
                className={cn(chapter.color, {
                  open: cache.chapter === chapter.name && !collapsed,
                  collapsed,
                  hidden: isChapterHidden(cache.role, cache.area, chapter.sections, chapter)
                })}
              >
                <Link
                  className={cn({ active: cache.chapter === chapter.name })}
                  onClick={() => {
                    if (cache.chapter === chapter.name) {
                      // Toggle collapse
                      setCollapsed(!collapsed);
                    } else {
                      setCollapsed(false);
                      updateCache({ chapter: chapter.name, showSidebar: false, keywords: null });
                    }
                  }}
                  to={`?chapter=${escape(chapter.name)}`}
                >
                  <span>{ chapter.title }</span>
                  <div className="progress" style={{ width: `${chapterProgress(chapter.name)}%` }} />
                </Link>
                <ul>
                  { chapter.sections && chapter.sections.map((section) => (
                    <li
                      key={section.index}
                      className={cn({ hidden: isHidden(cache.role, cache.area, section.index) })}
                    >
                      <Link
                        className={cn({
                          active: cache.visible && cache.visible === section.name
                        })}
                        onClick={() => updateCache({ showSidebar: false, keywords: null })}
                        to={`/?chapter=${escape(chapter.name)}&section=${section.index}`}
                      >
                        { getIndexToDisplay(section.index) } <span>{ section.title }</span>
                        <div
                          className="progress"
                          style={{
                            width: `${(cache.progress && cache.progress[section.index]) || 0}%`
                          }}
                        />
                      </Link>
                    </li>
                  )) }
                </ul>
              </li>
            )) }
          </ul>
        )}

        { tab === 'text' && (
          <ul>
            { filteredHandbookTemplates.map((chapter) => (
              <li
                key={chapter.name}
                className={cn(chapter.color, {
                  open: cache.chapter === chapter.name && !collapsed,
                  collapsed
                })}
              >
                <Link
                  className={cn({ active: cache.chapter === chapter.name })}
                  onClick={() => {
                    if (cache.chapter === chapter.name) {
                      // Toggle collapse
                      setCollapsed(!collapsed);
                    } else {
                      setCollapsed(false);
                      updateCache({ chapter: chapter.name, showSidebar: false, keywords: null });
                    }
                  }}
                  to={`?chapter=${escape(chapter.name)}`}
                >
                  <span>{ chapter.title }</span>
                  <div className="progress" style={{ width: `${chapterProgress(chapter.name)}%` }} />
                </Link>
                <ul>
                  { chapter.sections && chapter.sections.map((section) => (
                    <li key={section.index}>
                      <Link
                        type="button"
                        className={cn({
                          active: cache.visible && cache.visible === section.name
                        })}
                        onClick={() => updateCache({ showSidebar: false })}
                        to={`/?chapter=${escape(chapter.name)}&section=${section.index}`}
                      >
                        { section.index } <span>{ section.title }</span>
                        <div
                          className="progress"
                          style={{
                            width: `${(cache.progress && cache.progress[section.index]) || 0}%`
                          }}
                        />
                      </Link>
                    </li>
                  )) }
                </ul>
              </li>
            )) }
          </ul>
        ) }

        { tab === 'audio' && (
          <ul className="audio">
            { audio.map((item) => (
              <li key={item.title}>
                <div
                  className={cn({ active: cache.playing === item.title })}
                  onClick={() => {
                    window.location.hash = item.title;
                    updateCache({ showSidebar: false });
                  }}
                  aria-hidden
                  role="button"
                >
                  <span className="title">{ item.title }</span>
                  <span className="subtitle">{ item.subtitle }</span>
                  <div
                    className="progress"
                    style={{ width: `${(cache.audioProgress && cache.audioProgress[item.title]) || 0}%` }}
                  />
                </div>
              </li>
            )) }
          </ul>
        ) }

        { tab === 'resources' ? (
          <ul className="resources">
            { resources.map((resource) => {
              if (resource.sections) {
                return (
                  <li
                    key={resource.name}
                    className={cn(resource.color, {
                      open: cache.resource === resource.name && !collapsed,
                      collapsed
                    })}
                  >
                    <Link
                      className={cn({ active: cache.resource === resource.name })}
                      to={`?resource=${escape(resource.name)}`}
                      onClick={() => {
                        if (cache.resource === resource.name) {
                          // Toggle collapse
                          setCollapsed(!collapsed);
                        } else {
                          setCollapsed(false);
                          updateCache({ resource: resource.name, showSidebar: false, keywords: null });
                        }
                      }}
                    >
                      <span>{ resource.name }</span>
                    </Link>
                    <ul>
                      { resource.sections && resource.sections.map((section) => (
                        <li key={section.index}>
                          <Link
                            type="button"
                            className={cn({
                              active: cache.visible && cache.visible === section.name
                            })}
                            onClick={() => {
                              window.location.hash = section.index;
                              updateCache({ section: section.name, showSidebar: false, keywords: null });
                            }}
                            to={`/?resource=${escape(section.name)}&section=${section.index}`}
                          >
                            { section.name }
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              }
              return (
                <li
                  key={resource.name}
                  className={cn(resource.color)}
                >
                  <Link
                    type="button"
                    className={cn({ active: cache.resource === resource.name })}
                    onClick={() => updateCache({ resource: resource.name, showSidebar: false, keywords: null })}
                    to={`?resource=${escape(resource.name)}`}
                  >
                    <span className="title">{ resource.name }</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  cache: PropTypes.shape({
    resource: PropTypes.string,
    chapter: PropTypes.string,
    visible: PropTypes.string,
    progress: PropTypes.object, // eslint-disable-line
    area: PropTypes.string,
    role: PropTypes.string,
    tab: PropTypes.string,
    audioProgress: PropTypes.object, // eslint-disable-line
    playing: PropTypes.string,
    showSidebar: PropTypes.bool
  }).isRequired,
  updateCache: PropTypes.func.isRequired
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps, {
  updateCache: actions.updateCache
})(Sidebar);
