import { SET_COOKIES } from './types';

/**
 * Sets cookies from the cookie banner action
 * @param {Boolean} status - the status of cookies (true - enabled, false - disabled)
 */
const setCookies = (status) => ({ status, type: SET_COOKIES });

export default {
  setCookies
};
