import React from 'react';
import POCHotspotsImage from '../images/14.jpeg';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 8: Identifying and Prioritizing POC Threats and Risks',
  title: t('handbook.chapters.chapter8.title'),
  index: '8',
  color: 'un-green',
  content: (
    <>
      <div className="grid-three un-green">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            The identification and assessment of threats to civilians forms the basis of all POC planning and action. The mission should have processes in place at the strategic, operational and tactical levels to identify, analyse and prioritize POC threats and risks. These processes should consider threats faced by different groups of civilians, their potential severity and impact, the motivations of perpetrators and the capacity and intention of other protection actors.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            In order to determine actions to protect civilians and take a strategic approach to POC, it is necessary first to identify and prioritize threats against civilians. POC is a whole-of-mission responsibility and it is important to have a common understanding among mission components of the threats faced by civilians and their prioritization.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            To identify and prioritize POC threats and risks, missions should take into account a variety of types and topics of information. The analysis process involves identifying civilians at risk, considering the capacity and intent of other protection actors and evaluating the risk associated with the threats. A POC Threat Matrix, as well as trends analyses, can be helpful.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            The identification and prioritization of POC threats and risks is a process led by the POC lead or POC Advisers that involves all mission components (civilian, police and military), and will often require engagement with the UN Country Team, host state authorities, communities at risk and humanitarian actors. POC coordination mechanisms should be leveraged in this process.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            The identification of threats to civilians is often done at tactical level, by mission field offices and at bases. Further analysis and prioritization is normally done at field offices and mission headquarters, with support from UN Headquarters.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            The process of identifying and prioritizing POC threats is done continually as the situation on the ground changes. It should also form part of the drafting of the POC strategy. Forward-looking threat assessments should be developed quarterly and shared with Headquarters.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '8.1',
      name: 'Which POC Threats and Risks to Consider',
      title: t('handbook.chapters.chapter8.1.title'),
      content: (
        <>
          <p>
            <strong>Consider all sources of violence against civilians. </strong>Peacekeeping operations with POC mandates are deployed into a broad range of contexts, including armed conflict, post-conflict and situations other than armed conflict. The operational context and threats to civilians may vary significantly between different geographical areas within a mission. The threats to civilians that the mission should take into account when identifying POC threats may include:
          </p>
          <ul className="square un-green">
            <li>
              Threats posed by non-state armed groups (including ‘irregular&apos; and non-traditional armed groups such as those engaged in violent extremism);
            </li>
            <li>
              Threats from host state defence and security forces, other state actors and their proxies;
            </li>
            <li>
              Threats from foreign state security forces, including regional forces;
            </li>
            <li>
              Threats based within communities, including intercommunal violence, housing-, land- and property-related conflicts and from self-defence groups;
            </li>
            <li>
              Threats emanating from organized crime and organized criminal groups; and
            </li>
            <li>
              Threats from actions of the mission itself.
            </li>
          </ul>
          <p>
            <strong>Consider all threats of physical violence against civilians. </strong>This includes violence that is occurring and violence that has the realistic potential to occur. It includes:
          </p>
          <ul className="square un-green">
            <li>
              Direct and indiscriminate attacks (attacks targeting civilians or those which do not distinguish between civilians and combatants);
            </li>
            <li>
              Attempts to kill, torture, maim, rape or sexually exploit, forcibly displace, starve, pillage, abduct or arbitrarily detain, kidnap, disappear or traffic persons;
            </li>
            <li>
              The recruitment and use of children by armed forces and groups; and
            </li>
            <li>
              Harm associated with the presence of explosive ordnance including mines, explosive remnants of war and improvised explosive devices.
            </li>
          </ul>
          <p>
            <strong>Missions are not expected to prevent or intervene to stop all instances of violence. </strong>Peacekeeping missions are required to protect civilians within their capabilities in areas of responsibility. However, they may have to focus their resources on <strong>strategic threats</strong>, in particular <strong>violence that may result in mass civilian casualties or lead to broader instability</strong>. Missions should intervene to stop physical violence against civilians taking place in proximity of a mission presence, regardless of the level of violence. Failure to respond to these threats undermines the credibility of the mission with the local population, perpetrators and other stakeholders.
          </p>
          <table className="examples margin-top">
            <thead>
              <tr>
                <td>Example: Assessing and prioritizing threats</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>
                    Many missions facing large-scale violent attacks on civilians by armed groups or serious intercommunal violence will also face a situation of general lawlessness and instability. Criminal groups may take advantage of the security vacuum to commit violent acts against the civilian population. While the mission should identify and take all threats into account in its analysis, it will normally decide to prioritize those threats that have the highest impact on the civilian population and the stability of the country in general — in this case, large-scale attacks by armed groups. In response to these prioritized threats, the mission should develop a comprehensive strategy using a tailored combination of civilian, police and military capabilities and the priority use of mission resources. At the same time, although violent criminal activities may not be the highest priority, the mission can recognize the threat and consider implementing a response focused on Tier III using existing programmatic activities of the mission to support the capacity of host state authorities (in particular, police and justice institutions). These ongoing programmatic activities should be considered with a ‘POC lens&apos; (see chapter 5), to ensure that they are designed to maximize their POC impact.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )
    },
    {
      index: '8.2',
      name: 'POC Threats and Risk Assessment',
      title: t('handbook.chapters.chapter8.2.title'),
      content: (
        <>
          <p>
            As part of the threat assessment and prioritization process, missions should assess existing and likely threats, communities at risk, mission capacity, the capacity of other protection actors and the comparative advantage of the mission. Priority strategic threats should be agreed by mission leadership and detailed in a POC Threat Matrix, which should be regularly reviewed at the strategic, operational and tactical levels.
          </p>
          <p>
            Threats to civilians will be evaluated on an ongoing, daily basis by COBs and TOBs. However, a strategic and comprehensive POC threat assessment and prioritization process should normally be undertaken by the POC coordination forums/mechanisms established by the mission (see chapter 6). These mechanisms should include representatives from all relevant mission components and, where appropriate, the UN Country Team, and ensure that relevant factors (political, security, gender, human rights, etc.) are taken into account when assessing and prioritizing threats to civilians.
          </p>
          <div className="sub-section 8-2-1">
            <h5>
              <span className="un-green">8.2.1</span> Assess threats
            </h5>
            <p>
              Missions should take into account a variety of types and topics of information to ensure a comprehensive threat assessment. Proper integration of the various types of information into the POC threat assessment depends on adequate information sharing amongst relevant components responsible for collecting or analysing the information. Types of assessments and topics to take into account include:
            </p>
            <p>
              <strong>Political assessments: </strong>A relapse into conflict by the parties to a peace or cease-fire agreement, including violence accompanying key milestones of the peace process, will generally have the highest impact on the security of civilians and potentially widespread security repercussions. Therefore, it is important to include an assessment of the political situation into POC threat assessments. These political assessments will normally be the regular work of the Political Affairs Section and/or conducted as part of an integrated analysis conducted by the JMAC. They may include assessments of:
            </p>
            <ul className="square un-green">
              <li>
                Internal and external threats to a political or peace process and the motivations behind those threats;
              </li>
              <li>
                Changes in leadership, coalitions or alliances that may lead to instability;
              </li>
              <li>Changes in political/military dynamics;</li>
              <li>
                Upcoming triggers or events, for example elections, anniversaries or key dates; and
              </li>
              <li>Implementation and monitoring of peace agreements.</li>
            </ul>
            <p>
              <strong>Security assessment: </strong>Ongoing monitoring and analysis of the general security situation – including its impact on the civilian population and threats to the safety and security of peacekeepers – should be part of regular mission security processes and procedures. This includes the use of mission peacekeeping intelligence in line with the DPO Policy on Peacekeeping-Intelligence<sup>21</sup>. Security information and assessments may be provided by the Force, the Police, JOC, UNDSS, JMAC and UNMAS and can include information on:
            </p>
            <ul className="square un-green">
              <li>
                Current, recurrent and potential perpetrators of threats to civilians and/or peacekeepers;
              </li>
              <li>
                Underlying causes of security threats (e.g., ethnic or religious conflicts, housing, land, property and natural-resource conflicts, historical grievances, political manipulations, revenge attacks, regional security dynamics, presence of other forces that may threaten civilians or be targets for other armed actors);
              </li>
              <li>Likely hotspots and triggers; and</li>
              <li>
                Possible presence of explosive ordnance, including threat of improvised explosive devices, as well as unsecured weapons and ammunition.
              </li>
            </ul>
            <p>
              <strong>Perpetrator analysis: </strong>Missions should analyse the nature, deployments, modus operandi, capacity, motive and intent of actual and potential perpetrators of violence. They should also assess the opportunity for a threat to take place (i.e., time, location, terrain and weather conditions, or other factors that allow a perpetrator to inflict violence). All relevant mission components will contribute to the process but the JMAC and the Force U2 branch (Intelligence), and in some missions, the Police Analysis Units, will have specialist analytical skills and access to information. Such analysis must be shared with POC coordination mechanisms.
            </p>
            <p>
              <strong>Human rights, child protection and CRSV monitoring: </strong>Monitoring of violations of international humanitarian, human rights and refugee law can provide information and analysis on the gravity, intensity, pattern and scale of physical violence affecting different groups of civilians, as well as information on perpetrators. It can also enable analysis of historical trends, early warning and impact. Information and analysis from human rights monitoring, the Monitoring and Reporting Mechanism on grave violations of children&apos;s rights (MRM) and the Monitoring, Analysis and Reporting Arrangements on CRSV (MARA) should be fed into POC threat assessments to ensure that threats, including to children and of CRSV, are integrated. Analysis of threats to civilians should take into account early warning indicators of CRSV<sup>22</sup> and potential mass atrocities, such as a patterns of violence, increased use of hate speech, increased reports of SGBV, incitement to hatred or violence, and build-up of weapons<sup>23</sup>. The mission&apos;s Women&apos;s Protection Advisers should contribute information and analysis on CRSV and the human rights component on indicators of mass atrocities (see chapter 8.5).
            </p>
            <div className="half template-container">
              <div>
                <p>
                  <strong>Analysis of institutions: </strong>A POC threat assessment should consider an analysis of institutional weakness that may exacerbate conflict and violence, as well as an assessment of the functioning and potential shortcomings in the criminal justice chain, including the capacity to investigate and prosecute conflict-related serious crimes and detain perpetrators securely, safely and humanely. This analysis will provide information on the ability and willingness of the justice system to respond to serious crimes committed against civilians, to restore law and order and deter future violations. It should include an analysis of national capacity to provide the necessary protection and support to survivors/witnesses. This analysis can be provided by the rule of law components, with support from the human rights component.
                </p>
                <p>
                  <strong>Analysis of the mission&apos;s own actions: </strong>In identifying and analysing threats to civilians, missions should take into account possible threats caused by the mission&apos;s own actions. This could include possible harm to civilians caused by military and police operations, potential harm caused by mission presence or partnerships or reprisals for engaging with the mission. Missions should also be conscious of threats caused by confusion of the mission with other international and regional forces operating in the area.
                </p>
              </div>
              <div className="template">
                <h5>TEMPLATE – Analysis of Threats and Perpetrators</h5>
                <p className="un-green bold">Nature, identity, profiles</p>
                <p>
                  Perpetrators of violence against civilians include elements of national and international security forces, non-state armed groups, criminals and other civilians.
                </p>
                <p className="un-green bold">Motivation, interest or intent</p>
                <p>
                  Violence against civilians may be strategic and systematic, opportunistic or even accidental; indiscriminate or targeted at an individual or group; motivated by power, ideology or identity (cultural, ethnic, tribal or religious), fear, survival or greed.
                </p>
                <p className="un-green bold">
                  Structure, capabilities and resources, preparedness
                </p>
                <p>
                  Command and control; human, material and financial means; supply lines.
                </p>
                <p className="un-green bold">Presence and deployments</p>
                <p>
                  Location(s); size; specialized capacities; availability of reinforcements.
                </p>
                <p className="un-green bold">Relations</p>
                <p>
                  Political, security, economic and social supporters and allies; affiliation and clients; relationship with the mission.
                </p>
                <p className="un-green bold">
                  Mode of action or tactics, techniques and procedures (TTPs)
                </p>
                <p>
                  Patterns of attacks or other forms of violence (including attacks on specific categories of civilians such as women, children or IDPs, etc.); movements of potential perpetrators; access to weapons.
                </p>
                <p className="un-green bold">History</p>
                <p>
                  Monitoring of violations of international law will provide further information on the gravity, intensity, pattern and scale of physical violence affecting civilians and enable trend and impact analysis.
                </p>
              </div>
            </div>

            <p>
              <strong>Humanitarian Needs Overview (HNO): </strong>This document, developed by humanitarian actors, presents a comprehensive analysis and shared understanding of the impact of a crisis to inform humanitarian advocacy and response planning. The HNO outlines key protection threats faced by the affected population of a crisis, including concerns related to child protection, GBV, housing, land and property, mines and displacement, as well as gaps in the local and humanitarian protection capacity to respond effectively to the protection risks.
            </p>
          </div>
          <div className="sub-section 8-2-2">
            <h5>
              <span className="un-green">8.2.2</span> Identify civilians at risk
            </h5>
            <p>
              See chapter 2 above for guidance on the factors to take into account when defining and identifying civilians to be protected under the POC mandate. As part of the threat assessment process, missions should identify which civilians are at risk from each threat. In assessing threats, the mission should develop a nuanced understanding of the impact of a conflict on different parts of the civilian population.
            </p>
            <div className="half">
              <div>
                <p>
                  When identifying priority populations to be protected, missions should seek early-warning information through monitoring, as well as engagement and consultations with civil society organizations and communities. Civilian mission components will often be the main conduit for community engagement, in close coordination with humanitarian partners, but military and police components should also seek to build relationships with local communities (see chapter 10). Where deployed, Community Liaison Assistants may assist the military or Police component in this regard (see chapter 9).
                </p>
              </div>
              <table className="examples">
                <thead>
                  <tr>
                    <td>Example: Considering the impact of conflict on different parts of the civilian population</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        In one conflict situation, different groups of civilians may face very different threats. For example, a non-state armed group affiliated with a particular ethnic or religious group may directly target civilians of an opposing ethnic or religious group motivated by a political agenda, historical grievance or revenge. At the same time, youth and children of the same ethnic or religious group as the armed group may face risks of forced recruitment or may face reprisals from state forces if they are perceived to support the armed group. Women from both groups may face a heightened risk of sexual violence and civil society activists, journalists and human rights defenders may be targeted by all sides.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              <strong>Assessing vulnerabilities: </strong>While all civilians may be victims of physical violence and therefore require protection from it, missions should pay specific attention to individuals or groups most at risk of violence. These can include girls and boys, women, men, minority groups, refugees, IDPs, persons with disabilities, the wounded and older persons, as well as professionals such as human rights defenders, medical personnel, teachers, journalists and humanitarian personnel. The level of vulnerability of an individual or group will vary, including with time or a specific operational context.Moreover, each different group may be vulnerable to specific threats and risks. For example, women and children, as well as men in detention, may be particularly vulnerable to sexual violence, young men may be more vulnerable to arbitrary detention or execution, and children may be more vulnerable to recruitment into armed groups or harm from unexploded ordnance. IDPs and refugees may be more vulnerable to militarization or infiltration by armed elements, attacks against camps and settlements and/or severe restrictions of movement that effectively prevent them from accessing safety during violence. Assessing the level and nature of vulnerability to violence of different categories of civilians will enable better responses to their specific protection needs.
            </p>
            <p>
              To facilitate the identification of civilians at risk, missions should ensure the consolidation and consistency of information related to incidents affecting the security of civilians. Reports of incidents should be gathered in one place, using a database such as the Unite Aware Incidents application (formerly known as &apos;SAGE&apos;) or other mission data collection arrangements. All reports should include, at a minimum, disaggregated data on the number of killings, rapes and other incidents affecting the security of civilians, per area, gender and age category. Information collection and analysis should include data on mission responses to POC threats in addition to POC trends and patterns.
            </p>
          </div>

          <div className="sub-section 8-2-3">
            <h5>
              <span className="un-green">8.2.3</span> Assess capacity and intent of other protection actors
            </h5>
            <p>
              UN peacekeeping missions operate alongside a range of other protection actors. There are situations where the mission may not be the best-placed or most appropriate actor to respond to a particular threat, including, where the host state has the capacity to respond, or situations when the presence of the mission itself creates a risk to civilians, or where other actors have more capacity, influence or acceptance. In assessing risks to civilians, it is necessary to map all protection actors and their capacity and intent to protect.
            </p>
            <p>
              The other protection actors that the mission should take into account include: communities themselves, state authorities and security forces, international security forces, national and international civil society, religious leaders, human rights, humanitarian and development actors. In some cases, non-state armed actors may also play a protection role.
            </p>
            <p>
              In assessing the capacity and intent of other protection actors, the mission should consider several variables, particularly those related to:
            </p>
            <ul className="square un-green">
              <li>
                Their presence or ability to reach the area where civilians are at risk;
              </li>
              <li>Their capabilities relative to the threat;</li>
              <li>
                Their neutrality and relationship with/acceptance by the population at risk;
              </li>
              <li>Any legal or policy constraints they may face;</li>
              <li>
                Their potential to cause harm (intentionally or unintentionally);
              </li>
              <li>Their willingness to take action to protect civilians; and</li>
              <li>
                Their willingness/readiness to coordinate/cooperate with the mission.
              </li>
            </ul>
          </div>
          <div className="sub-section 8-2-4">
            <h5>
              <span className="un-green">8.2.4</span> Evaluate the risk associated with threats to civilians
            </h5>
            <p>
              This diagram shows how to evaluate the risk associated with threats to civilians, by considering both the likelihood of the threat materializing and the impact on the population if it does. The red zone poses the highest level of risk to civilians. Other risks may be likely but have a smaller impact (yellow) or be less likely but have a more serious impact should the threat materialize (orange).
            </p>

            <table className="risk">
              <thead>
                <tr>
                  <td />
                  <td colSpan="5">Risk = Likelihood x Impact</td>
                </tr>
                <tr>
                  <td />
                  <td colSpan="5">Impact</td>
                </tr>
                <tr>
                  <td>LIKELIHOOD</td>
                  <td>INSIGNIFICANT</td>
                  <td>MINOR</td>
                  <td>MODERATE</td>
                  <td>MAJOR</td>
                  <td>SEVERE</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Almost Certain</td>
                  <td className="yellow">Medium</td>
                  <td className="orange">High</td>
                  <td className="orange">High</td>
                  <td className="red">Extreme</td>
                  <td className="red">Extreme</td>
                </tr>
                <tr>
                  <td>Likely</td>
                  <td className="yellow">Medium</td>
                  <td className="yellow">Medium</td>
                  <td className="orange">High</td>
                  <td className="red">Extreme</td>
                  <td className="red">Extreme</td>
                </tr>
                <tr>
                  <td>Possible</td>
                  <td className="yellow">Medium</td>
                  <td className="yellow">Medium</td>
                  <td className="orange">High</td>
                  <td className="orange">High</td>
                  <td className="red">Extreme</td>
                </tr>
                <tr>
                  <td>Unlikely</td>
                  <td className="green">Low</td>
                  <td className="yellow">Medium</td>
                  <td className="yellow">Medium</td>
                  <td className="orange">High</td>
                  <td className="orange">High</td>
                </tr>
                <tr>
                  <td>Rare</td>
                  <td className="green">Low</td>
                  <td className="green">Low</td>
                  <td className="yellow">Medium</td>
                  <td className="orange">High</td>
                  <td className="orange">High</td>
                </tr>
              </tbody>
            </table>

            <p>
              Once POC threats have been identified, the mission should evaluate the level of risk faced by the civilian population for each threat. This is an assessment of the likelihood of the threat occurring and the actual or potential impact of that threat on the civilian population. In assessing the impact, the mission should take into account the scale, gravity, intensity, regularity or systematic nature of violence. Assessment of impact should also take into account the impact on specific groups more likely to be affected. Assessment of likelihood and impact should consider any pattern of threats or incidents or past records of a similar nature that could act as early warning signs for the commission of atrocity crimes.
            </p>

            <p>
              Both likelihood and impact are a result of the capacity, intent and opportunity for the perpetrator to inflict violence, combined with the vulnerability of the civilians to the threat. They are also a result of the presence, capacity and intent of protection actors, who play a deterrent role.
            </p>
            <p>
              POC risks should be jointly assessed regularly at tactical, operational and strategic levels so that the mission has a common and up-to-date operational picture.
            </p>
            <p>
              The mission should continue to keep threats under review in case either the likelihood or impact increases or decreases.
            </p>
            <p>
              The following criteria can be used to evaluate the impact and likelihood of POC threats, with comparisons necessarily varying between and within missions depending on the context:
            </p>

            <table className="impact">
              <tbody>
                <tr className="header">
                  <td width="10%">IMPACT OF THE THREAT</td>
                  <td>DEFINITION</td>
                </tr>
                <tr>
                  <td className="red">HIGH</td>
                  <td>
                    <p>
                      A significant number of civilians, including women and children, will be directly affected by physical violence including, but not limited to: direct or indirect loss of lives; CRSV; serious injury; severe internal displacement; elevated food insecurity; destruction or loss of civilian assets/infrastructure required for civilian survival; or the imposition of conditions that prevent food supply or urgent humanitarian assistance, especially medical assistance.
                    </p>
                    <p>
                      And/or will have a direct destabilizing impact on the conflict/peace process.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="orange">MEDIUM</td>
                  <td>
                    <p>
                      Some civilians, including women and children, will be directly affected by physical violence, including, but not limited to injuries, internal displacement, or food insecurity. There may be some repercussions for civilian assets/infrastructure required for civilian survival. Food supply or urgent humanitarian assistance, especially medical assistance, can be disrupted.
                    </p>
                    <p>
                      And/or may have some destabilizing impact on the conflict/peace process.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="yellow">LOW</td>
                  <td>
                    <p>
                      A small number of civilians, including women and children, will be directly affected by physical violence. Food security and humanitarian assistance will be able to continue.
                    </p>
                    <p>
                      Unlikely to itself have a destabilizing effect on the conflict/peace process.
                    </p>
                  </td>
                </tr>
                <tr className="header">
                  <td>LIKELIHOOD OF THREAT</td>
                  <td>DEFINITION</td>
                </tr>
                <tr>
                  <td className="red">HIGH</td>
                  <td>
                    The agent generating the physical threat against civilians is highly motivated and sufficiently capable, and actions to prevent the threat are not used or are ineffective.
                  </td>
                </tr>
                <tr>
                  <td className="orange">MEDIUM</td>
                  <td>
                    The agent generating the physical threat against civilians is motivated and capable, but actions to prevent the threat may impede the successful materialization of the threat.
                  </td>
                </tr>
                <tr>
                  <td className="yellow">LOW</td>
                  <td>
                    The agent generating the physical threat against civilians lacks motivation or capability, or actions to prevent the threat are in place and/or can impede the threat from materializing.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="sub-section 8-2-5">
            <h5>
              <span className="un-green">8.2.5</span> Prioritize situations to address
            </h5>
            <p>
              Some POC mandates refer to protection from imminent threats of physical violence (see chapter 2). The term ‘imminent&apos; does not imply that violence is guaranteed to happen in the immediate future. A threat of violence against civilians is imminent from the time it is identified until such time that the mission can determine that the threat no longer exists. Actions or behaviours that appear to be preparatory to a hostile act can inform the determination of whether a threat is imminent.
            </p>
            <p>
              In order to develop a strategic response to threats to civilians, missions should prioritize threats that pose the highest level of risk to civilians and utilize mission resources accordingly. The mission will then need to examine other categories of threat and decide which to prioritize or whether there are less resource-intensive preventive actions to take than physical protection (e.g., advocacy). Priority medium- to long-term POC threats must be incorporated into the mission POC strategy and other mission strategic planning documents.
            </p>
            <p>
              When deciding whether and how to respond to a specific POC threat, the mission should take into account:
            </p>
            <ul className="square un-green">
              <li>
                The nature of the threat and risk to civilians associated with it;
              </li>
              <li>
                The ability and willingness of the host state to respond to the threat;
              </li>
              <li>
                The mission&apos;s ability to address the threat, unilaterally or jointly with other protection actors;
              </li>
              <li>
                The comparative advantages and expected impact the mission may have in mitigating or eliminating the threat; and
              </li>
              <li>
                The possible negative consequences of the mission&apos;s actions or inactions. A mission&apos;s actions can lead to direct or indirect harm to civilians, possible political fallout or retaliatory attacks. A failure to act can also result in civilian harm, a loss of legitimacy and diminished ability to deter. Both action and inaction may result in heightened insecurity for peacekeepers and/or civilians.
              </li>
            </ul>
          </div>
        </>
      )
    },
    {
      index: '8.3',
      name: 'POC Threat Matrix',
      title: t('handbook.chapters.chapter8.3.title'),
      content: (
        <>
          <p>
            The process of conducting a POC threat and risk analysis will result in a priority list of threats to be addressed. These can be organized in a POC Threat Matrix, a tool to identify and prioritize POC threats easily.
          </p>
          <p>
            At the operational and tactical levels (normally a mission field office), a POC Threat Matrix should be developed that will identify key POC concerns over a time period from a few days to a few months. The POC Threat Matrix should be developed and regularly reviewed by the POC coordination forum and approved by the Head of Office and relevant
          </p>
          <div className="template padding-left margin-top">
            <h4>TEMPLATE – POC Threat Matrix</h4>
            <table className="grid border">
              <thead>
                <tr>
                  <td />
                  <td>Priority level</td>
                  <td>Risk level</td>
                  <td>Location</td>
                  <td>Threat Type &amp; Scale (if known)</td>
                  <td>Civilians at Risk &amp; Vulnerabilities</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>1/2/3</td>
                  <td>
                    High<br />
                    Medium<br />
                    Low
                  </td>
                  <td>Should be as specific as possible</td>
                  <td>Type of violence/violation </td>
                  <td>Note groups particularly at risk</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td>3.</td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tbody>
            </table>
          </div>

          <p>
            heads of military and police components. The process should ensure that relevant factors (political, security, gender, human rights, etc.) are taken into account when assessing and prioritizing threats to civilians. The matrix should prioritize situations to address and indicate the required contribution of relevant mission components and necessary resources.
          </p>
          <p>
            A POC Threat Matrix shall as much as possible:
          </p>
          <ul className="square un-green">
            <li>
              List and prioritize the short- to medium-term POC threats based on their probability of occurrence and potential impact on civilians;
            </li>
            <li>
              Map specific populations at risk and vulnerabilities affecting them;
            </li>
            <li>
              Map specific locations where the probability of occurrence of a threat and its potential impact on civilians are identified as the highest;
            </li>
            <li>
              Map other protection actors (presence, resources, intent and actions);
            </li>
            <li>
              List and monitor implementation of all short and medium term activities conducted to prevent, pre-empt or respond to all priority POC threats;
            </li>
            <li>Identify available mission capacities and resources; and</li>
            <li>Flag gaps in response and recommend corrective action.</li>
          </ul>
          <p>
            While the POC Adviser or Focal Point may take the lead in coordinating and drafting the POC Threat Matrix, it must be a consultative and collaborative process in which the threats to civilians are agreed and prioritized by all relevant actors. A draft of the matrix should always be presented for consultation with relevant mission components/sections and UNCT and humanitarian partners when the content directly relates to their programmes or areas of expertise.
          </p>
          <p>
            Missions face high expectations of their POC response but limited resources and capabilities. Therefore, mission decision makers <strong>should articulate and communicate clear, realistic and achievable objectives for each of the priority threats </strong>identified in the POC Threat Matrix.
          </p>
          <div className="template padding-right margin-top">
            <table className="grid border">
              <thead>
                <tr>
                  <td>Source of Violence (Actor)</td>
                  <td>Other Actors Present &amp; Role</td>
                  <td>Mission Capacity &amp; Resources to Respond</td>
                  <td>Mission Response to Date</td>
                  <td>Action Required</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Potential perpetrator </td>
                  <td>Other protection actors</td>
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            Hotspot and blind-spot mapping may contribute to the development of the POC Threat Matrix. Mission POC coordination mechanisms can then continue ongoing mapping exercises of potential hotspots where threats to civilians have arisen or are likely to arise. At the same time, they may map blind spots where the mission does not have a presence or easy access to information (yet) as to where threats may arise.
          </p>
          <table className="examples margin-top">
            <thead>
              <tr>
                <td>Example: MINUSCA hotspot map</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src={POCHotspotsImage} alt="POC hotspots map" />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )
    },
    {
      index: '8.4',
      name: 'Quarterly Forward-looking Reporting',
      title: t('handbook.chapters.chapter8.4.title'),
      content: (
        <>
          <p>
            In accordance with the DPO POC Policy, peacekeeping missions with POC mandates are expected to provide UN Headquarters with brief, quarterly forward-looking assessments of threats to civilians in their areas of operation. Forward-looking threat and risk assessments will enable the mission to anticipate and prevent violence before it occurs or, at a minimum, mitigate its impact on civilians. They will also better enable UN Headquarters to keep the Security Council and other key political actors informed of escalating risks to civilians and strengthen preventive diplomacy and early action.
          </p>
          <p>
            The forward-looking threat assessments will rely on the POC threat and risk assessments and POC Threat Matrix already created by the mission; these should be subsequently refined and/or updated according to the outlook of the succeeding three months (i.e., these are dynamic assessment tools for present and future resource deployment).
          </p>
          <div className="half template-container">
            <div>
              <p>
                <strong>Form: </strong>Assessments should normally be delivered to UN Headquarters via confidential code cable. However, if the mission believes that a confidential code cable is not a suitable vehicle for frank reporting, alternative formats can be considered.
              </p>
              <p>
                <strong>Length: </strong>Assessments should be brief and succinct, approximately 2-5 pages.
              </p>
              <p>
                <strong>Sources/process: </strong>Inputs should be drawn from across the mission, including the civilian, police, military and support components. With the POC Adviser or Focal Point in the drafting lead, existing mission processes or forums should be leveraged to produce the report to the extent possible, for example relying on information shared through the JOC, JMAC, POC working groups, hotspot/threat mapping, Unite Aware Incidents or related mechanisms. Mission actors may engage with humanitarians and other partners in developing the analysis, but formal consultations are not required for this report.
              </p>
              <p>
                <strong>Content: </strong>Assessments should examine threats to civilians in the area of operations, identifying current threats and outlining possible threats over the next quarter. The report should indicate the likelihood of a threat materializing and the expected impact of that threat should it materialize. These threats may or may not be in areas where the mission is present or has access.
              </p>
              <p>
                <strong>Frank reporting is strongly encouraged. </strong>Assessments should focus on analytical and trends-based reporting rather than technical and incident-based reporting. As a quarterly report, the assessment horizon should look approximately three months ahead. The mission is free to organize such analysis geographically, thematically or in any manner that suits the context.
              </p>
            </div>
            <div className="template margin-top margin-bottom">
              <h4>
                TEMPLATE – Quarterly Forward-Looking Threat Assessments
              </h4>
              <p className="un-green bold">
                [Quarter] report<br />
                [Mission]
              </p>
              <ol type="I" className="color bold">
                <li>
                  Summary of threats to civilians
                  <ol type="a">
                    <li>
                      Summary of previously identified and prioritized threats and communities-at-risk
                    </li>
                    <li>
                      Summary of recent trends/incidents
                      <ol type="i">
                        <li>
                          Activity of armed forces or groups (including recruitment trends)
                        </li>
                        <li>Patterns of attacks on civilians</li>
                        <li>Civilian casualty estimates</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  Forward-looking analysis
                  <ol type="a">
                    <li>
                      Changes or potential changes to previous threat assessments, including:
                      <ol type="i">
                        <li>Status of armed groups, other threats</li>
                        <li>Elections, negotiations, other political processes</li>
                        <li>
                          Seasonal variation (dry/rainy season, migration, etc.)
                        </li>
                        <li>Economy, food security, flooding, drought</li>
                      </ol>
                    </li>
                    <li>
                      Observations regarding potential impact on civilians
                    </li>
                  </ol>
                </li>
                <li>Anticipated response</li>
              </ol>
            </div>
          </div>
        </>
      )
    },
    {
      index: '8.5',
      name: 'Integrating Indicators of Potential Mass Atrocities',
      title: t('handbook.chapters.chapter8.5.title'),
      content: (
        <>
          <p>
            The commission of mass atrocities (genocide, crimes against humanity, war crimes and ethnic cleansing) are some of the gravest threats to civilians that a peacekeeping operation may encounter. Throughout the process described above of identifying and analysing threats to civilians, it may be useful for missions to identify and monitor specific early warning and other indicators that could point to the possibility of the preparation or commission of atrocity crimes. As the mission produces its POC threat and risk assessments, POC Threat Matrix and forward-looking reporting on POC threats, indicators of potential mass atrocities should be integrated, as relevant.
          </p>
          <p>
            Considering incidents and threats through the lens of atrocity prevention may help missions to understand better the nature and potential impact of a threat to civilians, allowing them to determine the likelihood of whether atrocity crimes may be committed and whether particular groups are being systematically threatened. This may be particularly useful in situations where civilians face the risk of atrocity crimes in the absence of current violence or open armed conflict. Some actions taken by state actors — such as the adoption of measures or legislation that affect or deliberately discriminate against particular groups or imposition of severe restrictions on the presence or movement
            of NGOs, international organizations or media — or non-state actors may constitute early warning of atrocity crimes, regardless of whether populations are experiencing active conflict.
          </p>
          <p>
            When applying an atrocity-prevention lens, incidents at the local level (such as grievances between particular ethnic groups) should not be viewed in isolation from national-level events (such as sudden changes in government). The interdependence of these dynamics may trigger the perpetration of mass atrocity crimes. Considering these together will enable analysts to more easily anticipate where and when those risks may be escalated.
          </p>
          <p>
            A forward-looking approach to atrocity prevention assists actors in reframing analysis and information gathering to identify patterns of behaviour that may precede mass atrocity crimes. This long-term pattern recognition allows protection actors to understand several crucial variables and influences, specifically:
          </p>
          <div className="half">
            <div>
              <ul className="square un-green">
                <li>Who is vulnerable?</li>
                <li>Which factors intensify their vulnerabilities?</li>
                <li>Where atrocities may take place?</li>
              </ul>
            </div>
            <div>
              <ul className="square un-green">
                <li>What triggers escalation?</li>
                <li>How to protect civilians before atrocities take place?</li>
                <li>How to deter perpetrators from committing crimes?</li>
              </ul>
            </div>
          </div>
          <h5>How can this lens be utilized in the field?</h5>
          <p>
            Through tools such as the UN&apos;s Framework of Analysis for Atrocity Crimes, developed by the Office of the Special Advisers to the Secretary-General on Genocide Prevention and the Responsibility to Protect<sup>24</sup>, protection actors can develop their awareness of the political and societal conditions that may increase the likelihood of atrocities or trigger their commission. The Framework of Analysis lists 14 risk factors for mass atrocity crimes and provides  various indicators that may help analysts in identifying when those risk factors may be elevated in a given situation. Once analysts have identified the likelihood of atrocities being perpetrated, they can convey these risks to tactical and strategic decision makers via the products described above (threat assessments and threat matrices, including forward-looking reporting). Equipped with such analysis, those leaders can determine appropriate preventive action, including support to and coordination with national mechanisms for the prevention of genocide, as appropriate.
          </p>
          <p>
            Mission analysts should use their understanding of the baseline status quo within the mission area of responsibility to determine which of the 14 risk factors and related indicators are most relevant to their specific context. By measuring changes in those indicators against the baseline they can assess whether the likelihood of atrocities taking place is increasing.
          </p>
          <p>
            Although the inventory in the following box is not exhaustive, it presents pertinent indicators to be included.
          </p>
          <table className="examples two-column">
            <thead>
              <tr>
                <td colSpan="2">
                  Enabling circumstances or preparatory action for the commission of atrocity crimes
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <em>Events or measures, whether gradual or sudden, which provide an environment conducive to the commission of atrocity crimes, or which suggest a trajectory towards their perpetration</em>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Increased inflammatory rhetoric, propaganda campaigns or hate speech targeting protected groups, populations or individuals
                </td>
                <td>
                  Increases in dangerous speech are quantifiable when looking at media reports or while participating in community engagement. Inflammatory rhetoric is particularly dangerous when linked with other indicators such as acts of incitement or hate propaganda and politicization of past grievances.
                </td>
              </tr>
              <tr>
                <td>
                  Marking of people or their property based on affiliation to a group or increased politicization of identity
                </td>
                <td>
                  Acts used to single out a particular group — as well as politicization of group differences — are warning signs of potential mobilization along these inter-group differences.
                </td>
              </tr>
              <tr>
                <td>
                  Acquisition of large quantities of arms or ammunition or of other objects that could be used to inflict harm
                </td>
                <td>
                  Acquiring large quantities of arms may increase the capacity to commit atrocities. In a situation of armed conflict or recent conflict there may be a baseline availability of personnel, arms and ammunition, an increase in which may be used to perpetrate atrocities. It is important to measure fluctuation in those resources, such as sudden acquisition of arms or large-scale movement of troops or combatants.
                </td>
              </tr>
              <tr>
                <td>
                  Increased attacks against life, physical integrity, liberty or security of members of protected groups, populations or individuals — or increased serious acts of violence against women and children
                </td>
                <td>
                  Increasing violations against vulnerable populations is a sign of perpetrators disrespecting international human rights and humanitarian law, as well as a growing willingness to commit acts that may constitute atrocity crimes. Those assessing the risk of atrocity crimes should look for a pattern of increased attacks in a particular area or at specific times that could be associated with potential triggering factors like elections, commemorative or religious events, or seasonal changes.
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>Triggering factors </strong>are events or circumstances that, even if seemingly unrelated to atrocity crimes, may seriously exacerbate existing conditions or may spark the onset of atrocity crimes. An adequate early warning assessment should be mindful of all such events or circumstances and consider their potential impact, even if they appear to be unrelated to more direct or structural risk factors. One way of doing this is by creating an annual calendar, mapping relevant events that have proven to increase tensions in the past. This way appropriate action can be considered ahead of the events to mitigate tensions and avoid escalation, and the mission can increase its monitoring of identified risk factors.
          </p>
          <p>
            Potential triggering factors:
          </p>
          <ul>
            <li>
              Sudden deployment of security forces or commencement of armed hostilities;
            </li>
            <li>
              Spillover of armed conflicts or serious tensions in neighbouring countries;
            </li>
            <li>
              Abrupt or irregular regime changes, transfers of power or changes in political power groups;
            </li>
            <li>
              Onsets of seasons or cultural/religious periods/holidays/observances;
            </li>
            <li>
              Acts of incitement or hate propaganda targeting particular groups or individuals;
            </li>
            <li>
              Census taking, elections, pivotal activities related to those processes, or measures that destabilize them; and
            </li>
            <li>
              Other annual events that may increase tensions between groups, such as cattle fairs or religious events.
            </li>
          </ul>
        </>
      )
    }
  ],
  footnotes: {
    content: (
      <ol type="1" start="21" className="refs">
        <li className="8-2-1">DPO Policy on Peacekeeping-Intelligence (2019.08).</li>
        <li className="8-2-1">
          See, e.g., UN Action Matrix on Early Warning Indicators for CRSV, available at <a href="https://issat.dcaf.ch/download/127367/2601654">https://issat.dcaf.ch/download/127367/2601654.</a>
        </li>
        <li className="8-2-1">
          See Framework of Analysis for Atrocity Crimes, available at <a href="https://www.un.org/en/genocideprevention/documents/publications-and-resources/Genocide_Framework%20of%20Analysis-English.pdf">http://www.un.org/en/genocideprevention/documents/publications-and-resources/</a><a href="https://www.un.org/en/genocideprevention/documents/publications-and-resources/Genocide_Framework%20of%20Analysis-English.pdf">Genocide_Framework%20of%20Analysis-English.pdf.</a>
        </li>
        <li className="8-5">
          Available at <a href="https://www.un.org/en/genocideprevention/documents/publications-and-resources/Genocide_Framework%20of%20Analysis-English.pdf">http://www.un.org/en/genocideprevention/documents/publications-and-resources/Genocide_Framework%20of%20Analysis-English.pdf.</a>
        </li>
      </ol>
    )
  }
};
