import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { t } from '../services/i18n';

const sanitizationOptions = {
  allowedAttributes: {
    span: ['class'],
    strong: ['class'],
  }
};

/**
 * The following will render the translated language string with HTML content
 * if it has any.
 * @param translationKey
 * @param typeOfRenderedTag
 * @param className
 * @returns {JSX.Element}
 */
export const renderSanitizedHTMLElement = (translationKey, typeOfRenderedTag, className = '') => {
  const translationString = t(translationKey);
  const sanitizedString = sanitizeHtml(translationString, sanitizationOptions);

  switch (typeOfRenderedTag) {
    case 'div':
      return (<div className={className} dangerouslySetInnerHTML={{ __html: sanitizedString }} />);
    case 'span':
      return (<span className={className} dangerouslySetInnerHTML={{ __html: sanitizedString }} />);
    case 'li':
      return (<li className={className} dangerouslySetInnerHTML={{ __html: sanitizedString }} />);
    default:
      return (<p className={className} dangerouslySetInnerHTML={{ __html: sanitizedString }} />);
  }
};
