import React from 'react';
import coordination from '../images/chapters/chapter6/coordination_with_humanitarians.jpg';
import team from '../images/chapters/chapter6/MINUSMA_integrated_team.jpg';
import humanitarian from '../images/chapters/chapter6/UNAMID_humanitarian_coordination.jpg';
import sites from '../images/chapters/chapter6/UNMISS_POC_sites.jpg';
import MissionPOCCoordinationStructureDiagram from '../images/Mission_POC_coordination_structure_diagram.png';
import AudioPlayer from '../components/audio';
import audio from './audio';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 6: Coordinating POC',
  title: t('handbook.chapters.chapter6.title'),
  index: '6',
  color: 'un-red',
  content: (
    <>
      <div className="grid-three un-red">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            POC coordination mechanisms should be established at the strategic, operational and tactical levels for information sharing and analysis, planning, decision making, implementation and monitoring and reporting of POC activities.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            Effective POC requires a comprehensive and integrated approach, which can only be achieved through focused and joint civilian-police-military planning.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            The mission POC strategy should set out the mission POC coordination mechanisms at the strategic, operational and tactical levels, including their composition and terms of reference.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            POC coordination mechanisms should always include civilian, police and military components, and should often include OCHA and a representative from the Protection Cluster, as well as other protection actors, including host state authorities, as appropriate.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            POC coordination mechanisms should be established at both mission headquarters and subnational mission field offices.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            POC coordination mechanisms should be established at mission start-up and meet regularly.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '6.1',
      name: 'Coordination Within the Mission',
      title: t('handbook.chapters.chapter6.1.title'),
      content: (
        <>
          <p>
            In accordance with the DPO POC Policy, all missions with a POC mandate shall establish POC planning and coordination forums at the strategic, operational and tactical levels. In addition to all relevant mission components (civilian, police and military), these forums should include, as appropriate, OCHA and representation from the Protection Cluster. Where relevant, other representatives of the UN Country Team and other humanitarian actors may also be included. The composition and role of coordination mechanisms will be set out in the mission&apos;s comprehensive POC strategy. These mechanisms will serve as forums for information sharing and analysis, planning, decision making, implementation and monitoring and reporting of POC activities.
          </p>
          <p>
            This chapter contains examples of mission coordination mechanisms on POC and their main responsibilities. <strong>Missions should adapt these examples to their specific contexts and requirements.</strong>
          </p>
          <AudioPlayer
            src={audio[2].file}
            title={audio[2].title}
            subtitle={audio[2].subtitle}
            avatar={audio[2].avatar}
            speaker={audio[2].speaker}
            transcript={audio[2].transcript}
          />
          <div className="voices un-red margin-bottom">
            <div className="avatar">
              <img alt={audio[2].speaker} src={audio[2].avatar} />
            </div>
            <h3>VOICES FROM THE FIELD</h3>
            <div className="content">
              <h4>The Importance of Coordination</h4>
              <h5>Lt Gen Elias Rodrigues Martins Filho, MONUSCO Force Commander (2018-2019)</h5>
              <div className="half">
                <div>
                  <p>
                    MONUSCO is one of the most complex UN peacekeeping operations and has the protection of civilians as its main pillar and the core of its mandate.
                  </p>
                  <p>
                    To fulfil the mandate, the MONUSCO Force interacts on a daily basis with the Mission’s civilian components — POC, Civil Affairs, Human Rights, JMAC, etc. We discuss the threats and risks for the civilian population and work together to incorporate civilian protection in the military operational plans and orders. Such coordination and cooperation is crucial for the effective use and deployment of the Force and must be continuously improved. To implement my own Directives in terms of POC, I have motivated my subordinate commanders to interact with the Heads of Field Offices and other mission civilian personnel for a rational use of our resources and effective decisions.
                  </p>
                  <p>
                    When patrolling, deploying Quick Reaction Forces or conducting offensive operations, POC remains the biggest concern for commanders at all levels. Working together with the POC Advisers, the Community Liaison Assistants (CLAs) and the Language Assistants
                  </p>
                </div>
                <div>
                  <p>
                    (LAs) is also essential for the projection of the Force to interact with the local population, sensitize them and collect the necessary information to prevent attacks on civilians. In this regard, we are constantly working to improve our Early Warning and Response System including by developing a reliable network of sources amongst the local communities.
                  </p>
                  <p>
                    The Ebola Outbreak of 2018-19 has brought an unexpected and different dynamic to the Mission requiring our efforts to protect both the civilian population at risk and humanitarians delivering assistance to contain the outbreak and save lives of thousands of people.
                  </p>
                  <p>
                    In my experience here in MONUSCO, the Force cannot implement the POC mandate alone. It is required to work in close cooperation with all components of the Mission: the political leadership, POC Advisers, UN Police (UNPOL) and the civilian personnel, as well as humanitarians. Working as one mission has contributed immensely to the protection of civilians in the DRC.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="relative">
            <img src={team} alt="MINUSMA integrated team" />
            <div className="description">
              <p>
                Members of a MINUSMA integrated team interview civilians who fled their village after it was entirely burnt down during an attack. (2019)
              </p>
            </div>
            <p className="ref">UN Photo/Marco Dormino</p>
          </div>

          <div className="sub-section 6-1-1">
            <h5>
              <span className="un-red">6.1.1</span> POC coordination at the strategic level
            </h5>
            <p>
              A strategic-level body, composed of the mission&apos;s senior management, bears overall responsibility for the development and implementation of the mission&apos;s POC strategy. This can be achieved by including POC in the agenda of an existing mechanism, or by creating a separate body, for example, a <strong>Senior Management Group on Protection (SMG-P)</strong>. For such an entity, the mission POC lead should ensure that secretariat support is provided, assisted by the mission POC Advisers or Focal Points.
            </p>
            <p>
              In addition to the mission&apos;s senior management, the SMG-P should include Country Representatives of UN entities with protection mandates. The Group should:
            </p>
            <div className="alt-grid">
              <div>
                <p className="un-red bold">Provide strategic vision</p>
                <p>
                  and policy guidance on the POC mandate through the POC strategy
                </p>
              </div>
              <div>
                <p className="un-red bold">Ensure overall consistency</p>
                <p>
                  between various mission and integrated strategies, and place POC as a priority objective for CPAS, the ISF and the Mission Concept
                </p>
              </div>
              <div>
                <p className="un-red bold">Ensure overall coordination</p>
                <p>
                  of the mission&apos;s POC activities
                </p>
              </div>
              <div>
                <p className="un-red bold">Review reports and recommendations</p>
                <p>
                  provided by POC mechanisms at the operational and tactical levels
                </p>
              </div>
              <div>
                <p className="un-red bold">Provide overall guidance</p>
                <p>
                  on policy or operational responses to protection threats, including the use of mission assets
                </p>
              </div>
              <div>
                <p className="un-red bold">Engage, consult and coordinate</p>
                <p>
                  with national authorities on the development and implementation of the POC strategy, possibly through joint discussions and including the Ministries of Defence, Interior and Justice
                </p>
              </div>
              <div>
                <p className="un-red bold">Support the implementation</p>
                <p>of the HRDDP</p>
              </div>
              <div>
                <p className="un-red bold">Take required political action</p>
                <p>to mitigate threats of violence against civilians</p>
              </div>
              <div>
                <p className="un-red bold">Ensure that POC is anchored</p>
                <p>in the overall political strategy of the mission</p>
              </div>
              <div>
                <p className="un-red bold">
                  Manage, in close coordination with Headquarters
                </p>
                <p>
                  resources and capabilities required to implement the mission&apos;s POC mandate, including military, police and civilian deployments
                </p>
              </div>
            </div>
          </div>

          <div className="sub-section 6-1-2">
            <h5>
              <span className="un-red">6.1.2</span> POC coordination at the operational level
            </h5>
            <p>
              Effective protection of civilians is ultimately an operational matter requiring coordinated action from many components. Short to mid-term (1-12 months) joint operational planning is in some cases the responsibility of  an <strong>Operations Planning Team (OPT)</strong>, or equivalent. An OPT is placed under the mission Chief of Staff, to plan for and coordinate all joint operations by the mission, including on POC. Where the <strong>Joint Operations Centre (JOC) </strong>facilitates the coordination of operational activity, it should, in coordination with the POC Adviser, help ensure coordinated POC operations. Whichever forum is used, senior mission leadership is responsible for ensuring implementation of POC as a cross-cutting mandate.
            </p>
            <p>
              As most mission components play a role in implementing the POC mandate, missions should also establish an operational level POC coordination forum (<strong>Protection Working Group, Joint Protection Group, Protection Taskforce, etc.</strong>), responsible for coordinating the development and implementation of the POC Action Plan. The operational POC forum is chaired and managed by the POC lead, the POC Adviser(s) or any other staff member designated by mission leadership.
            </p>
            <p>
              For example, the Protection Working Group will normally:
            </p>
            <ul className="square un-red">
              <li>
                Conduct POC assessments and analysis, support POC analysis and actions taken by field offices, and prioritize POC threats across the mission;
              </li>
              <li>
                Coordinate POC early warning and response at the operational level, including identifying and monitoring situations where the response requires support or action from mission headquarters;
              </li>
              <li>
                Take decisions on rapid reactions related to POC when this has not been done at the tactical level or requires additional resources;
              </li>
              <li>
                Take decisions on planning, reporting, public information and training under the POC action plan;
              </li>
              <li>
                Contribute to mission strategic planning processes, particularly monitoring and reporting; and
              </li>
              <li>
                Highlight areas for consideration by the strategic-level SMG-P and develop recommendations to be presented to the SMG-P for further advice and guidance and, where necessary, elevated to the mission&apos;s Senior Leadership Team.
              </li>
            </ul>
          </div>
          <div className="sub-section 6-1-3">
            <h5>
              <span className="un-red">6.1.3</span> POC coordination at the tactical level
            </h5>
            <p>
              At the tactical level, mission components contributing to POC mandate implementation will focus on developing situational awareness and preventing or responding to threats of violence against civilians. The latter two activities will result in the development of a local POC threat analysis through the POC Threat Matrix. When required and relevant, local POC strategies or plans may also be drafted at the tactical level for a specific geographical area:
              a region, province, county or territory, or an IDP/refugee camp, village or group of villages. Such local POC strategies or plans will include, at a minimum, information on the protection risks, as well as the activities, capacity and roles and responsibilities of protection actors in the area.
            </p>
            <p>
              A <strong>local Senior Management Group on Protection </strong>should be established in each priority POC area to coordinate the implementation of the POC strategy and Action Plan at the regional/local level, in close coordination with the POC Adviser or Focal Point. Led by the Head of Office and composed of the relevant mission components and representatives from the UNCT, it should:
            </p>
            <ul className="square un-red">
              <li>Share information and analysis on threats to civilians;</li>
              <li>Take decisions on rapid reactions related to POC;</li>
              <li>
                Take decisions on planning, reporting, public information and training under the POC action plan;
              </li>
              <li>
                Contribute to mission strategic planning processes, particularly monitoring and reporting; and
              </li>
              <li>
                Highlight areas for consideration by the SMG-P and develop recommendations to be presented to the SMG-P for further advice and guidance and, where necessary, elevated to the mission&apos;s Senior Leadership Team.
              </li>
            </ul>
            <p>
              Whenever a tactical level POC coordination forum or field JOC is established at subnational level, additional dedicated POC capacity will be attached to it, as required, to support and advise the Head of Office in her/his efforts to analyse, plan, coordinate, report, monitor and evaluate the mission protection activities and support POC training activities. Commensurate with the tempo and complexity of POC activity in the area, such capacity may be established through the appointment of one or more POC Adviser(s) attached to the Head of Office, as well as through the nomination of POC focal points within all relevant mission components.
            </p>
            <h5>Related coordination mechanisms</h5>
            <p>
              Other coordination mechanisms within the mission area may share information to take action relevant to POC. These include:
            </p>
            <ul className="square un-red">
              <li>
                <strong>Monitoring and Reporting Mechanism Country Taskforce on Children in Armed Conflict (MRM CTF):</strong> co-chaired by the mission and the UNICEF Country Representative
              </li>
              <li>
                <strong>Monitoring, Analysis and Reporting Arrangement Working Group on CRSV (MARA WG): </strong>chaired by a DSRSG or Senior WPA
              </li>
              <li>
                <strong>HRDDP Taskforce: </strong>chaired by a DSRSG, with the human rights component as secretariat
              </li>
              <li>
                <strong>Rule of Law mechanisms to coordinate international support for investigations and prosecutions for CRSV</strong>
              </li>
            </ul>

            <table className="examples">
              <thead>
                <tr>
                  <td>Example: Mission POC coordination structure</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img alt="Internal Coordination Mechanisms Diagram" src={MissionPOCCoordinationStructureDiagram} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )
    },
    {
      index: '6.2',
      name: 'Coordination with Humanitarians and Other Non-government Protection Actors',
      title: t('handbook.chapters.chapter6.2.title'),
      content: (
        <>
          <p>
            Effective coordination between the mission and humanitarians is essential to the effective implementation of the POC mandate. This coordination must be based on respect for the distinct mandates, principles and approaches of peacekeeping operations and humanitarian actors. The coordination strategy and mechanisms established should be tailored to the operational context because the appropriate level of interaction, coordination and cooperation will vary. Relevant factors include the perceptions of different protection actors by the civilian population, armed actors and the host state, including questions of (perceived) neutrality and impartiality, and the specifics of the mission mandate.
          </p>
          <p>
            In developing systems and mechanisms for coordination with humanitarian actors, missions should understand the humanitarian protection approach. The Inter-Agency Standing Committee (IASC) defines protection as: &apos;all activities aimed at obtaining full respect for the rights of the individual in accordance with the letter and the spirit of the relevant bodies of law (i.e., International Human Rights Law (IHRL), International Humanitarian Law, International Refugee Law (IRL)&apos;<sup>20</sup>. This definition of protection is broader than the POC mandate of peacekeeping operations, which is limited to threats of physical violence against civilians. The IASC definition of protection may align with human rights and other protection mandates of the mission such as those on child protection and conflict-related sexual violence and more broadly with the women, peace and security agenda.
          </p>
          <p>
            Humanitarian organizations also operate within the four humanitarian principles of humanity, neutrality, impartiality and independence. Missions should be aware that their status as political and military actors will influence the level and type of cooperation and coordination with humanitarian actors. In contexts of ongoing armed conflict, where the mission is mandated to support the host state and/or where the mission is actively involved in military operations, coordination with humanitarian actors may be more limited so that humanitarian actors preserve compliance with humanitarian principles. For humanitarian access to be sustained, it is crucial that all stakeholders perceive humanitarian assistance as neutral, impartial and independent from political activities.
          </p>
          <p>
            The aims of coordination and interaction between the mission and humanitarian, development and other protection actors for POC can include, as appropriate:
          </p>
          <ul className="square un-red">
            <li>
              Timely sharing of information on threats to civilians including early warning;
            </li>
            <li>
              Timely sharing of analysis on priority protection locations and issues;
            </li>
            <li>
              Consulting and sharing POC strategies and priorities;
            </li>
            <li>
              Referring victims and witnesses to support systems and services;
            </li>
            <li>
              Developing mutual understanding of approaches and messaging;
            </li>
            <li>
              Contingency planning for civilian, police and military operations;
            </li>
            <li>
              Supporting training activities and exercises;
            </li>
            <li>
              Understanding and deconflicting planned protection activities and interventions;
            </li>
            <li>
              Addressing root causes of conflict and violence against civilians;
            </li>
            <li>
              Conducting joint assessment or joint protection missions; and
            </li>
            <li>
              Ensuring complementary and coordinated strategic communications on POC.
            </li>
          </ul>
          <AudioPlayer
            src={audio[3].file}
            title={audio[3].title}
            subtitle={audio[3].subtitle}
            avatar={audio[3].avatar}
            speaker={audio[3].speaker}
            transcript={audio[3].transcript}
          />
          <div className="voices un-red">
            <div className="avatar">
              <img alt={audio[3].speaker} src={audio[3].avatar} />
            </div>
            <h3>VOICES FROM THE FIELD</h3>
            <div className="content">
              <h4>
                Coordination with Humanitarians in a Humanitarian Disaster Context
              </h4>
              <h5>Ben Majekodunmi, MINUSTAH Chief, a.i., and Deputy Chief Human Rights Section (2010-2011)</h5>
              <cite>
                &quot;...just a few days after the report was completed, the entire MINUSTAH Force was assigned as a top priority to conduct patrols in camps, on foot, with Haitian police.&quot;
              </cite>
              <div className="half">
                <div>
                  <p>
                    Following the 2010 earthquake in Haiti, there were major challenges with delimiting “protection.” The needs were so great, and some argued that protection was basically anything that a person needed but lacked. The media wanted a single prism through which to describe UN action in Haiti, and they found it in “protection”: in the desperate months of early 2010 they wrote critically of the lack of tents, latrines, sanitation kits, water, bedding, buckets, lights, etc., describing everything as a problem of “protection”. The media, and many humanitarian actors, pointed the finger at the “protection cluster.” In fact, there was a logistics cluster, a shelter cluster, a camp management cluster, a water and sanitation cluster and a coordination cluster, all of which were specialized in precisely these issues. In contrast, as the head of OHCHR in Haiti, head of the MINUSTAH human rights component and head of the Protection Cluster, I spent a lot of time explaining that it was unhelpful to define these issues as protection concerns, where the Protection Cluster had no skills or capacity, not least because there were other actors well-equipped to address them. Instead, I argued, the Protection Cluster’s value added lay in a different kind of protection: getting the Haitian police to address sexual violence, ensuring the courts followed up, getting MINUSTAH troops to patrol for protection, ensuring communities and women were consulted; looking at land rights issues for IDPs, and eventually grappling with the cholera epidemic.
                  </p>
                </div>
                <div>
                  <p>
                    In one successful example of coordinating with humanitarians, my section led a “joint protection study” which involved MINUSTAH military and police, Haitian police and various UN entities joining to do a three-day survey in IDP camps. The teams met with women and other IDPs and asked them about their protection concerns. From concept to implementation and presentation of the report was just three weeks.
                  </p>
                  <p>
                    One of the outcomes was that just a few days after the report was completed, the entire MINUSTAH Force was assigned as a top priority to conduct patrols in camps, on foot, with Haitian police. Tens of thousands of patrols were conducted over the course of 18 months and were credited with a major calming effect in the camps. The humanitarian community had initially rejected the proposal for patrols, but participation in the survey changed their minds.
                  </p>
                  <p>
                    It was a huge achievement at a time when there were 1.5 million people in urban camps and a high risk of sexual and other violence.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h5>Integrated UN presence</h5>
          <p>
            In conflict and post-conflict situations where a peacekeeping operation is deployed alongside a UN Country Team, the principle of UN integration applies. The aim of this ‘integrated UN presence&apos; is to ensure that the political, peacekeeping, humanitarian, human rights and development entities of the UN share a common analysis and agree on a set of common strategic objectives for peace consolidation as a starting point for planning and implementing their responses. Implementation of UN integration is guided by the UN Policy on Integrated Assessment and Planning (IAP). The IAP calls on the UN system to (a) develop a common understanding of the situation, (b) agree, jointly, on when, where, and how to respond, and (c) establish coordination mechanisms in the field and at Headquarters at the senior and working levels to achieve the aims and, once consensus is reached, (d) monitor and report jointly on progress toward the joint vision.
          </p>
          <p>
            The primary purpose of humanitarian action is to address lifesaving needs and alleviate suffering, and integration with a political or peace operation may challenge humanitarian principles, so humanitarian activities undertaken by UN humanitarian actors usually remain outside the scope of integration. However, depending on the context, certain activities related to POC — such as return, reintegration and early recovery — may be included in the UN&apos;s integrated strategic approach. Hence, the integration arrangements determined in country should be used to establish a shared analysis and broad strategic approach to POC by the mission and the UN Country Team.
          </p>
          <p>
            The respective roles of the mission, UN entities and humanitarian actors can be determined and articulated through integrated mechanisms and tools, including the conduct of an integrated strategic assessment, an up-front risk analysis and the development of the Integrated Strategic Framework (ISF) or equivalent.
          </p>
          <h5>Humanitarian coordination structure on protection</h5>
          <p>
            Interaction between the mission and humanitarian actors on POC will often be through the <strong>Humanitarian Coordinator (HC) </strong>at the most senior level, UN OCHA as the secretariat to the <strong>Humanitarian Country Team (HCT) </strong>and the <strong>Protection Cluster</strong>.
          </p>
          <p>
            <strong>The HC </strong>is responsible for leading and coordinating humanitarian action by relevant organizations in country, including protection interventions, with a view to ensuring that it is principled, timely, effective and efficient, and contributes to longer-term recovery. The leadership and coordination role of the HC entails building consensus among relevant humanitarian organizations and leading the HCT in deciding the most appropriate coordination solutions for the context.
          </p>
          <p>
            <strong>The HCT </strong>is a strategic and operational decision-making and oversight forum established and led by the HC. Composition includes representatives from the UN, international NGOs and the Red Cross/Red Crescent Movement. The HCT is responsible for agreeing on common strategic issues and priorities on protection, which are outlined in the HCT Protection Strategy and governed by the IASC Policy on Protection in Humanitarian Action and the IASC Statement on the Centrality of Protection. The HC is usually supported at country level by an OCHA field office, which also acts as the secretariat to the HCT.
          </p>
          <p>
            <strong>Protection Clusters </strong>bring together protection partners, including UN agencies, funds and programmes and NGOs, who have the necessary expertise, resources, access and capacity on protection. The capacity of a Protection Cluster is flexible and should be adequate to respond to the scale and complexity of a particular crisis. UNHCR generally leads protection clusters in conflict-induced crises. Arrangements at country-level should be dictated by operational circumstances, including the presence and capacity of protection actors.
          </p>
          <p>
            At country level, the Protection Cluster supports the delivery of specialized and specific protection activities, undertakes regular assessments and analysis, plans and implements its cluster strategy and coordinates and contributes to robust advocacy. Additionally, the Protection Cluster supports the mainstreaming of protection across all sectors.
          </p>
          <p>
            The Protection Cluster serves to contribute to timely and informed decision making by the HC and HCT, through an ongoing, in-depth and integrated analysis of the protection situation. As a priority, this analysis is enabled by meaningful engagement with affected persons. The Protection Cluster must also monitor and continuously assess whether and how protection risks are changing so that the HC and HCT can review protection priorities in light of the evolving operational context, and then measure progress towards collective protection outcomes.
          </p>

          <div className="relative">
            <img src={humanitarian} alt="UNAMID humanitarian coordination" />
            <div className="description">
              <p>
                An inter-agency assessment mission including representatives of UNAMID, the UNCT, the Government and NGOs meet with the local population. (2017)
              </p>
            </div>
            <p className="ref">UN Photo/Mohammed Idriss</p>
          </div>

          <h5>Participation in coordination forums</h5>
          <p>
            Depending on the context and situation on the ground, coordination and information sharing with humanitarian actors can be achieved through participation in relevant coordination forums. Humanitarian actors can be represented in mission POC coordination forums at both mission headquarters and field office level through UN OCHA and the Protection Cluster (usually represented by UNHCR). In some situations, it may be appropriate to invite other humanitarian organizations.
          </p>
          <p>
            Similarly, the mission participates in meetings of the Protection Cluster, normally represented by a civilian mission component, such as the POC Adviser or Civil Affairs Officers. The human rights component of a mission is a member of the Protection Cluster by virtue of its dual status and reporting lines to both DPO and OHCHR. The routine participation of uniformed personnel may not be considered appropriate in Protection Cluster meetings, although it might be useful for uniformed personnel to meet with or address the Protection Cluster on specific issues, for example, when military or police operations that could have protection consequences are being considered.
          </p>
          <p>
            Civil-Military Coordination (CMCoord) platforms facilitate the dialogue between the humanitarian community and military forces. They can be exclusively composed of humanitarian actors, with a CMCoord Officer acting as interlocutor, or of both humanitarian and military actors. They are scalable and based on agreed deliverables and the prevailing dynamics in the operational area. CMCoord platforms remain humanitarian in nature and are chaired by a humanitarian actor (usually OCHA).
          </p>

          <div className="case-study un-red">
            <h3>CASE STUDY</h3>
            <h4>Shabunda Action Plan in DRC</h4>
            <div className="content">
              <div className="relative">
                <img src={sites} alt="UNMISS POC sites" />
                <div className="description">
                  <p>The UNMISS POC site in Bentiu. (2016)</p>
                </div>
                <p className="ref">UN Photo/Isaac Billy</p>
              </div>
              <p>
                In 2013, the United Nations Mission in South Sudan (UNMISS) began providing physical protection within its bases to civilians seeking protection from physical violence. By the end of April 2019, approximately 200,000 IDPs were hosted in UNMISS POC sites in Bentiu, Bor, Juba, Malakal and Wau. Over the years, coordination between the Mission and humanitarians has been key to managing and operating these POC sites
              </p>
              <p>
                UNMISS remains committed to providing protection from physical violence to the IDPs hosted in POC sites, yet the sites have become protracted and unsustainable. To address this, a number of Solutions Working Groups have been established with a core membership comprising UNHCR, WFP, OCHA, IOM, UNMISS and ACTED (the NGO responsible for camp management of UNMISS POC sites). Other stakeholders are invited as relevant. The leadership of the Solutions Working Groups is assumed by UNHCR acting in its capacity as Protection Cluster lead. The Solutions Working Groups operate in close collaboration with humanitarian branches of both the government and opposition (Relief and Rehabilitation Commission and Relief and Rehabilitation Organization of South Sudan, respectively).
              </p>
              <p>
                The main objective of Solutions Working Groups is to find durable solutions for the IDPs both in and outside POC sites, including supporting and facilitating the voluntary and dignified return of IDPs. As a core member of the group, UNMISS actively participates in the planning and facilitation of returns. UNMISS also provides logistical support for assessment visits; advisory support on housing, land and property issues affecting IDPs intending to return/relocate; and, as a last resort, resources in support of returns.
              </p>
              <p>
                To date, the Bor Solutions Working Group has supported the voluntary return of some 880 IDPs, and the Malakal Solutions Working Group some 1,500 IDPs, to various locations of choice or of origin.
              </p>
            </div>
          </div>
          <h5>Information sharing and analysis</h5>
          <p>
            A priority objective of coordination between the mission and humanitarian actors on POC is sharing of information on threats to civilians and on responses to those threats. Information from humanitarian actors who may have access to different populations and locations can usefully inform the mission&apos;s situational awareness and threat assessment. Information sharing on protection activities being undertaken by humanitarians contributes to the mission&apos;s mapping of protection activities to ensure that the mission focuses on its comparative advantage: taking action where it is best placed to do so.
          </p>
          <p>
            In sharing information, both the mission and humanitarians need to be aware about confidentiality of information from victims, witnesses and sources. It may be appropriate to develop an SOP or guidelines on sharing of information between the mission and humanitarian actors, which includes: what information can be shared, how information will be used, who will be responsible for sharing information, how and when information will be shared, as well as standards for data collection and storage.
          </p>

          <div className="case-study un-red">
            <h3>CASE STUDY</h3>
            <h4>Shabunda Action Plan in DRC</h4>
            <div className="content">
              <div className="half">
                <div>
                  <p>
                    In 2014, MONUSCO and the HCT in the DRC developed new guidance on coordination between humanitarian actors and the Mission. The guidelines were developed by a working group and endorsed by the HCT, the SRSG and the Force Commander. They aimed to provide operational guidance for constructive coordination between humanitarian actors and MONUSCO, avoid conflict between them, strengthen the coordination of activities and ensure preservation of humanitarian space and access all while adhering to humanitarian principles
                  </p>
                  <p>
                    The guidelines recognized that peacekeeping missions such as MONUSCO have a political and military mandate, which can mean that some of their positions or activities are not considered impartial by all parties in the country. For the humanitarian community, adherence to the humanitarian principles of humanity, neutrality, impartiality and operational independence was the critical means by which they gained the acceptance of and access to all parties, as well as to the community they served. Doing so enabled them to achieve the primary objective of protecting the lives, the livelihoods and dignity of populations in need and alleviate human suffering.
                  </p>
                  <p>
                    The guidelines set out a coordination strategy of co-existence between the Mission and humanitarian actors and the relevant coordination structure, including the participation of humanitarian actors in MONUSCO coordination mechanisms and MONUSCO participation in Inter-Agency Standing Committee humanitarian clusters. They addressed distinction of activities and assets, in particular, the use of military assets and escorts, medical/casualty evacuation (MEDEVAC/CASEVAC) and information sharing.
                  </p>
                  <p>
                    Specifically, with regard to POC activities undertaken by both the Mission and humanitarian actors, the guidelines noted that both undertake activities that can complement each other. As with other areas of coordination, a clear distinction needed to be maintained between the two actors’ roles. At the same time, coordination between humanitarian actors and MONUSCO on POC issues was essential to ensure timely two-way information exchange and early warning, consultative analysis particularly on
                  </p>
                </div>
                <div>
                  <div className="relative">
                    <img src={coordination} alt="Coordination with humanitarians" />
                    <div className="description">
                      <p>
                        MONUSCO works in coordination with humanitarians during the Ebola outbreak. (2018)
                      </p>
                    </div>
                    <p className="ref">UN Photo/MONUSCO</p>
                  </div>
                  <p>
                    contingency planning before military operations, prioritization of geographical areas and thematic issues, and distinction of activities in the field, taking into account different organizational approaches and mandates.
                  </p>
                  <p>
                    The guidelines stated that MONUSCO and humanitarian coordination bodies would ensure regular consultation on their respective protection strategies, policies and thematic guidelines on issues of mutual relevance. MONUSCO and the Protection Cluster would share information on protection concerns and protection analyses. UNHCR, as the Protection Cluster lead, would, in particular, ensure that protection priorities were transmitted to MONUSCO through reflection in tools such as the protection matrix at the provincial level. Sharing of sensitive information concerning individual cases would be done in compliance with professional protection standards on data sharing, with principles of confidentiality, free and informed consent of the persons involved, the principles of do no harm and duty of care. UNHCR, as the Protection Cluster lead, other protection-mandated agencies/organizations (including UNICEF for child protection and child DDR-related issues) and MONUSCO would ensure the coordination of response to protection needs as per respective mandates.
                  </p>
                  <p className="small">
                    * As of 2019, the guidelines have in practice been superseded. The HCT is involved in consultation with MONUSCO on strategic protection priorities as per the HCT protection strategy, in addition to consultation on operational protection with the Protection Cluster.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      index: '6.3',
      name: 'Coordination With Host Authorities',
      title: t('handbook.chapters.chapter6.3.title'),
      content: (
        <>
          <p>
            <strong className="un-red">The primary responsibility to protect civilians always lies with host states. </strong>UN peacekeeping operations with POC mandates support the government to protect civilians within its territory. This requires dialogue between the mission and government on protection issues. A strong coordination system ensures that threats to civilians are identified and a POC lens is brought to the engagement with national authorities, enabling the mission to understand the government&apos;s views and priorities relating to threats and risks to civilians and its capacities to address them. Government engagement forums can also be key opportunities for the exchange of information on early warning about POC. The existence of coordinated channels with government is particularly important where the host government is unwilling to protect its own civilians or where its security forces have become an active threat to civilians.
          </p>

          <table className="examples">
            <thead>
              <tr>
                <td>Example: MINUSCA coordination with host state authorities</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>
                    MINUSCA has progressively established POC coordination mechanisms with CAR authorities. POC planning operations are coordinated through a variety of formal and ad hoc mechanisms, including regular meetings between the SRSG and the Head of State and periodically with the Government of CAR Security Committee. Other mechanisms include:
                  </p>
                  <ol type="I" className="marked-color">
                    <li>
                      <strong>A joint coordination mechanism between the MINUSCA Force, UNPOL and the Forces Armées Centrafricaines (FACA) and internal security forces</strong> comprising:
                      <ul className="square un-red">
                        <li>
                          Weekly meetings of the Comité Conjoint Technique (CCT), under the leadership of the Ministry of Defence and the Ministry of Interior, as well as with participation from other relevant defence and security officials;
                        </li>
                        <li>
                          Weekly meetings of the Comité des Chefs d’Etat-Major (COCEM), attended by the Force Commander, the Police Commissioner, the FACA Chief of Staff, as well as the General Directors of Police and Gendarmerie; and
                        </li>
                        <li>
                          A joint operations centre, the Centre d’Information et de Coordination (CIC), hosted by the Centre Operationel Commun (COC), to provide the COCEM with shared information and a capacity for coordination of security operations, through joint planning.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Local peace, reconciliation or security committees</strong>, with a membership tailored to the specificities of each local context (e.g., MINUSCA, local authorities, civil society, community leaders, armed groups, humanitarian actors, inter alia).
                    </li>
                    <li>
                      <strong>Coordination with judicial authorities on the investigation and prosecution of serious crimes</strong>, through, for example, the steering committee on the Special Criminal Court (SCC) mandated to investigate international crimes, which is co-chaired by the Minister of Justice and the DSRSG and includes representatives of other national ministries, the judicial service, civil society, the bar association and international donors. Further coordination occurs in the context of MINUSCA support to regular national criminal investigations, where the mission interacts frequently with national prosecutors and other judicial authorities in support of investigations and the organization of trials.
                    </li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )
    }
  ],
  footnotes: {
    content: (
      <ol type="1" start="20" className="refs">
        <li className="6-2">
          Inter-Agency Standing Commitee Policy on Protection in Humanitarian Action (2016). Available at <a href="https://interagencystandingcommittee.org/system/files/isac_policy_on_protection_in_humanitarian_action.pdf">https://interagencystandingcommittee.org/system/files/iasc_policy_on_protection_in_humanitarian_action.pdf</a>.
        </li>
      </ol>
    )
  }
};
