import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import audio from '../../templates/audio';
import AudioPlayer from '../../components/audio';
import menuElipsis from '../../images/design/menu_elipsis.svg';
import menuElipsisGrey from '../../images/design/menu_elipsis-grey.svg';
import handbook from '../../images/design/handbook-grey.svg';
import actions from '../../redux/actions';
import { t } from '../../services/i18n';

const Audio = ({ cache, updateCache }) => {
  const [showMenu, setShowMenu] = useState(false);
  const filterRef = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (filterRef.current && !filterRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <>
      <header>
        <div>
          <div>
            <h1>{t('handbook.audio.title')}</h1>
            <span>{t('handbook.audio.subtitle')}</span>
          </div>
          <div ref={filterRef} className="mobile-options">
            <button type="button" onClick={() => setShowMenu((s) => !s)}>
              <img src={showMenu ? menuElipsis : menuElipsisGrey} alt="show menu" />
            </button>
            { showMenu && (
              <div className="submenu">
                <button
                  type="button"
                  onClick={() => {
                    setShowMenu(false);
                    updateCache({ showSidebar: !cache.showSidebar });
                  }}
                >
                  <img src={handbook} alt="handbook icon" /> {t('common.handbook-contents')}
                </button>
              </div>
            ) }
          </div>
        </div>
      </header>
      <div className="scroller">
        { audio.map((item) => (
          <AudioPlayer
            key={item.title}
            src={item.file}
            title={item.title}
            subtitle={item.subtitle}
            avatar={item.avatar}
            speaker={item.speaker}
            transcript={item.transcript}
            languageSpoken={item.language}
          />
        )) }
      </div>
    </>
  );
};

Audio.propTypes = {
  cache: PropTypes.shape({
    showSidebar: PropTypes.bool
  }).isRequired,
  updateCache: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps, {
  updateCache: actions.updateCache
})(Audio);
