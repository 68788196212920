import React from 'react';
import visit from '../images/chapters/chapter4/MINUSMA_force_commander_visits_northern mali.jpg';
import briefing from '../images/chapters/chapter4/MONUSCO_DRSG_recieves_briefing_on_attacks.jpg';
import planning from '../images/chapters/chapter4/UNISFA_planning.jpg';
import leer from '../images/chapters/chapter4/UNMISS_SRSG_visits_leer_and_thonyor.jpg';
import AudioPlayer from '../components/audio';
import audio from './audio';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 4: Leading on POC',
  title: t('handbook.chapters.chapter4.title'),
  index: '4',
  color: 'un-orange',
  content: (
    <>
      <div className="grid-three un-orange">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            Mission leaders and managers — at all levels (strategic, operational and tactical) — must ensure that the protection of civilians is at the heart of the mission’s political strategy. They must address strategic threats efficiently, provide a strategic vision, decide on resource allocation and sequencing of efforts, and ensure that POC is mainstreamed in all plans and performance processes.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            As a whole-of-mission responsibility and a priority mandate, the protection of civilians must be driven from the top. Within the framework of the DPO POC Policy, mission leaders and managers are accountable for the implementation of the POC mandate and must ensure that the mission takes the most effective approach to POC and that there is coherence with other mandated tasks.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            Effective strategic leadership on POC begins with linking political and POC objectives and planning, including in command and control and operational readiness. Mission leadership must prioritize civilian harm mitigation and consider coherence with other UN actors. Leadership also requires resource management and innovation, as well as maximizing the specialist capacities of the POC unit.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            Mission leadership is ultimately responsible for POC mandate implementation, with support from their advisers, including POC Advisers. Leaders at all levels set the expectations for their teams.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            Leadership on POC is exercised at mission headquarters, in dialogue with and with support from UN Headquarters, and at the field-office level.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            Effective strategic leadership on POC begins at mission start-up and continues throughout the lifecycle of the mission. Mission leadership must also determine when and to what extent to use security approaches and force to protect civilians.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '4.1',
      name: 'Strategic Considerations',
      title: t('handbook.chapters.chapter4.1.title'),
      content: (
        <>
          <div className="sub-section 4-1-1">
            <h5>
              <span className="un-orange">4.1.1</span> Human rights up front: a moral obligation and collective responsibility
            </h5>
            <p>
              The promotion and protection of human rights is an important tool for prevention of harm to civilians, as recognized by the adoption of the Human Rights up Front (HRuF) initiative by the Secretary-General in 2013. Human rights monitoring, analysis and reporting help to identify warning signs of crisis and conflict that, if left unaddressed, can lead to conflict, and form a strong basis for Member States, the UN system and the international community to identify solutions to address risks and prevent such crises. The HRuF initiative was established to strengthen the preventive work of the UN to utilize early warning signals to avert crises. It aims to help the UN act more coherently across the pillars of the Organization&apos;s work to meet its responsibilities to prevent and respond to serious violations of international human rights and international humanitarian law. It also seeks to improve the functioning of the UN system and performance of UN personnel in this regard. <strong className="un-orange">Coordination, information sharing, advocacy and proactive engagement with key stakeholders, including Member States, are key to HRuF.</strong>
            </p>
            <p>
              Mission leaders and managers hold a collective responsibility to be protection-sensitive; they must respect and disseminate the core human rights values of the United Nations at all times. Mission leaders are role models for all personnel and represent UN Peacekeeping to partners. A mission&apos;s legitimacy and credibility rely on the consistency of its support to the human rights agenda and its ability to meet protection expectations. The protection of civilians is therefore often compared to a moral imperative, one which leaders should publicly commit to and own.
            </p>
          </div>

          <div className="sub-section 4-1-2">
            <h5>
              <span className="un-orange">4.1.2</span> Accountability for implementing the POC mandate
            </h5>
            <p>
              In line with the DPO POC Policy, senior mission leaders are responsible and accountable both for their own strategic actions and guidance and for ensuring that organizational and individual performance management systems are fully utilized to ensure that all relevant personnel in missions are accountable for the implementation of their tasks and responsibilities and for meeting performance expectations in the delivery of POC mandates of missions.
            </p>
            <p>
              To ensure effective performance and accountability, senior leaders in missions with POC mandates should include a <strong>strategic objective </strong>on POC in their Senior Manager&apos;s Compact, aligned with mandate priorities, the mission&apos;s strategic objectives and the leader&apos;s oversight role.
            </p>

            <table className="examples fade">
              <thead>
                <tr>
                  <td>Example: POC Strategic Objective for Senior Managers Compact</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p><strong>Strategic Objective</strong></p>
                    <p>
                      Fulfil leadership responsibilities to implement the mission&apos;s POC mandate.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Expected Accomplishment</strong></p>
                    <p>
                      The mission effectively protects civilians through the activities of all components, within resources and capabilities, and taking into account the host government&apos;s primary protection responsibilities.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>Performance Measure</strong>
                    </p>
                    <p>
                      Compliance of the mission with organizational and DPO policies and guidance on the protection of civilians in United Nations peacekeeping, including minimum requirements to implement the POC mandate:
                    </p>
                    <ul className="square un-orange">
                      <li>
                        POC strategy reviewed on an annual basis and revised as necessary
                      </li>
                      <li>
                        POC coordination and joint operations mechanisms established and meeting regularly at all levels
                      </li>
                      <li>
                        Quarterly forward-looking POC threat assessment shared with Headquarters
                      </li>
                      <li>POC contingency plans prepared</li>
                      <li>POC military and police orders issued</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="sub-section 4-1-3">
            <h5>
              <span className="un-orange">4.1.3</span> Core responsibilities of senior leaders
            </h5>
            <p>
              The DPO POC Policy sets out the core responsibilities of senior leaders for the implementation of POC mandates. Senior leaders should be aware of these responsibilities and implement them; a few are elaborated here.
            </p>
            <ol type="1" className="color headers">
              <li className="un-orange">
                Define the strategic and political approaches to POC, as well as the link to the overall mission political and communications strategy
              </li>
              <p>
                Ensuring a strategic approach to POC requires politically led plans and actions that address strategic, operational and tactical-level threats to civilians.
              </p>
              <p>
                Leaders should therefore define a <strong>political approach to addressing each identified POC threat</strong>. These will include: strategies to affect the intent and actions of perpetrators and spoilers, mobilizing or supporting influencers and other protection actors and managing perceptions and expectations. As part of this political approach to POC, leaders and managers will define how and when to engage with and persuade perpetrators, mobilize or support other actors and make use of strategic communication, including to denounce perpetrators.
              </p>
              <p>
                For this approach to succeed, mission leaders may want to pay specific attention to <strong>ensuring mission impartiality, credibility and legitimacy</strong>. For instance, as expectations will always exceed a mission&apos;s ability to protect, public recognition of the multiple challenges facing POC operations, and sensitization as to efforts underway to address them, is of strategic importance.
              </p>
              <p>
                <strong className="un-orange">Mission leaders should combine strategic communication and decisive action. </strong>Activities performed by the uniformed components demonstrating the mission&apos;s willingness to take action will convey a message about the commitment of the mission to protecting civilians. These activities are far more convincing and powerful than any press statement, media article or statement by a mission leader.
              </p>
              <p>
                This political approach to POC should create the necessary space for overall and longer term political and stabilization strategies, aiming to address the root causes of the conflict, restore peace and security and, as a result, ensure long-term protection of civilians. In cases where the political agenda of non-state armed groups is limited or non-existent (e.g., violent extremism), the mission&apos;s political approach may consider opening and sustaining a political space for civilians facing state failure, the absence of the rule of law, or weak infrastructure and economies that exacerbate intercommunal violence or intensify popular support for extremism.
              </p>
              <p>
                To ensure success of a comprehensive approach to POC, or, at minimum, a coordinated approach to actions on POC, mission leaders should establish dedicated POC coordination and/or operations mechanisms aimed at dealing with emerging or actual threats and risks to civilians (see chapter 6). Response plans per threat and a liaison or engagement matrix can also assist in clarifying internal mission leads for engagement per category of external actors.
              </p>
              <div className="relative margin-bottom">
                <img src={briefing} alt="DSRG receives briefing" />
                <div className="description">
                  <p>
                    MONUSCO’s DSRSG-Ops and Deputy Force Commander along with a DRC armed forces commander receive a briefing on attacks and plan a coordinated response. (2017)
                  </p>
                </div>
                <p className="ref">UN Photo/Anne Herrmann</p>
              </div>
              <li className="un-orange">
                Plan for success: moving from outputs to outcome and impact
              </li>
              <p>
                Mission leaders are expected to devise a vision for effective POC and integrate the strategic and political approaches to POC in the mission concept and POC strategy. Throughout the mission planning processes at the strategic, operational and tactical levels, leaders and managers should:
              </p>
              <ul className="square striped un-orange margin-bottom">
                <li>
                  Ensure the mechanisms, resources and skills are in place for joint planning at all levels;
                </li>
                <li>
                  Order the identification of priority threats to civilians and the development of operational and tactical prevention and response, or contingency plans for each priority threat or risk identified;
                </li>
                <li>
                  Ensure compliance with the overall strategic and political approach to POC and dissemination of the POC strategy to all staff to be used as the basis for planning POC action;
                </li>
                <li>
                  Ensure that heads of section at all levels understand their POC responsibilities and the role of their section and build POC activities into their strategic plans in line with the mission POC strategy;
                </li>
                <li>
                  Constantly balance and synchronize actions and resources aiming to reach short-term POC success and those aiming to ensure longer term peace and stability;
                </li>
                <li>
                  Ensure that UN-led programmatic activities are in support of the POC mandate and POC strategic outcome and impact objectives;
                </li>
                <li>
                  Ensure monitoring and evaluation of POC actions in line with the relevant output, outcome and impact indicators;
                </li>
                <li>
                  Lead early, persistent and coordinated messaging for the protection of civilians to all relevant parties and influential actors;
                </li>
                <li>
                  Provide guidance on actions aiming at synergized and coordinated approaches with the UNCT, and humanitarian and development actors;
                </li>
                <li>
                  Provide avenues for dialogue and engagement with national and local government actors, as well as other parties to the conflict, to mitigate risks and/or develop alternatives to conflict including conflict resolution and peacebuilding; and
                </li>
                <li>
                  Ensure that effective early warning and information gathering mechanisms are in place alongside functional operational and tactical planning.
                </li>
              </ul>
              <li className="un-orange">
                Mainstream military and police action in the mission&apos;s planning and execution and build consensus on the use of force
              </li>
              <p>
                Heads of missions or their representatives, advised by the heads of the military and police components, should <strong>determine when to use security action </strong>(military and police), <strong>when to use force, and to what effect</strong>. Mission leaders, as well as the military and police components, must be sensitive to the difference between use of force to protect civilians from violence by perpetrators and use of force to protect UN staff and assets from attacks.
              </p>
              <p>
                The gradual use of force should not be seen as contradictory or an impediment to political solutions, but, rather, as an enabler to apply credible deterrence or physical prevention whenever violence against civilians occurs or is about to occur. Indeed, many situations may not require the use of security means or force. The question therefore lies more in the determination of when and how military or police action, and, as required, force, will be utilized. Mission leaders should ensure that the military and police components are supported by a wide range of civilian actions and programmes while responding to a developing security situation.
              </p>
              <p>
                As defined in a mission&apos;s ROE and DUF, the use of force by peacekeeping operations must be proportional to the threat at hand and can range from a defensive presence, crowd control and the conduct of visible exercises, to the show of force and, when required, the use of deadly force (see chapter 12.4). Further details on the use of military or police components to ensure POC are contained in the Department of Peacekeeping Operations—Department of Field Support (DPKO-DFS) POC Military Guidelines and POC Police Guidelines as well as the DPO Directives on the Use of Force.
              </p>
              <p>
                <strong>Field commanders may be disinclined to act or use force </strong>if they are unclear about the vision and intent of the leadership, about the nature of the target and what is allowed or not allowed in a specific situation. <strong>Equally</strong>, the military and police components must be aware of how the use of force at the tactical level might be politicized and have negative consequences for the mission and its strategic relationships.
              </p>
            </ol>

            <table className="checklist un-orange">
              <thead>
                <tr>
                  <td />
                  <td>Checklist: Mission leaders should therefore:</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="checked" />
                  <td>
                    Be aware of constraints on the mission’s freedom of movement and act to ensure freedom of movement for the mission. It is important to create an understanding across the mission of the necessity of freedom of movement for the mission to carry out the POC mandate, to protect civilians from physical violence.
                  </td>
                </tr>
                <tr>
                  <td className="checked" />
                  <td>
                    Ensure POC strategies are concise, unambiguous and that they are consistent with the ROE/DUF.
                  </td>
                </tr>
                <tr>
                  <td className="checked" />
                  <td>
                    Clarify the scope of POC action with the military and police leadership, including defensive actions to protect civilians and facilitate humanitarian assistance. This includes clarifying the scope of proactive actions to deal with emerging threats to prevent or stop violence against civilians.
                  </td>
                </tr>
                <tr>
                  <td className="checked" />
                  <td>
                    Ensure proactive and clear interpretation of the tactical actions required and authorized under the POC mandate, as well as the conditions for the use of force, possibly through joint contingency planning, a mission-specific tactical POC handbook and table top exercises (TTX).
                  </td>
                </tr>
                <tr>
                  <td className="checked" />
                  <td>
                    Prioritize prevention before response where possible. This requires better understanding of the threats and how to affect them with different means. It also allows the mission to use the full range of capabilities available. Mission leaders and managers may therefore wish to ensure that analysis and political action always support and guide security activities, including at the tactical level.
                  </td>
                </tr>
                <tr>
                  <td className="checked" />
                  <td>
                    As a POC situation unfolds, ensure field commanders are empowered to take proactive prevention and response actions locally, as required by a tactical situation. Reassure field commanders that the use of force against a perpetrator is authorized and may be required, and that they will not face retribution should collateral damage occur, as long as they strictly comply with the ROE/DUF.
                  </td>
                </tr>
              </tbody>
            </table>
            <AudioPlayer
              src={audio[0].file}
              title={audio[0].title}
              subtitle={audio[0].subtitle}
              avatar={audio[0].avatar}
              speaker={audio[0].speaker}
              transcript={audio[0].transcript}
            />
            <div className="voices un-orange">
              <div className="avatar">
                <img alt={audio[0].speaker} src={audio[0].avatar} />
              </div>
              <h3>VOICES FROM THE FIELD</h3>
              <div className="content">
                <h4>A Whole-of-Mission Approach to POC</h4>
                <h5>MONUSCO SRSG Leila Zerrougui (2018-2022; DSRSG 2008-2012)</h5>
                <div className="half">
                  <div>
                    <p>
                      While the Democratic Republic of the Congo has seen progressive stabilization over the past years, in the East of the country civilians still face critical protection concerns. Supporting the Government, particularly the Congolese Army (FARDC) and National Police (PNC), to provide safety and security for all civilians is therefore a strategic objective. I believe we have been able to provide closer protection to civilians by deploying, as quickly as possible, more nimble military structures, known as
                    </p>
                    <cite>
                      &quot;...we developed a robust response capacity with local actors to mitigate threats...&quot;
                    </cite>
                    <p>
                      Standing Combat Deployments (SCD), in addition to more permanent bases in conflict-prone areas. Supporting the protection of civilians does not however, end there. The work of our uniformed personnel primarily serves to open space for civilian personnel to engage in view of working towards more sustainable solutions.
                    </p>
                  </div>
                  <div>
                    <p>
                      To reach our very ambitious and critical goals in terms of POC, we are therefore implementing a strategy that addresses all three aspects of the humanitarian protection concept: prevention and response, remedial action and support for the establishment of a protective environment. In this regard, we developed a robust response capacity with local actors to mitigate threats, including building community resilience and early warning mechanisms based on community protection plans.
                    </p>
                    <p>
                      More broadly, our POC tools include a Must-Should-Could Protect Matrix, Community Liaison Assistants, Joint Protection Teams, Community Alert Networks and Joint Assessment Missions. We will continue improving all these tools in the provinces affected by armed group activities that threaten the civilian population and cause continued suffering and population displacement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sub-section 4-1-4">
            <h5>
              <span className="un-orange">4.1.4</span> Civilian harm mitigation: ensure conduct and discipline and accountability
            </h5>
            <p>
              A key POC responsibility of mission leaders is to ensure missions do not harm civilians by their presence or actions. Ensuring that the mission does not harm or negatively impact civilians is of strategic importance to ensure the acceptance of the mission in country. Sexual exploitation and abuse are strictly prohibited and must be addressed in accordance with relevant procedures<sup>12</sup>. Zero tolerance for sexual exploitation and abuse is a requirement for the effective protection of civilians. The use of force by the mission must comply with the ROE/DUF and international humanitarian law, where applicable, and must proactively minimize the potential consequences for the civilian population.
            </p>
            <p>
              Steps to mitigate harm to civilians should be undertaken for all mission activities that could result in risks to civilians, but measures are particularly important in relation to operations that may include the use of force. Mission leaders should therefore ensure that rigorous measures are taken to prevent all instances of direct or indirect harm, including through the establishment of tools, mechanisms and processes to systematically:
            </p>
            <ul className="square un-orange">
              <li>
                Identify how the peacekeeping operation&apos;s presence and activities could affect civilians prior to the implementation of an activity or operation;
              </li>
              <li>
                Take steps to try to prevent or mitigate the harm that could result from the activity or operation;
              </li>
              <li>
                Track the positive and negative impact of the operation on the civilian population; and
              </li>
              <li>
                Take steps to integrate lessons learned to prevent or mitigate future harm.
              </li>
            </ul>

            <p>
              In some cases, the mission&apos;s use of force may trigger obligations to prevent or mitigate harm under international humanitarian law. In contexts where peacekeeping operations are providing support to other armed actors, such as host state security forces or regional or coalition operations, the mission will also analyse the risks of, monitor the impact of, and take steps to mitigate the harm that those forces may cause during their operations — in line with the Human Rights Due Diligence Policy (see chapter 11.4).
            </p>
            <p>
              Mission leaders aiming to establish rigorous measures to prevent all instances of direct or indirect harm will promote the mainstreaming of POC, human rights, child protection and gender across all components and apply a protection lens to all plans and activities conducted. Vulnerabilities vary within a community, so it is important to analyse the risk that a mission may pose to different members of a population (e.g., men, boys, women, girls, persons with disabilities, older persons, those who are economically disenfranchised and ethnic, political or religious minorities).
            </p>
            <p>
              Ensuring proactive transparency, while respecting the safety and dignity of witnesses, victims and their families, is also of strategic importance. All harm done to civilians, directly or indirectly, should be tracked, investigated, analysed and addressed. This includes ensuring, as appropriate, that the applicable disciplinary or accountability measures are taken, and making amends for harm caused. Amends should be made even in cases where the harm was caused by operations conducted within the principles set in the ROE/DUF and the mission&apos;s code of conduct.
            </p>
          </div>
        </>
      )
    },
    {
      index: '4.2',
      name: 'Operations Command, Control, Coordination and Communications',
      title: t('handbook.chapters.chapter4.2.title'),
      content: (
        <>
          <ol type="1" className="headers color">
            <li className="un-orange">
              Move beyond reactive or ad hoc responses and motivate the mission to be proactive in implementing the POC mandate
            </li>
            <p>
              Numerous evaluations point to the fact that POC responses are usually ad hoc or incident-driven. Lack of deliberate action and consistency in approach creates confusion for peacekeeping personnel and partners, and sometimes inability to implement or follow up on previous decisions because shifting major resources and capabilities requires time and resources. It is important to establish a consistent approach that focuses on early and preventive action.
            </p>
            <p className="un-orange bold">
              Displaying a willingness to intervene under adversity and apply a proactive stance is strategic, as reclaiming the initiative will provide peacekeepers with a dominant psychological position over armed groups or spoilers, in support of host state authority and the rule of law.
            </p>
            <p>
              Effective POC, including for physical protection, depends heavily on engaged and supportive leadership. Guidance for leading successful operations includes:
            </p>
            <ul className="square un-orange margin-bottom">
              <li>
                The Special Representative of the Secretary-General (SRSG) should initiate action from the top, and personally tackle the most complex and strategic POC threats, particularly through engagement and/or coordination with high-level host state officials.
              </li>
              <li>
                At the strategic level, should the SRSG lack time to lead all POC operations, he or she should designate and empower a dedicated senior mission lead on POC and crisis management, normally his or her deputy.
              </li>
              <li>
                At operational and tactical levels, the POC Adviser or Focal Point will ensure that each POC threat has a designated lead, and that appropriate response plans are developed, resourced, implemented and supported by all relevant personnel and external actors.
              </li>
              <li>
                When delegating authority for a threat, decision or implementation, mission leaders need to ensure that a delegate is fully informed and confident, that his or her authority is clear and accepted by all mission personnel, including partners and host authorities, and that relations with partners and host authorities are clear. Mission leaders should request regular updates from delegates.
              </li>
              <li>
                When a situation is stalled at lower levels or is at risk of escalation, mission leaders should be prepared to intervene, take decisions, help to identify innovative solutions, and to take political action with high-level decision makers or influencers. Examples of this include contacting a relevant minister or other ranking official in the host government, reorienting a physical manoeuvre, communicating publicly on a topic, convening a political event to diffuse tensions, or leading a joint visit with state authorities to a hotspot to prevent escalation of violence and provide guidance to tactical-level actors.
              </li>
            </ul>
            <li className="un-orange">
              Take the lead in strategic communications and relations with external actors to persuade or deter perpetrators
            </li>
            <p>
              Mission leaders and managers generally act as the main interface with the host state and a range of external actors. They lead and define the mission&apos;s positioning and outreach or engagement efforts.
            </p>
            <p>
              Such efforts will naturally build on the POC risk analysis, which includes an assessment of the intent and capacity of perpetrators, spoilers, influencers and other protection actors. As part of the political approach to POC, leaders and managers should define how and when to engage with and persuade perpetrators, as well as how and when to mobilize or support other actors and communicate publicly to this end.
            </p>
            <p>
              <strong className="un-orange">Despite its highly sensitive nature, mission leaders should not shy away from tackling violence perpetrated by host government security forces or government proxies. </strong>Rather, leaders should use the range of options at their disposal to mediate and convince, deter spoilers, support positive forces, or mobilize strategic and other external influencers. They can also leverage specific tools, including national or international accountability mechanisms, political opportunities and the Human Rights Due Diligence Policy.
            </p>
            <p>
              Alongside host state partners, mission leaders and managers will also build strategic partnerships with development, humanitarian and human rights actors and/or the media to apply further pressure on perpetrators and spoilers or address broader dynamics of violence, including at the regional level.
            </p>

            <div className="relative">
              <img src={visit} alt="MINUSMA force command visits northern Mali" />
              <div className="description">
                <p>
                  After armed clashes between two armed groups, MINUSMA’s Force Commander meets with representatives of one of the groups and of the local government. (2015)
                </p>
              </div>
              <p className="ref">UN Photo/Marco Dormino</p>
            </div>

            <p>
              When confronted with political and security dynamics beyond the mission&apos;s theatre of operations, mission leaders can mobilize strategic-level support through inter-mission cooperation and coordination with regional or international actors, including UN offices and organs, neighbouring states, regional organizations and the Security Council and its mechanisms (Sanctions Committees, Panels of Experts, etc.).
            </p>
            <li className="un-orange">
              Manage risks, ensure time-critical decision making and consistency
            </li>
            <p>
              Certain challenges may hamper bold, robust or proactive POC decision making, such as:
            </p>
            <div className="odd-even">
              <div>Complex POC threats and conflict dynamics</div>
              <div>
                Numerous and contradicting pieces of information, analysis, recommendations or requests from staff and external actors, or insufficient reliable and integrated information available
              </div>
              <div>
                Unknowns and dilemmas as to the consequences of decisions
              </div>
              <div>
                Potential political, POC and security risks, including for the mission and peacekeeping personnel
              </div>
              <div>
                Risks of accusations of excessive use of force or use of military solutions, or perceptions of partiality
              </div>
              <div>Lack of time and resources</div>
            </div>
            <p>
              However, most of these challenges and risks can be managed or mitigated to a certain degree by:
            </p>
            <ul className="triangle un-orange">
              <li>
                <strong>Making decisions and tasking clear and concise</strong>. Even in complex situations, it is preferable to give clear instructions to accountable actors rather than a long list of recommended actions.
              </li>
              <li>
                <strong>Consulting integrated mechanisms </strong>(operations planning and coordination teams, POC Working Group, Humanitarian Country Team, Protection Cluster, etc.). These working-level groups can research and prepare joint options and recommendations for leadership to consider, with pros and cons, including a political, POC and security risk analysis and proposed mitigation measures for each option. Should confidentiality be required, consider assembling a small team of handpicked expert personnel.
              </li>
              <li>
                <strong>Building alliances with, and support from, key stakeholders, including partners and influencers.</strong> For instance, inform, consult and mobilize host state authorities, humanitarians, communities or key Member States on a course of action so that they may assist in building support and changing the narrative on a developing situation, the mission&apos;s actions and associated risks.
              </li>
            </ul>
            <p>
              Finally, when considering a course of action, always keep in mind the risks associated with inaction, including:
            </p>
            <ul className="square un-orange">
              <li>
                Escalating violence related to the POC threat, leading to criticism around lack of action, as missions are often the centre of attention of the international community and media, or a scapegoat for host authorities unable or unwilling to act;
              </li>
              <li>
                Loss of morale amongst peacekeeping personnel and partners, who may become passive or even spoilers in the future;
              </li>
              <li>
                Loss of credibility with perpetrators, the Security Council and/or the public, which will in turn affect ability to operate and achieve success.
              </li>
            </ul>
            <p>
              Taking risks is necessary and it may require time to build support and ensure success. Once made, decisions may require staying the course, despite short term criticism that may arise.
            </p>
            <li className="un-orange">
              Maintain morale, encourage innovation and teamwork
            </li>
            <table className="title-left margin-top margin-bottom">
              <tbody>
                <tr>
                  <td>Conduct regular field visits</td>
                  <td>
                    To ensure maximum effectiveness, mission leaders and managers should pay specific attention to the morale of their personnel and partners. Beyond the usual challenges related to working in a peacekeeping operation, staff morale is constantly put to the test and often negatively affected by the inevitable failures to deliver fully on a challenging mandate such as POC. Further, the consequences of POC failures, when many people die, can be devastating. Mission leaders and managers are therefore advised to mobilize, sensitize, convince and ‘lead by example&apos; all peacekeeping and/or United Nations personnel. <strong>Regular field visits are recommended</strong>. Such visits also contribute to ensuring compliance with the strategic approach to POC – i.e., political, proactive, deliberate and integrated.
                  </td>
                </tr>
                <tr><td className="spacer" colSpan="2" /></tr>
                <tr>
                  <td>Communicate the POC vision regularly <br /><br />Listen to mission staff</td>
                  <td>
                    Staff confidence in a mission&apos;s vision, stance and actions are key to POC success. Mission leaders and managers have a unique responsibility to guide, support and provide the necessary feedback required to mobilize, motivate, and frame the mind-set of personnel. While sensitive decisions, particularly at the strategic level, may need to be made in closed circles, that does not prevent mission leaders and managers from <strong>regularly communicating the POC vision </strong>to personnel and partners. It is also essential to <strong>listen to mission staff </strong>and their concerns and recommendations.
                  </td>
                </tr>
                <tr><td className="spacer" colSpan="2" /></tr>
                <tr>
                  <td>Commend efforts and provide feedback</td>
                  <td>
                    Making decisions is part of the leader&apos;s job; a leader should also determine how decisions are received by those working with and for them. To manage expectations, mitigate frustrations or criticism, and create the space or support of personnel and partners to ensure maximum effect, mission leaders should also make sure to <strong>commend efforts and provide feedback </strong>about reports, analysis or recommendations provided. Failing to do so, particularly for transmitted alerts and recommendations, will result in low morale, poor external support and possibly active opposition to decisions and actions.
                  </td>
                </tr>
                <tr><td className="spacer" colSpan="2" /></tr>
                <tr>
                  <td>Empower personnel to innovate and reward initiative</td>
                  <td>
                    Finally, mission leaders and managers should also <strong>empower personnel to innovate and openly commend or reward initiative and exemplary POC actions</strong>. This will result in enhanced motivation and will also create new and more efficient ways of operating. For instance, the establishment of community-based mechanisms and the use of technology can greatly improve the transmission of early warning information and alerts; the use of social media and community radios can help build appropriate lines of messaging and counter negative propaganda campaigns and rumours; and the use of specific international legal mechanisms can affect perpetrators&apos; intent.
                  </td>
                </tr>
              </tbody>
            </table>
            <li className="un-orange">
              Strengthen planning and coordination of operations
            </li>
            <p>
              Mission leadership should see joint planning between the components, at all levels, as a prerequisite for effective POC. Coordination mechanisms and processes should be conceived as ways to set appropriate operational and strategic objectives for the implementation of the mission&apos;s mandate. Mission leaders and managers should therefore ensure that adequate joint mechanisms to coordinate and plan operations are in place and that POC strategic objectives are inserted as a central aspect of broader mission analysis, operations and political engagement.
            </p>
            <p>
              Leaders should consider <strong>addressing silo dynamics</strong>. Currently many missions collect, process and analyse information with a variety of disparate and uncoordinated reporting methodologies and technologies, often on an ad hoc basis. As a result, the situational awareness landscape is a picture of fragmented information streams that are vertically oriented (&apos;stove-piped&apos;) into single-use areas and not shared between mission components and sections. This greatly hinders decision making and makes holistic views of a mission&apos;s environment very difficult, if not impossible.
            </p>
            <div className="relative">
              <img src={planning} alt="UNISFA planning" />
              <div className="description">
                <p>
                  UNISFA personnel conduct a joint planning excercise. (2017)
                </p>
              </div>
              <p className="ref">UN Photo/UNISFA</p>
            </div>
            <p>
              A standardized, comprehensive and integrated approach to situational awareness, such as through mission-wide use of the Unite Aware platform, can remedy the fragmented and &apos;stove-piped&apos; approach to managing critical information flow in missions. Additionally, leaders should consider <strong>streamlining coordination mechanisms</strong>, as mission components and sections often wish to establish and lead their own coordination mechanism on a specific topic or thematic issue. Streamlining will also help avoid dispersion and inadequate attendance, and the resulting lack of decision making on POC. Creating a common situational understanding and enhancing and streamlining coordination requires leveraging the roles and responsibilities of various mission actors. This includes:
            </p>
            <ul className="square un-orange">
              <li>
                <strong>Integrated situational awareness and the coordination of operations </strong>falls under the responsibility of the JOC in most missions. The JOC is available to support the coordination of POC operations in line with an SOP, which describes how different components relate to others on operations coordination and POC early warning, alerts and response. The POC working group or equivalent could therefore become a technical resource focused on non-kinetic POC activities (guidance, training, etc.), and/or provide technical support to the JOC or any other mechanisms responsible for operations coordination on POC.
              </li>
              <li>
                <strong>Integrated threat analysis </strong>falls under the responsibility of the JMAC. The JMAC should be tasked to systematically integrate POC into its threat and risk assessments or analysis. POC Advisers and officers, POC technical groups and other mission components should be encouraged to contribute to integrated analysis through appropriate mission-specific channels, not compete with or duplicate it. Threat and risk assessments must be shared.
              </li>
              <li>
                <strong>Monitoring, investigation and public reporting on violations </strong>of international human rights and humanitarian law falls under the responsibility of the human rights component, in coordination with child protection and women&apos;s protection advisers. Outputs from human rights monitoring, including consolidated data, trend or pattern analysis on violations must be shared and should feed into POC threat assessments, reporting and decision making. Information on threats to civilians or relevant to early warning can and should be shared even before being corroborated/verified.
              </li>
              <li>
                Political affairs and public information sections should always be represented in operations coordination mechanisms to ensure alignment with <strong>political action and public information </strong>under the communications and influence strategy.
              </li>
              <li>
                All mission components must acknowledge the importance of adherence to a <strong>standardized methodology</strong>, and equally how a common terminology, labelling and categorization of threats will enhance common situational understanding related to POC.
              </li>
              <li>
                <strong>Operations coordination meetings should be held regularly </strong>and should not depend on the activation of crisis management mechanisms. This will avoid debates around the threshold of what constitutes a ‘crisis&apos; as well as ‘business as usual&apos; attitudes in the face of looming POC threats.
              </li>
              <li>
                The <strong>individuals leading </strong>operational or tactical planning and coordination are key to POC success. Their skills must be adequate and their roles and responsibilities clear, and they should be supported by all components and partners.
              </li>
              <li>
                <strong>Regular video teleconferencing (VTC) </strong>between mission leaders, managers and field offices (Heads of Offices and field commanders) should be held, with POC analysis and action inserted as a regular agenda item for those discussions.
              </li>
            </ul>
            <br />
            <li className="un-orange">
              Promote civil-police-military coordination
            </li>
            <p>
              In UN peacekeeping, all mission components — civilians, police and military — play a role in the protection of civilians. The military and police have expert knowledge of security issues, and accumulated experience on the management of large groups of personnel, as well as on planning and conducting operations in the field. To work with the military and police components, and obtain results, it is essential to understand their ways and means (tactics, techniques and procedures). It is also essential to sensitize and train the military and police on how to implement the POC mandate and on the role of civilian mission components. Some suggested approaches include:
            </p>
            <ul className="square un-orange">
              <li>
                <strong>Organize overall operational coordination and decision-making mechanisms </strong>around planning, operations and peacekeeping-intelligence/information-management thematic areas, at the strategic, operational and tactical levels. Note that integrated functions under the mission Chief of Staff (COS) are designed the same way: Strategic Planning, JOC and JMAC.
              </li>
              <li>
                <strong>Understand who is who and how they operate. </strong>For POC, the most relevant military officers under the commanders are, Intelligence (G/J/U2), Operations (G/J/U3), Plans (G/J/U5) and CIMIC/Human Rights/Civil Affairs (G/J/U9). They fall under the Deputy Force Chief of Staff for Operations (DCOS OPS). The most relevant police personnel for POC will be those under the Deputy Chief of Operations (Police), the chief of the police operations centre, and the police intelligence chief, if available. The military and police operations leadership are responsible for constantly following situations and threats at hand and coordinating the response on behalf of the military and police leadership.
              </li>
              <li>
                <strong>Respect an etiquette of engagement. </strong>Refrain from telling uniformed personnel ‘how&apos; to conduct an operation, but rather present to them the expected ‘effect&apos; and request, or exchange on, the ‘options available&apos;.
              </li>
              <li>
                <strong>Understand military and police component constraints. </strong>The operational strength of the military or police component is usually limited to the largest unit capable of conducting a coordinated operation in the same area — which would normally be up to a company size (maximum 150 individuals) — and the enablers to support it. Hence, the ability to counter threats will be determined by these constraints.
              </li>
              <li>
                <strong>Stand by troops in difficult times. </strong>Engage commanders to give them a chance to address any concerns and challenges before considering further actions.
              </li>
            </ul>

            <div className="relative">
              <img src={leer} alt="UNMISS SRSG visits Leer and Thonyor" />
              <div className="description">
                <p>
                  UNMISS’s SRSG visits a field office and meets with personnel. (2017)
                </p>
              </div>
              <p className="ref">UN Photo/UNMISS</p>
            </div>

            <ul className="square un-orange">
              <li>
                <strong>Sensitize and support </strong>military and police colleagues, on the need to:
                <ul className="triangle un-orange">
                  <li>
                    Ensure coordinated military-police security action in support of the POC political strategy.
                  </li>
                  <li>
                    Understand the strategic cost of civilian harm caused by the mission and address it as a priority. Move from an enemy-centred approach to planning to a people-centred approach and ensure broad and integrated planning of POC and security operations (for example by including state security forces in JOC and POC working groups, where appropriate). Ensure integrated analysis for POC and security threats, particularly with JMAC and the POC Adviser and POC working group.
                  </li>
                  <li>
                    Retrieve external information through engagement or in close coordination with civilian components, respecting leads inscribed in a liaison matrix, particularly for communication with national authorities, communities and humanitarian actors.
                  </li>
                  <li>
                    Engage civilian components in planning responses to POC threats, including their understanding of the perpetrators and populations at risk and potential consequences of any actions by uniformed components.
                  </li>
                  <li>
                    Understand they do not own the POC information set and need to share information on POC and security with civilian components.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Promote civil-police-military coordination forums </strong>and ensure coherence between the mission and host state POC priorities and country-specific guidelines and strategies of the Humanitarian Country Team and Protection Cluster. This may involve developing SOPs on coordination and cooperation between peacekeepers and humanitarian actors, which support overarching protection principles inscribed in international human rights and humanitarian law, including for the sharing of alert and early warning information and the protection of victims and witnesses.
              </li>
            </ul>
          </ol>
        </>
      )
    },
    {
      index: '4.3',
      name: 'Resource Management and Capabilities',
      title: t('handbook.chapters.chapter4.3.title'),
      content: (
        <>
          <p>
            Some capabilities and resources are of particular importance to ensure the success of the POC mandate. They include military capabilities and human resources, but also programmatic activities and funds, geared to meet POC objectives.
          </p>
          <p>
            <strong>Strategic capabilities </strong>are required to gather information and ensure physical protection through projection and deterrence. They include:
          </p>
          <div className="odd-even">
            <div>
              Projection assets including <strong>mobility assets</strong> to access difficult terrains — helicopters and off-road vehicles as a priority — and enablers to enhance access (<strong>engineering capabilities</strong>)
            </div>
            <div>
              Deterrence assets including <strong>combat-ready troops and formed police units (FPUs)</strong>, particularly special forces, Quick Reaction Forces or special weapons and tactics (SWAT) teams
            </div>
            <div>
              Strategic peacekeeping-intelligence and information collection capabilities, including <strong>UAVs or communications surveillance capabilities</strong>
            </div>
          </div>
          <p>
            Beyond capabilities, troop or police <strong>profile and posture </strong>can have immense protective effects, including through presence in strategic areas, use of night operations and day and night vehicle and foot patrolling.
          </p>
          <p>
            <strong>Operational and tactical capabilities and resources </strong>will contribute to operational planning and coordination, information collection, early warning and rapid response. All require dedicated capacity, funds and equipment.
          </p>
          <ul className="square un-orange">
            <li>
              POC operations will not benefit from capacity within components and sections if they are not properly coordinated for mutual reinforcement. The creation of a <strong>civilian position to overview planning, analysis and coordination under the mission COS (similar to the DCOS OPS for the military or the Deputy Chief of Operations for the police) </strong>may greatly enhance operational unity of vision and action.
            </li>
            <li>
              Dedicated <strong>human resources under the JOC, POC Unit and Strategic Planning Unit (SPU) </strong>(see below) <strong>and Heads of Offices </strong>are necessary to ensure operational planning and coordination.
            </li>
            <li>
              Staff and programmes can focus on <strong>assessing community perceptions </strong>by monitoring community radios and conducting community perception surveys.
            </li>
            <li>
              <strong>Gender, Women&apos;s Protection and Child Protection Advisers and Officers, </strong>including focal points within the military and police, ensure gender and child protection mainstreaming.
            </li>
            <li>
              <strong>Community Liaison Assistants, community outreach programmes and Community Alert Networks </strong>all contribute to community engagement and sensitization and enable the establishment of alert networks for early warning and response.
            </li>
            <li>
              <strong>Rapid response mobile teams </strong>provide a range of skillsets to ensure rapid prevention and response to POC threats, support assistance to victims and fight impunity for criminal violence and human rights violations.
            </li>
            <li>
              <strong>Joint Protection Teams and Joint Assessment Missions </strong>(conducted with humanitarians) inform threat and risk assessments and POC planning.
            </li>
            <li>
              <strong>Joint Investigation Teams </strong>(including with host state authorities), <strong>Prosecution Support Cells </strong>and programmes to support national authorities and justice sector institutions, including <strong>mobile courts</strong>, support the rule of law and accountability for violence against civilians.
            </li>
          </ul>
          <p>
            <strong>Other programmatic activities </strong>directly contribute to POC, including:
          </p>
          <ul className="square un-orange">
            <li>
              <strong>Victim and witness protection programmes</strong>, which are generally managed by the human rights and police components, in support of the host state, and
            </li>
            <li>
              Stabilization funds, particularly for rapid stabilization activities, including <strong>dialogue and conflict resolution, community violence reduction (CVR) and Disarmament, Demobilization and Reintegration (DDR) </strong>and <strong>depollution of explosive remnants of war (ERWs) and demining</strong>.
            </li>
          </ul>
          <p className="bold">
            Training Resources
          </p>
          <p>
            Capabilities and resources for integrated training and exercises on POC are required, covering all peacekeeping personnel. Mission leaders and managers are advised to prioritize POC training and allocate the necessary resources to enable Integrated Mission Training Centres (IMTC) to perform their role in empowering all UN staff members with the right knowledge of concepts, priorities, principles and relevant tasks, roles and responsibilities for POC. Missions should coordinate training needs with Headquarters and request deployment of mobile training teams (MTTs), as needed, to increase training capacities on POC in-mission.
          </p>
        </>
      )
    },
    {
      index: '4.4',
      name: 'Coherence/Coordination With Other UN Entities',
      title: t('handbook.chapters.chapter4.4.title'),
      content: (
        <>
          <p>
            Peacekeeping missions are often established in countries subject to other UN mandates related to protection. In addition to all the other UN protection actors operating on the ground (see chapter 6), several UN mechanisms/entities operate externally, potentially visiting the country or region on mission. These can include Security Council sanctions regimes, human rights special procedures, commissions of inquiry (whether mandated by the Security Council, Human Rights Council or General Assembly) and accountability mechanisms. In some contexts, the International Criminal Court (ICC) is also active<sup>13</sup>. Peacekeeping operations may be mandated to support the work of such entities.
          </p>
          <p>
            None of these mechanisms operates in a vacuum. The measures are most effective at maintaining or restoring international peace and security, promoting and protecting human rights and ensuring accountability for violations of international law when applied as part of a comprehensive strategy. Therefore, peacekeeping operations must consider how best to coordinate with such mechanisms and ensure coherence, while pursuing distinct objectives. Much of the work of these mechanisms will overlap with peacekeeping mandated activities, including in areas related to the protection of civilians. Further, many of the mechanisms receive large international media coverage when releasing statements and reports. These can impact peacekeeping operations on the ground, which, as the largest and most visible UN presence in a country, are often misconstrued as responsible.
          </p>
          <p>
            Security Council sanctions regimes, in particular, must be implemented in coherence with the overall strategy of the Security Council, including the mandates of peace operations. Sanctions that focus on specific governmental entities and representatives or non-state actors — including armed groups and their military leadership, political sponsors, supply networks and financers — are often used as leverage in ceasefire discussions, peace mediations and political transitions. However, sanctions can also have unintended consequences and complicate negotiations by creating additional obstacles. Moreover, while the Security Council applies sanctions with ever-increasing cognizance of the rights of those targeted, there are still human rights concerns related to their imposition and impact.
          </p>
          <p>
            To ensure the necessary coherence, mission leadership should consult with the relevant mechanisms regularly and request mission managers to cooperate actively with entities involved with the mechanisms, including specifically in the identification of individuals and entities responsible for violence against civilians, in accordance with relevant information-sharing protocols.
          </p>
        </>
      )
    },
    {
      index: '4.5',
      name: 'Placement and Right-sizing of the POC Unit',
      title: t('handbook.chapters.chapter4.5.title'),
      content: (
        <>
          <p>
            The <em>DPKO and DFS Comparative study and toolkit on Coordination Mechanisms in Peacekeeping Missions</em>, published in 2012, provided the first formal assessment and overview of the role of POC Coordinators. The DPO POC Policy calls for the establishment of planning and coordination forums at the strategic, operational and tactical levels and provides for the deployment and appointment of POC Advisers and focal points to support the mission leadership in coordinating all POC activities, in support of those coordination mechanisms (see Annex I for TORs for Mission Senior POC Advisers).
          </p>
          <p>
            Over the years, evaluations and mission practice have both confirmed that <strong className="un-orange">POC Advisers should lead small independent teams. </strong>Embedding POC within another section has been found to be sub-optimal. Placement with mission leadership ensures connection with information and decision making. POC Advisers cannot function effectively as ‘stand-alone&apos; individuals at mission headquarters but require a small number of personnel in key locations. Field offices where POC Officers are based possess a strong advantage compared to those without dedicated POC presence.
          </p>
          <p>
            The size of the team supporting the POC Adviser must be tailored to the scale and tempo of POC threats and operations in a mission. Surge capacities may be obtained from other sections or UN Headquarters when required. The Secretary-General&apos;s report on implementation of the High-level Independent Panel on Peace Operations (HIPPO) recommended that &apos;all peace operations with a protection of civilians mandate should have a senior protection of civilians adviser in the Office of the Special Representative of the Secretary-General to coordinate the development of mission-wide strategies and guidance for all components, in liaison with relevant protection actors, including the humanitarian protection cluster.&apos;<sup>14</sup>
          </p>
          <p className="bold">
            Where to place the POC unit?
          </p>
          <p>
            Placement under the SRSG will secure neutral positioning for the POC unit vis-à-vis the two mission pillars, as well as proximity to high-level decision making. This includes proximity to decision makers related to the military and police and other key integrated functions and units under the mission COS — JOC, JMAC and Strategic Planning. The SRSG may, however, designate a DSRSG as POC lead to guide daily POC mandate implementation and coordination mechanisms. Such a POC lead would become the POC Adviser&apos;s first reporting officer.
          </p>
        </>
      )
    }
  ],
  footnotes: {
    content: (
      <ol type="1" start="12" className="refs">
        <li className="4-1-4">
          Special measures for protection from sexual exploitation and abuse (ST/SGB/2003/13).
        </li>
        <li className="4-4">
          The ICC is not a part of the United Nations; relations between the UN and the ICC are governed by a Memorandum of Understanding.
        </li>
        <li className="4-5">A/70/357-S/2015/682.</li>
      </ol>
    )
  }
};
