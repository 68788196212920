/* eslint-disable no-unused-vars */
import React, { useMemo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import actions from '../../redux/actions';
import resources from '../../templates/resources';
import menuElipsis from '../../images/design/menu_elipsis.svg';
import menuElipsisGrey from '../../images/design/menu_elipsis-grey.svg';
import handbook from '../../images/design/handbook-grey.svg';
import textIcon from '../../images/design/text-only.svg';

const Resource = ({ cache, updateCache, location }) => {
  const refs = useRef([]);
  const container = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const [textOnly, setTextOnly] = useState(false);
  const filterRef = useRef();

  const resource = useMemo(() => {
    if (!cache.resource) return null;
    refs.current = [];
    return resources.find((t) => t.name === cache.resource);
  }, [cache.resource]);

  useEffect(() => {
    // Scroll to the top if we are going to recommendations
    if (cache.tab === 'resource') {
      container.current.scrollTo(0, 0);
    }
  }, [cache.tab]);

  const getVisible = () => {
    // Get the first visible section if one exists
    const visible = refs.current.find(({ r }) => {
      // Check if display is none and if it is ignore it
      if (window.getComputedStyle(r).display === 'none') return false;

      const { top } = r.getBoundingClientRect();
      const { height } = container.current.getBoundingClientRect();

      return top >= 0 && top <= height;
    });

    const { height: innerHeight, top: containerTop } = container.current.getBoundingClientRect();

    const allVisible = refs.current.reduce((all, ref) => { // eslint-disable-line
      const { top, height: refHeight } = ref.r.getBoundingClientRect();
      const bottom = (top + refHeight) - containerTop;

      if ((top >= 0 || bottom >= 0) && top <= innerHeight) {
        all.push(ref);
      }

      return all;
    }, []);

    if (!visible) return;

    // Only update the visible when a top is visible
    updateCache({ visible: visible.section.name });
  };

  useEffect(() => {
    container.current.addEventListener('scroll', getVisible);
  }, [location]);

  return (
    <>
      <header className={resource.color}>
        {cache.keywords ? (
          <div className="search-back">
            <a href={`/search?keywords=${cache.keywords}`}><span>&lt;</span> Back to Search Results</a>
          </div>
        ) : null}
        <div>
          <div>
            <h1>{ resource.name }</h1>
          </div>
          <div className="desktop-options">
            <button
              type="button"
              onClick={() => setTextOnly((t) => !t)}
            >
              <img src={textIcon} alt="text only icon" />
              <div>{ textOnly ? 'All Content' : 'Text Only' }</div>
            </button>
          </div>
          <div ref={filterRef} className="mobile-options">
            <button type="button" onClick={() => setShowMenu((s) => !s)}>
              <img src={showMenu ? menuElipsis : menuElipsisGrey} alt="show menu" />
            </button>
            { showMenu && (
              <div className="submenu">
                <button
                  type="button"
                  onClick={() => {
                    setShowMenu(false);
                    updateCache({ showSidebar: !cache.showSidebar });
                  }}
                >
                  <img src={handbook} alt="handbook icon" /> Handbook Contents
                </button>
                <button
                  type="button"
                  onClick={() => setTextOnly((t) => !t)}
                >
                  <img src={textIcon} alt="text only icon" />
                  { textOnly ? 'All Content' : 'Text Only' }
                </button>
              </div>
            ) }
          </div>
        </div>
      </header>
      <div
        className={cn('resource', resource.color, 'scroller', { 'text-only': textOnly, 'search-back': cache.keywords?.length > 0 })}
        ref={container}
      >
        {resource && resource.content && resource.content.map((section, i) => (
          <div
            key={section.index}
            className={`section ${section.index.replace('.', '-')}`}
            data-name={section.name}
            ref={(r) => {
              // the refs are set to null on elements that are being removed
              // we want to ignore those
              if ((refs.current[i] && refs.current[i].r) || !r) return;
              refs.current[i] = { r, section };
            }}
          >
            <div className="track">
              <div className="title" id={section.index}>
                <h3>{ section.name }</h3>
              </div>
            </div>
            <div className="main" id={section.index}>
              <h4><span className={cn(section.color)}>{ section.index } </span>{ section.name }</h4>
              { section.content }
            </div>
          </div>
        ))}

        { resource.pre_content != null ? (
          <div className="main">
            { resource.pre_content }
          </div>
        ) : null }

        { resource && resource.sections && resource.sections.map((section, i) => (
          <div
            key={section.index}
            className={`section ${section.index.replace('.', '-')}`}
            data-name={section.name}
            ref={(r) => {
              // the refs are set to null on elements that are being removed
              // we want to ignore those
              if ((refs.current[i] && refs.current[i].r) || !r) return;
              refs.current[i] = { r, section };
            }}
          >
            <div className="track">
              <div className="title" id={section.index}>
                <h3>{ section.name }</h3>
              </div>
            </div>
            <div className="main">
              { section.content }
            </div>
          </div>
        )) }
      </div>
    </>
  );
};
Resource.propTypes = {
  cache: PropTypes.shape({
    chapter: PropTypes.string,
    resource: PropTypes.string,
    visible: PropTypes.string,
    area: PropTypes.string,
    role: PropTypes.string,
    progress: PropTypes.object, // eslint-disable-line
    showSidebar: PropTypes.bool,
    tab: PropTypes.string,
    keywords: PropTypes.string
  }).isRequired,
  updateCache: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps, {
  updateCache: actions.updateCache
})(Resource);
