const areas = [
  { label: 'Leadership', value: 'leadership' },
  { label: 'Planning', value: 'planning' },
  { label: 'Communications', value: 'communications' },
  { label: 'General', value: 'none' }
];

export default {
  civilian: {
    areas,
    label: 'Civilian'
  },
  police: {
    areas,
    label: 'Police'
  },
  military: {
    areas,
    label: 'Military'
  },
  'non-member': {
    areas,
    label: 'Unaffiliated'
  }
};
