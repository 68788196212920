import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from '../redux/actions';
import CookieBanner from './cookie';
import logo from '../images/design/header-logo.png';
import { ReactComponent as About } from '../images/design/about.svg';
import { ReactComponent as Handbook } from '../images/design/handbook.svg';
import { ReactComponent as Search } from '../images/design/search.svg';
import { ReactComponent as Profile } from '../images/design/profile.svg';
import { ReactComponent as Menu } from '../images/design/menu.svg';

const Wrapper = ({ children, page, updateCache }) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="page-wrapper">
      <header>
        <div className="brand">
          <a href="https://peacekeeping.un.org/en/protection-of-civilians-mandate"><img src={logo} alt="UN logo" /></a>
          <span>
            The Protection of Civilians in United
            Nations Peacekeeping Handbook
          </span>
        </div>
        <nav>
          <div className={cn({ show: showMenu })}>
            <Link to="/about" className={cn({ active: page === 'about' })} onClick={() => updateCache({ keywords: null })}>
              <div><About /></div>
              <span>About</span>
            </Link>
            <Link to="/" className={cn({ active: page === 'handbook' })} onClick={() => updateCache({ keywords: null })}>
              <div><Handbook /></div>
              <span>Handbook</span>
            </Link>
            <Link to="/search" className={cn({ active: page === 'search' })} onClick={() => updateCache({ keywords: null })}>
              <div><Search /></div>
              <span>Search</span>
            </Link>
            <Link to="/profile" className={cn({ active: page === 'profile' })} onClick={() => updateCache({ keywords: null })}>
              <div><Profile /></div>
              <span>Profile</span>
            </Link>
          </div>
          <button
            type="button"
            onClick={() => setShowMenu((ssm) => !ssm)}
          >
            { showMenu ? <span>Close Menu</span> : <Menu /> }
          </button>
        </nav>
      </header>
      <div className="page-container">
        {children}
      </div>
      <CookieBanner />
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]).isRequired,
  page: PropTypes.string,
  updateCache: PropTypes.func.isRequired
};

Wrapper.defaultProps = {
  page: null
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps, {
  updateCache: actions.updateCache
})(Wrapper);
