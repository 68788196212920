import templates from './templates';
import config, { recommendedChapters, isVisible } from './config/access';

/**
 * Gets the current chapter progress from the cache
 * @param chapt - the current chapter name
 * @param cache - the cache from redux
 * @returns {number} - the percentage complete
 */
export const chapterProgress = (chapt, cache) => {
  const { sections } = templates.find((c) => c.name === chapt);
  if (!sections) return 0;

  // Filter out hidden
  const filtered = sections.filter(
    (section) => isVisible(cache.role, cache.area, section.index)
  );

  if (!filtered || !filtered.length) return 0;

  const target = filtered.length * 100;

  return (filtered.reduce((total, section) => {
    const progress = (cache.progress && cache.progress[section.index]) || 0;
    return total + progress;
  }, 0) / target) * 100;
};

/**
 * Gets all chapters based on the area and role passed
 * @param {Array} data - a full list of templates from the book
 * @param {String} area - the user area (set at onboarding or profile)
 * @param {String} role - the user role (set at onboarding or profile)
 * @returns {Array} an array of chapters
 */
export const getChaptersByPersona = (data, area, role) => {
  const rules = recommendedChapters(role, area);
  if (!rules) return data;

  // Filter out all chapters that are not available
  return data.filter(
    (template) => rules.indexOf(template.index) > -1
      || (template.annex && rules.indexOf(`annex-${template.annex}`) > -1)
  );
};

/**
 * Filters out all sections that are not shown for this persona
 * @param {Array} data - a full list of templates from the book
 * @param {String} area - the user area (set at onboarding or profile)
 * @param {String} role - the user role (set at onboarding or profile)
 * @returns {Array} an array of chapters
 */
export const filterSectionsByPersona = (data, area, role) => {
  const rules = config[`${role}-${area}`];
  if (!rules) return data;

  return data.map((item) => {
    if (!item.sections) return item;
    const sections = item.sections.filter((section) => {
      const match = section.index.match('^[0-9]+');

      function check(result) {
        return result.section === match || result.section === section.index.replace('.', '-');
      }
      // Check if this in a chapter that is shown or has an exact match
      if (rules.some(check)) return true;
      return false;
    });
    return { ...item, sections };
  });
};

export const getChapterIndexFromName = (name) => {
  const chapter = templates.find((t) => t.name === name);

  if (chapter && chapter.index) {
    return `chap-${chapter.index}`;
  }

  if (chapter && chapter.annex) {
    return `annex-${chapter.annex}`;
  }

  return 'no-index-available';
};
