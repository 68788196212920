import React from 'react';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Annex I: TORs for Mission Senior POC Advisers',
  title: t('handbook.annexes.i.title'),
  annex: '1',
  color: 'un-orange',
  content: (
    <div className="main-content">
      <u>Responsibilities</u>
      <p>
        Within the delegated authority, the Senior Protection of Civilians
        Adviser will perform the following roles and duties:
      </p>
      <h5>Advisory:</h5>
      <ul>
        <li>
          Ensure that senior Mission leadership is regularly updated on
          current and emerging threats against civilians;
        </li>
        <li>
          Ensure that a comprehensive approach is adopted on all matters pertaining to Protection of Civilians (POC) in order to effectively leverage resources within the Mission for POC objectives as defined in the Mission-wide POC strategy;
        </li>
        <li>
          Provide advice to the senior Mission leadership on ways in which the Mission can assist and build the capacity of the National Authorities to fulfil their POC responsibilities;
        </li>
        <li>
          Provide support to relevant components and sections, including military and police, to ensure that POC concerns are adequately reflected in Mission operations, particularly as concerns civilian-military planning processes; and
        </li>
        <li>
          Ensure overall compliance of the Mission’s POC efforts with relevant United Nations or DPKO and DFS policies and guidelines.
        </li>
      </ul>
      <h5>Coordination and Communication:</h5>
      <ul>
        <li>
          Oversee the development and implementation of the Mission-wide POC strategy and related in-Mission guidance and operational plans;
        </li>
        <li>
          Support senior Mission leadership in establishing sustainable coordination structures in order to strengthen cooperation with relevant protection actors, particularly civil society, local communities and the Government authorities at the national and local levels;
        </li>
        <li>
          Ensure close coordination with relevant POC partners in the development and implementation of the strategy, both civilian and uniformed personnel, particularly colleagues working on child protection, sexual violence, as well as humanitarian colleagues, focal points for human rights, civil affairs, etc.;
        </li>
        <li>Provide POC inputs to Mission planning and performance systems;</li>
        <li>
          Provide Secretariat support to Mission POC coordination structures;
        </li>
        <li>
          Coordinate and facilitate information sharing with the Protection Cluster, other security actors that may be present in the Mission area, and other such arrangements;
        </li>
        <li>
          Assist in the development of an effective POC communications strategy, in close coordination with the public information office and the Office of the Humanitarian Coordinator; and
        </li>
        <li>
          Provide support as required and undertake other tasks as may be appropriate to the specific context and tasks vis-à-vis PoC.
        </li>
      </ul>
      <h5>Monitoring, Analysis and Reporting:</h5>
      <ul>
        <li>
          Coordinate the establishment/monitoring of early warning tools and processes, including through community liaison and alert systems;
        </li>
        <li>
          Lead or join joint POC assessments with other relevant actors; and
        </li>
        <li>
          Work with the relevant staff in the Mission to conduct and support after action reviews and lessons learned studies on the Mission’s POC efforts.
        </li>
      </ul>
      <h5>Training:</h5>
      <ul>
        <li>
          Assess the training needs on POC issues in the area of
          responsibility, and design and support the delivery of
          tailored POC modules with relevant Mission components,
          including military and police, in close consultation with
          the IMTC and the POC Coordination Team in DPKO/DFS; and
        </li>
        <li>
          In cooperation with the IMTC and other Mission components,
          ensure adequate training and sensitization on POC for local
          communities, humanitarian, development or security partners
          and Governmental institutions.
        </li>
      </ul>
      <h5>Management:</h5>
      <ul>
        <li>
          Act as the first or second reporting officer for and
          coordinate the actions of all staff dedicated to POC
          coordination within the Mission.
        </li>
      </ul>
      <h5>Monitoring, Analysis and Reporting:</h5>
      <ul>
        <li>
          Coordinate the establishment/monitoring of early warning tools and processes, including through community liaison and alert systems;
        </li>
        <li>
          Lead or join joint POC assessments with other relevant actors; and
        </li>
        <li>
          Work with the relevant staff in the Mission to conduct and support after action reviews and lessons learned studies on the Mission’s POC efforts.
        </li>
      </ul>
      <u>Competencies</u>
      <p>
        <strong>Professionalism: </strong>Shows pride in work
        and in achievements; Demonstrates professional competence
        and mastery of subject matter; Is conscientious and efficient
        in meeting commitments, observing deadlines and achieving
        results; Is motivated by professional rather than personal
        concerns; Shows persistence when faced with difficult problems
        or challenges; Remains calm in stressful situations; Takes
        responsibility for incorporating gender perspectives and
        ensuring the equal participation of women and men in all areas
        of work.
      </p>
      <p>
        <strong>Teamwork: </strong>Works collaboratively with
        colleagues to achieve organizational goals; Solicits input
        by genuinely valuing others&apos; ideas and expertise; is willing
        to learn from others; Places team agenda before personal agenda;
        Supports and acts in accordance with final group decision, even
        when such decisions may not entirely reflect own position; Shares
        credit for team accomplishments and accepts joint responsibility
        for team shortcomings.
      </p>
      <p>
        <strong>Planning and Organizing: </strong>Develops clear goals
        that are consistent with agreed strategies; Identifies priority
        activities and assignments; Adjusts priorities as required;
        Allocates appropriate amount of time and resources for completing
        work; Foresees risks and allows for contingencies when planning;
        Monitors and adjusts plans and actions as necessary; Uses time
        efficiently.
      </p>
      <p>
        <strong>Leadership: </strong>Serves as a role model that other
        people want to follow; Empowers others to translate vision into
        results; Is proactive in developing strategies to accomplish
        objectives; Establishes and maintains relationships with a broad
        range of people to understand needs and gain support; Anticipates
        and resolves conflicts by pursuing mutually agreeable solutions; Drives
        for change and improvement, does not accept the status quo; Shows the
        courage to take unpopular stands; Provides leadership and takes
        responsibility for incorporating gender perspectives and ensuring
        the equal participation of women and men in all areas of work;
        demonstrates knowledge of strategies and commitment to the goal of
        gender balance in staffing.
      </p>
      <p>
        <strong>Managing Performance: </strong>Delegates the appropriate
        responsibility, accountability and decision-making authority;
        Makes sure that roles, responsibilities and reporting lines are
        clear to each staff member; Accurately judges the amount of time
        and resources needed to accomplish a task and matches task to
        skills; Monitors progress against milestones and deadlines;
        Regularly discusses performance and provides feedback and coaching
        to staff; Encourages risk-taking and supports creativity and
        initiative; Actively supports development.
      </p>
    </div>
  )
};
