import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import edit from '../../images/design/edit.svg';
import GridChoice from '../../onboarding/stages/gridChoice';
import roles from '../../config/roles';
import actions from '../../redux/actions';

const Header = ({ cache, updateCache }) => {
  const [showRole, setShowRole] = useState(false);
  const [stage, setStage] = useState('role');
  const toastOptions = {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const roleKeys = Object.keys(roles);
  const roleOptions = roleKeys.map((k) => ({ label: roles[k].label, value: k }));
  return (
    <>
      <header>
        <div className="role">
          <h2>{ cache.areaLabel }</h2>
          <h4>{ cache.roleLabel }</h4>
        </div>
        <button type="button" onClick={() => setShowRole(true)}>
          <img src={edit} alt="edit icon" />
          Change Role
        </button>
        <ToastContainer
          position="top-right"
          autoClose={300}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </header>
      { showRole && (
        <div className="change-role">
          <div className="window">
            { stage === 'role' && (
              <GridChoice
                next={() => setStage('area')}
                value={cache.role}
                onChange={(role) => {
                  const roleLabel = roleOptions.filter((r) => r.value === role);
                  if (roleLabel.length > 0) {
                    updateCache({ role, roleLabel: roleLabel[0].label });
                  }
                }}
                title="Change your role in peacekeeping"
                description="Changing your role will result in different recommended chapters. Your reading progress will not be lost."
                options={roleOptions}
                onClose={() => setShowRole(false)}
              />
            ) }
            { stage === 'area' && (
              <GridChoice
                next={() => {
                  setShowRole(false);
                  setStage('role');
                  toast.info('Your role has now been updated', toastOptions);
                }}
                value={cache.area}
                onChange={(area) => {
                  const areaLabel = roles[cache.role].areas.filter((r) => r.value === area);
                  if (areaLabel.length > 0) {
                    updateCache({ area, areaLabel: areaLabel[0].label });
                  }
                }}
                title="Change the area of your role in peacekeeping"
                description="Changing your role will result in different recommended chapters. Your reading progress will not be lost."
                options={cache.role && roles[cache.role].areas}
                prev={() => setStage('role')}
                onClose={() => {
                  setShowRole(false);
                  setStage('role');
                }}
              />
            ) }
          </div>
        </div>
      ) }
    </>
  );
};

Header.propTypes = {
  cache: PropTypes.shape({
    area: PropTypes.string,
    role: PropTypes.string,
    roleLabel: PropTypes.string,
    areaLabel: PropTypes.string
  }).isRequired,
  updateCache: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps, { updateCache: actions.updateCache })(Header);
