/* eslint-disable */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import cn from 'classnames';
import arrow from '../../images/design/filter-arrow.svg';
import templates from '../../templates';
import { chapterProgress } from '../../helpers';
import { getChaptersByPersona } from '../../helpers';
import { t } from '../../services/i18n';

const Progress = ({ cache, active }) => {
  const [filter, setFilter] = useState('recommended');
  const [showFilters, setShowFilters] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const filterRef = useRef();

  const data = useMemo(() => {
    const chapters = templates.filter((template) => template.sections);

    if (filter  === 'all') return chapters;
    return getChaptersByPersona(chapters, cache.area, cache.role);
  }, [filter]);

  useEffect(() => {
    const handleClick = (e) => {
      if (filterRef.current && !filterRef.current.contains(e.target)) {
        setShowFilters(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  if (redirect) return <Redirect to={redirect} />

  return (
    <div className={cn('progress', { active: active === 'progress' })}>
      <div className="hero">
        <div>
          <h3>{t('profile.tabs.progress.title')}</h3>
          <h4>{t('profile.tabs.progress.subheading')}</h4>
        </div>
      </div>
      <ul>
        { data.map((template) => {
          const progress = Math.round(chapterProgress(template.name, cache));
          return (
            <li
              className={template.color}
              key={template.name}
              role="button"
              tabIndex={0}
              onClick={() => setRedirect(`/?chapter=${escape(template.name)}`)}
            >
              <span>{ template.name }</span>
              <span>{ progress }%</span>
              <div className="progress" style={{ width: `${progress}%` }} />
            </li>
          );
        }) }
      </ul>
    </div>
  )
};

Progress.propTypes = {
  cache: PropTypes.shape({
    progress: PropTypes.object // eslint-disable-line
  }).isRequired,
  active: PropTypes.string
};

Progress.defaultProps = {
  active: ''
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps)(Progress);
