import React from 'react';
import capacityBuilding from '../images/chapters/chapter2/MINUSTAH_capacity_building_of_state_forces.png';
import patrol from '../images/chapters/chapter2/MONUSCO_patrol.jpg';
import disarmament from '../images/chapters/chapter2/UNMIL_disarmament_and_rehabilitation.jpg';
import meeting from '../images/chapters/chapter2/UNMIS_UNMIS_SLA_Combatants_attend_meeting_of_head_of_UNMIS.jpg';
import gates from '../images/chapters/chapter2/UNMISS_opening_the_gates.jpg';
import tierI from '../images/chapters/chapter2/UNMISS_tier_i.jpg';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'POC Fundamentals',
  title: t('handbook.poc_fundamentals.title'),
  index: '0',
  color: 'un-blue',
  sections: [
    {
      index: '0.1',
      name: 'POC in UN Peacekeeping',
      title: t('handbook.chapters.chapter1.2.title'),
      content: (
        <>
          <p>
            Since the first POC-mandated peacekeeping mission, the United Nations Assistance Mission in Sierra Leone (UNAMSIL) in 1999<sup>5</sup>, UN peacekeeping operations have been confronted with a broad range of threats to civilians and increasingly complex operational environments. As the nature of peacekeeping has evolved, so has the POC mandate and the tasks, activities, roles and responsibilities necessary to implement it. At the same time, the high expectations on missions to protect civilians present risks to the UN&apos;s credibility where these expectations are not met or managed effectively.
          </p>
          <div className="half">
            <div>
              <p>
                Initially faced with limited standardized guidance, specialized staff or dedicated tools for the protection of civilians, UN peacekeeping operations progressively developed their own approaches to POC — strategies, capacities, mechanisms and tools. Much of this has since been captured in policy, guidance and training, and is continuously developed and built upon through ongoing sharing of best practices and lessons learned. A continual learning process that captures mission-specific POC challenges, lessons learned and good practices is required to adapt to changing environments.
              </p>
              <p>
                Contemporary approaches to POC involve and rely on many other aspects of mission planning and other mandated activities. In particular, POC requires strategic political engagement and proactive and preventive action and relies upon effective integrated planning and coordination including with the host state, humanitarians, civil society and the affected communities. This contributes to ensuring a comprehensive approach.
              </p>
            </div>
            <div>
              <table className="striped bold">
                <thead>
                  <tr>
                    <th colSpan="2">UN Peacekeeping Operations with POC Mandates</th>
                  </tr>
                </thead>
                <tbody className="second-aligned-right">
                  <tr>
                    <td>UNAMSIL (Sierra Leone)</td>
                    <td>1999 - 2005</td>
                  </tr>
                  <tr>
                    <td>MONUC (DRC)</td>
                    <td>1999 - 2010</td>
                  </tr>
                  <tr>
                    <td>UNMIL (Liberia)</td>
                    <td>2003 - 2018</td>
                  </tr>
                  <tr>
                    <td>ONUB (Burundi)</td>
                    <td>2004 - 2006</td>
                  </tr>
                  <tr>
                    <td>UNOCI (Côte d’Ivoire)</td>
                    <td>2004 - 2017</td>
                  </tr>
                  <tr>
                    <td>MINUSTAH (Haiti)</td>
                    <td>2004 - 2017</td>
                  </tr>
                  <tr>
                    <td>UNMIS (Sudan)</td>
                    <td>2005 - 2011</td>
                  </tr>
                  <tr>
                    <td>UNIFIL (Lebanon)</td>
                    <td>2006 -</td>
                  </tr>
                  <tr>
                    <td>UNAMID (Darfur)</td>
                    <td>2007 -</td>
                  </tr>
                  <tr>
                    <td>MINURCAT (Chad)</td>
                    <td>2009 - 2010</td>
                  </tr>
                  <tr>
                    <td>MONUSCO (DRC)</td>
                    <td>2010 -</td>
                  </tr>
                  <tr>
                    <td>UNMISS (South Sudan)</td>
                    <td>2011 -</td>
                  </tr>
                  <tr>
                    <td>UNISFA (Abyei)</td>
                    <td>2011 -</td>
                  </tr>
                  <tr>
                    <td>MINUSMA (Mali)</td>
                    <td>2013 -</td>
                  </tr>
                  <tr>
                    <td>MINUSCA (CAR)</td>
                    <td>2014 -</td>
                  </tr>
                  <tr>
                    <td>MINUJUSTH (Haiti)</td>
                    <td>2017 - 2019</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className="un-blue bold">
            The protection of civilians mandate in UN peacekeeping is defined as: &apos;without prejudice to the primary responsibility of the host state, integrated and coordinated activities by all civilian and uniformed mission components to prevent, deter or respond to threats of physical violence against civilians, within the mission&apos;s capabilities and areas of deployment, through the use of all necessary means, up to and including deadly force.&apos;
          </p>
        </>
      )
    },
    {
      index: '0.2',
      name: 'The Scope of the POC Mandate in UN Peacekeeping',
      title: t('handbook.poc_fundamentals.2-1.title'),
      content: (
        <>
          <div className="sub-section 0-2-1-1">
            <h5>
              <span className="un-green">2.1.1</span> Interpreting mandate
              language
            </h5>
            <table className="boxed-table un-green">
              <tbody>
                <tr>
                  <td>
                    <span className="border un-green">
                      ‘Imminent&apos; threats
                    </span>
                  </td>
                  <td>
                    Early mandate language on POC required the protection of
                    civilians under ‘imminent threat of physical violence.&apos;
                    In most multidimensional missions, the word ‘imminent&apos;
                    has since been removed and the mandate now requires the
                    protection of civilians under ‘threat of physical
                    violence.&apos; This language change clarified that a
                    mission can and should take proactive and preventive action
                    to protect civilians under threat and that these actions can
                    include all necessary means (including the use of deadly
                    force). Those missions which still have ‘imminent&apos;
                    threat within the POC mandate language are still expected to
                    take a proactive and preventive approach to POC, but short
                    of using force when the threat is not imminent.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-green">
                      In support of the host state
                    </span>
                  </td>
                  <td>
                    As the host state always bears the primary responsibility to
                    protect civilians, most country-specific mandates include
                    explicit language that the protection of civilians is
                    without prejudice to the primary responsibility of the host
                    state, so that the mission is not expected to substitute for
                    the role of the host state in protecting the population.
                    There may also be language in the mandate on certain tasks
                    being in support of the host state (see, e.g., MINUSMA
                    mandate language above). This highlights the importance of
                    supporting the protection efforts and capacity of the host
                    state, though it does not diminish the authorization for the
                    mission to act independently to protect civilians, in
                    particular, when the host state is unwilling or unable to do
                    so. In situations where the host state has a history of
                    failing to protect civilians or is itself a threat to
                    civilians, the mandate language has sometimes not referenced
                    support to the protection efforts of the host state. For
                    example, when the UNMISS mandate was renewed in 2014,
                    following the outbreak of war in South Sudan, the mandate
                    changed to exclude language on support to the host state,
                    including on protection<sup>7</sup>.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-green">A priority mandate</span>
                  </td>
                  <td>
                    Most mandates are explicit that the protection of civilians
                    is a priority for the mission in terms of its allocation of
                    capabilities and resources. However, even where this is not
                    stated in the country-specific mandate, the Security Council
                    has been clear that where mandated, the protection of
                    civilians is a priority unless the relevant Security Council
                    resolution stipulates otherwise<sup>8</sup>.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-green">
                      All necessary means/all necessary action
                    </span>
                  </td>
                  <td>
                    The mandate to protect civilians has always been accompanied
                    by an authorization to use all necessary means or actions.
                    This is the language used by the Security Council to
                    authorize the full range of measures available to the
                    mission, up to and including the use of deadly force.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="border un-green">
                      Within capabilities and areas of deployment
                    </span>
                  </td>
                  <td>
                    The mandate to protect civilians is normally specified to be
                    within the capabilities and areas of deployment of the
                    mission. This limitation recognizes that peacekeeping
                    operations have limited capacity and footprint and cannot
                    protect everyone, everywhere, at all times. However,
                    missions should consider the various threats to civilians
                    when determining the mission footprint and deployment of
                    resources and should be prepared to redeploy resources as
                    necessary to respond to serious threats to civilians.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="sub-section 0-2-1-2">
            <h5>
              <span className="un-green">2.1.2</span>Who is a civilian?
            </h5>
            <div className="half">
              <div>
                <h5>Defining Civilians</h5>
                <p>
                  When international humanitarian law applies to United Nations
                  forces, they must comply with it, including the principles of
                  distinction, proportionality and precaution. This obligation
                  is independent of the POC mandate. Guidance on whether
                  international humanitarian law applies to a specific
                  peacekeeping operation will be developed on a case-by-case
                  basis and as required by the Office of Legal Affairs, in
                  consultation with DPO and the relevant mission. See also
                  chapter 11.4 on protection of civilians during military
                  operations. Defining &apos;civilians&apos; and distinguishing
                  them from combatants is a feature of international
                  humanitarian law (IHL), which specifically applies to
                  situations of armed conflict. However, the term
                  &apos;civilians&apos; as used in POC mandates is also applied
                  in contexts unrelated to armed conflict. A principal aim of
                  IHL is to ensure that parties to conflict respect and protect
                  those who are not or are no longer directly participating in
                  hostilities, such as civilians. Under IHL, therefore, the main
                  purpose of defining civilians is directly related to the
                  obligation of those engaged in conflict to protect civilians
                  from harm. Under IHL, civilians are generally defined as
                  persons who are not members of the armed forces or of
                  organized armed groups.
                </p>
                <p>
                  For the specific purposes of the POC mandate in peacekeeping,
                  the DPO POC Policy sets out who is considered a civilian:
                </p>
                <p className="un-green bold">
                  For the purposes of the protection of civilians mandate in
                  peacekeeping, everyone is to be considered a civilian, except
                  persons falling in one of the following categories:
                </p>
                <ul className="square un-green color">
                  <li className="un-green bold">members of armed forces;</li>
                  <li className="un-green bold">
                    members of organized armed groups who have continuous combat
                    functions; and
                  </li>
                  <li className="un-green bold">
                    civilians directly participating in hostilities, for such
                    time as they do so.
                  </li>
                </ul>
                <p className="un-green">
                  In case of doubt whether a person is a civilian, that person
                  shall be considered a civilian.
                </p>
                <p>
                  Everyone who is not excluded, as stated above, falls within
                  the scope of the POC mandate. They may be preventively and
                  proactively protected by a peacekeeping operation from threats
                  of physical violence. Where a peacekeeping operation is
                  deployed in a non-armed conflict situation, everyone except
                  members of armed forces are civilians within the scope of the
                  POC mandate.
                </p>
                <p>
                  In some situations, such as community-based violence or
                  criminal gang activity, the perpetrators or potential
                  perpetrators of violence against civilians will be other
                  civilians. Where this is the case, the mission must make an
                  operational decision on how to apply the POC mandate, with
                  support from Headquarters, as required.
                </p>
              </div>
              <div>
                <div className="background border margin-bottom">
                  When international humanitarian law applies to United Nations
                  forces, they must comply with it, including the principles of
                  distinction, proportionality and precaution. This obligation
                  is independent of the POC mandate. Guidance on whether
                  international humanitarian law applies to a specific
                  peacekeeping operation will be developed on a case-by-case
                  basis and as required by the Office of Legal Affairs, in
                  consultation with DPO and the relevant mission. See also
                  chapter 11.4 on protection of civilians during military
                  operations.
                </div>
                <div className="relative">
                  <img src={meeting} alt="Combatants" />
                  <div className="description">
                    <p>
                      Combatants from an
                      <br />
                      armed group in Sudan. (2006)
                    </p>
                  </div>
                  <p className="ref">UN Photo/Fred Noy</p>
                </div>
              </div>
            </div>
            <table className="examples striped margin-top">
              <thead>
                <tr>
                  <td>
                    Examples: POC mandate application when civilians are
                    perpetrators of violence against other civilians
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where two armed groups or two armed communities are
                        violently clashing, the mission would not normally be
                        expected to intervene under the POC mandate to protect
                        those participating in the clashes but would be expected
                        to protect other civilians affected by those clashes.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where intercommunal violence involves attacks against
                        civilians, rather than direct attacks against an armed
                        adversary, the mission should intervene to protect.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where an armed mob attacks a group of unarmed civilians,
                        the mission should intervene to protect.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where organized criminal entities are preying on the
                        local civilian population, the mission should intervene
                        to protect.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where a physically violent crime, unrelated to armed
                        conflict, occurs in the presence or vicinity of
                        peacekeepers, the mission should intervene to protect
                        the victims of the crime. However, the mission cannot be
                        expected to intervene or address all ordinary crime
                        without an executive policing mandate.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul className="square un-green">
                      <li>
                        Where an individual on the premises of a UN peacekeeping
                        operation (such as in a POC site in UNMISS) is
                        committing crimes or causing violence to others, the
                        mission should intervene to protect. However, as the
                        individual is still a civilian, and if he/she is on UN
                        premises to avoid harm from an armed conflict, the
                        mission must still protect the individual. Where
                        expelling him/her or handing him/her over to local
                        government authorities would endanger him/her, the
                        mission must respect the principle of non-refoulement
                        <sup>9</sup>.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <h4>Distinguishing Civilians</h4>
            <p>
              In developing an understanding of the scope of the POC mandate,
              key mission documents on POC at strategic, operational and
              tactical levels should clarify who is a civilian that a
              peacekeeping operation may seek to protect where it has the
              capacity to do so. In distinguishing civilians, the mission
              should:
            </p>
            <ul className="square un-green">
              <li>
                Identify armed forces or organized armed groups operating in the
                mission area, members of which would not normally be civilians
                protected under the POC mandate, bearing in mind that:
                <ul className="triangle un-green">
                  <li>
                    The level of ‘organization&apos; of an armed group can be
                    difficult to appreciate but the mission can consider factors
                    such as the group&apos;s structure and extent of its
                    hierarchy, its control over a geographical area and its
                    self-identification as an armed group.
                  </li>
                  <li>
                    Membership in an armed group or state security force may be
                    difficult to ascertain but the mission can take into account
                    factors including whether the individual(s) concerned are
                    wearing a uniform or distinctive clothing or signs, carrying
                    a weapon or taking active part in hostilities or attacks on
                    civilians. However, absence of uniforms or signs is not
                    definitive, as members of armed groups or state security
                    forces may display no visible signs revealing their status.
                  </li>
                  <li>
                    Where an armed group has both a military and a political
                    wing, persons involved only in the political wing would
                    normally be considered civilians. Where political actors of
                    any affiliation endorse orders for violence, they remain
                    civilians, but it may not be advisable to exercise the POC
                    mandate to protect them.
                  </li>
                  <li>
                    Dependents of armed forces or an armed group would normally
                    be considered to be civilians to the extent that they do not
                    directly participate in hostilities.
                  </li>
                  <li>
                    A member of an armed force or armed group can become a
                    civilian when he/she disengages from active duty or their
                    fighting function and reintegrate into civilian life.
                  </li>
                </ul>
              </li>
              <li>
                Recall that civilians who directly participate in hostilities
                are excluded from protection for such time as they do so. This
                may include civilians in self-defence groups. However, once they
                cease to engage in violence they should be protected.
              </li>
              <li>
                Consider that possession of a weapon does not in itself prevent
                a civilian from being considered a civilian, especially in
                contexts where it may be tradition to carry a weapon (whether
                guns, machetes, knives, spears, etc.).
              </li>
              <li>
                Remember that, in case of doubt, the individual or group of
                individuals shall be considered civilian and protected as
                civilians until determined otherwise.
              </li>
            </ul>
            <div className="relative">
              <img src={disarmament} alt="Disaramament and Rehabilitation" />
              <div className="description">
                <p>
                  More than 2,000 former combatants turn in their weapons to
                  UNMIL at the start of a disarmament and rehabilitation
                  campaign to stabilize the country. (2003)
                </p>
              </div>
              <p className="ref">UN Photo/Shima Roy</p>
            </div>
            <p>
              Once the mission has identified those individuals or groups
              falling within the scope of the POC mandate, and determined that
              the POC mandate applies to protecting them, then the mission must
              prioritize among threats and civilians at risk, based on the
              prevention and response capabilities available (see chapter 8) as
              well as other relevant circumstances.
            </p>
            <div className="case-study un-green">
              <h3>CASE STUDY</h3>
              <h4>Opening the gates in South Sudan - Who to let in?</h4>
              <div className="content">
                <div className="relative">
                  <img src={gates} alt="Opening the gates" />
                  <div className="description">
                    <p>
                      Tens of thousands of civilians seek refuge at UNMISS bases
                      due to outbreaks of violence across the country. (2013)
                    </p>
                  </div>
                  <p className="ref">UN Photo/UNMISS</p>
                </div>
                <p>
                  In December 2013, when violence broke out in Juba, and then
                  quickly spread to other areas of South Sudan, thousands of
                  people fled to UNMISS compounds seeking refuge. They were men,
                  women and children of all ethnicities. In the immediate
                  moment, and the initial days to follow, the mission had no
                  time or capacity to assess who was coming in to the compounds.
                  Two simple rules were established: no uniforms and no weapons.
                  Anyone willing to abide by those rules was determined to merit
                  protection. The reality of this was that many fighters arrived
                  at the gates, laid down their guns, and changed clothes. Piles
                  of weapons and fatigues grew at the gates, with the mission
                  safely storing the former. This approach drew strong criticism
                  from the government, particularly once fighting had died down
                  and it was suspected that many former fighters were in the POC
                  sites. However, UNMISS stood by its policy that these
                  individuals, even if they were former fighters, should be
                  protected once they ceased to directly participate in
                  hostilities by effectively disengaging from their fighting
                  function. This demonstrates the operational challenges in the
                  field to determine who falls within the POC mandate.
                </p>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      index: '0.3',
      name: 'The Operational Concept for POC in UN Peacekeeping',
      title: t('handbook.chapters.chapter2.2.title'),
      content: (
        <>
          <p>
            The operational concept for the protection of civilians in United Nations peacekeeping is set out in the DPO POC Policy. In accordance with the POC Policy, protection of civilians in peacekeeping is implemented through three tiers, namely, protection through dialogue and engagement, provision of physical protection and establishment of a protective environment, as outlined below.
          </p>

          <table className="columns">
            <tbody>
              <tr>
                <td><img src={tierI} alt="protection through dialogue" /></td>
                <td>
                  <img src={patrol} alt="provision of physical protection" />
                </td>
                <td>
                  <img
                    src={capacityBuilding}
                    alt="establishment of a protective environment"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="un-green title">Tier I:</p>
                  <p className="subtitle">
                    Protection through dialogue and engagement
                  </p>
                  <p>
                    Tier I activities include active, structured and regular dialogue with perpetrators or potential perpetrators of violence against civilians; conflict resolution and mediation between parties to the conflict; advocating with the host government, its security institutions and other relevant actors to intervene to protect civilians; local conflict resolution and social cohesion activities; strategic communication; investigation; advocacy; reconciliation initiatives; reporting on human rights and protection concerns; and other initiatives that seek to protect civilians through communications, dialogue and direct or indirect engagement.
                  </p>
                </td>
                <td>
                  <p className="un-green title">Tier II:</p>
                  <p className="subtitle">Provision of physical protection</p>
                  <p>
                    Tier II encompasses activities by all mission components to physically protect civilians, whether through protective presence, interpositioning, the threat or use of force, or facilitating safe passage or refuge. Notably, Tier II includes activities by uniformed components involving the show or use of force to prevent, deter, pre-empt and respond to threats to civilians. However, civilian mission components can also act as a protective presence through their regular, visible and direct engagement with civilian populations at risk.
                  </p>
                </td>
                <td>
                  <p className="un-green title">Tier III:</p>
                  <p className="subtitle">
                    Establishment of a protective environment
                  </p>
                  <p>
                    Tier III activities are frequently programmatic in nature and designed with committed resources for peacebuilding and conflict prevention/resolution objectives. Sometimes presented as separate mandated tasks under country-specific resolutions, activities under Tier III help create a protective environment for civilians, prevent the (re-)emergence of threats of physical violence, support the legitimacy of the host state and its capacity to protect civilians, and support the (re-)establishment of the rule of law and criminal justice chain.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            The three tiers are mutually accommodating and reinforcing and are implemented simultaneously and strategically in accordance with the mission mandate, mission phase and the circumstances on the ground. There is no inherent hierarchy or sequencing between the tiers.
          </p>
          <p>
            The protection of civilians requires actions with both short-term and long-term outlook, based on an analysis of the environment, the phase of the conflict, if any, and the mission&apos;s lifecycle, as well as the nature of the threat. The strategic approach to POC, and the three tiers, are therefore implemented along four phases:
          </p>

          <div className="grid-four">
            <div className="box un-green">
              <header>Prevention</header>
              <p>
                where <i><strong>no clear threat</strong></i> to civilians has been identified (longer term)
              </p>
            </div>
            <div className="box un-green">
              <header>Pre-emption</header>
              <p>
                where <i><strong>likely threats</strong></i> are identified and attacks against civilians are <i><strong>anticipated</strong></i> (short term)
              </p>
            </div>
            <div className="box un-green">
              <header>Response</header>
              <p>
                where attacks against civilians are <i><strong>imminent</strong></i> or <i><strong>occurring</strong></i> (short term)
              </p>
            </div>
            <div className="box un-green">
              <header>Consolidation</header>
              <p>
                where violence against civilians is <i><strong>subsiding</strong></i> (longer term)
              </p>
            </div>
          </div>

          <div className="start-third margin-top">
            <p>
              Similar to the tiers, these phases are not mutually exclusive and can overlap. There can be phases to individual threats in specific places and at specific times, and there can be phases to entire conflicts. The same action by the mission can potentially address various phases simultaneously.
            </p>
            <div className="horizontal-flow">
              <div>
                <header>Prevention</header>
                <p>Threat is latent (risk)</p>
              </div>
              <div className="border-dashed un-green" />
              <div>
                <header>Pre-emption</header>
                <p>Threat is identified and likely or actual</p>
                <footer>Response</footer>
              </div>
              <div className="border-dashed un-green" />
              <div>
                <header>Consolidation</header>
                <p>Threat has been mitigated/eliminated</p>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      index: '0.4',
      name: 'Relating POC in Peacekeeping To Other Protection Mandates',
      title: t('handbook.poc_fundamentals.2-3.title'),
      content: (
        <>
          <div className="sub-section 0-4-3-1">
            <h5>
              <span className="un-green">2.3.1</span>Within the peacekeeping
              operation
            </h5>
            <p>
              The POC mandate in UN peacekeeping is grounded in international
              law, including international humanitarian law, international human
              rights law and international refugee law, and reflects the desire
              of the Security Council to protect civilians from harm. As such,
              the POC mandate in peacekeeping is also linked to other protection
              mandates given to peacekeeping missions by the Security Council,
              in particular those on human rights, children and armed conflict
              (CAAC), conflict-related sexual violence (CRSV) and rule of law.
              The implementation of the POC mandate should support and reinforce
              the Women, Peace and Security (WPS) agenda. Interpreting the scope
              of the POC mandate therefore requires consideration of the
              interaction and complementarity of these other protection
              mandates.
            </p>
            <table className="examples underborder">
              <thead>
                <tr>
                  <td colSpan="2">Other Peacekeeping Protection Mandates</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bold">Human Rights</td>
                  <td>
                    <ul className="triangle">
                      <li>
                        Protection and prevention based on law, advocacy and
                        capacity-building
                      </li>
                      <li>
                        Addresses everyone and all human rights, not only
                        physical integrity
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="bold">Children and Armed Conflict (CAAC)</td>
                  <td>
                    <ul className="triangle">
                      <li>Focuses on children</li>
                      <li>
                        Built around the six grave violations (killing and
                        maiming, recruitment or use, sexual violence, abduction,
                        attacks against schools or hospitals, denial of
                        humanitarian access)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="bold">
                    Conflict-Related Sexual Violence (CRSV)
                  </td>
                  <td>
                    <ul className="triangle">
                      <li>Focuses on women, girls, boys and men</li>
                      <li>
                        Requires a conflict-nexus, does not include all sexual
                        and gender based violence (SGBV)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="bold">Rule of Law</td>
                  <td>
                    <ul className="triangle">
                      <li>
                        Protection and prevention based on support to criminal
                        justice institutions (police, justice and corrections)
                      </li>
                      <li>
                        Focuses on combatting impunity through investigations
                        and prosecutions
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="sub-section 0-2-3-2">
            <h5>
              <span className="un-green">2.3.2</span>Other UN protection
              mandates beyond peacekeeping<sup>10</sup>
            </h5>
            <p>
              Different UN actors deployed to the same country or situation will
              have different mandates and roles with regard to the protection of
              civilians, and protection more broadly, and may use different
              definitions or work under different parameters. To identify
              complementarities and areas for coordination and cooperation (see
              chapter 6), it is therefore useful to understand their mandates
              and roles. It may also be necessary to distinguish the mandates of
              the different actors for the benefit of host state authorities,
              armed actors and local communities, who may perceive all UN
              entities to be the same.
            </p>
            <table className="striped bordered un-green">
              <thead>
                <tr>
                  <td>UN ACTOR</td>
                  <td>PROTECTION ROLE</td>
                  <td>SOURCE OF THE MANDATE</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>UNHCR</td>
                  <td>
                    Refugees and Internally Displaced Persons (IDPs) and lead
                    agency for the Protection Cluster
                  </td>
                  <td>GA Resolution A/RES/428(V) (1950)</td>
                </tr>
                <tr>
                  <td>UNICEF</td>
                  <td>Children and adolescents</td>
                  <td>GA Resolution A/RES/57(I) (2002)</td>
                </tr>
                <tr>
                  <td>OCHA</td>
                  <td>Coordination of humanitarian action</td>
                  <td>GA Resolution A/RES/46/182 (1991)</td>
                </tr>
                <tr>
                  <td>WFP</td>
                  <td>Food as an aid to economic and social development</td>
                  <td>GA Resolution A/RES/1714(XVI) (1961)</td>
                </tr>
                <tr>
                  <td>OHCHR</td>
                  <td>All human rights of all people</td>
                  <td>GA Resolution A/RES/48/141 (1993)</td>
                </tr>
                <tr>
                  <td>UN Women</td>
                  <td>
                    Gender equality, empowerment and the advancement of women
                    and girls
                  </td>
                  <td>GA Resolution A/RES/64/289 (2010)</td>
                </tr>
                <tr>
                  <td>SRSG CAAC</td>
                  <td>
                    Protection and well-being of children affected by armed
                    conflict and CAAC as a peace and security issue
                  </td>
                  <td>
                    GA Resolution A/RES/51/77 (1997) &amp; SC Resolution
                    S/RES/1261 (1999)
                  </td>
                </tr>
                <tr>
                  <td>SRSG SVC</td>
                  <td>
                    Conflict-related sexual violence (CRSV) as a peace and
                    security issue
                  </td>
                  <td>SC Resolution S/RES/1888 (2009)</td>
                </tr>
                <tr>
                  <td>TOE-RoL SVC</td>
                  <td>
                    Criminal accountability for CRSV through investigations and
                    prosecutions
                  </td>
                  <td>SC Resolution S/RES/1888 (2009)</td>
                </tr>
              </tbody>
            </table>
            <ol type="1" start="5" className="refs">
              <li>S/RES/1270 (1999).</li>
            </ol>
            <ol type="1" start="7" className="refs">
              <li>
                S/RES/2155 (2014). Following the Revised Agreement on the
                Resolution of Conflict on the Situation in South Sudan
                (R-ARCSS), subsequent UNMISS mandates have reinserted language
                on support to the state. See, e.g., S/RES/2459 (2019).
              </li>
              <li>S/RES/1894 (2009) OP 19.</li>
              <li>
                The principle of non-refoulement, reflected in different bodies
                of international law, protects a person from being transferred
                from one authority to another when there are substantial grounds
                for believing that the person would be at risk of being
                subjected to irreparable harm, including persecution, torture,
                ill-treatment, or other serious human rights violations.
              </li>
              <li>
                Some protection mandates apply both within and beyond
                peacekeeping and are therefore presented twice in these
                sections. For example, the Office of the High Commissioner for
                Human Rights (OHCHR) is both integrated within peacekeeping
                operations and has a mandate beyond peacekeeping operations.
              </li>
            </ol>
          </div>
        </>
      ),
    },
  ],
};
