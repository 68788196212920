import * as React from 'react';
import { useEffect, useState } from 'react';
import { setI18nConfig } from '../I18N';

/**
 * A service component to initalise and monitor localisation changes
 */
export const I18NService: React.FunctionComponent = (props) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setI18nConfig();
    setLoaded(true);
  }, []);

  if (loaded) {
    return props.children;
  }
  return null;
};
