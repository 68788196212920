import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actions from '../redux/actions';

const CookieBanner = ({ setCookies, chosen }) => {
  if (chosen) return null;
  return (
    <div className="cookie-banner">
      <div className="copy">
        <h2>Cookie Preferences</h2>
        <p>To access all features of the application, please accept cookies.</p>
      </div>
      <div className="buttons">
        <button type="button" className="decline" onClick={() => setCookies(false)}>
          Decline
        </button>
        <button type="button" className="accept" onClick={() => setCookies(true)}>
          Accept
        </button>
      </div>
    </div>
  );
};

CookieBanner.propTypes = {
  setCookies: PropTypes.func.isRequired,
  chosen: PropTypes.bool
};

CookieBanner.defaultProps = {
  chosen: false
};

const mapStateToProps = ({ cookies }) => ({ chosen: cookies.chosen });
export default connect(mapStateToProps, {
  setCookies: actions.setCookies
})(CookieBanner);
