import React from 'react';
import satelite from '../images/chapters/chapter9/communication_specialists_set_up_a_satellite_in_mali.jpg';
import khalilNgobozouaAvatar from '../images/avatars/Khalil_Ngobozoua.png';
import drone from '../images/chapters/chapter9/MONUSCO_drone.jpg';
import team from '../images/chapters/chapter9/MONUSCO_joint_protection_team.jpg';
import kambaleMughanyiriAvatar from '../images/avatars/Kambale_Mughanyiri.png';
import { t, setI18nConfig } from '../services/i18n';
import audio from './audio';
import AudioPlayer from '../components/audio';

setI18nConfig();
export default {
  name: 'Chapter 9: Using Tools for POC',
  title: t('handbook.chapters.chapter9.title'),
  index: '9',
  color: 'un-navy',
  content: (
    <>
      <div className="grid-three un-navy">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            Changing operational variables, actors and dynamics affect POC threats and risks. As situations change, mission personnel must remain current in their understanding of these threats and risks so that they can anticipate, act and adapt effectively. This requires the mission to have effective tools for information gathering and analysis, early warning and response.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            Without tools and systems to provide information, analysis and early warning, missions will be unable to implement timely and effective responses.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            A mission’s early warning system should be focused at the tactical and operational levels, seeking to identify specific threats in defined areas and producing short to medium-term predictive analysis. Many of the tools available to missions rely on engagement with local communities.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            POC coordination mechanisms, with guidance from POC Advisers, often develop a mission’s information gathering, analysis and early warning and response tools. Many of the tools are managed by Civil Affairs, Human Rights, JOC and JMAC.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            Tools for information gathering and analysis, early warning and response are developed at both mission headquarters and field offices but usually implemented in mission field offices.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            Tools for information gathering and analysis, early warning and response should be developed as early in the mission lifecycle as possible, but also need to adapt and evolve along with the changing dynamics on the ground. Innovations are always timely.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '9.1',
      name: 'Early Warning Systems',
      title: t('handbook.chapters.chapter9.1.title'),
      content: (
        <>
          <p>
            POC threat and risk assessments rely on the constant collection and analysis of information relevant to POC. Particularly in dynamic contexts, missions must maintain situational awareness to inform planning and timely response to threats to civilians. A structured early warning system will help the mission gather and analyse information on threats to civilians in a timely manner and thus enable the adoption of proactive measures to prevent credible threats against civilians. Early warning contributes to better resource allocation, facilitates a comprehensive, whole-of mission approach to POC and enhances early action and prevention. See Annex V for an SOP on early warning.
          </p>
          <p>
            The purpose of an early warning system is to ensure that:
          </p>
          <ul className="square un-purple">
            <li>
              The mission understands the indicators, signals, necessary conditions and triggers that make violence against civilians likely to occur;
            </li>
            <li>
              There is a process in place for collecting, sharing and corroborating early warning information within the mission and/or with external actors;
            </li>
            <li>
              Situations requiring urgent action can quickly be escalated, responded to, and referred to the attention of mission leadership, where necessary; and
            </li>
            <li>
              Situations requiring longer-term action, deployment of mission assets or resource allocation can be referred to the POC planning and coordination mechanisms.
            </li>
          </ul>
        </>
      )
    },
    {
      index: '9.2',
      name: 'Tools for Information Gathering, Early Warning and Response',
      title: t('handbook.chapters.chapter9.2.title'),
      content: (
        <>
          <p>
            Peacekeeping missions have developed a range of tools to facilitate situational awareness, particularly related to the integrated identification and analysis of threats to civilians. Most of these operate at the tactical level, at field offices or within COBs and TOBs, and generate primary assessments of threats to civilians. These tools provide information that will directly inform threat assessments, analysis and response, and which should also be combined with information and analysis from POC coordination mechanisms, as well as broader political and contextual analysis, to inform strategic decision making at the operational and strategic level. Below are some examples of these tools, which can be adapted as appropriate in various mission contexts.
          </p>
          <h5>Community Liaison Assistants (CLAs)</h5>
          <p>
            Community Liaison Assistants are national Civil Affairs officers who are deployed alongside military and police field commanders to support and enhance the missions&apos; engagement with communities. CLAs normally speak the local language and are able to build relationships with local communities. As such, they can support the military and police components to interact with local authorities, communities and other relevant actors in the field and perform a range of tasks, including information gathering, threats or needs assessments, conflict mediation, contributing to the mission&apos;s early warning system activities, local-level protection planning, coordination of and follow up on field visits and patrols/operations and strengthening the resilience of local communities.
          </p>
          <AudioPlayer
            src={audio[11].file}
            title={audio[11].title}
            subtitle={audio[11].subtitle}
            avatar={audio[11].avatar}
            speaker={audio[11].speaker}
            transcript={audio[11].transcript}
          />
          <div className="voices un-navy">
            <div className="avatar">
              <img alt="Khalil Ngobozoua" src={khalilNgobozouaAvatar} />
            </div>
            <h3>VOICES FROM THE FIELD</h3>
            <div className="content">
              <h4>The Role of a CLA</h4>
              <h5>Khalil Ngobozoua, MINUSCA CLA (2015-)</h5>
              <div className="half">
                <div>
                  <p>
                    When I was initially recruited to MINUSCA in 2015, I had limited knowledge of the crisis that had spread across my native Central African Republic (CAR). After some months serving as a Community Liaison Assistant (CLA), when I had come to understand the mandate and purpose of MINUSCA, I began to understand how the work of the peacekeeping operation was helping to protect civilians. As a CLA, I initially thought that my work would be limited to organizing and communicating with communities. What I have come to understand, however, is that I have been able to play a key role in the protection of communities as well, mostly by engaging with them, setting up Community Alert Networks (CANs) and by working with the uniformed components of the Mission to ensure we have local level early warning. In my experience, the work of a CLA is important for POC in connecting the local population to the Mission. Several times I have accompanied the Force to translate between them and locals, but also to explain cultural aspects foreign troops might not understand. Being able to increase the levels of
                  </p>
                </div>
                <div>
                  <cite>
                    &quot;... I have been able to play a key role in the protection of communities as well, mostly by engaging with them, setting up Community Alert Networks (CANs)...&quot;
                  </cite>
                  <p>
                    communication between the communities and the Force has made a real difference, especially in the perception the population has of the troops. Sometimes I’ve found the role of a CLA presents risks, because you might need to interact with armed groups as well. These interactions are important, though, as they help us anticipate threats and understand the motivations of armed elements. I find that CLAs are indispensable for the protection of civilians within a peacekeeping operation. Our work helps to reinforce protection initiatives and bridge gaps that might exist between the Mission and the local community.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h5>Community Alert Networks (CANs)</h5>
          <p>
            Community Alert Networks (CANs) are a tactical level early warning tool. They are human and/or technology-based communication systems to reinforce the capacities of communities to protect themselves, by enhancing and organizing their means of communication. They aim at providing early warning information on threats to communities or individuals at risk of violence and can assist tactical level mission presences (including COBs and TOBs) to triangulate information and prevent or respond to threats. A CAN is able to facilitate and enable a community at risk to contact the closest mission presence and alert them to likely impending threats.
          </p>
          <p>
            Community Liaison Assistants and Joint Protection Teams (see below) can assist military components to design CANs for the area of responsibility of a particular base. This can include assisting with the identification of i) reliable focal points and sources of information within the community, including community-based groups and civil society organizations, and ii) processes and material and/or technology required to transmit information and alerts. For example, alerts can be transmitted through noise (whistles, kitchen equipment in an IDP camp, etc.), transport (motorcycles, bicycles, etc.) or technology-based means (phone/SMS, satellite phones, HF radios, etc.).
          </p>

          <table className="examples">
            <thead>
              <tr>
                <td>Toolkit</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h4 className="un-purple">
                    How to set up a Community Alert Network (CAN)
                  </h4>
                  <h5>What is a Community Alert Network (CAN)?</h5>
                  <p>
                    A CAN is a network of individuals from the local communities, for example one from each village, who alert the mission when the population is facing a violent threat. The network can rely on mobile phones or other ways to communicate. CANs are usually set up by CLAs. If there is no CLA in the area, one can be assigned on a short-term basis to set up the CAN.
                  </p>
                  <h4 className="un-purple">Checklist</h4>
                  <ul className="checklist un-purple">
                    <li>Identify communities/villages at risk.</li>
                    <li>
                      Identify an individual who can serve as the community focal point to alert the mission about threats.
                    </li>
                    <li>
                      Determine the best means of communication. For example, provide a mobile phone, or phone credit or establish a free hotline number.
                    </li>
                    <li>
                      Where mobile phones do not work, consider other options. For example, the focal point can travel to a neighbouring town to place the call or can use a system of relaying the information by messengers on motorbike from one village to the next.
                    </li>
                    <li>
                      Be clear about what kinds of threats the mission can respond to, and what the mission can and cannot do. For example, how long a response will take, whether it would be a police or military response, etc.
                    </li>
                    <li>Maintain the network by staying in contact.</li>
                    <li>Routinely check and maintain the equipment required.</li>
                    <li>
                      Keep a record of alerts that come in, the mission&apos;s response and the results.
                    </li>
                  </ul>
                  <h4 className="un-purple">Reminders</h4>
                  <ul className="reminders un-purple">
                    <li>
                      Be creative. Use the community&apos;s available tools for communication.
                    </li>
                    <li>Do no harm.</li>
                    <li>Manage expectations.</li>
                    <li>The CAN should focus on early warning before violence occurs.</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <h5>Joint Protection Teams (JPTs)</h5>
          <p>
            Joint Protection Teams (JPTs) are multi-disciplinary teams deployed to hotspots to analyse protection needs and recommend preventive and responsive interventions to address them. JPTs are comprised of mission civilian, police and military components and possibly, state officials and/or humanitarian agencies. The teams deploy to a location where civilians may be at risk to provide detailed analysis on the threats to civilians in the area and to support comprehensive activities to protect civilians by all components. In particular, a JPT provides analysis on political, security and social dynamics in an area, particularly to identify threats of violence and communities at risk and to propose recommendations for appropriate protection strategies through Community Protection Plans (CPPs) (see below); establishing early warning mechanisms, including CANs, to enable rapid response; establishing coordination mechanisms with local authorities or communities; and addressing specific protection needs, including protection against sexual violence and grave violations against children. JPTs can also work with the military and police components to develop appropriate risk mitigation measures for the conduct of military and police operations. See Annex VI for an SOP on JPTs.
          </p>
          <table className="examples">
            <thead>
              <tr>
                <td>Toolkit</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h4>How to Conduct a Joint Protection Team (JPT) Mission</h4>
                  <h5>What is a Joint Protection Team (JPT)?</h5>
                  <p>
                    A JPT is a mission team, including civilian, police and military personnel, that conducts field visits. Usually coordinated by Civil Affairs or Human Rights, the purpose of a JPT is to identify threats, inform responses to threats, build ties and improve relations between the mission and local communities, lower tensions and enhance early warning. The JPT should serve to combine civilian and uniformed personnel capabilities to enhance the mission’s capacity to protect.
                  </p>
                  <h4 className="un-purple">Checklist</h4>
                  <p><em>Before the mission:</em></p>
                  <ul className="checklist un-purple">
                    <li>
                      Work with other components and sections in the duty station to identify areas of risk.
                    </li>
                    <li>
                      Determine if the JPTs will be strategic and preventive, deployed along priority axes or responsive to specific incidents or trends.
                    </li>
                    <li>
                      Utilize existing information/sources to gain as comprehensive an understanding of the situation as possible before departure (e.g., CANs, local sources, previous patrol reports, JMAC analysis, human rights monitoring, etc.).
                    </li>
                    <li>
                      Build terms of reference (TORs) that clarify the objective, duration, participating entities and their responsibilities and logistical arrangements for the JPT.
                    </li>
                  </ul>
                  <p><em>On mission:</em></p>
                  <ul className="checklist un-purple">
                    <li>
                      Establish or maintain coordination mechanisms between local communities, local authorities and the mission&apos;s nearest military presence.
                    </li>
                    <li>
                      Analyse local dynamics for protection planning purposes.
                    </li>
                    <li>
                      Work with mission military commanders to develop or update community protection plans.
                    </li>
                    <li>Ensure smooth and safe functioning of CANs.</li>
                    <li>
                      Engage with local authorities and armed actors on the ground to promote respect for human rights and mitigation of harm to civilians.
                    </li>
                  </ul>
                  <p><em>After mission:</em></p>
                  <ul className="checklist un-purple">
                    <li>
                      Make concrete recommendations for mission action at local level and, where necessary, national level to improve the protection of civilians in the area.
                    </li>
                  </ul>

                  <h4 className="un-purple">Reminders</h4>
                  <ul className="reminders un-purple">
                    <li>Do no harm.</li>
                    <li>
                      Military should participate substantively and not just for escort security.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="voices un-navy">
            <div className="avatar">
              <img alt="Kambale Mughanyiri" src={kambaleMughanyiriAvatar} />
            </div>
            <h3>VOICES FROM THE FIELD</h3>
            <div className="content">
              <h4>Joint Protection Teams</h4>
              <h5>
                Kambale Mughanyiri, MONUSCO National Human Rights Officer,<br />Butembo-Lubero field office (2005-)
              </h5>
              <div className="half">
                <div>
                  <p>
                    In May 2014, the village of Fatua (in the Lubero territory of North Kivu province, DRC), was burned and looted, including homes, schools and the local health centre. In the incident, several women were raped, and more than 10 civilians killed. The violence was the result of fighting between the FDLR and the NDC/R, two rival armed groups responsible for recurrent human rights abuses and violence against civilians in the area since 1998. Due to the ongoing instability, MONUSCO had visited the area before.
                  </p>
                  <p>
                    Following this specific incident, MONUSCO deployed a Joint Protection Team (JPT) mission for a week. The JPT was composed of Human Rights Officers (including myself), Child Protection Officers and Civil Affairs Officers, escorted by the Force. Our objective in visiting the village was to reassure the population of MONUSCO’s continuous protection, to understand the protection concerns of the population and to investigate the attacks.
                  </p>
                  <p>
                    When we arrived, we found a village completely deserted. All the villagers had fled for safety. But then,
                  </p>
                </div>
                <div>
                  <p>
                    two days after we arrived, villagers began gradually trickling back to the village. They were timid, but seemed reassured by the presence of MONUSCO. They continued to arrive throughout the week, motivated to return from the bushes and hills where they had been hiding. With the troops providing security, our civilian presence and engagement with the community was also able to build the confidence of the population. We investigated the incidents and assessed the protection needs of the community. We met members of the Local Protection Committee (LPC) and the Community Alert Network (CAN) and held a capacity-building session with human rights NGOs. We updated all these actors with mobile telephone numbers of our MONUSCO sections, so that they could easily reach us to provide early warning.
                  </p>
                  <p>
                    Soon the villagers had returned to their routine livelihood activities. Following our JPT mission, the health centre, which had been destroyed, was refurbished with equipment, medicine and personnel by the local health authorities. On subsequent visits to the area, we were able to see the community recovering.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="relative margin-top">
            <img src={team} alt="MONUSCO joint protection team" />
            <div className="description">
              <p>
                A MONUSCO Joint Protection Team (JPT) visits a village in Masisi Territory, North Kivu. (2019)
              </p>
            </div>
            <p className="ref">UN Photo/MONUSCO</p>
          </div>

          <h5>Joint Assessment Missions (JAMs)</h5>
          <p>
            Joint Assessment Missions (JAMs) are composed of mission civilians and humanitarian or development actors, aimed at assessing and recommending responses to protection and humanitarian or development situations, including for the protection of civilians.
          </p>

          <table className="examples">
            <thead>
              <tr>
                <td>TOOLKIT</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h4 className="un-purple">
                    How to Conduct a Joint Assessment Mission (JAM)
                  </h4>
                  <h5>What is a Joint Assessment Mission (JAM)?</h5>
                  <p>
                    A JAM is a protection field visit usually consisting of mission civilian personnel and members of the United Nations Country Team (UNCT) or relevant NGOs. Where appropriate, government authorities may also be included. Similar to a JPT, the purpose of a JAM is to jointly conduct an assessment to identify threats, inform responses to threats, improve relations with local communities, lower tensions and enhance early warning. A JAM will also consider the humanitarian situation.
                  </p>
                  <h4 className="un-purple">Checklist</h4>
                  <ul className="checklist un-purple">
                    <li>
                      Take the same steps as for a JPT (see above), but factor in consultation with UNCT and relevant NGO colleagues, as well as government actors, where relevant, prior to departure to determine priorities and TORs.
                    </li>
                    <li>
                      Ensure that information is shared among mission and humanitarian and development team members, as appropriate.
                    </li>
                  </ul>
                  <h4 className="un-purple">Reminder</h4>
                  <ul className="reminders un-purple">
                    <li>
                      Humanitarian actors may be wary of participating jointly with uniformed colleagues, especially military. Discuss options to best preserve any necessary humanitarian distinction.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <h5>Community Protection Plans (CPPs)</h5>
          <p>
            At the tactical level, each mission base or field presence is expected to have a Community Protection Plan (CPP), which provides an overview of the security situation, threats to the civilian population, priority communities at risk of violence and actions planned or required to address POC risks. Depending on the composition of the base or field presence, CPPs will often be drafted by the field Commander (UNPOL/Force) and CLA, with support from civilian components, in consultation with local communities.
          </p>

          <table className="examples toolkit">
            <thead>
              <tr>
                <td>TOOLKIT</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h4 className="un-purple">
                    How to Develop a Community Protection Plan (CPP)
                  </h4>
                  <h5>What is a Community Protection Plan (CPP)?</h5>
                  <p>
                    A CPP is a plan developed by the mission for the protection of a particular population or area, taking into account the specific dynamics and context of the community. CPPs are normally developed jointly by uniformed colleagues and Community Liaison Assistants (CLAs), with the support and guidance of Civil Affairs. An effective CPP should include a CAN or a network of CANs, as well as information on key community leaders, effective forms of mitigation relevant to local populations and a strategy for engagement with the local population as well as potential perpetrators.
                  </p>
                  <h4 className="un-purple">Checklist</h4>
                  <ul className="checklist un-purple">
                    <li>
                      Undertake a robust mapping of the community. Take into account drivers of conflict, historical context and grievances, key leadership, spoilers, minority groups, youth and women&apos;s representation and the network of relations that exist between major actors.
                    </li>
                    <li>
                      Engage all relevant actors in the community in the consultation process.
                    </li>
                    <li>
                      Include the best means of communication with each relevant local actor in the CPP.
                    </li>
                    <li>
                      Identify likely threats, possible triggers, preventive action and planned responses to protect civilians.
                    </li>
                    <li>
                      Identify vulnerable individuals and infrastructure (e.g., schools and medical facilities).
                    </li>
                    <li>
                      Establish locations for locals to meet military or police escorts, or &apos;safe areas&apos; where the population can seek refuge.
                    </li>
                    <li>
                      Establish a CAN or a series of CANs to function as part of the overall CPP.
                    </li>
                    <li>
                      Share the plan with the local community so that they know what to expect of the mission.
                    </li>
                  </ul>
                  <h4 className="un-purple">Reminder</h4>
                  <ul className="reminders un-purple">
                    <li>
                      Consult with women&apos;s organizations when creating a CPP.
                    </li>
                    <li>Update the CPP periodically.</li>
                    <li>
                      Be realistic, do not overpromise the protection the mission can provide.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )
    },
    {
      index: '9.3',
      name: 'Casualty Recording',
      title: t('handbook.chapters.chapter9.3.title'),
      content: (
        <>
          <p>
            Another tool missions can use to contribute to information gathering and analysis, early warning and response for POC is casualty recording. Casualty recording is the process of systematically and comprehensively recording and verifying information on individuals killed and injured in a specific set of circumstances. The systematic recording of casualties is useful for POC for the following purposes:
          </p>
          <ul className="square un-purple">
            <li>
              To provide a detailed understanding of the nature and severity of threats to civilians as well as changes over time;
            </li>
            <li>
              For ‘real-time&apos; prevention and protection such as identifying harmful practices and supporting decisions on the deployment of UN forces;
            </li>
            <li>
              For informing evidence-based advocacy with the host state and with perpetrators of violence to bring about a change in their behaviour;
            </li>
            <li>
              For planning appropriate responses, including military and political engagement;
            </li>
            <li>For pursuing accountability;</li>
            <li>
              For peacebuilding purposes, including community reconciliation; and
            </li>
            <li>
              To inform strategic planning and reporting and monitoring and to evaluate the implementation of the POC mandate.
            </li>
          </ul>
          <p>
            For these purposes to be served, casualty recording must be based on a methodology that is systematically and consistently applied. The roles and responsibilities of those involved must be agreed upon and understood in the mission. While human rights components are generally responsible for verifying and analysing casualty information and for generating casualty figures, the information and analysis resulting from casualty recording must be regularly shared with the POC Adviser and POC coordination mechanisms. Where the mission cites casualty figures externally, it should be clear and consistent about the source and methodology of those figures.
          </p>
          <p>
            Casualty recording is usually conducted by the mission human rights component as part of their mandate on human rights monitoring, investigating and reporting. Gathering and verifying information on casualties to produce timely and reliable information and analysis requires not only available resources and a degree of expertise but also a robust methodology and the building up of a network of sources over time. This work is very challenging due to the nature of the perpetrators, the remote locations where incidents occur combined with poor infrastructure and access, and/or a paucity of sources.
          </p>
          <p>
            In most situations, reports on casualties stem from a wide range of sources, including new and traditional media, NGO and other civil society actors, government actors, as well as through early warning or Community Alert Networks. It may not be possible to verify such reports immediately but any information relevant to casualties received by mission personnel should be documented and immediately shared with the human rights component for verification and follow-up. The verification process is critical to ensuring that information and analysis on casualties is reliable, credible and can serve as an effective basis for decision making and engagement.
          </p>
          <p>
            Other mission actors such as the JOC or POC Advisers may collate information on casualties or seek to aggregate available information to produce an initial database of incidents affecting the security of civilians, for example in Unite Aware Incidents. This can inform POC threat assessments and planning, and tentatively identify trends in violence. Such information can also form a baseline for when further investigation by Human Rights Officers is possible.
          </p>
          <div className="card un-purple">
            <p>
              OHCHR&apos;s <em>Guidance on Casualty Recording </em>explains what casualty recording is and why it is carried out<sup>25</sup>. It sets out key considerations for peace operations when deciding whether to start casualty recording, as well as the basic steps to establish such a system. For the most part, it is based on UN best practices and a review of casualty recording systems within and outside of the UN. It can be applied in a range of contexts, including non-conflict settings, whether by the UN, authorities, civil society or other relevant actors. The Guidance aims to contribute to generating more data and reporting of casualties, including pursuant to Security Council mandates<sup>26</sup> and the Sustainable Development Goals<sup>27</sup>.
            </p>
            <p>
              Non-UN actors, including government agencies and civil society, may also be engaged in casualty recording. The <em>Standards for Casualty Recording</em><sup>28</sup> have been developed by civil society and seek to promote and standardize best practices, principles and criteria for casualty recording efforts.
            </p>
          </div>

          <p>
            When considering whether to institute casualty recording, a mission must consider relevant contextual and organizational factors.
          </p>
          <p>
            Contextual factors: Is casualty recording suitable and possible in the context?
          </p>
          <ul className="circle un-purple">
            <li>
              Is there a deteriorating, crisis, armed conflict or protracted situation in which individuals are killed or injured in incidents that are not merely isolated and sporadic acts of violence or singular criminal acts?
            </li>
            <li>
              Is information on incidents involving casualties available or potentially available across the country or the area to be covered?
            </li>
            <li>
              Are there different types of sources on incidents involving casualties? Organizational factors: Is casualty recording appropriate and feasible?
            </li>
            <li>
              Will casualty recording help bring about the desired POC impact?
            </li>
            <li>
              What sources and what information can the mission access?
            </li>
            <li>
              Does the mission have sufficient resources to dedicate to maintaining consistent casualty recording?
            </li>
          </ul>

          <table className="examples">
            <thead>
              <tr>
                <td>TOOLKIT</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h4 className="un-purple">How to Conduct Casualty Recording</h4>
                  <h5>What is casualty recording?</h5>
                  <p>
                    Casualty recording is the process of recording and verifying information on individuals killed and injured, often in a conflict setting, or otherwise as a result of violence. Where information on individuals killed and injured is not available, information can be recorded on incidents that reportedly result in casualties. Casualty recording usually involves using a database to store and manage information, but it can be as simple as keeping lists or tables, where resources are limited, as long as the information is securely stored and managed, and confidentiality and informed consent can be fully respected.
                  </p>
                  <h4 className="un-purple">Checklist</h4>
                  <ul className="checklist un-purple">
                    <li>
                      Define who is a ‘casualty&apos;: Identify the legal framework, assess what information is available, set the boundaries of who will be included/excluded.
                    </li>
                    <li>
                      Standardize data categories: Identify and define terms to be used.
                    </li>
                    <li>
                      Verify information: Set out how to verify information, including the types of sources to be used.
                    </li>
                    <li>Identify minimum data and disaggregation required.</li>
                    <li>
                      Develop and use appropriate and secure information management tools.
                    </li>
                    <li>
                      Apply a standard of proof: Set out the standard of proof to be applied in deciding whether the information is sufficient to believe that the casualties occurred as described.
                    </li>
                    <li>
                      Establish internal quality control: Set out methods for quality control of information; clarify roles and responsibilities for quality control.
                    </li>
                    <li>
                      Determine how and when information will be used and how it will be presented.
                    </li>
                    <li>
                      Measure progress: Periodically review the context and determine the impact/outcomes of casualty recording.
                    </li>
                  </ul>
                  <h4 className="un-purple">Reminders</h4>
                  <div className="half">
                    <div>
                      <p>To strengthen the data:</p>
                      <ul className="reminders un-purple">
                        <li>Ensure accuracy and precision</li>
                        <li>Preserve impartiality</li>
                        <li>Uphold objectivity</li>
                        <li>Ensure transparency about methodology</li>
                      </ul>
                    </div>
                    <div>
                      <p>
                        To protect cooperating persons by reducing any possible negative repercussions:
                      </p>
                      <ul className="reminders un-purple">
                        <li>Respect confidentiality</li>
                        <li>Ensure informed consent</li>
                        <li>Do no harm</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )
    },
    {
      index: '9.4',
      name: 'New Technologies',
      title: t('handbook.chapters.chapter9.4.title'),
      content: (
        <>
          <p>
            New technologies are constantly developing that provide new tools for the collection and analysis of information and contributing to the mission&apos;s situational awareness. As technological developments will likely outpace the revision of this handbook, the points herein are provided only as indicative examples of how technology can be used by missions in implementing the POC mandate. DPO and individual missions must continually innovate to stay abreast of the possibilities and best utilize new technologies.
          </p>
          <p>
            As of 2019, the Unite Aware (UA) platform of IT applications (formerly the Situational Awareness Programme) is being piloted and rolled out as the flagship for situational awareness in peacekeeping missions. The UA platform is an overarching, umbrella technology platform that:
          </p>
          <ul className="square un-purple">
            <li>
              aggregates and processes critical data and
            </li>
            <li>
              presents that data to decision makers and mission leadership, via fit-for-purpose technical tools, including wide-screen visualization, reporting and analysis instruments.
            </li>
          </ul>
          <p>
            Specific applications that should prove useful in support of POC mandate implementation include:
          </p>
          <ul className="square un-purple">
            <li>
              Unite Aware Incidents can track incidents and events directly related to the protection of civilians and gather them into a central database repository;
            </li>
            <li>
              Unite Aware Maps (formerly the &apos;MCOP&apos;) provides a visual, geospatial presentation of fixed and variable mission data, such as locations of key infrastructure and assets, incidents, patrol plans, etc.; and
            </li>
            <li>
              Unite Aware Dashboards can provide custom views of data, such as on the number of killings, rapes and other incidents affecting the security of civilians, disaggregated as appropriate (per area, gender, age, etc.).
            </li>
          </ul>
          <p>
            To facilitate the identification of civilians at risk, missions should ensure the consolidation of a consistent set of information related to incidents affecting the security of civilians, including location, date and time, actors involved, nature of the incident and numbers of individuals affected. This can be coordinated by the POC Adviser or via the POC coordination mechanisms, or in some missions, may be managed by the JOC or JMAC. Reports of incidents should be gathered in one place, though this needs to be harmonized or aligned with any casualty recording the mission is conducting (see chapter 9.3). Drawing on UA (when available) or other platforms, missions should consider the use of analysis tools to understand incident data better and to draw out patterns, dynamics and trends that may not be apparent from an anecdotal or qualitative approach to analysis. Potential tools (in the absence of UA) could include geospatial and geographic information system (GIS) tools, including satellite imagery and digital mapping, and data analytics technologies and dashboards (e.g., &apos;Microsoft PowerBI&apos; and &apos;Google Analytics&apos;). Such analytical tools will usually be managed by the JMAC, though the POC Adviser and coordination mechanisms may also find them useful.
          </p>
          <p>
            One tool that missions can use to collect critical information to feed into such analytical platforms is the operation of Unmanned Aerial Vehicles (UAVs, also referred to as &apos;drones&apos;). UAVs are multipurpose remotely controlled flying platforms that can have different capabilities depending on available accessories (e.g., thermal-imaging cameras). Often operated by military and police components, UAVs are able to capture detailed, high-resolution imagery of areas. Such imagery can be used to locate objects or elements, analyse terrain, measure distances and areas or obtain the exact coordinates of a location or incident. UAVs can create a multiplier effect when the mission has insufficient human and transport resources to reach all areas and can help the mission to see and gather information from areas that are difficult or dangerous to access. UAVs can be particularly useful for monitoring and assessing a situation in remote, hard to access or hostile areas, as the drone operator can receive data remotely without being physically present in a conflict area. UAVs can be controlled not only by the operator, but in some cases, directed automatically by artificial intelligence. The information gathered by UAVs can contribute to POC mandate implementation by improving situational awareness, tracking the movements of armed groups and actors, as well as displaced populations, and assisting investigation of POC incidents.
          </p>

          <div className="case-study">
            <h3>Case Study</h3>
            <h4>MONUSCO Use of UAVs</h4>
            <div className="content">
              <div className="relative top-right">
                <img src={drone} alt="MONUSCO drone" />
                <div className="description">
                  <p>A MONUSCO UAV taxiing after a successful flight. (2013)</p>
                </div>
                <p className="ref">UN Photo/Sylvain Liechti</p>
              </div>
              <p>
                In early June 2019, MONUSCO received information about the sudden escalation of inter-ethnic conflict between the Hema and Lendu communities, near Bunia, Ituri province. According to preliminary data, dozens of people were killed, and thousands forced out of their homes who had to take refuge in surrounding villages.
              </p>
              <p>
                In response, MONUSCO deployed a joint mission to immediately investigate the events. As part of the joint mission, the police component organized crime unit deployed a forensic expert who was also a specialist in the use of drones. Arriving in the area, the team faced unprecedented opposition from Lendu community leaders and undisguised hostility. The community leaders firmly rejected the Mission’s calls to stop violence against civilians. Conducting the investigation was difficult due to what was perceived as an immediate threat to the lives of the joint mission members.
              </p>
              <p>
                In this situation, using drones was the only possible means to study the inaccessible locations where the Hema victims were allegedly killed. With the drones, UNPOL was able to conduct a survey of extensive areas, up to 12 hectares in 15 minutes. The imagery of the area was then used to search for bodies, graves and evidence, as well as for subsequent analysis of the terrain at the crime scene for when access was possible, in particular by counting burned houses, measuring distances and areas and obtaining the exact coordinates of the evidence found. During crime scene analysis, items found by the team included dead bodies, mass graves, parts of ammunition for firearms (bullet casings) as well as edged weapons
              </p>
              <p>
                MONUSCO promptly transferred the evidence obtained during the investigation to the national armed forces, which initiated a large-scale military operation against the armed group affiliated with the Lendu. As a result of the operation, the attacks on civilians ceased for the time being, and thousands of displaced people were able to return home.
              </p>
            </div>
          </div>

          <p>
            Finally, as discussed in previous chapters, social media is a critical technology for strategic communications. Monitoring of social media can also serve as a tool for situational awareness and can contribute to regular analysis of perceptions and objectives of key stakeholders, or to access or &apos;crowdsource&apos; local perceptions of critical issues. Moreover, armed actors and spoilers often use social media to coordinate their activities and secure resources, including through the trafficking of persons and weapons and recruitment of new members, as well as to carry out influence campaigns or encourage negative popular sentiments. Monitoring of social media can be done manually, if regular, or can employ technology to mine, track and analyse the available data more efficiently for sentiments and trends, in addition to potentially warn of or flag critical incidents. UN missions have most recently employed &apos;Dataminr&apos; and &apos;Crimson Hexagon&apos; programs to monitor and conduct analysis of social media, but a wide range of social media analysis tools are available and continually improving in scope and accuracy.
          </p>

          <div className="case-study">
            <h3>CASE STUDY</h3>
            <h4>MINUSMA Radio Mining and Analysis</h4>
            <div className="content">
              <div className="relative">
                <img src={satelite} alt="Communication specialists set up a satellite in Mali" />
                <div className="description">
                  <p>
                    MINUSMA’s communications specialists set up a satellite communications link in a UN base. (2013)
                  </p>
                </div>
                <p className="ref">UN Photo/Blagoje Grujic</p>
              </div>
              <p>
                In Mali, a country of 18.5 million people with an internet penetration of only 10%, 80% of the population uses radio as the main source of information. Radio serves as a vital platform for public discussion, information sharing and news. Mali has one of the strongest community radio networks in Africa, which remains a popular way for Malians to voice their needs, concerns and opinions, particularly in rural areas. In 2019, MINUSMA, the Office of Information and Communication Technology, UN Global Pulse and DPO Office of Military Affairs launched an 18-month pilot project to verify whether it is possible to conduct analysis of public discussion on the radio in local languages. Building on existing open-source peacekeeping intelligence and strategic communication capabilities, the analysis of radio content presented an opportunity to take the pulse of populations that are excluded by the digital divide and contribute to early warning systems for POC.
              </p>
              <p>
                The project makes public radio broadcasts machine-readable using “Qatalog”, a speech recognition and transcription technology, that transforms radio content into text. Tracking public conversation from radio will help the Mission expand the scope of sentiment analysis in Mali to remote areas, improve situational awareness and identify trends amongst larger groups and communities. Analysing radio data can aid in understanding critical issues affecting the population, such as an outbreak of violence and related responses, according to the perspectives of members of local communities. This helps the Mission to make more informed decisions around policy and interventions on POC.
              </p>
              <p>
                In Phase I of the project, recordings of all relevant radio stations are converted into text automatically using keyword analysis (what-when-where). Radio content and data is then prepared for analysis to inform the mission on issues relevant to the MINUSMA mandate. The project aims at developing a radio data collection infrastructure in Mali, an integrated data storage application for collecting and automatically transcribing data for different languages and an application providing a keyword-based analysis of radio content, as well as SOPs for the use of the collection and analysis applications.
              </p>
            </div>
          </div>
        </>
      )
    }
  ],
  footnotes: {
    content: (
      <ol type="1" start="25" className="refs">
        <li className="9-3-7">
          Available at <a href="https://www.ohchr.org/Documents/Publications/Guidance_on_Casualty_Recording.pdf">https://www.ohchr.org/Documents/Publications/Guidance_on_Casualty_Recording.pdf.</a>
        </li>
        <li className="9-3-7">
          E.g., SC/RES/1612 (2005) on monitoring and reporting on grave violations committed against children in times of armed conflict and SC/RES/2286 (2016) on attacks on health care.
        </li>
        <li className="9-3-7">
          SDG 16.1.2 on conflict-related deaths and 16.10.1 on the killing of media personnel, trade unionists and human rights advocates.
        </li>
        <li className="9-3-8">
          Available at <a href="http://www.everycasualty.org/standards-v1">http://www.everycasualty.org/standards-v1</a>.
        </li>
      </ol>
    )
  }
};
