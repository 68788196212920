import React from 'react';
import PropTypes from 'prop-types';
import slide1 from '../../images/design/carousel/slide-1.png';
import { t, setI18nConfig } from '../../services/i18n';

setI18nConfig();

const Intro = ({ goToNext }) => (
  <div className="carousel-container">
    <div className="carousel">
      <div className="carousel-image">
        <img src={slide1} alt={t('onboarding.screen1.info.h2')} />
      </div>

      <div className="info">
        <h3>{t('onboarding.screen1.info.h3')}</h3>
        <h2>{t('onboarding.screen1.info.h2')}</h2>
        <p>{t('onboarding.screen1.info.p')}</p>

        <button type="button" className="goto" onClick={goToNext}>
          {t('onboarding.screen1.info.btn')}
        </button>
      </div>
    </div>
  </div>
);

Intro.propTypes = {
  goToNext: PropTypes.func.isRequired
};

export default Intro;
