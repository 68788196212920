import React from 'react';
import chapter2 from './chapter2';
import chapter3 from './chapter3';
import chapter4 from './chapter4';
import chapter5 from './chapter5';
import chapter6 from './chapter6';
import chapter7 from './chapter7';
import chapter8 from './chapter8';
import chapter9 from './chapter9';
import chapter10 from './chapter10';
import chapter11 from './chapter11';
import chapter12 from './chapter12';
import chapter13 from './chapter13';
import chapter14 from './chapter14';
import chapter15 from './chapter15';
import annexI from './annex_i';
import annexII from './annex_ii';
import annexIII from './annex_iii';
import annexIV from './annex_iv';
import annexV from './annex_v';
import annexVI from './annex_vi';
import annexVII from './annex_vii';
import annexVIII from './annex_viii';

export default [
  {
    name: 'Overview of Chapters',
    index: '1',
    color: 'un-blue',
    sections: [
      {
        index: 'Chapter 2',
        name: 'Understanding POC',
        color: 'un-green',
        content: chapter2.content
      },
      {
        index: 'Chapter 3',
        name: 'Overcoming obstacles to POC',
        color: chapter3.color,
        content: chapter3.content
      },
      {
        index: 'Chapter 4',
        name: 'Leading on POC',
        color: chapter4.color,
        content: chapter4.content
      },
      {
        index: 'Chapter 5',
        name: 'Strategic Planning for POC',
        color: chapter5.color,
        content: chapter5.content
      },
      {
        index: 'Chapter 6',
        name: 'Coordinating POC',
        color: chapter6.color,
        content: chapter6.content
      },
      {
        index: 'Chapter 7',
        name: 'Communicating about POC',
        color: chapter7.color,
        content: chapter7.content
      },
      {
        index: 'Chapter 8',
        name: 'Identifying and Prioritizing POC Threats and Risks',
        color: chapter8.color,
        content: chapter8.content
      },
      {
        index: 'Chapter 9',
        name: 'Using Tools for POC',
        color: chapter9.color,
        content: chapter9.content
      },
      {
        index: 'Chapter 10',
        name: 'Working with Communities on POC',
        color: chapter10.color,
        content: chapter10.content
      },
      {
        index: 'Chapter 11',
        name: 'Operational Planning for POC',
        color: chapter11.color,
        content: chapter11.content
      },
      {
        index: 'Chapter 12',
        name: 'Responding to POC Threats',
        color: chapter12.color,
        content: chapter12.content
      },
      {
        index: 'Chapter 13',
        name: 'Integrating Gender Equality and WPS in POC',
        color: chapter13.color,
        content: chapter13.content
      },
      {
        index: 'Chapter 14',
        name: 'Advising on POC',
        color: chapter14.color,
        content: chapter14.content
      },
    ]
  },
  {
    name: "Do's and Don'ts on POC",
    index: '2',
    color: 'un-blue',
    pre_content: chapter15.content,
    sections: chapter15.sections
  },
  {
    name: 'Toolkits',
    index: '3',
    color: 'un-blue',
    sections: [
      {
        name: 'How to Design a Strategic Communications Strategy on POC',
        index: 'Toolkit 1',
        color: 'un-blue',
        content: (
          <>
            <table className="examples margin-bottom toolkit">
              <thead>
                <tr>
                  <td>TOOLKIT</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h4 className="color">
                      How to Design a Strategic Communications Strategy on POC
                    </h4>
                    <h5>What is the role of strategic communication on POC?</h5>
                    <p>
                      Strategic communication in peacekeeping operations is conceptualized, planned, implemented and evaluated in alignment with the mandates, strategic objectives and intended outcomes of a mission. Strategic communication activities function to support a mission’s reputation, outreach, and advocacy goals. Specific strategies for communicating about POC can contribute to POC mandate implementation and should be part of the mission’s comprehensive POC strategy.
                    </p>
                    <h4 className="un-blue">Checklist</h4>
                    <ul className="checklist">
                      <li>
                        When designing POC communication strategies, pay attention to armed actors and community perceptions.
                      </li>
                      <li>
                        Map the specific communication targets (armed groups, communities, host state, media, etc.).
                      </li>
                      <li>
                        Establish profiles and influence strategies for each relevant actor or group of actors.
                      </li>
                      <li>
                        Establish a timeline for both behind-the-scenes and public engagement or advocacy actions, including actions by all mission components and sections.
                      </li>
                      <li>
                        Review costs and benefits of different communication means and media available.
                      </li>
                      <li>
                        Hold regular meetings with community representatives and leaders (tribal, religious, political), as well as youth and women&apos;s groups, community-based organizations and national NGOs at all levels. Bear in mind that leaders may not represent the experiences or perceptions of all sections of the community.
                      </li>
                      <li>
                        Ensure that all mission actors engaging with armed actors are first trained in the POC concept.
                      </li>
                      <li>
                        Ensure that all mission actors engaging with armed actors are integrated into POC planning, strategizing and information sharing, so that messaging is standardized and engagement on POC is coordinated.
                      </li>
                    </ul>
                    <h4 className="un-blue">Reminders</h4>
                    <ul className="reminders">
                      <li>
                        Strategic communications should be planned pre-emptively with clearly identified goals.
                      </li>
                      <li>
                        Effective communication should benefit a mission&apos;s reputation, outreach abilities and advocacy goals.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )
      },
      {
        name: 'How to set up a Community Alert Network (CAN)',
        index: 'Toolkit 2',
        color: 'un-blue',
        content: (
          <>
            <table className="examples">
              <thead>
                <tr>
                  <td>Toolkit</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h4 className="un-purple">
                      How to set up a Community Alert Network (CAN)
                    </h4>
                    <h5>What is a Community Alert Network (CAN)?</h5>
                    <p>
                      A CAN is a network of individuals from the local communities, for example one from each village, who alert the mission when the population is facing a violent threat. The network can rely on mobile phones or other ways to communicate. CANs are usually set up by CLAs. If there is no CLA in the area, one can be assigned on a short-term basis to set up the CAN.
                    </p>
                    <h4 className="un-purple">Checklist</h4>
                    <ul className="checklist un-purple">
                      <li>Identify communities/villages at risk.</li>
                      <li>
                        Identify an individual who can serve as the community focal point to alert the mission about threats.
                      </li>
                      <li>
                        Determine the best means of communication. For example, provide a mobile phone, or phone credit or establish a free hotline number.
                      </li>
                      <li>
                        Where mobile phones do not work, consider other options. For example, the focal point can travel to a neighbouring town to place the call or can use a system of relaying the information by messengers on motorbike from one village to the next.
                      </li>
                      <li>
                        Be clear about what kinds of threats the mission can respond to, and what the mission can and cannot do. For example, how long a response will take, whether it would be a police or military response, etc.
                      </li>
                      <li>Maintain the network by staying in contact.</li>
                      <li>Routinely check and maintain the equipment required.</li>
                      <li>
                        Keep a record of alerts that come in, the mission&apos;s response and the results.
                      </li>
                    </ul>
                    <h4 className="un-purple">Reminders</h4>
                    <ul className="reminders un-purple">
                      <li>
                        Be creative. Use the community&apos;s available tools for communication.
                      </li>
                      <li>Do no harm.</li>
                      <li>Manage expectations.</li>
                      <li>The CAN should focus on early warning before violence occurs.</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )
      },
      {
        name: 'How to Conduct a Joint Protection Team (JPT) Mission',
        index: 'Toolkit 3',
        color: 'un-blue',
        content: (
          <>
            <table className="examples">
              <thead>
                <tr>
                  <td>Toolkit</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h4>How to Conduct a Joint Protection Team (JPT) Mission</h4>
                    <h5>What is a Joint Protection Team (JPT)?</h5>
                    <p>
                      A JPT is a mission team, including civilian, police and military personnel, that conducts field visits. Usually coordinated by Civil Affairs or Human Rights, the purpose of a JPT is to identify threats, inform responses to threats, build ties and improve relations between the mission and local communities, lower tensions and enhance early warning. The JPT should serve to combine civilian and uniformed personnel capabilities to enhance the mission’s capacity to protect.
                    </p>
                    <h4 className="un-purple">Checklist</h4>
                    <p><em>Before the mission:</em></p>
                    <ul className="checklist un-purple">
                      <li>
                        Work with other components and sections in the duty station to identify areas of risk.
                      </li>
                      <li>
                        Determine if the JPTs will be strategic and preventive, deployed along priority axes or responsive to specific incidents or trends.
                      </li>
                      <li>
                        Utilize existing information/sources to gain as comprehensive an understanding of the situation as possible before departure (e.g., CANs, local sources, previous patrol reports, JMAC analysis, human rights monitoring, etc.).
                      </li>
                      <li>
                        Build terms of reference (TORs) that clarify the objective, duration, participating entities and their responsibilities and logistical arrangements for the JPT.
                      </li>
                    </ul>
                    <p><em>On mission:</em></p>
                    <ul className="checklist un-purple">
                      <li>
                        Establish or maintain coordination mechanisms between local communities, local authorities and the mission&apos;s nearest military presence.
                      </li>
                      <li>
                        Analyse local dynamics for protection planning purposes.
                      </li>
                      <li>
                        Work with mission military commanders to develop or update community protection plans.
                      </li>
                      <li>Ensure smooth and safe functioning of CANs.</li>
                      <li>
                        Engage with local authorities and armed actors on the ground to promote respect for human rights and mitigation of harm to civilians.
                      </li>
                    </ul>
                    <p><em>After mission:</em></p>
                    <ul className="checklist un-purple">
                      <li>
                        Make concrete recommendations for mission action at local level and, where necessary, national level to improve the protection of civilians in the area.
                      </li>
                    </ul>

                    <h4 className="un-purple">Reminders</h4>
                    <ul className="reminders un-purple">
                      <li>Do no harm.</li>
                      <li>
                        Military should participate substantively and not just for escort security.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>

          </>
        )
      },
      {
        name: 'How to Conduct a Joint Assessment Mission (JAM)',
        index: 'Toolkit 4',
        color: 'un-blue',
        content: (
          <>
            <table className="examples">
              <thead>
                <tr>
                  <td>TOOLKIT</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h4 className="un-purple">
                      How to Conduct a Joint Assessment Mission (JAM)
                    </h4>
                    <h5>What is a Joint Assessment Mission (JAM)?</h5>
                    <p>
                      A JAM is a protection field visit usually consisting of mission civilian personnel and members of the United Nations Country Team (UNCT) or relevant NGOs. Where appropriate, government authorities may also be included. Similar to a JPT, the purpose of a JAM is to jointly conduct an assessment to identify threats, inform responses to threats, improve relations with local communities, lower tensions and enhance early warning. A JAM will also consider the humanitarian situation.
                    </p>
                    <h4 className="un-purple">Checklist</h4>
                    <ul className="checklist un-purple">
                      <li>
                        Take the same steps as for a JPT (see above), but factor in consultation with UNCT and relevant NGO colleagues, as well as government actors, where relevant, prior to departure to determine priorities and TORs.
                      </li>
                      <li>
                        Ensure that information is shared among mission and humanitarian and development team members, as appropriate.
                      </li>
                    </ul>
                    <h4 className="un-purple">Reminder</h4>
                    <ul className="reminders un-purple">
                      <li>
                        Humanitarian actors may be wary of participating jointly with uniformed colleagues, especially military. Discuss options to best preserve any necessary humanitarian distinction.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )
      },
      {
        name: 'How to Develop a Community Protection Plan (CPP)',
        index: 'Toolkit 5',
        color: 'un-blue',
        content: (
          <>
            <table className="examples toolkit">
              <thead>
                <tr>
                  <td>TOOLKIT</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h4 className="un-purple">
                      How to Develop a Community Protection Plan (CPP)
                    </h4>
                    <h5>What is a Community Protection Plan (CPP)?</h5>
                    <p>
                      A CPP is a plan developed by the mission for the protection of a particular population or area, taking into account the specific dynamics and context of the community. CPPs are normally developed jointly by uniformed colleagues and Community Liaison Assistants (CLAs), with the support and guidance of Civil Affairs. An effective CPP should include a CAN or a network of CANs, as well as information on key community leaders, effective forms of mitigation relevant to local populations and a strategy for engagement with the local population as well as potential perpetrators.
                    </p>
                    <h4 className="un-purple">Checklist</h4>
                    <ul className="checklist un-purple">
                      <li>
                        Undertake a robust mapping of the community. Take into account drivers of conflict, historical context and grievances, key leadership, spoilers, minority groups, youth and women&apos;s representation and the network of relations that exist between major actors.
                      </li>
                      <li>
                        Engage all relevant actors in the community in the consultation process.
                      </li>
                      <li>
                        Include the best means of communication with each relevant local actor in the CPP.
                      </li>
                      <li>
                        Identify likely threats, possible triggers, preventive action and planned responses to protect civilians.
                      </li>
                      <li>
                        Identify vulnerable individuals and infrastructure (e.g., schools and medical facilities).
                      </li>
                      <li>
                        Establish locations for locals to meet military or police escorts, or &apos;safe areas&apos; where the population can seek refuge.
                      </li>
                      <li>
                        Establish a CAN or a series of CANs to function as part of the overall CPP.
                      </li>
                      <li>
                        Share the plan with the local community so that they know what to expect of the mission.
                      </li>
                    </ul>
                    <h4 className="un-purple">Reminder</h4>
                    <ul className="reminders un-purple">
                      <li>
                        Consult with women&apos;s organizations when creating a CPP.
                      </li>
                      <li>Update the CPP periodically.</li>
                      <li>
                        Be realistic, do not overpromise the protection the mission can provide.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )
      },
      {
        name: 'How to Conduct Casualty Recording',
        index: 'Toolkit 6',
        color: 'un-blue',
        content: (
          <>
            <table className="examples">
              <thead>
                <tr>
                  <td>TOOLKIT</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h4 className="un-purple">How to Conduct Casualty Recording</h4>
                    <h5>What is casualty recording?</h5>
                    <p>
                      Casualty recording is the process of recording and verifying information on individuals killed and injured, often in a conflict setting, or otherwise as a result of violence. Where information on individuals killed and injured is not available, information can be recorded on incidents that reportedly result in casualties. Casualty recording usually involves using a database to store and manage information, but it can be as simple as keeping lists or tables, where resources are limited, as long as the information is securely stored and managed, and confidentiality and informed consent can be fully respected.
                    </p>
                    <h4 className="un-purple">Checklist</h4>
                    <ul className="checklist un-purple">
                      <li>
                        Define who is a ‘casualty&apos;: Identify the legal framework, assess what information is available, set the boundaries of who will be included/excluded.
                      </li>
                      <li>
                        Standardize data categories: Identify and define terms to be used.
                      </li>
                      <li>
                        Verify information: Set out how to verify information, including the types of sources to be used.
                      </li>
                      <li>Identify minimum data and disaggregation required.</li>
                      <li>
                        Develop and use appropriate and secure information management tools.
                      </li>
                      <li>
                        Apply a standard of proof: Set out the standard of proof to be applied in deciding whether the information is sufficient to believe that the casualties occurred as described.
                      </li>
                      <li>
                        Establish internal quality control: Set out methods for quality control of information; clarify roles and responsibilities for quality control.
                      </li>
                      <li>
                        Determine how and when information will be used and how it will be presented.
                      </li>
                      <li>
                        Measure progress: Periodically review the context and determine the impact/outcomes of casualty recording.
                      </li>
                    </ul>
                    <h4 className="un-purple">Reminders</h4>
                    <div className="half">
                      <div>
                        <p>To strengthen the data:</p>
                        <ul className="reminders un-purple">
                          <li>Ensure accuracy and precision</li>
                          <li>Preserve impartiality</li>
                          <li>Uphold objectivity</li>
                          <li>Ensure transparency about methodology</li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          To protect cooperating persons by reducing any possible negative repercussions:
                        </p>
                        <ul className="reminders un-purple">
                          <li>Respect confidentiality</li>
                          <li>Ensure informed consent</li>
                          <li>Do no harm</li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )
      },
      {
        name: 'How to Protect Civilians in an Election Context',
        index: 'Toolkit 7',
        color: 'un-blue',
        content: (
          <>
            <div className="case-study">
              <h3>TOOLKIT</h3>
              <h4 className="un-red">
                How to Protect Civilians in an Election Context
              </h4>
              <div className="content">
                <h5>What to consider in an election context?</h5>
                <p>
                  Whether or not support to elections is within the peacekeeping mission mandate, local and national elections often take place in contexts where peace operations are deployed. During election periods, tensions can run high, and contentious political support groups may resort to forms of violence that threaten civilian populations. UNPOL will often be best placed within the mission to provide protection to civilians in such contexts, either directly or through support to state actors.
                </p>
                <h4 className="un-red">Checklist</h4>
                <ul className="checklist un-red">
                  <li>
                    Before and during an election period, remain abreast of political dynamics and seek forward-looking analyses. Consult Political Affairs, Electoral Affairs, Human Rights and JMAC.
                  </li>
                  <li>
                    Map critical dates, political leaders and priority zones for potential election-related tensions and violence.
                  </li>
                  <li>
                    Engage with authorities to advocate respect towards peaceful demonstrations and to restrict the use of lethal force. Provide capacity building to these ends, including human rights and public-order management training for national police, where appropriate.
                  </li>
                  <li>
                    Engage with local communities, and, where necessary, create Community Alert Networks (CANs). Build community capacity to raise election-related concerns with local authorities.
                  </li>
                  <li>
                    Where applicable, provide support to the government security plan and establish a show of presence (not a show of force).
                  </li>
                  <li>
                    Develop a network of mission staff, primarily Human Rights Officers, to deploy to potential hotspots and monitor the situation, including any election-related violence.
                  </li>
                  <li>
                    Conduct TTXs, including with Headquarters, to ensure preparedness for all potential scenarios.
                  </li>
                </ul>
                <h4 className="un-red">Reminders</h4>
                <ul className="reminders un-red">
                  <li>
                    Violence can erupt during the campaigning period, on voting day(s) or after results are announced.
                  </li>
                  <li>
                    Providing protection in an election context should be a police activity, not a military activity. Deploy FPUs where available. QRFs should be on standby as needed.
                  </li>
                  <li>
                    Election violence often occurs in an urban setting. The mission may need to adapt operations to reflect this, particularly where the mission’s usual POC activities and responses are in more rural areas.
                  </li>
                </ul>
              </div>
            </div>
          </>
        )
      }
    ]
  },
  {
    name: 'Templates',
    index: '4',
    color: 'un-blue',
    sections: [
      {
        index: 'Template 1',
        name: 'POC Strategy',
        color: 'un-blue',
        content: (
          <>
            <div className="half template-container">
              <div className="template margin-top">
                <h4>TEMPLATE – POC Strategy</h4>
                <ol type="A">
                  <li>
                    Purpose, scope and key principles
                    <ol type="1">
                      <li>Purpose</li>
                      <li>Scope</li>
                      <li>Key principles</li>
                    </ol>
                  </li>
                  <li>Strategic threat assessment</li>
                  <li>Strategic objectives, indicators and risks</li>
                  <li>POC response model and planned activities</li>
                  <li>
                    Analysis of mission capacities, resources and national caveats
                  </li>
                </ol>
                <p>
                  <strong>Annexes to POC strategy or separate SOPs:</strong>
                  <br />
                  Engagement and coordination mechanisms
                </p>
                <p>
                  <strong>Annexes to POC strategy or separate SOPs:</strong>
                  <br />
                  Preparedness activities
                </p>
              </div>
              <div>
                <p>
                  Based on this template, below is a suggested approach, including key elements to include:
                </p>
                <p className="un-navy bold">
                  A. Purpose, scope and key principles
                </p>
                <ol type="1">
                  <li>
                    <strong>Purpose: </strong>A succinct statement of the purpose and objectives of the strategy including any specific requirements under the mission-specific POC mandate.
                  </li>
                  <li>
                    <strong>Scope</strong>: This section should address:
                    <p>
                      <strong><em>Compliance: </em></strong>A clear statement that compliance is mandatory for all mission staff. It should also note those aspects that may require coordination with other UN entities.
                    </p>
                    <p>
                      <strong><em>Temporal and geographic scope: </em></strong>The timeframe of the POC strategy and the area to which it applies (normally the whole mission area).
                    </p>
                    <p>
                      <strong><em>References, alignment and existing strategies:</em></strong>The POC strategy does not exist in isolation and must be aligned and linked with other existing protection-related strategies and planning documents, both within the mission and externally. These should be listed here. Specific attention will be given to linking and complementing priorities identified by the host government and any existing protection strategies that may have been developed by the Protection Cluster and its sub-clusters. In integrated mission settings, the POC strategy should be explicitly linked to the Integrated Strategic Framework (ISF), which is designed to articulate the UN&apos;s strategic vision in a mission context and must include POC as a priority. POC strategies should also reflect and be aligned with the military and police CONOPS, ROE and DUF.
                    </p>
                    <p>
                      <strong><em>Review of the strategy: </em></strong>Date for review of the strategy linked with other mission planning processes (e.g., the CPAS/Results Based Budget) or as a response to changes in the mission mandate and/or operating context.
                    </p>
                  </li>
                  <li>
                    <strong>Key Principles</strong>: Brief reminder of the guiding principles from the DPO POC Policy, tailored to the mission&apos;s environment.
                  </li>
                </ol>
              </div>
            </div>
          </>
        )
      },
      {
        index: 'Template 2',
        name: 'Planning and Preparedness for Strategic Communications',
        color: 'un-blue',
        content: (
          <>
            <div className="template margin-top">
              <h4>
                TEMPLATE – Liaison Matrix for planning and tracking POC communications
              </h4>
              <table className="grid">
                <thead>
                  <tr>
                    <td width="33%">Target audience</td>
                    <td width="33%">Activity/means</td>
                    <td width="33%">Message</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>To whom the communication is aimed</td>
                    <td>
                      What activity or means of communication will the mission use to engage/communicate with the intended audience
                    </td>
                    <td>What key messages are being imparted</td>
                  </tr>
                </tbody>
              </table>

              <table className="grid">
                <thead>
                  <tr>
                    <td width="33%">Comment</td>
                    <td width="33%">Mission component</td>
                    <td width="33%">Release authority</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Include any related activities, risks or issues to consider when designing or conducting the activity or message
                    </td>
                    <td>Who will lead the communication</td>
                    <td>
                      Who in the mission will authorize the action or communication
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      },
      {
        index: 'Template 3',
        name: 'POC Threats and Risk Assessment',
        color: 'un-blue',
        content: (
          <>
            <div className="template">
              <h5>TEMPLATE – Analysis of Threats and Perpetrators</h5>
              <p className="un-green bold">Nature, identity, profiles</p>
              <p>
                Perpetrators of violence against civilians include elements of national and international security forces, non-state armed groups, criminals and other civilians.
              </p>
              <p className="un-green bold">Motivation, interest or intent</p>
              <p>
                Violence against civilians may be strategic and systematic, opportunistic or even accidental; indiscriminate or targeted at an individual or group; motivated by power, ideology or identity (cultural, ethnic, tribal or religious), fear, survival or greed.
              </p>
              <p className="un-green bold">
                Structure, capabilities and resources, preparedness
              </p>
              <p>
                Command and control; human, material and financial means; supply lines.
              </p>
              <p className="un-green bold">Presence and deployments</p>
              <p>
                Location(s); size; specialized capacities; availability of reinforcements.
              </p>
              <p className="un-green bold">Relations</p>
              <p>
                Political, security, economic and social supporters and allies; affiliation and clients; relationship with the mission.
              </p>
              <p className="un-green bold">
                Mode of action or tactics, techniques and procedures (TTPs)
              </p>
              <p>
                Patterns of attacks or other forms of violence (including attacks on specific categories of civilians such as women, children or IDPs, etc.); movements of potential perpetrators; access to weapons.
              </p>
              <p className="un-green bold">History</p>
              <p>
                Monitoring of violations of international law will provide further information on the gravity, intensity, pattern and scale of physical violence affecting civilians and enable trend and impact analysis.
              </p>
            </div>
          </>
        )
      },
      {
        index: 'Template 4',
        name: 'POC Threat Matrix',
        color: 'un-blue',
        content: (
          <>
            <div className="template padding-left margin-top">
              <h4>TEMPLATE – POC Threat Matrix</h4>
              <table className="grid border">
                <thead>
                  <tr>
                    <td />
                    <td>Priority level</td>
                    <td>Risk level</td>
                    <td>Location</td>
                    <td>Threat Type &amp; Scale (if known)</td>
                    <td>Civilians at Risk &amp; Vulnerabilities</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>1/2/3</td>
                    <td>
                      High<br />
                      Medium<br />
                      Low
                    </td>
                    <td>Should be as specific as possible</td>
                    <td>Type of violence/violation </td>
                    <td>Note groups particularly at risk</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      },
      {
        index: 'Template 5',
        name: 'Quarterly Forward-looking Reporting',
        color: 'un-blue',
        content: (
          <>
            <div className="template margin-top margin-bottom">
              <h4>
                TEMPLATE – Quarterly Forward-Looking Threat Assessments
              </h4>
              <p className="un-green bold">
                [Quarter] report<br />
                [Mission]
              </p>
              <ol type="I" className="color bold">
                <li>
                  Summary of threats to civilians
                  <ol type="a">
                    <li>
                      Summary of previously identified and prioritized threats and communities-at-risk
                    </li>
                    <li>
                      Summary of recent trends/incidents
                      <ol type="i">
                        <li>
                          Activity of armed forces or groups (including recruitment trends)
                        </li>
                        <li>Patterns of attacks on civilians</li>
                        <li>Civilian casualty estimates</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  Forward-looking analysis
                  <ol type="a">
                    <li>
                      Changes or potential changes to previous threat assessments, including:
                      <ol type="i">
                        <li>Status of armed groups, other threats</li>
                        <li>Elections, negotiations, other political processes</li>
                        <li>
                          Seasonal variation (dry/rainy season, migration, etc.)
                        </li>
                        <li>Economy, food security, flooding, drought</li>
                      </ol>
                    </li>
                    <li>
                      Observations regarding potential impact on civilians
                    </li>
                  </ol>
                </li>
                <li>Anticipated response</li>
              </ol>
            </div>
          </>
        )
      },
      {
        index: 'Template 6',
        name: 'Resources and Capabilities',
        color: 'un-blue',
        content: (
          <>
            <div className="template">
              <h4>TEMPLATE – POC Resources and Capabilities Matrix</h4>
              <p>
                This matrix charts the resources and capabilities within a mission according to the objectives and activities of the mission-wide POC strategy. It also identifies critical gaps that may hinder the mission in effectively implementing its POC mandate. The criticality of the gap is rated according to the following index:
              </p>
              <p>
                <strong>RED </strong>signifies that the mission is unable to implement key POC mandate tasks because of the lack of adequate resources.
              </p>
              <p>
                <strong>ORANGE </strong>signifies that the mission is able to implement a POC mandated task but not optimally and effectively because of insufficient/inadequate resources.
              </p>
              <p>
                <strong>GREEN </strong>signifies that the mission does not experience any significant resources gap related to the POC mandated task.
              </p>
              <table className="with-borders small">
                <thead>
                  <tr>
                    <td colSpan="7">
                      <h4>TIER 1: Protection through Dialogue and Engagement</h4>
                    </td>
                  </tr>
                  <tr>
                    <td>OBJECTIVE</td>
                    <td>ACTIVITIES</td>
                    <td>EXISTING RESOURCES</td>
                    <td>CURRENT CAPABILITIES</td>
                    <td>GAPS (LACK OF EFFECT)</td>
                    <td>GAP CRITICALITY</td>
                    <td>RECOMMENDATIONS</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan="3">
                      Increased willingness and capacity of the Government ofX,in particular the State security forces, to fulfil its primary responsibility to provide security and protection for civilians
                    </td>
                    <td>
                      Advocacy towards, and dialogue with, GoX to ensure better understand- ing and implementation of the state’s responsibility to protect civilians
                    </td>
                    <td />
                    <td />
                    <td />
                    <td><div className="circle green" /></td>
                    <td />
                  </tr>
                  <tr>
                    <td>
                      Support the development of GoX policies and strategies on the protection of civilians
                    </td>
                    <td />
                    <td />
                    <td />
                    <td><div className="circle orange" /></td>
                    <td />
                  </tr>
                  <tr>
                    <td>
                      Promote improved conduct of national security forces in locations where civilians are at risk of physical attack
                    </td>
                    <td />
                    <td />
                    <td />
                    <td><div className="circle red" /></td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      },
      {
        index: 'Template 7',
        name: 'Conducting POC-sensitive Police and Military Operations',
        color: 'un-blue',
        content: (
          <>
            <div className="template margin-bottom">
              <h4>
                <span className="un-navy">TEMPLATE</span> – SRSG/DSRSGs Security Operations Brief
              </h4>
              <ol type="I" className="un-navy">
                <li>
                  <span className="un-navy">Information Brief</span>
                  <ol type="a">
                    <li>
                      Target Analysis
                      <ol type="i">
                        <li>What</li>
                        <li>Why</li>
                        <li>Where</li>
                        <li>Who</li>
                        <li>When (immediate, when ready, contingency, etc.)</li>
                      </ol>
                    </li>
                    <li>Legal/Mandate Basis</li>
                    <li>
                      Impact Analysis
                      <ol type="i">
                        <li>Political (Pros and Cons)</li>
                        <li>Socio-economic (Pros and Cons)</li>
                        <li>Protection of Civilians (Pros and Cons)</li>
                        <li>Security (Pros and Cons)</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <span className="un-navy">Information Brief</span>
                  <ol type="a">
                    <li>Force Commander’s Assessment and Intent</li>
                    <li>
                      Options
                      <ol type="i">
                        <li>
                          Option 1
                          <ol type="1">
                            <li>Outline</li>
                            <li>Pros and Cons</li>
                            <li>Potential Collateral Damage</li>
                          </ol>
                        </li>
                        <li>
                          Option 2
                          <ol type="1">
                            <li>Outline</li>
                            <li>Pros and Cons</li>
                            <li>Potential Collateral Damage</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>Force Commander&apos;s Recommendation</li>
                    <li>
                      Consultation
                      <ol type="i">
                        <li>UN Headquarters</li>
                        <li>National/Regional Partners</li>
                      </ol>
                    </li>
                    <li>SRSG&apos;s Decision</li>
                  </ol>
                </li>
                <li>
                  <span className="un-navy">Action Brief</span>
                  <ol type="a">
                    <li>
                      Tasking
                      <ol type="i">
                        <li>Force HQ</li>
                        <li>Police Component</li>
                        <li>Civilian Pillars</li>
                        <li>Director of Mission Support</li>
                        <li>Head of Office</li>
                      </ol>
                    </li>
                    <li>
                      Communication
                      <ol type="i">
                        <li>What</li>
                        <li>When</li>
                        <li>To Whom</li>
                        <li>By Whom</li>
                      </ol>
                    </li>
                    <li>
                      Operational Security
                      <ol type="i">
                        <li>
                          Timeline for release of information/coordination/action
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </>
        ),
      }
    ],
  },
  {
    name: 'Annexes',
    index: '5',
    color: 'un-blue',
    sections: [
      {
        index: '1',
        name: annexI.name,
        content: annexI.content
      },
      {
        index: '2',
        name: annexII.name,
        content: annexII.content
      },
      {
        index: '3',
        name: annexIII.name,
        content: annexIII.content
      },
      {
        index: '4',
        name: annexIV.name,
        content: annexIV.content
      },
      {
        index: '5',
        name: annexV.name,
        content: annexV.content
      },
      {
        index: '6',
        name: annexVI.name,
        content: annexVI.content
      },
      {
        index: '7',
        name: annexVII.name,
        content: annexVII.content
      },
      {
        index: '8',
        name: annexVIII.name,
        content: annexVIII.content
      }
    ]
  }
];
