import React from 'react';
import visit from '../images/chapters/chapter11/MINUSMA_head_visits_timbuktu.jpg';
import improve from '../images/chapters/chapter11/MONUSCO_aiming_to_improve_FARDC_effectiveness.jpg';
import santos from '../images/chapters/chapter11/MONUSCO_santos_cruz.jpg';
import secure from '../images/chapters/chapter11/MONUSCO_secures_area.jpg';
import darfur from '../images/chapters/chapter11/UNAMID_deputy_JSR-protection_visits_sortony_north_darfur.jpg';
import koffiWogomebouAvatar from '../images/avatars/Koffi_Wogomebou.png';
import { t, setI18nConfig } from '../services/i18n';
import { renderSanitizedHTMLElement } from '../helpers/renderSanitizedHTMLElement';
import audio from './audio';
import AudioPlayer from '../components/audio';

setI18nConfig();
export default {
  name: 'Chapter 11: Integration POC into Operational Planning and Coordination',
  title: t('handbook.chapters.chapter11.title'),
  index: '11',
  color: 'un-navy',
  content: (
    <>
      <div className="grid-three un-navy">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            Missions should ensure that they have the necessary capacity and resources available to implement the POC mandate, and that staff are prepared through the necessary training and understand the necessity of coordination. All mission operations and activities can be designed in a way that is sensitive to the POC mandate. Effective planning for POC operations entails readiness for crises. Yet, critical situations can arise that even early warning systems may not fully predict. Hence, operational planning includes designing crisis management mechanisms and activation procedures that can address both anticipated and unanticipated critical situations.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            The protection of civilians is a priority mandate and thus should be given primacy in decisions regarding the allocation and use of available capacity and resources. As missions must protect civilians from potential harm caused by the mission’s own actions, including unintended consequences of mission activities or operations, the planning and execution of military or police operations must involve civilian harm mitigation. Without sufficient preparedness, missions will not be able to manage crises.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            POC should be integrated into all mission planning, preparedness and coordination at the operational level as well as the planning of operations by uniformed components. Mission-specific SOPs and guidance, training, scenario and table top exercises (TTXs), contingency planning and AARs will all contribute to preparedness. Situations of threats to civilians should be built into crisis management protocols, planning and rehearsals.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            Mission leadership are responsible for ensuring that all mission personnel are trained and prepared to implement their tasks and responsibilities under the POC mandate. Mission support should ensure that the necessary resources and capabilities are available for implementing the POC mandate. While military and police operations will be planned and executed by uniformed personnel, this should be done in consultation with civilian staff who can help to apply a POC lens and identify possible consequences for civilians and appropriate mitigating measures. In the case of POC crises, POC Advisers support mission leadership. When mission crisis response mechanisms are activated, JOCs facilitate the crisis response process.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            Preparation for POC is done at both mission headquarters and mission field offices, with support from UN Headquarters as needed.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            Preparation for POC in the mission should begin at mission start-up, with regular review of available resources and capabilities. Integrating POC in operations occurs before, during and after security operations. Crisis management processes should be activated, as necessary, when the mission is faced with significant and/or large-scale threats to civilians.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '11.1',
      name: 'Integration POC into Operational Planning and Coordination',
      title: t('handbook.chapters.chapter11.1.title'),
      content: (
        <>
          <p>
            {t('handbook.chapters.chapter11.1.pg-1')}
          </p>
          <p>
            {t('handbook.chapters.chapter11.1.pg-2')}
          </p>
          <ul className="square un-navy">
            <li>
              {t('handbook.chapters.chapter11.1.list.li-1')}
            </li>
            <li>
              {t('handbook.chapters.chapter11.1.list.li-2')}
            </li>
            <li>
              {t('handbook.chapters.chapter11.1.list.li-3')}
            </li>
            <li>
              {t('handbook.chapters.chapter11.1.list.li-4')}
            </li>
            <li>
              {t('handbook.chapters.chapter11.1.list.li-5')}
            </li>
            <li>
              {t('handbook.chapters.chapter11.1.list.li-6')}
            </li>
            <li>
              {t('handbook.chapters.chapter11.1.list.li-7')}
            </li>
            <li>
              {t('handbook.chapters.chapter11.1.list.li-8')}
            </li>
          </ul>
        </>
      )
    },
    {
      index: '11.2',
      name: 'Resources and Capabilities',
      title: t('handbook.chapters.chapter11.2.title'),
      content: (
        <>
          {renderSanitizedHTMLElement('handbook.chapters.chapter11.2.pg-1')}
          {renderSanitizedHTMLElement('handbook.chapters.chapter11.2.pg-2')}
          <div className="half side-by-side">
            <div>
              <h4 className="un-navy">
                {t('handbook.chapters.chapter11.2.side-by-side.left.title')}
              </h4>
              <ul className="square">
                <li>{t('handbook.chapters.chapter11.2.side-by-side.left.1')}</li>
                <li>
                  {t('handbook.chapters.chapter11.2.side-by-side.left.2')}
                </li>
                <li>
                  {t('handbook.chapters.chapter11.2.side-by-side.left.3')}
                </li>
                <li>
                  {t('handbook.chapters.chapter11.2.side-by-side.left.4')}
                </li>
                <li>
                  {t('handbook.chapters.chapter11.2.side-by-side.left.5')}
                </li>
              </ul>
            </div>
            <div>
              <h4 className="un-navy">
                {t('handbook.chapters.chapter11.2.side-by-side.right.title')}
              </h4>
              <ul className="square">
                <li>
                  {t('handbook.chapters.chapter11.2.side-by-side.right.1')}
                </li>
                <li>
                  {t('handbook.chapters.chapter11.2.side-by-side.right.2')}
                </li>
                <li>
                  {t('handbook.chapters.chapter11.2.side-by-side.right.3')}
                </li>
              </ul>
            </div>
          </div>

          <p>
            {t('handbook.chapters.chapter11.2.pg-3')}
          </p>
          <ul className="square un-navy">
            {renderSanitizedHTMLElement('handbook.chapters.chapter11.2.list.1', 'li')}
            {renderSanitizedHTMLElement('handbook.chapters.chapter11.2.list.2', 'li')}
            {renderSanitizedHTMLElement('handbook.chapters.chapter11.2.list.3', 'li')}
          </ul>
          <p>
            {t('handbook.chapters.chapter11.2.pg-4')}
          </p>
          <div className="template">
            <h4>{t('handbook.chapters.chapter11.2.template-1.title')}</h4>
            <p>
              {t('handbook.chapters.chapter11.2.template-1.pg-1')}
            </p>
            {renderSanitizedHTMLElement('handbook.chapters.chapter11.2.template-1.pg-2')}
            {renderSanitizedHTMLElement('handbook.chapters.chapter11.2.template-1.pg-3')}
            {renderSanitizedHTMLElement('handbook.chapters.chapter11.2.template-1.pg-4')}
            <table className="with-borders small">
              <thead>
                <tr>
                  <td colSpan="7">
                    <h4>{t('handbook.chapters.chapter11.2.template-1.table.title')}</h4>
                  </td>
                </tr>
                <tr>
                  <td>{t('handbook.chapters.chapter11.2.template-1.table.headings.heading-1')}</td>
                  <td>{t('handbook.chapters.chapter11.2.template-1.table.headings.heading-2')}</td>
                  <td>{t('handbook.chapters.chapter11.2.template-1.table.headings.heading-3')}</td>
                  <td>{t('handbook.chapters.chapter11.2.template-1.table.headings.heading-4')}</td>
                  <td>{t('handbook.chapters.chapter11.2.template-1.table.headings.heading-5')}</td>
                  <td>{t('handbook.chapters.chapter11.2.template-1.table.headings.heading-6')}</td>
                  <td>{t('handbook.chapters.chapter11.2.template-1.table.headings.heading-7')}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan="3">
                    {t('handbook.chapters.chapter11.2.template-1.table.pg-1')}
                  </td>
                  <td>
                    {t('handbook.chapters.chapter11.2.template-1.table.pg-2')}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td><div className="circle green" /></td>
                  <td />
                </tr>
                <tr>
                  <td>
                    {t('handbook.chapters.chapter11.2.template-1.table.pg-3')}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td><div className="circle orange" /></td>
                  <td />
                </tr>
                <tr>
                  <td>
                    {t('handbook.chapters.chapter11.2.template-1.table.pg-4')}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td><div className="circle red" /></td>
                  <td />
                </tr>
              </tbody>
            </table>
          </div>

          <h5 className="un-navy">{t('handbook.chapters.chapter11.2.h5')}</h5>
          <p>
            {t('handbook.chapters.chapter11.2.pg-5')}
          </p>
          <div className="vs">
            <div>
              <h4 className="un-navy">{t('handbook.chapters.chapter11.2.side-vs-side.left.title')}</h4>
              <p>
                {t('handbook.chapters.chapter11.2.side-vs-side.left.pg-1')}
              </p>
            </div>
            <span className="un-navy">{t('handbook.chapters.chapter11.2.side-vs-side.center-text')}</span>
            <div>
              <h4 className="un-navy">{t('handbook.chapters.chapter11.2.side-vs-side.right.title')}</h4>
              <p>
                {t('handbook.chapters.chapter11.2.side-vs-side.right.pg-1')}
              </p>
            </div>
          </div>
        </>
      )
    },
    {
      index: '11.3',
      name: 'Training',
      title: t('handbook.chapters.chapter11.3.title'),
      content: (
        <>
          {renderSanitizedHTMLElement('handbook.chapters.chapter11.3.pg-1', 'p')}
          {renderSanitizedHTMLElement('handbook.chapters.chapter11.3.pg-2', 'p')}
          {renderSanitizedHTMLElement('handbook.chapters.chapter11.3.pg-3', 'p')}
          <p>
            {t('handbook.chapters.chapter11.3.pg-4')}
          </p>
          <p>
            {t('handbook.chapters.chapter11.3.pg-5')}
          </p>
          <p>
            {t('handbook.chapters.chapter11.3.pg-6')}
          </p>
        </>
      )
    },
    {
      index: '11.4',
      name: 'Conducting POC-sensitive Police and Military Operations',
      title: t('handbook.chapters.chapter11.4.title'),
      content: (
        <>
          <div className="sub-section 11-4-1">
            <h5>
              <span className="un-navy">11.4.1</span> {t('handbook.chapters.chapter11.4.1.title')}
            </h5>
            <p>
              {t('handbook.chapters.chapter11.4.1.pg-1')}
            </p>
            <p>
              {t('handbook.chapters.chapter11.4.1.pg-2')}
            </p>

            <div className="relative">
              <img src={secure} alt={t('handbook.chapters.chapter11.4.1.photo-1.alt')} />
              <div className="description">
                <p>
                  {t('handbook.chapters.chapter11.4.1.photo-1.description')}
                </p>
              </div>
              <p className="ref">{t('handbook.chapters.chapter11.4.1.photo-1.ref')}</p>
            </div>
            <p>
              {t('handbook.chapters.chapter11.4.1.pg-3')}
            </p>
            <p>
              {t('handbook.chapters.chapter11.4.1.pg-4')}
            </p>
            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.1.pg-5')}

            <div className="template margin-bottom">
              <table className="templateSecurityOperations">
                <thead>
                  <tr>
                    <td colSpan="2">
                      <h4>
                        {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.1.template-1.title', 'span')}
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ol type="I" className="un-navy">
                        <li>
                          <span className="un-navy">{t('handbook.chapters.chapter11.4.1.template-1.list.i.title')}</span>
                          <ol type="a">
                            <li>
                              <span>{t('handbook.chapters.chapter11.4.1.template-1.list.i.a.title')}</span>
                              <ol type="i">
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.i.a.i')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.i.a.ii')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.i.a.iii')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.i.a.iv')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.i.a.v')}</li>
                              </ol>
                            </li>
                            <li>
                              <span>{t('handbook.chapters.chapter11.4.1.template-1.list.i.b.title')}</span>
                            </li>
                            <li>
                              <span>{t('handbook.chapters.chapter11.4.1.template-1.list.i.c.title')}</span>
                              <ol type="i">
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.i.c.i')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.i.c.ii')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.i.c.iii')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.i.c.iv')}</li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <span className="un-navy">{t('handbook.chapters.chapter11.4.1.template-1.list.ii.title')}</span>
                          <ol type="a">
                            <li>
                              <span>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.a.title')}</span>
                            </li>
                            <li>
                              <span>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.b.title')}</span>
                              <ol type="i">
                                <li>
                                  {t('handbook.chapters.chapter11.4.1.template-1.list.ii.b.i.title')}
                                  <ol type="1">
                                    <li>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.b.i.1')}</li>
                                    <li>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.b.i.2')}</li>
                                    <li>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.b.i.3')}</li>
                                  </ol>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </td>
                    <td>
                      <ol type="I" start="1" className="un-navy">
                        <li style={{ listStyleType: 'none' }}>
                          <li>
                            <ol type="i" start="2">
                              <li>
                                {t('handbook.chapters.chapter11.4.1.template-1.list.ii.b.ii.title')}
                                <ol type="1">
                                  <li>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.b.ii.1')}</li>
                                  <li>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.b.ii.2')}</li>
                                  <li>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.b.ii.3')}</li>
                                </ol>
                              </li>
                            </ol>
                          </li>
                          <ol type="a" start="3">
                            <li>
                              <span>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.c.title')}</span>
                            </li>
                            <li>
                              <span>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.d.title')}</span>
                              <ol type="i">
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.d.i')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.d.ii')}</li>
                              </ol>
                            </li>
                            <li>
                              <span>{t('handbook.chapters.chapter11.4.1.template-1.list.ii.e.title')}</span>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <span className="un-navy">{t('handbook.chapters.chapter11.4.1.template-1.list.iii.title')}</span>
                          <ol type="a">
                            <li>
                              <span>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.a.title')}</span>
                              <ol type="i">
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.a.i')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.a.ii')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.a.iii')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.a.iv')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.a.v')}</li>
                              </ol>
                            </li>
                            <li>
                              <span>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.b.title')}</span>
                              <ol type="i">
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.b.i')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.b.ii')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.b.iii')}</li>
                                <li>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.b.iv')}</li>
                              </ol>
                            </li>
                            <li>
                              <span>{t('handbook.chapters.chapter11.4.1.template-1.list.iii.c.title')}</span>
                              <ol type="i">
                                <li>
                                  {t('handbook.chapters.chapter11.4.1.template-1.list.iii.c.i')}
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="relative">
              <img src={darfur} alt={t('handbook.chapters.chapter11.4.1.photo-2.alt')} />
              <div className="description">
                <p>
                  {t('handbook.chapters.chapter11.4.1.photo-2.description')}
                </p>
              </div>
              <p className="ref">{t('handbook.chapters.chapter11.4.1.photo-2.ref')}</p>
            </div>
          </div>
          <div className="sub-section 11-4-2">
            <h5>
              <span className="un-navy">11.4.2</span> {t('handbook.chapters.chapter11.4.2.title')}
            </h5>
            <p>
              {t('handbook.chapters.chapter11.4.2.pg-1')}
            </p>
            <p>
              {t('handbook.chapters.chapter11.4.2.pg-2')}
            </p>
            <p>
              {t('handbook.chapters.chapter11.4.2.pg-3')}
            </p>
            <ul className="square un-navy">
              <li>
                {t('handbook.chapters.chapter11.4.2.list.1')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.2.list.2')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.2.list.3')}
              </li>
            </ul>
            <p>
              {t('handbook.chapters.chapter11.4.2.pg-4')}
            </p>
            <p>
              {t('handbook.chapters.chapter11.4.2.pg-5')}
            </p>
          </div>
          <div className="sub-section 11-4-3">
            <h5>
              <span className="un-navy">11.4.3</span> {t('handbook.chapters.chapter11.4.3.title')}
            </h5>
            <p>
              {t('handbook.chapters.chapter11.4.3.pg-1')}
            </p>
            <ul className="square un-navy">
              <li>
                {t('handbook.chapters.chapter11.4.3.list.1')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.3.list.2')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.3.list.3.title')}
                <ul className="triangle un-navy">
                  <li>
                    {t('handbook.chapters.chapter11.4.3.list.3.1')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.3.list.3.2')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.3.list.3.3')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.3.list.3.4')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.3.list.3.5')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.3.list.3.6')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.3.list.3.7')}
                  </li>
                </ul>
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.3.list.4')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.3.list.5')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.3.list.6')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.3.list.7')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.3.list.8')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.3.list.9')}
              </li>
            </ul>
          </div>
          <div className="sub-section 11-4-4">
            <h5>
              <span className="un-navy">11.4.4</span> {t('handbook.chapters.chapter11.4.4.title')}
            </h5>
            <p>
              {t('handbook.chapters.chapter11.4.4.pg-1')}
            </p>
            <ul className="square un-navy">
              <li>
                {t('handbook.chapters.chapter11.4.4.list.1.title')}
                <ul className="triangle un-navy">
                  <li>
                    {t('handbook.chapters.chapter11.4.4.list.1.1')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.4.list.1.2')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.4.list.1.3')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.4.list.1.4')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.4.list.1.5')}
                  </li>
                </ul>
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.4.list.2')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.4.list.3')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.4.list.4')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.4.list.5')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.4.list.6')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.4.list.7')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.4.list.8')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.4.list.9')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.4.4.list.10')}
              </li>
            </ul>
            <div className="relative">
              <img src={santos} alt={t('handbook.chapters.chapter11.4.4.photo-1.alt')} />
              <div className="description">
                <p>
                  {t('handbook.chapters.chapter11.4.4.photo-1.description')}
                </p>
              </div>
              <p className="ref">{t('handbook.chapters.chapter11.4.4.photo-1.ref')}</p>
            </div>
          </div>

          <div className="sub-section 11-4-5">
            <h5>
              <span className="un-navy">11.4.5</span> {t('handbook.chapters.chapter11.4.5.title')}
            </h5>
            <p>
              {t('handbook.chapters.chapter11.4.5.pg-1')}
            </p>

            <div className="operations">
              <div>
                <h4 className="un-navy">
                  {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.5.operations.1.title', 'span')}
                </h4>
                <ul className="square un-navy">
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.1')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.2')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.3.title')}
                    <ul className="triangle un-navy">
                      <li>
                        {t('handbook.chapters.chapter11.4.5.operations.1.list.3.1')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.5.operations.1.list.3.2')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.5.operations.1.list.3.3')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.5.operations.1.list.3.4')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.5.operations.1.list.3.5')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.5.operations.1.list.3.6')}
                      </li>
                    </ul>
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.4.title')}
                    <ul className="triangle un-navy">
                      <li>
                        {t('handbook.chapters.chapter11.4.5.operations.1.list.4.1')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.5.operations.1.list.4.2')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.5.operations.1.list.4.3')}
                      </li>
                    </ul>
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.5')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.6')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.7')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.8')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.9')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.10')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.11')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.1.list.12')}
                  </li>
                </ul>
              </div>
              <div>
                <h4 className="un-navy">{t('handbook.chapters.chapter11.4.5.operations.2.title')}</h4>
                <ul className="square un-navy">
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.2.list.1')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.2.list.2')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.2.list.3')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.2.list.4')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.2.list.5')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.2.list.6')}
                  </li>
                  <li>
                    {t('handbook.chapters.chapter11.4.5.operations.2.list.7')}
                  </li>
                </ul>
              </div>
              <div>
                <h4 className="un-navy">{t('handbook.chapters.chapter11.4.5.operations.3.title')}</h4>
                <div className="half">
                  <ul className="square un-navy">
                    <li>
                      {t('handbook.chapters.chapter11.4.5.operations.3.list.1')}
                    </li>
                    <li>
                      {t('handbook.chapters.chapter11.4.5.operations.3.list.2')}
                    </li>
                    <li>
                      {t('handbook.chapters.chapter11.4.5.operations.3.list.3')}
                    </li>
                    <li>
                      {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.5.operations.3.list.4')}
                    </li>
                    <li>
                      {t('handbook.chapters.chapter11.4.5.operations.3.list.5')}
                    </li>
                    <li>
                      {t('handbook.chapters.chapter11.4.5.operations.3.list.6')}
                    </li>
                  </ul>
                  <ul className="square un-navy">
                    <li>
                      {t('handbook.chapters.chapter11.4.5.operations.3.list.7')}
                    </li>
                    <li>
                      {t('handbook.chapters.chapter11.4.5.operations.3.list.8')}
                    </li>
                    <li>
                      {t('handbook.chapters.chapter11.4.5.operations.3.list.9')}
                    </li>
                    <li>
                      {t('handbook.chapters.chapter11.4.5.operations.3.list.10')}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <AudioPlayer
              src={audio[12].file}
              title={audio[12].title}
              subtitle={audio[12].subtitle}
              avatar={audio[12].avatar}
              speaker={audio[12].speaker}
              transcript={audio[12].transcript}
            />
            <div className="voices un-navy">
              <div className="avatar">
                <img alt="Koffi Wogomebou" src={koffiWogomebouAvatar} />
              </div>
              <h3>{t('handbook.chapters.chapter11.4.5.voices.title')}</h3>
              <div className="content">
                <h4>{t('handbook.chapters.chapter11.4.5.voices.heading')}</h4>
                <h5>{t('handbook.chapters.chapter11.4.5.voices.subheading')}</h5>
                <div className="half">
                  <div>
                    <p>
                      {t('handbook.chapters.chapter11.4.5.voices.pg-1')}
                    </p>
                    <ul className="square">
                      <li>
                        {t('handbook.chapters.chapter11.4.5.voices.list.1')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.5.voices.list.2')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.5.voices.list.3')}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className="square">
                      <li>
                        {t('handbook.chapters.chapter11.4.5.voices.list.4')}
                      </li>
                    </ul>
                    <p>
                      {t('handbook.chapters.chapter11.4.5.voices.pg-2')}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="case-study margin-top">
              <h3>{t('handbook.chapters.chapter11.4.5.case-study.title')}</h3>
              <h4>
                {t('handbook.chapters.chapter11.4.5.case-study.heading')}
              </h4>
              <div className="content">
                <div className="half">
                  <div>
                    <p>
                      {t('handbook.chapters.chapter11.4.5.case-study.pg-1')}
                    </p>
                    <p>
                      {t('handbook.chapters.chapter11.4.5.case-study.pg-2')}
                    </p>
                    <p>
                      {t('handbook.chapters.chapter11.4.5.case-study.pg-3')}
                    </p>
                    <p>
                      {t('handbook.chapters.chapter11.4.5.case-study.pg-4')}
                    </p>
                  </div>
                  <div>
                    <div className="relative">
                      <img src={improve} alt={t('handbook.chapters.chapter11.4.5.case-study.photo-1.alt')} />
                      <div className="description">
                        <p>
                          {t('handbook.chapters.chapter11.4.5.case-study.photo-1.description')}
                        </p>
                      </div>
                      <p className="ref">{t('handbook.chapters.chapter11.4.5.case-study.photo-1.ref')}</p>
                    </div>
                    <p>
                      {t('handbook.chapters.chapter11.4.5.case-study.pg-5')}
                    </p>
                    <p>
                      {t('handbook.chapters.chapter11.4.5.case-study.pg-6')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sub-section 11-4-6">
            <h5>
              <span className="un-navy">11.4.6</span> {t('handbook.chapters.chapter11.4.6.title')}
            </h5>
            <p>
              {t('handbook.chapters.chapter11.4.6.pg-1')}
            </p>
            <table className="examples">
              <thead>
                <tr>
                  <td>{t('handbook.chapters.chapter11.4.6.example.title')}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="no-padding">
                    <div className="padded">
                      <p>
                        {t('handbook.chapters.chapter11.4.6.example.pg-1')}
                      </p>
                      {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.example.pg-2')}
                      {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.example.pg-3')}
                    </div>
                    <table className="vertical">
                      <thead>
                        <tr>
                          <td>{t('handbook.chapters.chapter11.4.6.table.headings.1')}</td>
                          <td>{t('handbook.chapters.chapter11.4.6.table.headings.2')}</td>
                          <td>{t('handbook.chapters.chapter11.4.6.table.headings.3')}</td>
                          <td>{t('handbook.chapters.chapter11.4.6.table.headings.4')}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <p>
                              {t('handbook.chapters.chapter11.4.6.table.body.1.pg-1')}
                            </p>
                            <p>
                              {t('handbook.chapters.chapter11.4.6.table.body.1.pg-2')}
                            </p>
                            <p className="bold">
                              {t('handbook.chapters.chapter11.4.6.table.body.1.pg-3')}
                            </p>
                          </td>
                          <td>
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-1')}
                            <p>
                              {t('handbook.chapters.chapter11.4.6.table.body.2.pg-2')}
                            </p>
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-3')}
                            <p>
                              {t('handbook.chapters.chapter11.4.6.table.body.2.pg-4')}
                            </p>
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-5')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-6')}
                            <p>
                              {t('handbook.chapters.chapter11.4.6.table.body.2.pg-7')}
                            </p>
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-8')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-9')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-10')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-11')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-12')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-13')}
                          </td>
                          <td />
                          <td>
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.4.pg-1')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.4.pg-2')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.4.pg-3')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.4.pg-4')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.4.pg-5')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.4.pg-6')}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>
                              {t('handbook.chapters.chapter11.4.6.table.body.1.pg-4')}
                            </p>
                            <p className="bold">{t('handbook.chapters.chapter11.4.6.table.body.1.pg-5')}</p>
                          </td>
                          <td>
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-14')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-15')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.2.pg-16')}
                            <p>
                              {t('handbook.chapters.chapter11.4.6.table.body.2.pg-17')}
                            </p>
                          </td>
                          <td />
                          <td>
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.4.pg-7')}
                            <p>{t('handbook.chapters.chapter11.4.6.table.body.4.pg-8')}</p>
                            <p>{t('handbook.chapters.chapter11.4.6.table.body.4.pg-9')}</p>
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.4.pg-10')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.4.pg-11')}
                            {renderSanitizedHTMLElement('handbook.chapters.chapter11.4.6.table.body.4.pg-12')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="sub-section 11-4-7">
            <h5>
              <span className="un-navy">11.4.7</span> {t('handbook.chapters.chapter11.4.7.title')}
            </h5>
            <p>
              {t('handbook.chapters.chapter11.4.7.pg-1')}
            </p>
            <ul className="square un-navy">
              <li>{t('handbook.chapters.chapter11.4.7.list.1')}</li>
              <li>{t('handbook.chapters.chapter11.4.7.list.2')}</li>
              <li>{t('handbook.chapters.chapter11.4.7.list.3')}</li>
              <li>{t('handbook.chapters.chapter11.4.7.list.4')}</li>
            </ul>
            <p>
              {t('handbook.chapters.chapter11.4.7.pg-2')}
            </p>
            <p>
              {t('handbook.chapters.chapter11.4.7.pg-3')}
            </p>
            <table className="examples">
              <thead>
                <tr>
                  <td>{t('handbook.chapters.chapter11.4.7.example.title')}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="no-padding">
                    <div className="color-25 padded">
                      <p>
                        {t('handbook.chapters.chapter11.4.7.example.pg-1')}
                      </p>
                      <ol type="i">
                        <li>
                          {t('handbook.chapters.chapter11.4.7.example.list-1.i')}
                        </li>
                        <li>
                          {t('handbook.chapters.chapter11.4.7.example.list-1.ii')}
                        </li>
                        <li>
                          {t('handbook.chapters.chapter11.4.7.example.list-1.iii')}
                        </li>
                      </ol>
                      <p>
                        {t('handbook.chapters.chapter11.4.7.example.pg-3')}
                      </p>
                    </div>
                    <ul className="square striped even ">
                      <li>
                        {t('handbook.chapters.chapter11.4.7.example.list-2.1')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.7.example.list-2.2')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.7.example.list-2.3')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.7.example.list-2.4')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.7.example.list-2.5')}
                      </li>
                      <li>
                        {t('handbook.chapters.chapter11.4.7.example.list-2.6')}
                      </li>
                    </ul>
                    <p className="padded-bottom">
                      {t('handbook.chapters.chapter11.4.7.example.pg-4')}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )
    },
    {
      index: '11.5',
      name: 'Crisis Management and POC',
      title: t('handbook.chapters.chapter11.5.title'),
      content: (
        <>
          {renderSanitizedHTMLElement('handbook.chapters.chapter11.5.pg-1')}
          <p>
            {t('handbook.chapters.chapter11.5.pg-2')}
          </p>
          <div className="background">
            {renderSanitizedHTMLElement('handbook.chapters.chapter11.5.pg-3')}
            <ol type="a">
              <li>
                {t('handbook.chapters.chapter11.5.list-1.a')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.5.list-1.b')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.5.list-1.c')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.5.list-1.d')}
              </li>
              <li>
                {t('handbook.chapters.chapter11.5.list-1.e')}
              </li>
            </ol>
            {renderSanitizedHTMLElement('handbook.chapters.chapter11.5.pg-4')}
            {renderSanitizedHTMLElement('handbook.chapters.chapter11.5.pg-5')}
          </div>
          <p>
            {t('handbook.chapters.chapter11.5.pg-6')}
          </p>
          <h5>{t('handbook.chapters.chapter11.5.heading-1')}</h5>
          <p>
            {t('handbook.chapters.chapter11.5.pg-7')}
          </p>
          <p>
            {t('handbook.chapters.chapter11.5.pg-8')}
          </p>
          <p>
            {t('handbook.chapters.chapter11.5.pg-9')}
          </p>

          <div className="crisis">
            <div>{t('handbook.chapters.chapter11.5.crisis.heading-1')}</div>
            {renderSanitizedHTMLElement('handbook.chapters.chapter11.5.crisis.heading-2', 'div')}
            <div>
              <div>
                {renderSanitizedHTMLElement('handbook.chapters.chapter11.5.crisis.heading-3', 'div')}
                <div>{t('handbook.chapters.chapter11.5.crisis.heading-4')}</div>
              </div>
              <span>{t('handbook.chapters.chapter11.5.crisis.heading-5')}</span>
            </div>
          </div>
          <p>
            {t('handbook.chapters.chapter11.5.pg-10')}
          </p>
          <p>
            {t('handbook.chapters.chapter11.5.pg-11')}
          </p>
          <ul className="square un-navy">
            <li>
              {t('handbook.chapters.chapter11.5.list-2.1')}
            </li>
            <li>
              {t('handbook.chapters.chapter11.5.list-2.2')}
            </li>
            <li>
              {t('handbook.chapters.chapter11.5.list-2.3')}
            </li>
            <li>
              {t('handbook.chapters.chapter11.5.list-2.4')}
            </li>
          </ul>

          <div className="relative">
            <img src={visit} alt={t('handbook.chapters.chapter11.5.photo-1.alt')} />
            <div className="description">
              <p>
                {t('handbook.chapters.chapter11.5.photo-1.description')}
              </p>
            </div>
            <p className="ref">{t('handbook.chapters.chapter11.5.photo-1.ref')}</p>
          </div>

          <table className="checklist un-orange">
            <thead>
              <tr>
                <td />
                <td>{t('handbook.chapters.chapter11.5.checklist.title')}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2">
                  {t('handbook.chapters.chapter11.5.checklist.pg-1')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-1')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-2')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-3')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-4')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-5')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-6')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-7')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-8')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-9')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-10')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-11')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-12')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-13')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-14')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-15')}
                </td>
              </tr>
              <tr>
                <td className="checked" />
                <td>
                  {t('handbook.chapters.chapter11.5.checklist.checkmark-16')}
                </td>
              </tr>
            </tbody>
          </table>
          <h5>{t('handbook.chapters.chapter11.5.heading-2')}</h5>
          <p>
            {t('handbook.chapters.chapter11.5.pg-12')}
          </p>
        </>
      )
    }
  ],
  footnotes: {
    content: (
      <ol type="1" start="29" className="refs">
        {renderSanitizedHTMLElement('handbook.chapters.chapter11.footnotes.1', 'li', '11-3-1')}
        {renderSanitizedHTMLElement('handbook.chapters.chapter11.footnotes.2', 'li', '11-3-1')}
        {renderSanitizedHTMLElement('handbook.chapters.chapter11.footnotes.3', 'li', '11-3-1')}
        {renderSanitizedHTMLElement('handbook.chapters.chapter11.footnotes.4', 'li', '11-3-2')}
        {renderSanitizedHTMLElement('handbook.chapters.chapter11.footnotes.5', 'li', '11-4-1-5')}
        {renderSanitizedHTMLElement('handbook.chapters.chapter11.footnotes.6', 'li', '11-4-1-5')}
      </ol>
    )
  }
};
