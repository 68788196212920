import React from 'react';
import UNLogoImage from '../images/un_logo.jpeg';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Annex VII: TORs for Joint Monitoring Mechanism',
  title: t('handbook.annexes.vii.title'),
  annex: '7',
  color: 'un-orange',
  content: (
    <div className="main-content">
      <div className="logo">
        <img src={UNLogoImage} alt="UN logo" />
        MONUSCO - GOMA
      </div>
      <div className="half">
        <div>
          <p className="no-margin">UNITED NATIONS</p>
          <p className="no-margin">
            United Nations Organization Stabilization<br />
            Mission in the Democratic Republic of the Congo
          </p>
        </div>
        <div>
          <p className="no-margin">NATIONS UNIES</p>
          <p className="no-margin">
            Mission de l&apos;Organisation des Nations Unies pour la<br />
            Stabilisation en République Démocratique du Congo
          </p>
        </div>
      </div>
      <h4>
        <u>Draft Terms of Reference for North Kivu Joint Monitoring Mechanism</u>
      </h4>
      <h4>18 March 2014</h4>
      <h4>Background</h4>
      <p>
        On 11 March 2014, SMGP-NK approved the establishment of a Joint Monitoring Mechanism tasked to monitor ongoing military operations in North Kivu with respect to the protection of civilians. Composed of civilian, military and UNPOL representatives, the Mechanism will ascertain the consequences of joint MONUSCO/FARDC military operations on the protection of civilians, with particular regard to population displacement and human rights violations (including violations committed by armed groups), and monitor the behavior of the FARDC in respect of MONUSCO&apos;s conditionality policy. The Joint Monitoring Mechanism will report to the SMGPP and will make timely recommendations for immediate action. It will report to the HoO and SMGP-NK. Contingency planning and risk analysis before operations will be developed by the Contingency Planning Task Force.
      </p>
      <h4>Membership</h4>
      <p>
        SMGP-NK has agreed that the membership of the Joint Monitoring mechanism will be composed of: UNJHRO (leader), CAS, UNPOL, CPS, NKB and SVU with a focal point designated from each section. The mechanism will operate as a standing committee based in Goma but with travel to the areas of operations or nearby locations during and after operations, as security allows.
      </p>
      <h4>Mandate</h4>
      <p>
        The mandate of the Mechanism during and after military operations is as follows:
      </p>
      <ol type="a">
        <li>
          To monitor the impact of military operations on civilian populations and recommend appropriate action, including for ensuring protection after the end of operations,
        </li>
        <li>
          To monitor FARDC and MONUSCO conduct during operations and prevent / minimize human rights violations against civilians,
        </li>
        <li>
          To liaise with the Contingency Planning Taskforce to provide ongoing and timely protection input to ongoing operations planning,
        </li>
        <li>
          To conduct on the ground advocacy with FARDC troops on human rights and the protection of civilians,
        </li>
        <li>
          To refer cases of children associated with armed groups to CPS for action,
        </li>
        <li>
          To monitor any support provided by MONUSCO to the FARDC within the framework of its conditionality policy and the Human Rights Due Diligence Policy (HRDDP),
        </li>
        <li>
          To alert the HoO to possible breaches of the conditionality policy and the HRDDP,
        </li>
        <li>
          To report allegations of Human Rights Violations (HRVs) and breaches of International Humanitarian Law (IHL), for follow up action by relevant sections including recommending human rights investigation missions, and
        </li>
        <li>
          To assess local capacities and mobilize local and international partners working for POC.
        </li>
      </ol>

      <h4>Methodology</h4>
      <p>
        The methodology to be used by the Joint Monitoring Mechanism will include:
      </p>
      <ul>
        <li>
          The appointment of a focal point for the mechanism by each concerned section,
        </li>
        <li>
          Weekly meetings in order to share information and coordinate activities, with additional ad hoc meetings as operations dictate,
        </li>
        <li>
          Establishment of a stand-by team ready to deploy within 24 hours,
        </li>
        <li>
          Deploying immediately after any joint military operation whether or not human rights violations are reported,
        </li>
        <li>
          Liaison with Contingency Planning Task Force before, during and after operations,
        </li>
        <li>
          Field visits to areas of operations (including missions of over five days),
        </li>
        <li>
          Where necessary, use of military transportation by civilian team members,
        </li>
        <li>
          Use of existing tools and networks (CLAs, CAN, human rights networks, humanitarian partners) to gather information,
        </li>
        <li>
          Drafting joint reports and making recommendations to military and civilian section. All reports will be reviewed and approved by HoO before dissemination, and
        </li>
        <li>Fortnightly updates to SMGP-NK.</li>
      </ul>
      <h4>Division of Tasks within JMM team</h4>
      <table className="directory second-align-left">
        <tbody>
          <tr>
            <td width="20%">JHRO</td>
            <td>
              <ul>
                <li>
                  Team Leader
                </li>
                <li>
                  Advocacy with FARDC on human rights issues
                </li>
                <li>
                  Identifying and documenting human rights violations and on-going risks of human rights violations and recommending preventive and responsive measures
                </li>
                <li>
                  Assessing the need to dispatch a human rights investigative team to the concerned area after operations
                </li>
                <li>
                  Engaging on advocacy on specific HR cases and situations where appropriate
                </li>
                <li>
                  When possible and relevant, supporting the referral of victim(s) of human rights violations to a competent local organization and/or to the relevant judicial authority in situ or upon return from the mission
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>SVC-U</td>
            <td>
              <ul>
                <li>
                  Analysis of sexual violence trends in area of operation (no investigation of individual cases)
                </li>
                <li>
                  Analysis of capacity of service providers
                </li>
                <li>
                  Monitoring behavior of FARDC regarding SV, to be shared with JHRO
                </li>
                <li>
                  Follow-up with Humanitarians for emergency service delivery (PEP kits)
                </li>
                <li>
                  Follow-up with Protection Actors for development of Protection mechanisms
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>CAS</td>
            <td>
              <ul>
                <li>
                  Assessment of displacement and population movements in field of operations and consequent protection risks
                </li>
                <li>
                  Analysis of other local issues relevant to protection during operations including ethnic, land, natural resources and other issues
                </li>
                <li>
                  Liaison with humanitarian actors, local leaders and communities
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>UNPOL</td>
            <td>
              <ul>
                <li>
                  Interacting with PNC to monitor and document human rights violations and assess risks to the protection of civilians
                </li>
                <li>
                  Reporting and following up HRVs committed by the PNC
                </li>
                <li>
                  Assessing the security situation and logistical conditions of the deployment of PNC elements
                </li>
                <li>
                  Collecting, analyzing and reporting all relevant information pertaining to the restoration of state authority after operations
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>CPS</td>
            <td>
              <ul>
                <li>
                  Document cases of children associated with armed groups
                </li>
                <li>
                  Document cases of grave violations against children committed by FARDC, MONUSCO and armed groups including occupation of schools for military camps
                </li>
                <li>
                  Follow-up with humanitarians actors dealing with children associated to armed groups
                </li>
                <li>
                  Advocate for evacuation of injured children
                </li>
                <li>
                  Ensure protection and release from detention of captured child soldiers by FARDC in the field and at Goma level
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>NKB</td>
            <td>
              <ul>
                <li>
                  Key liaison point with FARDC before, during and after operations
                </li>
                <li>
                  Identify specific military risks to the protection of civilians
                </li>
                <li>
                  Ensure the provision of logistical support to JMM team on the ground
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
};
