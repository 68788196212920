import React from 'react';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 15: Do\'s and Don\'ts on POC',
  title: t('handbook.chapters.chapter15.title'),
  index: '15',
  color: 'un-purple',
  content: (
    <div className="main-content">
      <p>
        Civilian, police and military personnel may all face the following scenarios and must be prepared to respond accordingly and in line with DPO policy and guidance. However, Force contingents and FPUs are the most likely to encounter some (or all) of these situations, as they are often deployed to the most remote locations and areas of insecurity and may have to make quick decisions on the ground in response to threats to civilians.
      </p>
      <div className="background border">
        <strong>A mission-specific tactical POC handbook </strong>can be a useful tool to provide mission-specific contextual guidance. The tactical handbook can list &apos;Do&apos;s and Don&apos;ts&apos; for contingent and FPU commanders, in line with the mission ROE and DUF and actions catered for in the DPKO/DFS military and police POC Guidelines. The tactical handbook should clarify both collective and individual protection measures to be considered, for instance to protect individual witnesses and victims; protect civilian communities when the mission is itself a target; distinguish civilians from perpetrators when confronted with community-based violence; or act when state security forces commit violence against civilians, when IDPs are trapped in a fighting area or flee and seek shelter at a military base, etc. The tactical POC handbook should incorporate and be aligned with the guidance in this Handbook.
      </div>
    </div>
  ),
  sections: [
    {
      index: '15.1',
      name: 'Civilians Seeking Protection at a Mission Base or Premises',
      title: t('handbook.chapters.chapter15.1.title'),
      content: (
        <>
          <p>
            Civilians at risk may seek the direct physical protection of a peacekeeping mission by gathering outside or seeking entry to UN premises. In anticipation of such, all bases (however temporary) of UN peacekeeping missions must have contingency plans in place to provide physical protection in both scenarios, in consultation with relevant partners including, as appropriate, the host state, the UN Country Team and humanitarian actors. In order of priority, physical protection should be provided:
          </p>
          <ol type="1">
            <li>
              On non-UN premises, including in camps or settlements or with host communities;
            </li>
            <li>
              In areas adjacent to or close to existing mission premises, identified for that purpose; or
            </li>
            <li>
              In extremis, including due to a lack of preparedness or where the mission has insufficient military or police capacity to secure a site outside the mission compound, within existing premises. A decision to provide physical protection within UN premises must be taken by the head of mission, in consultation, if time permits, with the Under-Secretary General DPO (USG DPO). This option shall be enabled for the minimum duration possible, normally for the extent of the threat, and the decision to relocate IDPs shall lie with the mission leadership, acting in close consultation with the Humanitarian Country Team (HCT).
            </li>
          </ol>

          <div className="to-do margin-top">
            <div className="do">
              <header>What to DO</header>
              <p>
                Always have a contingency plan ready for such occurrences and ensure that civilians are protected according to the base Community Protection Plan.
              </p>
              <p>
                Stop the advance of armed actors so that they do not threaten civilians who are gathered around or in the base.
              </p>
              <p>
                Make security arrangements in and around the protection site. If required, extend the security perimeter to provide protection. As a last resort, open the gates to provide temporary shelter to civilians and establish a secure space for them within the compound, separate from UN staff accommodations or offices.
              </p>
              <p>
                Ensure the civilian nature of any site where civilians are gathered. Separate fighters from civilians because armed actors, or potential perpetrators, should not be allowed to enter the site or pressure civilians to stay or leave. Disarm combatants and require them to remove any uniforms before entry to seek protection, if the mission decides to extend such protection.
              </p>
              <p>
                Use CLAs, national staff or interpreters to understand the concerns of the protected population.
              </p>
              <p>
                Consider possible scenarios of community tensions and further violence among the protected civilian population. Make special considerations for the protection of ethnic, religious or other minorities. These may include, in some cases, separate areas for those who may be at risk of violence.
              </p>
              <p>
                In cases of crimes or acts of violence committed within the site, make special provision for the separation of individuals suspected of such acts.
              </p>
              <p>Treat civilians in a humane and dignified manner and observe the zero-tolerance policy on sexual exploitation and abuse. </p>
              <p>Cater for the special needs of women, children, older persons, persons with disabilities and other vulnerable populations. </p>
              <p>Inform Force headquarters, Police headquarters and the Head of Office, and request additional support, possibly through a Joint Protection Team, for identifying and assisting with collective or individual protection needs. </p>
              <p>
                Engage with the Protection Cluster members for the provision of further protection and humanitarian assistance in the event civilians continue to seek physical protection on or near the base for an extended period.
              </p>
              <p>
                If necessary, conduct patrols to facilitate civilians to gather firewood, water or food. Such patrols and their timing should be consulted with the community.
              </p>
              <p>
                Consider joint patrols with the national gendarmerie or police, including during night-time hours. Do not expose the civilians to additional risk when doing so, for example if the national authorities pose a threat to the community — real or perceived.
              </p>
              <p>
                Identify alternative safe sites in coordination with relevant authorities and in consultation with humanitarian actors and the protected community. Consultations with communities close to where the relocation is envisaged are also important to ensure acceptance. Access to these areas should be safe and perceived to be safe by the community.
              </p>
              <p>
                Facilitate access to information for members of the community, particularly so they can assess the situation of their own security.
              </p>
              <p>
                Control the spread of rumours, inflammatory language or propaganda.
              </p>
            </div>
            <div className="dont">
              <header>What NOT to do</header>
              <p>
                DO NOT force civilians to leave the base perimeter or force them to stay.
              </p>
              <p>
                DO NOT allow political meetings or military activities such as recruitment in the vicinity of the base.
              </p>
              <p>
                DO NOT provide direct humanitarian assistance to civilians, except last resort drinking water, medical and food assistance.
              </p>
              <p>
                DO NOT conduct military armed patrols or operations inside the area where the civilians are protected, except in cases of imminent threat to the population. UNPOL will primarily ensure security within the site.
              </p>
              <p>
                DO NOT ignore civilian communications: use CLAs or interpreters to engage the affected population.
              </p>
              <p>
                DO NOT forget protection needs in non-daylight time.
              </p>
              <p>
                DO NOT automatically separate ethnic or religious groups, as this may further entrench divisions or hostility.
              </p>
            </div>
          </div>
        </>
      )
    },
    {
      index: '15.2',
      name: 'Individual Civilians Seeking UN Protection',
      title: t('handbook.chapters.chapter15.2.title'),
      content: (
        <>
          <p>
            During periods of heightened political tension and civil unrest, individuals or small group of individuals may seek physical protection at bases or premises of peacekeeping missions, or otherwise request protection from the mission, due to a targeted threat against their person. Such individuals may include human rights defenders, victims and witnesses of human rights violations, community workers, journalists, lawyers, civil society representatives or other notable personalities or figures. Additionally, there have been instances in which members of non-state armed groups have expressed desire to join the peace process and may therefore seek the mission&apos;s protection. Peacekeeping operations must plan and be prepared for such requests.
          </p>

          <div className="to-do margin-top">
            <div className="do">
              <header>What to DO</header>
              <p>
                Before providing physical protection, and where time permits, consider alternative methods of reducing the threat. Such measures may include, for example, measures to prevent and address intimidation and reprisals for cooperation with the mission, advice and guidance on self-protection measures and documentation and reporting of cases. In certain cases, protection can be provided through the static deployment of armed units outside the individual&apos;s residence or the regular patrolling of its environs.
              </p>
              <p>
                Manage expectations of what protection the mission can provide to an individual. Usually this is limited, and will not include, for example, relocation.
              </p>
              <p>
                With consent of the individual, refer the case to other protection actors who may be able to facilitate the protection sought.
              </p>
              <p>
                Where an individual seeks entry to the mission&apos;s base for protection, seek instructions from the senior UN official on site before admitting the individual. However, in case there is an imminent threat to their physical integrity, life or liberty, these individuals shall be admitted into the base premises without delay.
              </p>
              <p>
                Conduct an initial interview to ascertain and document the request. Use best judgment and provide temporary/limited protection or assistance at the reception/gate, as necessary to secure the individual&apos;s physical integrity, subject to further consultation with a Human Rights Officer.
              </p>
              <p>
                Require the individual to disarm and remove any uniform before allowing entry.
              </p>
              <p>
                Thoroughly search the individual for weapons or other contraband. The search should preferably be conducted by personnel of the same gender as the individual.
              </p>
              <p>
                If an individual, whether armed or unarmed, claims to belong to an armed movement or to have deserted from an armed movement, immediately inform the individual of his/her status according to international humanitarian law. Seek guidance from the mission Legal Adviser.
              </p>
              <p>
                Refer individuals or groups interested in joining the DDR programme to the mission&apos;s DDR section.
              </p>
              <p>
                Where refuge is granted to individuals or small groups subject to an imminent threat of death or serious injury, it should be for the shortest period of time necessary to defuse that threat.
              </p>
              <p>
                Always allow access to the base to children under threat and contact mission Child Protection Advisers/Officers immediately.
              </p>
            </div>
            <div className="dont">
              <header>What NOT to do</header>
              <p>
                DO NOT report the individual&apos;s request for protection to anyone outside the mission, including local authorities, without voluntary, informed consent of the individual.
              </p>
              <p>
                DO NOT interview a victim or witness of human rights violations about their experience in the absence of a Human Rights Officer.
              </p>
              <p>
                DO NOT make assumptions about the risk the individual faces without sufficient inquiry.
              </p>
              <p>
                DO NOT admit armed or uniformed individuals into the UN premises.
              </p>
              <p>
                DO NOT admit additional individuals into UN premises in locations where there is neither current fighting nor threat of physical violence.
              </p>
              <p>
                DO NOT hand over to local authorities any individual admitted onto the peacekeeping base for protection purposes, unless a valid warrant of arrest is provided and written assurances are provided that the individual(s) concerned will be treated in accordance with national and international humanitarian and human rights law. DO NOT hand anyone over without explicit authorization of the senior UN official on site.
              </p>
            </div>
          </div>
        </>
      )
    },
    {
      index: '15.3',
      name: 'Securing IDP, Refugee and Returnee Sites, Camps or Settlements',
      title: t('handbook.chapters.chapter15.3.title'),
      content: (
        <>
          <p>
            Civilians in IDP, refugee and returnee camps, sites and settlements may be at particular risk of violent attacks. Displaced individuals may also be at risk if they are hosted within local communities or in urban areas. Displaced populations should be identified as a vulnerable group as part of the threat assessment process, and specific communities within the displaced population, for example women, children, young men, people with disabilities, older persons and minorities, should be identified as targets of particular threats, depending on the specific context. Where peacekeeping personnel are called upon to provide protection to displaced populations or are involved in securing sites comprised of displaced populations, they must take care to take these threats into consideration.
          </p>

          <div className="to-do margin-top">
            <div className="do">
              <header>What to DO</header>
              <p>
                Undertake a comprehensive effort, including civilian, police and military components, to assess the threats facing displaced populations and plan for their protection. Consult with representatives of the IDPs/refugees, and, when applicable, the humanitarian actors responsible for the management of the IDP/refugee sites, such as UNHCR and the Protection Cluster. Consider the specific needs of women, children, older persons, persons with disabilities and minorities in the assessment.
              </p>
              <p>
                Liaise closely with UNHCR, the Protection Cluster and other protection actors on the ground for updated protection analysis.
              </p>
              <p>
                Consider deploying a Joint Protection Team or other tool to provide in-depth analysis and recommendations for the protection needs of a particular site or hosting area.
              </p>
              <p>
                Coordinate (through the appropriate mission component), with IDP/refugee representatives, national police and military, as well as UNHCR and camp management partners to establish security arrangements in and around the site(s) and maintain its civilian and humanitarian character (i.e., prevent infiltration of armed elements and the presence of arms/weapons).
              </p>
              <p>
                Assess and address and potential explosive ordnance threat in areas of IDP/refugee sites.
              </p>
              <p>
                Establish a Community Alert Network with IDP/refugee representatives and relevant actors.
              </p>
              <p>
                Ensure that women are included among community representatives and/or make accommodations to ensure they can express their concerns directly, rather than via male representatives. (In some contexts, this could include holding separate meetings with just women, so that they can feel comfortable to share their concerns with UN and/or humanitarian actors coordinating the site.)
              </p>
              <p>
                Implement a Community Protection Plan.
              </p>
              <p>
                UN military personnel may only provide security outside of the IDP/refugee sites should and intervene inside the site only when civilians are under imminent threat of physical violence.
              </p>
              <p>
                UN police personnel may enter the IDP/refugee site, in coordination with civilian components of the mission and the IDP/refugee community, in consultation with UNCHR and local authorities and/or camp management.
              </p>
              <p>
                If necessary, conduct patrols to facilitate civilians&apos; gathering of firewood, water or food, or access to markets or services. Such patrols and their timing should be in consultation with the community, UNHCR, local authorities and/or camp management.
              </p>
              <p>
                Ensure that fighters are identified and separated from the civilian population and that they are not present inside or in the vicinity of IDP/refugee sites, or in public sites such as schools or churches. This may require advocacy with the host state and/or leadership of non-state armed groups.
              </p>
              <p>
                Ensure that civilians do not carry weapons or arms within or near the site.
              </p>
              <p>
                Uniformed components should seek support from civilian sections or CLAs for communication (including translation, if required), and engagement with and sensitization of the local population, local authorities and humanitarian actors.
              </p>
            </div>
            <div className="dont">
              <header>What NOT to do</header>
              <p>
                DO NOT provide security to an IDP or refugee site automatically. They may not be at risk of violence and full-time protection of the site will hamper capacity to address priority POC threats.
              </p>
              <p>
                DO NOT force civilians to leave the sites or force them to stay.
              </p>
              <p>
                DO NOT provide direct humanitarian assistance to civilians, except last resort clean drinking water, medical and food assistance. Alert UNHCR and the Humanitarian Country Team if specific needs are unmet.
              </p>
              <p>
                DO NOT conduct military patrols inside the IDP/refugee site, except when faced with an imminent threat to the population. UNPOL and national police patrols may take place, in coordination with civilian components of the mission and humanitarian actors, such as UNHCR.
              </p>
              <p>
                DO NOT ignore civilian communications. Use CLAs or interpreters to understand the population&apos;s concerns.
              </p>
              <p>
                DO NOT forget protection needs in non-daylight hours.
              </p>
            </div>
          </div>
        </>
      )
    },
    {
      index: '15.4',
      name: 'Securing Civilians Close To Conflict Zones',
      title: t('handbook.chapters.chapter15.4.title'),
      content: (
        <>
          <p>
            Civilians close to conflict zones are at particular risk of both direct and indiscriminate attacks. They should be a high priority for the mission&apos;s POC response plans, taking the following into account.
          </p>

          <div className="to-do margin-top">
            <div className="do">
              <header>What to DO</header>
              <p>
                Identify in advance civilians at risk of both direct and indiscriminate attack.
              </p>
              <p>
                Carry out advocacy and sensitization activities with potential perpetrators to prevent attacks on civilians and urge compliance with international humanitarian law, when applicable.
              </p>
              <p>
                Identify and support existing community self-protection mechanisms.
              </p>
              <p>
                Engage with host state authorities (civilian, police and military) to understand and support their plans for protecting civilian populations at risk.
              </p>
              <p>
                Always intervene to prevent and stop violence against civilians under direct threat, no matter the source of the threat. Intervention can include dialogue and engagement with perpetrators and influencers or direct physical protection, usually by uniformed components.
              </p>
              <p>
                Military components may interpose themselves, establish weapons-free areas or conduct patrols, including foot and night patrols, wherever possible and appropriate.
              </p>
              <p>
                Assess further risks to the populations caught in the conflict zone. Coordinate with UNMAS and other relevant actors to consider risks of explosive ordnance, including mines, explosive remnants of war and improvised explosive devices.
              </p>
              <p>
                Consult the population and inform them of measures the UN will take to protect them.
              </p>
              <p>
                If requested, consider relocating/evacuating the population, in consultation with the Protection Cluster.
              </p>
              <p>
                If necessary, conduct patrols to facilitate civilians to gather firewood, water or food. Such patrols and their timing should be consulted with the community.
              </p>
              <p>
                Always provide objective information on the security situation and potential threats to the civilian population and avoid raising false expectations of protection.
              </p>
            </div>
            <div className="dont">
              <header>What NOT to do</header>
              <p>
                DO NOT force civilians to leave the location or force them to stay.
              </p>
              <p>DO NOT provide direct assistance to civilian populations, including IDPs, except last resort drinking water, medical and food assistance.</p>
              <p>DO NOT ignore civilian communications just because they are in a language that you do not understand. Use CLAs or interpreters.</p>
              <p>DO NOT ignore protection needs during night-time.</p>
            </div>
          </div>
        </>
      )
    },
    {
      index: '15.5',
      name: 'Childran Associated With or Held By Armed Groups or Forces',
      title: t('handbook.chapters.chapter15.5.title'),
      content: (
        <>
          <p>
            UN personnel, particularly uniformed personnel, may encounter children or young people, who appear to be children, in the presence of armed groups or forces. Such children may be associated with the armed group or force, or may be children in military custody, detained, or held as prisoners of war. UN personnel who come across such cases must proceed cautiously. Mission Senior Child Protection Advisers and Child Protection Officers, in coordination with DDR/CVR components, are the experts and first port of call in situations where children may be at risk, including where children face general protection risks. Where time allows, they must be consulted before taking any action. Missions may devise specific SOPs or protocols, including Force/Police Child Protection Directives, on how to respond to children associated with armed groups. In the absence of such specific guidance, the following is general advice to follow.
          </p>

          <div className="to-do margin-top">
            <div className="do">
              <header>What to DO</header>
              <p>
                Remind national military or police personnel and armed groups that using children, including as combatants, labour and/or sex slaves, is a grave violation under national and international law.
              </p>
              <p>
                Intervene, seek the release of recruited children and their timely transfer to child protection actors, and deter child recruitment, where possible, after consulting with child protection specialists in the mission.
              </p>
              <p>
                At the same time, consider the repercussions the intervention could have on other children who may still be with the group.
              </p>
              <p>
                Report all information immediately to Child Protection Advisers/Officers or the Head of Office.
              </p>
              <p>
                Document the activity, if possible. Handle any information, photos or evidence that confirms identity with the utmost confidentiality.
              </p>
              <p>
                Patrol in communities and locations (e.g., near schools and other children-centred facilities) that are vulnerable to threats against children.
              </p>
              <p>
                House children (or potential children) at mission military bases only as a temporary protection measure while awaiting Child Protection or DDR personnel to facilitate solutions.
              </p>
              <p>
                Every person under the age of 18 is considered a child. If their age is in question, consider them children and refer them to Child Protection or other child protection actors.
              </p>
              <p>
                Remind all personnel of their obligation to observe the zero-tolerance policy for any form of sexual exploitation and abuse.
              </p>
              <p>
                Remember children associated with armed forces or armed groups benefit from a special protection and assistance measure and should be considered as victims.
              </p>
            </div>
            <div className="dont">
              <header>What NOT to do</header>
              <p>
                DO NOT take photos of the children under any circumstances.
              </p>
              <p>
                DO NOT attempt to verify the age of youth in the presence of other members of the armed group.
              </p>
              <p>
                DO NOT ask children about any violations they may have experienced or are experiencing in the presence of the armed groups with which they were found.
              </p>
              <p>
                DO NOT ask children directly whether they have been physically or sexually abused. This violates their privacy. Allow Child Protection Advisers/Officers to handle such queries and follow-up investigations.
              </p>
              <p>
                DO NOT interview a child without the presence of Child Protection or UNICEF personnel.
              </p>
              <p>
                DO NOT deny access to the UN base for children who are under threat.
              </p>
              <p>
                DO NOT hand children associated with armed actors over to authorities outside of the mission. In accordance with child protection standards, children that were associated with armed actors must be handed over to Child Protection, DDR representatives or to UNICEF for family reunification and reintegration.
              </p>
              <p>
                DO NOT further stigmatize children associated with or perceived to be associated with armed forces or groups.
              </p>
            </div>
          </div>
        </>
      )
    },
    {
      index: '15.6',
      name: 'Sexual Violence Being Committed or Imminent',
      title: t('handbook.chapters.chapter15.6.title'),
      content: (
        <>
          <p>
            UN personnel, particularly uniformed personnel, may encounter ongoing or imminent acts of sexual violence. Sexual violence can be committed against women, men, girls and boys, and can be perpetrated by armed and unarmed actors. Most missions will have dedicated capacity on the prevention and response to sexual violence. This can be through the Senior Women&apos;s Protection Adviser or other dedicated staff working on CRSV. The input of specialists should be sought on threat assessment, analysis and response planning and, if a situation of sexual violence arises, they will lead on the development of prevention and response strategies and referral pathways for survivors. Missions may devise specific SOPs or protocols, including force- or police-specific guidance, on how to respond to sexual violence. In the absence of such specific guidance, the following is general advice to follow.
          </p>

          <div className="to-do margin-top">
            <div className="do">
              <header>What to DO</header>
              <p>
                Uniformed components must intervene and/or deter the sexual violence from occurring, whether the perpetrator(s) is armed or not.
              </p>
              <p>
                Remind the attacker/perpetrator and accomplices that they are violating national and international law and the consequence of the crime.
              </p>
              <p>
                Bring the victim(s)/survivor(s) of sexual violence to safety and, with the victim/survivor&apos;s informed consent, refer them to relevant services and or CRSV actors, for example, medical and psychosocial services, women&apos;s protection, human rights, POC or child protection, as relevant. Likewise, take all efforts to ensure the safety and welfare of the victim/survivor&apos;s family or witnesses, as relevant.
              </p>
              <p>
                Document the event as thoroughly as possible with names, location, and weapons used. If the perpetrator(s) belong to military, police, gendarmerie or another uniformed authority, ensure to obtain the rank, unit and any other pertinent identification information. Documentation should not involve interviewing victims/survivors or witnesses, which requires expertise.
              </p>
              <p>
                Report the occurrence of sexual violence encountered directly by UN personnel immediately, including to the Head of Office, Women&apos;s Protection Advisers and Human Rights Officers. Do not report any identifying information regarding the victim/survivor or witnesses without their informed consent.
              </p>
            </div>
            <div className="dont">
              <header>What NOT to do</header>
              <p>
                DO NOT interview or question the sexual assault survivor/potential victim. Only trained professionals such as Women&apos;s Protection Advisers or Human Rights Officers should conduct such interviews.
              </p>
              <p>
                DO NOT retraumatize, further stigmatize, or otherwise endanger the victim/survivor.
              </p>
              <p>
                DO NOT express bias or prejudice regarding the victim(s)/survivor(s) of sexual violence.
              </p>
            </div>
          </div>
        </>
      )
    },
    {
      index: '15.7',
      name: 'Sexual Violence Has Already Occurred',
      title: t('handbook.chapters.chapter15.7.title'),
      content: (
        <>
          <p>
            UN personnel, particularly uniformed personnel, may encounter a situation where sexual violence has already occurred. Sexual violence can be committed against women, men, girls and boys, and can be perpetrated by armed and unarmed actors. In such cases, UN personnel should proceed with caution, and immediately contact the mission&apos;s dedicated capacity on CRSV. Missions may devise specific SOPs or protocols, including force- or police-specific guidance, on how to respond to sexual violence. In the absence of such specific guidance, the following is general advice to follow.
          </p>

          <div className="to-do margin-top">
            <div className="do">
              <header>What to DO</header>
              <p>
                Remember that the health and welfare of the victim(s)/survivor(s) is the foremost priority, together with protecting the victim/survivor&apos;s identity and confidentiality. This also includes the victim/survivor&apos;s family or witnesses.
              </p>
              <p>
                As soon as possible, contact the mission&apos;s sexual violence focal point(s), including women&apos;s protection, human rights and, where relevant, child protection officers. Only share information about the case with informed consent of the victim/survivor.
              </p>
              <p>
                Inform the victim(s)/survivor(s) about the possibilities for immediate medical assistance (for instance in local healthcare centres) and psychosocial assistance. If the victim(s)/survivor(s) decline assistance, do not force them or coerce them to obtain it.
              </p>
              <p>
                Inform the victim(s)/survivor(s) that chances of transmission of sexually transmitted diseases are considerably reduced if the victim/survivor receives adequate medical support (including PEP Kits) within 72 hours.
              </p>
            </div>
            <div className="dont">
              <header>What NOT to do</header>
              <p>
                DO NOT ask the victim(s)/survivor(s) directly if he/she/they have been raped.
              </p>
              <p>
                DO NOT conduct interviews with the victim(s)/survivor(s) (except human rights, child protection or specialist personnel working on CRSV).
              </p>
              <p>
                DO NOT express bias or prejudice regarding the victim(s)/survivor(s) of sexual violence.
              </p>
              <p>
                DO NOT force a victim/survivor to obtain assistance against their will, such as seeking medical treatment or counseling.
              </p>
              <p>
                DO NOT visit the family, the house, a treating doctor or an assisting NGO that is linked to the victim(s)/survivor(s). They might unintentionally be put at risk.
              </p>
              <p>
                DO NOT take any action related to sexual violence without first consulting with the mission&apos;s Women&apos;s Protection Adviser or sexual violence focal point.
              </p>
              <p>
                DO NOT release the names, photos or details of the victim/survivor(s) of sexual violence crimes in reports or in meetings with local officials. Confidentiality of the victim/survivor(s) is paramount.
              </p>
            </div>
          </div>
        </>
      )
    }
  ]
};
