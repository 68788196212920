import leilaZerrougui from '../audio/leila_zerrougui.m4a';
import davidShearer from '../audio/david_shearer.mp4';
import ltGenEliasRogriguesMartinsFilho from '../audio/lt_gen_elias_rodrigues_martins_filho.m4a';
import benMajekodunmi from '../audio/ben_majekodunmi.mp3';
import ameenAlabasi from '../audio/ameen_alabasi.m4a';
import allySaidBabu from '../audio/ally_said_babu.m4a';
import khalilNgobozoua from '../audio/khalil_ngobozoua.mp3';
import luluMasunga from '../audio/lulu_masunga.mp3';
import kirstenYoung from '../audio/kirsten_young.m4a';
import guangCong from '../audio/guang_cong.m4a';
import elenaIkumapayi from '../audio/elena_ikumapayi.m4a';
import damianLilly from '../audio/damian_lilly.mp3';
import koffiWogomebou from '../audio/koffi_wogomebou.m4a';
import leilaZerrouguiAvatar from '../images/avatars/Leila_Zerrougui.png';
import davidShearerAvatar from '../images/avatars/SHEARER_David.png';
import ltGenEliasRogriguesMartinsFilhoAvatar from '../images/avatars/General_de_divisao_Elias_Rodrigues_Martins_Filho.png';
import benMajekodunmiAvatar from '../images/avatars/Ben_Majekodunmi.png';
import ameenAlabasiAvatar from '../images/avatars/Ameen_Alabsi.png';
import allySaidBabuAvatar from '../images/avatars/Ally_Said_Babu.png';
import khalilNgobozouaAvatar from '../images/avatars/Khalil_Ngobozoua.png';
import luluMasungaAvatar from '../images/avatars/Lulu_Masunga.png';
import kirstenYoungAvatar from '../images/avatars/Kirsten_Young.png';
import guangCongAvatar from '../images/avatars/Guang_Cong.png';
import elenaIkumapayiAvatar from '../images/avatars/Elena_Olusola_Giorgia_Ikumapayi.png';
import damianLillyAvatar from '../images/avatars/Damian_Lilly.png';
import koffiWogomebouAvatar from '../images/avatars/Koffi_Wogomebou.png';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default [
  {
    title: t('handbook.audio.1.title'),
    subtitle: t('handbook.audio.1.subtitle'),
    file: leilaZerrougui,
    avatar: leilaZerrouguiAvatar,
    speaker: 'Leila Zerrougui - MONUSCO SRSG (2018-2022; DSRSG 2008-2012)',
    transcript: []
  },
  {
    title: t('handbook.audio.2.title'),
    subtitle: t('handbook.audio.2.subtitle'),
    file: davidShearer,
    avatar: davidShearerAvatar,
    speaker: 'David Shearer - UNMISS SRSG David Shearer (2017-2021)',
    transcript: [],
  },
  {
    title: t('handbook.audio.3.title'),
    subtitle: t('handbook.audio.3.subtitle'),
    file: ltGenEliasRogriguesMartinsFilho,
    avatar: ltGenEliasRogriguesMartinsFilhoAvatar,
    speaker: 'Lt Gen Elias Rogrigues Martins Filho - MONUSCO Force Commander (2018-2019)',
    transcript: [],
  },
  {
    title: t('handbook.audio.4.title'),
    subtitle: t('handbook.audio.4.subtitle'),
    file: benMajekodunmi,
    avatar: benMajekodunmiAvatar,
    speaker: 'Ben Majekodunmi - MINUSTAH Chief, a.i., and Deputy Chief Human Rights Section (2010-2011)',
    transcript: [],
  },
  {
    title: t('handbook.audio.5.title'),
    subtitle: t('handbook.audio.5.subtitle'),
    file: ameenAlabasi,
    avatar: ameenAlabasiAvatar,
    speaker: 'Ameen Alabasi - MINUSCA MILOB (2014-2017)',
    transcript: [],
  },
  {
    title: t('handbook.audio.6.title'),
    subtitle: t('handbook.audio.6.subtitle'),
    file: allySaidBabu,
    avatar: allySaidBabuAvatar,
    speaker: 'Ally Said Babu - MONUSCO UNPOL (2018–)',
    transcript: [],
  },
  {
    title: t('handbook.audio.12.title'),
    subtitle: t('handbook.audio.12.subtitle'),
    language: 'French',
    file: khalilNgobozoua,
    avatar: khalilNgobozouaAvatar,
    speaker: 'Khalil Ngobozoua - MINUSCA CLA (2015-)',
    transcript: [],
  },
  {
    title: t('handbook.audio.7.title'),
    subtitle: t('handbook.audio.7.subtitle'),
    file: luluMasunga,
    avatar: luluMasungaAvatar,
    speaker: 'Lulu Masunga - UNAMID UNPOL (2017-2020)',
    transcript: [],
  },
  {
    title: t('handbook.audio.13.title'),
    subtitle: t('handbook.audio.13.subtitle'),
    file: koffiWogomebou,
    avatar: koffiWogomebouAvatar,
    speaker: 'Koffi Wogomebou - MINUSCA Senior POC Adviser (2017-)',
    transcript: [],
  },
  {
    title: t('handbook.audio.8.title'),
    subtitle: t('handbook.audio.8.subtitle'),
    file: kirstenYoung,
    avatar: kirstenYoungAvatar,
    speaker: 'Kirsten Young - UNMISS Protection of Civilians Officer, Bentiu (2012-2014)',
    transcript: [],
  },
  {
    title: t('handbook.audio.9.title'),
    subtitle: t('handbook.audio.9.subtitle'),
    file: guangCong,
    avatar: guangCongAvatar,
    speaker: 'Guang Cong - UNMISS Director of Civil Affairs Division (2016-2020)',
    transcript: [],
  },
  {
    title: t('handbook.audio.10.title'),
    subtitle: t('handbook.audio.10.subtitle'),
    file: elenaIkumapayi,
    avatar: elenaIkumapayiAvatar,
    speaker: 'Elena Ikumapayi - MINUSMA Associate Judicial Affairs Offi cer (2016-)',
    transcript: [],
  },
  {
    title: t('handbook.audio.11.title'),
    subtitle: t('handbook.audio.11.subtitle'),
    file: damianLilly,
    avatar: damianLillyAvatar,
    speaker: 'Damian Lilly - UNMISS Senior Protection of Civilians Adviser (2012-2014)',
    transcript: [],
  },
];
