import React from 'react';
import PropTypes from 'prop-types';
import access from '../../config/access';
import { hasSubs } from '../../helpers/index';

const hasConfig = (role, area, tab) => tab === 'recommended' && !!access[`${role}-${area}`];

const gran = (id) => {
  const count = id.split('').reduce((c, i) => {
    if (i === '-') return c + 1;
    return c;
  }, 0);
  return count === 1 ? `section ${id}` : `sub-section ${id}`;
};

const dashCount = (str) => str.split('').reduce((count, el) => {
  if (el === '-') count += 1;
  return count;
}, 0);

/**
 * Take an Array of indices for elements that should be visible in the content,
 * and create necessary CSS for them.
 *
 * @param {String} pParentSelector The CSS parent selector for this element
 * @param {String} selector The element selector
 * @param {Array} indices The specific elements that should be visible
 * @param {String} display What display rendering to use on the element - default block
 * @param {Boolean} direct Are we targeting the direct descendant (e.g `>`) or not - default true
 * @returns {String}
 */
const includeContentTag = (pParentSelector, selector, indices, display = 'block', direct = true) => {
  const child = direct ? '>' : '';
  return indices.map((e) => `${pParentSelector} ${child} ${selector}:nth-of-type(${e}) { display: ${display}; }`).join('\n');
};

/**
 * Take an array of selectors that should be hidden in the content, and create
 * necessary CSS for them.
 *
 * @param {String} pParentSelector The CSS parent selector for this element
 * @param {Array} selectors The element selectors to hide
 * @returns {String}
 */
const excludeContentTag = (pParentSelector, selectors) => selectors.map((selector) => `${pParentSelector} ${selector} { display: none; }`).join('\n');

/**
 * Take an array of selectors that should be display in the content, and create
 * necessary CSS for them.
 *
 * @param {String} pParentSelector The CSS parent selector for this element
 * @param {Array} selectors The element selectors to hide
 * @returns {String}
 */
const displayFootnote = (pParentSelector, selectors) => selectors.map((selector) => `
  ${pParentSelector} li[class="${selector}"] { visibility: visible; height: auto;}
`).join('\n');

/**
 * Take an Array of includes for a given section in the document, hide all
 * top level content and only show the indexes that are visible
 *
 * @param {String} pParentSelector  The CSS parent selector for this element
 * @param {Array} includes The elements that should be visible with their indexes
 * @returns {String}
 */
const includeContent = (pParentSelector, includes) => {
  const visibleElements = includes
    .map((element, idx) => {
      if (typeof element === 'string') {
        return `${pParentSelector} > ${element} { display: block; }`;
      }

      // Is this a "defined" object with selector, display and indices fields
      // or is it just a flat object
      if (element.selector && element.display && element.indices) {
        return includeContentTag(
          pParentSelector,
          element.selector,
          element.indices,
          element.display,
          element.direct
        );
      }

      const keys = Object.keys(element);
      return keys.length
        ? includeContentTag(pParentSelector, keys[0], includes[idx][keys[0]])
        : '';
    })
    .join('\n');

  // Hide content selectors at this level
  const toggleTags = `
    ${pParentSelector} > p,
    ${pParentSelector} > ul,
    ${pParentSelector} > ol,
    ${pParentSelector} > table,
    ${pParentSelector} > div,
    ${pParentSelector} > a,
    ${pParentSelector} > img { display: none; }
  `;

  return `${toggleTags}\n${visibleElements}`;
};

/**
 * Will change the visibility of footnote items, and display them if the current
 * chapter content contains any superscript/footnotes references in the subsections
 * @param stylingBlock
 * @param selector
 * @returns {*}
 */
const displayFootNoteItems = (stylingBlock, selector) => {
  if (selector?.footnotes) {
    const footnoteSectionSelector = '.handbook .content.has-flow .scroller .section-footnotes .main';
    stylingBlock = `${stylingBlock}\n${displayFootnote(footnoteSectionSelector, selector.footnotes)}`;
  }

  return stylingBlock;
};

const CustomStyles = ({ role, area, tab }) => {
  if (!hasConfig(role, area, tab)) return null;
  const config = access[`${role}-${area}`];
  const selectors = config
    .map((selector) => {
      const item = selector.section;

      // If this is a chapter, and it has no sections listed in the
      // config then we want to show the entire thing
      if (dashCount(item) === 0 && !hasSubs(item, config)) {
        const pParentSelector = `.handbook .content.has-flow.chap-${item} .scroller`;
        let blockVis = `${pParentSelector} .section, ${pParentSelector} .sub-section { display: block; }`;

        // Does the selector have any explicitly hidden content
        // If so, hide that content only
        if (selector?.exclude) {
          blockVis = `${blockVis}\n${excludeContentTag(pParentSelector, selector.exclude)}`;
        }

        // Display available footnotes conditionally based on the recommended visible content.
        return displayFootNoteItems(blockVis, selector);
      }

      const pParentSelector = `.handbook .content.has-flow .scroller [class="${gran(item)}"]`;

      // if this is a section, and it has no sub-sections listed in
      // the config then we want to show the entire thing
      if (dashCount(item) === 1 && !hasSubs(item, config)) {
        // Show the top level element here and its navigation
        let blockVis = `
          ${pParentSelector} .sub-section { display: block; }
          ${pParentSelector} { display: block; }
          ${pParentSelector} > div.track, ${pParentSelector} > div.main { display: block; }
        `;

        // Does the selector have any explicitly visible content
        // If so, hide the rest and show those listed
        if (selector?.include) {
          blockVis = `${blockVis}\n${includeContent(`${pParentSelector} > div.main`, selector.include)}`;
        }

        // Does the selector have any explicitly hidden content
        // If so, hide that content only
        if (selector?.exclude) {
          blockVis = `${blockVis}\n${excludeContentTag(`${pParentSelector} > div.main`, selector.exclude)}`;
        }

        // Display available footnotes conditionally based on the recommended visible content.
        return displayFootNoteItems(blockVis, selector);
      }

      // Show the top level element here
      let blockVis = `${pParentSelector} { display: block; }`;

      // Does the selector have any explicitly visible content
      // If so, hide the rest and show those listed
      if (selector?.include) {
        blockVis = `${blockVis}\n${includeContent(pParentSelector, selector.include)}`;
      }

      // Does the selector have any explicitly hidden content
      // If so, hide that content only
      if (selector?.exclude) {
        blockVis = `${blockVis}\n${excludeContentTag(pParentSelector, selector.exclude)}`;
      }

      // Display available footnotes conditionally based on the recommended visible content.
      return displayFootNoteItems(blockVis, selector);
    })
    .join('\n');

  const handBookSelectors = `
  ${selectors}\n
  .audio-player { display: none; }\n
  .handbook .has-flow .scroller .section-footnotes { display: flex; }
  `;

  return (<style dangerouslySetInnerHTML={{ __html: handBookSelectors }} />);
};

CustomStyles.propTypes = {
  tab: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
};

export default React.memo(CustomStyles);
