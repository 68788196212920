import React from 'react';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Annex VIII: List of Acronyms',
  title: t('handbook.annexes.viii.title'),
  annex: '8',
  color: 'un-orange',
  content: (
    <div className="main-content">
      <div className="half acronyms">
        <div>
          <div><span>AAR -</span><span>After Action Review</span></div>
          <div><span>ADF -</span><span>Allied Democratic Forces</span></div>
          <div><span>AOR -</span><span>Area of Responsibility</span></div>
          <div><span>BOI -</span><span>Board of Inquiry</span></div>
          <div><span>CAAC -</span><span>Children and Armed Conflict</span></div>
          <div><span>CAN -</span><span>Community Alert Network</span></div>
          <div><span>CAR -</span><span>Central African Republic</span></div>
          <div><span>CBO -</span><span>Community-Based Organization</span></div>
          <div><span>CCT -</span><span>Comite Conjoint Technique</span></div>
          <div><span>CIC -</span><span>Centre d’Information et de Coordination</span></div>
          <div><span>CIMIC -</span><span>Civil-Military Cooperation</span></div>
          <div><span>CLA -</span><span>Community Liaison Assistant</span></div>
          <div><span>CMCoord -</span><span>Civil-Military Coordination</span></div>
          <div><span>COC -</span><span>Centre Operationel Commun</span></div>
          <div><span>COCEM -</span><span>Comite des Chiefs d’Etat-Major</span></div>
          <div><span>COI -</span><span>Commission of Inquiry</span></div>
          <div><span>CONOPS -</span><span>Concept of Operations</span></div>
          <div><span>CoP -</span><span>Community-oriented Policing</span></div>
          <div><span>COS -</span><span>Chief of Staff</span></div>
          <div><span>COS-JOC -</span><span>Chief of Staff, Joint Operations Centre</span></div>
          <div><span>CPAS -</span><span>Comprehensive Performance Assessment System</span></div>
          <div><span>CPOC -</span><span>Comprehensive Protection of Civilians</span></div>
          <div><span>CPP -</span><span>Community Protection Plan</span></div>
          <div><span>CPTM -</span><span>Core Pre-deployment Training Materials</span></div>
          <div><span>CPX -</span><span>Command Post Exercise</span></div>
          <div><span>CRSV -</span><span>Conflict-Related Sexual Violence</span></div>
          <div><span>CSO -</span><span>Civil Society Organization</span></div>
          <div><span>CVR -</span><span>Community Violence Reduction</span></div>
          <div><span>DCOS OPS -</span><span>Deputy Chief of Staff for Operations</span></div>
          <div><span>DDR -</span><span>Disarmament, Demobilization, and Reintegration</span></div>
          <div><span>DFS -</span><span>Department of Field Support</span></div>
          <div><span>DOS -</span><span>Department of Operational Support</span></div>
          <div><span>DPA -</span><span>Department of Political Affairs</span></div>
          <div><span>DPET -</span><span>Policy, Evaluation and Training Division</span></div>
          <div><span>DPI -</span><span>Department of Public Information</span></div>
          <div><span>DPKO -</span><span>Department of Peace Keeping Operations</span></div>
          <div><span>DPO -</span><span>Department of Peace Operations</span></div>
          <div><span>DPPA -</span><span>Department of Political and Peacebuilding Affairs</span></div>
          <div><span>DRC -</span><span>Democratic Republic of the Congo</span></div>
          <div><span>DSRSG -</span><span>Deputy Special Representatives of the Secretary-General</span></div>
          <div><span>DUF -</span><span>Directive on the Use of Force</span></div>
          <div><span>ERW -</span><span>Explosive Remnants of War</span></div>
          <div><span>FACA -</span><span>Forces Armees Centrafricaines</span></div>
          <div><span>FAQ -</span><span>Frequently Asked Questions</span></div>
          <div><span>FARDC -</span><span>Armed Forces of the Democratic Republic of the Congo</span></div>
          <div><span>FC -</span><span>Force Commander</span></div>
          <div><span>FPU -</span><span>Formed Police Unit</span></div>
          <div><span>FRPI -</span><span>Patriotic Resistance Front in Ituri</span></div>
          <div><span>GBV -</span><span>Gender-Based Violence</span></div>
          <div><span>GPP -</span><span>Government-Provided Personnel</span></div>
          <div><span>HC -</span><span>Humanitarian Coordinator</span></div>
          <div><span>HCT -</span><span>Humanitarian Country Team</span></div>
          <div><span>HIPPO -</span><span>High Level Independent Panel on UN Peace Operations</span></div>
          <div><span>HNO -</span><span>Humanitarian Needs Overview</span></div>
          <div><span>HQ -</span><span>Headquarters</span></div>
          <div><span>HRDDP -</span><span>Human Rights Due Diligence Policy</span></div>
          <div><span>HRO -</span><span>Human Rights Officer</span></div>
          <div><span>HRuF -</span><span>Human Rights up Front</span></div>
          <div><span>IAP -</span><span>Integrated Assessment and Planning</span></div>
          <div><span>IASC -</span><span>Inter-Agency Standing Committee</span></div>
          <div><span>ICC -</span><span>International Criminal Court</span></div>
          <div><span>ICRC -</span><span>International Committee of the Red Cross</span></div>
          <div><span>IDP -</span><span>Internally Displaced Person</span></div>
          <div><span>IED -</span><span>Improvised Explosive Device</span></div>
          <div><span>IHL -</span><span>International Humanitarian Law</span></div>
          <div><span>IHRL -</span><span>International Human Rights Law</span></div>
          <div><span>IMTC -</span><span>Integrated Mission Training Centre</span></div>
          <div><span>INGO -</span><span>International Non-Governmental Organization</span></div>
          <div><span>IOM -</span><span>International Organization for Migration</span></div>
          <div><span>IRL -</span><span>International Refugee Law</span></div>
          <div><span>ISF -</span><span>Integrated Strategic Framework</span></div>
          <div><span>ITS -</span><span>Integrated Training Service</span></div>
          <div><span>JAM -</span><span>Joint Assessment Mission</span></div>
          <div><span>JMAC -</span><span>Joint Mission Analysis Centre</span></div>
          <div><span>JOC -</span><span>Joint Operations Centre</span></div>
          <div><span>JOPT -</span><span>Joint Operational Planning Team</span></div>
          <div><span>JPT -</span><span>Joint Protection Team</span></div>
          <div><span>LA -</span><span>Language Assistant</span></div>
          <div><span>LPC -</span><span>Local Protection Committee</span></div>
          <div><span>MARA -</span><span>Monitoring, Analysis, and Reporting Arrangement</span></div>
          <div><span>MEDEVAC / CASEVAC -</span><span>Medical / Casualty Evacuation</span></div>
          <div><span>MILOB -</span><span>Military Observer</span></div>
          <div><span>MINUJUSTH -</span><span>United Nations Mission for Justice Support in Haiti</span></div>
          <div><span>MINURCAT -</span><span>United Nations Mission in the Central African Republic and Chad</span></div>
          <div><span>MINUSCA -</span><span>United Nations Multidimensional Integrated Stabilization Mission in the Central African Republic</span></div>
        </div>
        <div>
          <div><span>MINUSMA -</span><span>United Nations Multidimensional Integrated Stabilization Mission in Mali</span></div>
          <div><span>MINUSTAH -</span><span>United Nations Stabilization Mission in Haiti</span></div>
          <div><span>MONUC -</span><span>United Nations Organization Mission in the Democratic Republic of the Congo</span></div>
          <div><span>MONUSCO -</span><span>United Nations Organization Stabilization Mission in the Democratic Republic of the Congo</span></div>
          <div><span>MRM -</span><span>Monitoring and Reporting Mechanism</span></div>
          <div><span>MRM CTF -</span><span>Monitoring and Reporting Mechanism Country Taskforce</span></div>
          <div><span>MTT -</span><span>Mobile Training Team</span></div>
          <div><span>NGO -</span><span>Non-Governmental Organization</span></div>
          <div><span>NSAG -</span><span>Non-State Armed Group</span></div>
          <div><span>OCHA -</span><span>United Nations Office for the Coordination of Humanitarian Affairs</span></div>
          <div><span>OHCHR -</span><span>Office of the United Nations High Commissioner for Human Rights</span></div>
          <div><span>ONUB -</span><span>United Nations Operation in Burundi</span></div>
          <div><span>OPT -</span><span>Operations Planning Team</span></div>
          <div><span>PBPO -</span><span>Policy and Best Practices Officer</span></div>
          <div><span>PC -</span><span>Police Commissioner</span></div>
          <div><span>PEP -</span><span>Post-Exposure Prophylaxis</span></div>
          <div><span>PIO -</span><span>Public Information Office</span></div>
          <div><span>PNC -</span><span>National Police (of the Democratic Republic of the Congo)</span></div>
          <div><span>POC -</span><span>Protection of Civilians</span></div>
          <div><span>PPDB -</span><span>Policy and Practice Database</span></div>
          <div><span>PWG -</span><span>Protection Working Group</span></div>
          <div><span>QIP -</span><span>Quick Impact Projects</span></div>
          <div><span>QRF -</span><span>Quick Reaction Force</span></div>
          <div><span>RBB -</span><span>Results-Based Budget</span></div>
          <div><span>ROE -</span><span>Rules of Engagement</span></div>
          <div><span>RoL -</span><span>Rule of Law</span></div>
          <div><span>SBE -</span><span>Scenario-Based Exercise</span></div>
          <div><span>SCC -</span><span>Special Criminal Court (in the Central African Republic)</span></div>
          <div><span>SCD -</span><span>Standing Combat Deployment</span></div>
          <div><span>SCPI -</span><span>Strategic Communication and Public Information</span></div>
          <div><span>SG -</span><span>Secretary-General</span></div>
          <div><span>SGBV -</span><span>Sexual and Gender-Based Violence</span></div>
          <div><span>SMART -</span><span>Specific, Measurable, Achievable, Relevant, Timebound</span></div>
          <div><span>SMG-P -</span><span>Senior Management Group on Protection</span></div>
          <div><span>SOFA -</span><span>Status of Forces Agreement</span></div>
          <div><span>SOP -</span><span>Standard Operating Procedure</span></div>
          <div><span>SPU -</span><span>Strategic Planning Unit</span></div>
          <div><span>SRSG -</span><span>Special Representative of the Secretary-General</span></div>
          <div><span>SSR -</span><span>Security Sector Reform</span></div>
          <div><span>SSU -</span><span>Stabilization and Support Unit</span></div>
          <div><span>STM -</span><span>Specialized Training Materials</span></div>
          <div><span>SUR -</span><span>Statement of Unit Requirements</span></div>
          <div><span>SVC -</span><span>Sexual Violence in Conflict</span></div>
          <div><span>SWAT -</span><span>Special Weapons and Tactics</span></div>
          <div><span>T/PCC -</span><span>Troop / Police Contributing Country</span></div>
          <div><span>TOE -</span><span>Team of Experts (on the Rule of Law and Sexual Violence in Conflict)</span></div>
          <div><span>TOR -</span><span>Terms of Reference</span></div>
          <div><span>TTP -</span><span>Tactics, Techniques, and Procedures</span></div>
          <div><span>TTX -</span><span>Tabletop Exercise</span></div>
          <div><span>UA -</span><span>Unite Aware</span></div>
          <div><span>UAV -</span><span>Unmanned Aerial Vehicle</span></div>
          <div><span>UN -</span><span>United Nations</span></div>
          <div><span>UNAMID -</span><span>African Union - United Nations Hybrid Operation in Darfur</span></div>
          <div><span>UNAMSIL -</span><span>United Nations Mission in Sierra Leone</span></div>
          <div><span>UNCG -</span><span>United Nations Communications Group</span></div>
          <div><span>UNCT -</span><span>United Nations Country Team</span></div>
          <div><span>UNDSS -</span><span>United Nations Department of Safety and Security</span></div>
          <div><span>UNHCR -</span><span>United Nations High Commissioner for Refugees</span></div>
          <div><span>UNICEF -</span><span>United Nations Children’s Fund</span></div>
          <div><span>UNIFIL -</span><span>United Nations Interim Force in Lebanon</span></div>
          <div><span>UNISFA -</span><span>United Nations Interim Security Force for Abyei</span></div>
          <div><span>UNMAS -</span><span>United Nations Mine Action Service</span></div>
          <div><span>UNMIL -</span><span>United Nations Mission in Liberia</span></div>
          <div><span>UNMIS -</span><span>United Nations Mission in the Sudan</span></div>
          <div><span>UNMISS -</span><span>United Nations Mission in South Sudan</span></div>
          <div><span>UNOCI -</span><span>United Nations Mission in Côte d’Ivoire</span></div>
          <div><span>UNPOL -</span><span>United Nations Police</span></div>
          <div><span>UNSC -</span><span>United Nations Security Council</span></div>
          <div><span>USG DPO -</span><span>Under-Secretary-General of the Department of Peace Operations</span></div>
          <div><span>UXO -</span><span>Unexploded Ordnance</span></div>
          <div><span>VTC -</span><span>Video Teleconferencing</span></div>
          <div><span>WFP -</span><span>World Food Programme</span></div>
          <div><span>WPA -</span><span>Women’s Protection Adviser</span></div>
          <div><span>WPS -</span><span>Women, Peace and Security</span></div>
        </div>
      </div>
    </div>
  )
};
