import React from 'react';
import attack from '../images/chapters/chapter3/MINUSMA_camp_attack.jpg';
import kitshanga from '../images/chapters/chapter3/MONUSCO_SRSG_visits_kitshanga.jpg';
import search from '../images/chapters/chapter3/UNMISS_integrated_search_operation_for_weapons.jpg';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Chapter 3: Overcoming obstacles to POC',
  title: t('handbook.chapters.chapter3.title'),
  index: '3',
  color: 'un-purple',
  content: (
    <>
      <div className="grid-three un-purple">
        <div>
          <h4 className="boxed">WHAT</h4>
          <p>
            Missions are given ambitious mandates and face high expectations but have limited resources and are faced with myriad constraints that may be beyond the sphere of influence of the mission.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHY</h4>
          <p>
            Identifying the contextual and systemic challenges and constraints faced by a mission and how they impact the ability of the mission to protect civilians enables the mission to address these challenges and mitigate their impact. Missions are also able to consider these when designing strategic, operational and tactical responses.
          </p>
        </div>
        <div>
          <h4 className="boxed">HOW</h4>
          <p>
            Identifying and analysing contextual and systemic constraints should be part of the ongoing risk analysis and assessment processes of the mission and should also be included in the POC strategy and POC planning processes.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHO</h4>
          <p>
            All relevant mission components should be involved in the identification and analysis of the wide range of constraints potentially faced by the mission. Mission leaders should lead and oversee this process, with support from POC Advisers.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHERE</h4>
          <p>
            Identifying and analysing contextual and systemic challenges and constraints is undertaken jointly between UN Headquarters in New York, mission headquarters and mission field offices.
          </p>
        </div>
        <div>
          <h4 className="boxed">WHEN</h4>
          <p>
            Identifying and analysing contextual and systemic challenges and constraints begins at mission start-up and must be regularly revisited and sustained throughout the mission’s lifecycle.
          </p>
        </div>
      </div>
    </>
  ),
  sections: [
    {
      index: '3.1',
      name: 'Identifying and Addressing Contextual Constraints',
      title: t('handbook.chapters.chapter3.1.title'),
      content: (
        <>
          <p>
            Peacekeeping missions operate in increasingly complex contexts. The nature of conflict is changing and lines between conflict and post-conflict operating environments have become less clear. Armed groups proliferate and violence against civilians takes many forms and is carried out by various perpetrators. Conflicts include more unconventional threats, asymmetric threats and urban warfare, as well as cyber dimensions. Underlying causes of conflict may increasingly stem from organized crime, climate change or human migration. Impunity prevails. Host state consent to the presence of a peacekeeping operation may be tenuous and various international forces with different but overlapping objectives may operate in the same areas. Peacekeeping operations must constantly recognize these new realities and their associated challenges and adapt to respond to them, including in implementing POC.
          </p>
          <p>
            There are a range of context-specific constraints that impact the implementation of POC mandates and that should be considered when designing strategic responses. The most common challenges, as well as suggested approaches for the mission to take, are included in the following table.
          </p>

          <table className="examples border-bottom">
            <thead>
              <tr>
                <td>Context</td>
                <td>Suggested Approach</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>
                    <strong>A weak or stalled peace process </strong>
                    may hinder protection of civilians as it can be challenging to build a protective environment in a contested space. In this context, the mission may have limited influence and leverage in relation to the political process.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Ensure the short-term protection of civilians at the tactical level while also providing ongoing long-term support to the peace process at the strategic level, for example through the good offices function.
                    </li>
                    <li>
                      Seek additional political support from the Security Council and influential Member States and third parties to further the peace process.
                    </li>
                    <li>
                      Develop comprehensive strategies to address threats to civilians at the local level.
                    </li>
                    <li>
                      Where feasible, support the longer-term capacity of rule of law, security and justice institutions as part of the creation of a protective environment.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Threats to civilians posed by elements of state security forces </strong>or their proxies are one of the biggest POC challenges faced by missions.
                  </p>
                  <p>
                    This may be combined with <strong>tenuous consent </strong>to the mission presence by host state authorities, manifested in threats against or other restrictions on the mission, movement restrictions (violations of Status of Forces Agreements) or attacks against peacekeepers.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Engage early, where possible, and at the highest levels, with political, security and justice actors to address violations of human rights and IHL. Adopt, as much as possible, an evidence-based approach and share information and analysis of state perpetrators and violations with relevant state authorities.
                    </li>
                    <li>
                      Prioritize early political engagement and training (in line with the Human Rights Due Diligence Policy [HRDDP]<sup>11</sup>, see chapter 11.4) and support to justice institutions.
                    </li>
                    <li>
                      Be prepared to intervene physically to protect civilians at risk, including through interposition and robust posture.
                    </li>
                    <li>
                      As feasible, seek support from Headquarters and the Security Council in advance should use of force be anticipated to be required.
                    </li>
                    <li>
                      Report Status of Forces Agreements (SOFA) violations so that the Secretariat can seek political support from the Security Council and influential Member States and third parties on the issue.
                    </li>
                    <li>
                      Consistently seek access to populations at risk, taking into account safety and security of peacekeepers and civilians.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Lack of accountability </strong>of perpetrators may perpetuate cycles of violence and civilians may seek protection from other actors including non-state armed groups.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Use the mission&apos;s good offices to advocate for accountability for violations against civilians by all actors, state and non-state. Such advocacy should be directed at both state authorities and leadership of non-state armed groups, who can discipline their own forces.
                    </li>
                    <li>
                      Support the capacity of rule of law, justice and corrections institutions, including through investigation and prosecution support, and witness protection, while also ensuring that perpetrators can be securely and safely detained.
                    </li>
                    <li>
                      Engage Member States and the Security Council on alternative accountability mechanisms, including hybrid courts, where the state is unwilling or unable to pursue accountability.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Complexity posed by <strong>varied and intertwined conflict drivers </strong>can result in contradictory calls for action, including by external actors.
                  </p>
                  <p>
                    There may not be agreement on the best response to strategic threats. Some may prefer the pursuit of military solutions while others favour political solutions for armed groups. Host states often call for robust security action against some armed groups or agitators who may not pose serious threats of violence to civilians or may have legitimate political claims and would benefit from a political solution.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Develop the POC strategy in coordination with all relevant stakeholders, taking the opportunity to discuss strategic threats, listen to different perspectives and develop an agreed approach, to the extent possible (see chapter 6).
                    </li>
                    <li>
                      Use the mission&apos;s good offices to engage authorities and armed actors in the pursuit of political solutions where feasible, to achieve a sustainable and durable peace.
                    </li>
                    <li>
                      Use the mission conflict analysis and ongoing Joint Mission Analysis Centre (JMAC) analysis to develop a strategic approach to threats to civilians that takes into account both immediate needs and longer-term solutions.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Peacekeeping missions may be <strong>deployed alongside other international forces</strong>, which may have different mandates and objectives. This may complicate the protection activities of the mission.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Remember that any support provided to non-UN security forces must be provided in accordance with the HRDDP.
                    </li>
                    <li>
                      Take into account the possible impact on the implementation of the POC mandate when establishing relationships between the mission and other forces, including counter-terrorism operations. Proceed with caution if the actions of those forces directly or indirectly cause threats or harm to civilians or if association with them affects the populations&apos; perception of the mission.
                    </li>
                    <li>
                      Use strategic communication and public information to explain the difference between peacekeeping and other forces and clarify roles.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Asymmetric environments and tactics </strong>often result in threats to state symbols, security forces or peacekeepers themselves, who, as a result, may focus more attention, resources and capabilities on self-protection, therefore limiting the resources that can be devoted to POC.
                  </p>
                  <p>
                    Asymmetric environments may also infringe on the space required for missions to access communities and conduct community-based POC activities, where the mission&apos;s presence puts those populations at risk.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Examine threats to civilians and threats to peacekeepers that may stem from the same sources. Ensuring the safety and security of peacekeepers and the protection of civilians are not contradictory.
                    </li>
                    <li>
                      Engage with civilian populations and gain their trust and support. Threats to peacekeepers can be reduced if the population is a source of information on perpetrators of threats.
                    </li>
                    <li>
                      Support third-party actors, for example NGOs, to carry out protection activities such as community dialogue and reconciliation, if direct engagement and action by the mission is not possible or advisable.
                    </li>
                    <li>
                      Be careful that the mission&apos;s presence or activities do not cause harm to civilians, for example, in contexts where peacekeeping forces are the likely target of attacks.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="relative margin-top margin-bottom">
            <img src={attack} alt="Camp attack" />
            <div className="description">
              <p>
                A MINUSMA camp is targeted by intensive rocket and mortar fire which injures five personnel. (2017)
              </p>
            </div>
            <p className="ref">UN Photo/Sylvain Liechti</p>
          </div>

          <table className="examples border-bottom">
            <thead>
              <tr>
                <td>Context</td>
                <td>Suggested Approach</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>
                    Addressing <strong>intertwined and complex threats</strong>, including community-based violence, wide-spread criminality or organized criminal networks, self-defence groups, militias or armed groups can be difficult when attempting to <strong>distinguish civilians from combatants </strong>or when <strong>linking local conflicts to national political processes</strong>.
                  </p>
                  <p>
                    Both asymmetric and complex environments may also bring confusion to determining ‘who is who&apos; and, as a result, pose a risk of inadequate or unlawful and indiscriminate <strong>action by peacekeepers against communities </strong>associated with the threat.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Seek to identify in advance who should be considered civilians and how the mission will make tactical decisions on the ground on who to protect. This should occur when analysing particular threats to civilians as part of the interpretation of the POC mandate and the development of a POC strategy.
                    </li>
                    <li>
                      Map the different actors and conflicts to determine the best leverage points for engagement at the local and national levels.
                    </li>
                    <li>
                      Take steps to mitigate civilian harm when engaged in military or police operations (see chapter 11.4).
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    The <strong>conflict environment </strong>within which the mission is operating may <strong>change </strong>over time and the mission may be slow to respond to this change.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Constantly adapt and sequence the mission&apos;s response to the lifecycle of a conflict, from addressing immediate and urgent threats (pre-emption and response phases) to dealing with latent threats and structural conflict drivers (prevention and consolidation phases).
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Lack of decisive deterrence </strong>mechanisms by the international community, as a whole, may result in pressure and expectations on a peacekeeping mission to fulfil a role that should be a shared responsibility supported by political and diplomatic efforts.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Raise at a diplomatic level the need for greater political support and engagement by Member States, the Security Council and other influential actors. Senior mission leadership and the Secretariat should lead such efforts.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>A negative perception and lack of acceptance </strong>of a mission by local actors, including the population, can hinder its ability to protect. This can be caused by failures to protect civilians, harm caused by peacekeepers themselves, including through sexual exploitation and abuse, or perceived partiality of the mission&apos;s actions or motives, which is often manipulated by those wishing to discredit the mission.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Use a strategic communications strategy to manage expectations, explain the POC mandate and the mission&apos;s approach, and address any rumours against the mission.
                    </li>
                    <li>
                      Invest in community engagement to build acceptance and access.
                    </li>
                    <li>
                      Ensure the highest standard of conduct of peacekeepers including through zero tolerance of sexual exploitation and abuse.
                    </li>
                    <li>
                      Acknowledge protection failures, conduct prompt after action reviews (AARs) and investigations, implement the resulting recommendations and be transparent in the process.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Divergence of views between the mission and relevant Security Council Sanctions Committees </strong>and their respective Panels of Experts, where applicable, in the identification of political spoilers and the use of targeted sanctions. There may be a disagreement about the utility and potential unintended consequences of the use of sanctions.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Help to mitigate or pre-empt differences of opinion with close engagement and discussion between the mission and Security Council Sanctions Committees and Panels of Experts.
                    </li>
                    <li>
                      Factor in possible unintended consequences in mission plans when such differences cannot be reconciled.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Geographical, physical and other access constraints</strong>, including non-permissive environments, may hamper the ability of the mission to gather information, assess threats and respond to POC threats in certain areas.
                  </p>
                </td>
                <td>
                  <ul className="square un-purple">
                    <li>
                      Prioritize POC in the allocation of mission capacities and resources and make efforts to access challenging areas. The mission can also consider using technological solutions such as Unmanned Aerial Vehicles (UAVs) or relying on information from other actors who may have better access.
                    </li>
                    <li>
                      Use a POC communications strategy to help manage expectations about the POC mandate and the ability of the mission to provide protection in certain areas.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )
    },
    {
      index: '3.2',
      name: 'Identifying and Addressing Systemic Constraints',
      title: t('handbook.chapters.chapter3.2.title'),
      content: (
        <>
          <p>
            Certain systemic issues and challenges that impact the performance of peacekeeping, as a whole, will affect the implementation of POC mandates. These constraints have been identified in various internal and external reviews and evaluations of peacekeeping and of POC and include:
          </p>
          <div className="odd-even">
            <div>
              Challenges in force and police-unit generation and recruitment of personnel.
            </div>
            <div>
              Provision of inadequate or inappropriate assets and capabilities.
            </div>
            <div>Gaps in strategic and joint operational planning.</div>
            <div>
              Poor civilian-uniformed coordination, as well as generally siloed approaches
            </div>
            <div>Language barriers between the mission and local communities.</div>
            <div>
              Lack of strategic communications, monitoring, evaluation and accountability.
            </div>
            <div>
              Undisclosed caveats placed on force and police activities by troop/police contributing countries.
            </div>
          </div>

          <p>
            While these challenges are broader than the POC mandate, they impact the ability of the mission to protect civilians and addressing them will set the foundation for effective POC. Many of these issues need to be addressed at the strategic level, by UN Headquarters and mission leadership, but can be supported by other mission efforts. All mission components can report upwards on challenges and constraints that affect the implementation of the POC mandate.
          </p>
          <p>
            Bearing these systemic constraints in mind, there are certain underlying conditions that will maximize the effectiveness of the mission&apos;s POC planning and response and help overcome, or at least minimize, such constraints. These are:
          </p>
          <ul className="square un-purple">
            <li>
              The <strong>operational readiness </strong>of military, police and civilian components is essential for effective peacekeeping and the protection of civilians. Force and police unit generation, screening and recruitment of personnel, including female personnel, must be timely, ensure the right skills and capabilities, and be followed by adequate pre-deployment and in-mission training, including on POC.
            </li>
            <li>
              When faced with limited or insufficient resources and capabilities, the mission will have to <strong>prioritize </strong>the most serious protection concerns and <strong>be strategic </strong>in its use of different mission capabilities and use of partnerships.
              <div className="relative">
                <img src={search} alt="Search for weapons" />
                <div className="description">
                  <p>
                    UNMISS conducts an integrated search operation for weapons and restricted items in a POC site to create a safe environment for IDPs. (2016)
                  </p>
                </div>
                <p className="ref">UN Photo/Ilya Medvedev</p>
              </div>
              The mission should also regularly and frankly assess its capacity to protect civilians and inform the Secretariat and Security Council accordingly, including through quarterly forward-looking threat assessments, and inform other stakeholders such as the local population.
            </li>
          </ul>

          <ul className="square un-purple">
            <li>
              <strong>Leadership and decision making </strong>are vital, and mission leaders and managers are required to be decisive in utilizing every tool available to protect civilians under threat. Mission leadership, including at the subnational level, must therefore be ‘protection sensitive&apos;. They must be willing to be proactive, undertake politically sensitive engagement, including with host state authorities, lead internal and external communication with regard to POC actions, and ensure mission personnel are prepared and equipped to fulfil their responsibilities.
            </li>
          </ul>
          <div className="relative">
            <img src={kitshanga} alt="Kitshanga visit" />
            <div className="description">
              <p>
                MONUSCO’s SRSG conducts a field visit where she is briefed by the Force Base Commander about the operational dynamics in the area. (2019)
              </p>
            </div>
            <p className="ref">UN Photo/ MONUSCO</p>
          </div>
          <ul className="square un-purple">
            <li>
              <strong>Strategic planning </strong>for POC is essential and should <strong>align POC and political imperatives</strong>. Political communication and engagement should take a more central role in mission POC strategies. Likewise, POC should be integrated into political strategies, as well as mission strategic planning and decision making, and therefore the prioritization of mission resources.
            </li>
            <li>
              <strong>Effective coordination </strong>at strategic, operational and tactical levels is necessary to overcome and synthesize the wide array of professional perspectives and cultures brought by civilian, police and military peacekeepers, as well as humanitarian, development or state actors. Missions must avoid siloed approaches and behaviour. Better coordination helps ensure a unified understanding of threats to civilians and what constitutes appropriate POC action. Coordination also helps to <strong>operationalize POC</strong>, which requires joint operational/tactical planning and centralized decision making. Joint Operations Centres (JOCs) and other operational coordination mechanisms therefore require dedicated capacity and should apply a POC lens to the coordination of operations.
            </li>
            <li>
              Implementing POC mandates requires timely, reliable and actionable information on threats to civilians, as well as analytical tools to evaluate the information. Efficient and proactive decision making on POC requires the systematic use of <strong>early warning, peacekeeping-intelligence, information acquisition and/or analysis and assessment </strong>tools, capabilities and/or processes. It also requires regular and structured information sharing, POC-sensitive situational awareness and threat assessments, and integration of analysis and recommendations.
            </li>
            <li>
              POC tools (see chapter 9), as well as other community engagement approaches (see chapter 10), should be leveraged to <strong>build bridges between the mission and local communities</strong>, and to assist the mission to understand local conflict dynamics and threats to civilians.
            </li>
            <li>
              The mission must <strong>address harm to civilians posed by its own presence or actions </strong>as a priority, as it may pose a strategic challenge to other POC activities and the overall ability of a mission to operate. Harm may be direct or indirect. While mainstreaming POC, mission leaders and managers are required to systematically identify and address these protection risks (see chapter 10.2).
            </li>
            <li>
              <strong>Strategic communications and influence, both internally and externally</strong>, are also key to effective POC. When designing POC strategies and plans, missions should pay attention to armed actors and community perceptions. Mission components involved in public information or engaging with state security forces and armed groups should be trained on POC. They should also be integrated into POC planning, strategizing and information-sharing mechanisms so that messaging is standardized and engagement on POC, particularly with perpetrators of violence, is coordinated.
            </li>
            <li>
              <strong>Reinforced monitoring and evaluation to assess performance </strong>(both institutional and individual), internal <strong>accountability </strong>and mechanisms to ensure institutional memory are all required to (re)direct POC approaches and capture and integrate lessons learned and best practices into POC planning response.
            </li>
          </ul>
        </>
      )
    }
  ],
  footnotes: {
    content: (
      <ol type="1" start="11" className="refs">
        <li className="3-1">
          Human Rights Due Diligence Policy on support to non-UN security forces (A/67/775 - S/2013/110).
        </li>
      </ol>
    )
  }
};
