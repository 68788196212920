/**
 * Function to return if an element in the configuration has child elements
 *
 * @param {string} str    The element to see if it has sub elements
 * @param {array} config  The config array to traverse for sub elements
 * @returns true if there are child elements in the config
 */
export const hasSubs = (str, config) => {
  const regex = new RegExp(`^${str}`);
  return config.filter((v) => (v !== str && regex.test(v))).length > 0;
};

/**
 * Function to return the index to display.
 *
 * @param {string} index    The element to see if it has sub elements
 * @returns The index to be displayed
 */
export const getIndexToDisplay = (index) => {
  if (index.charAt(0) === '0') {
    if (index === '0.1') {
      return '1.2';
    }
    if (index === '0.2') {
      return '2.1';
    }
    if (index === '0.3') {
      return '2.2';
    }
    if (index === '0.4') {
      return '2.3';
    }
  }

  return index;
};
