import React from 'react';
import UNLogoImage from '../images/un_logo.jpeg';
import PhasesImage from '../images/diagram_of_phases.png';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Annex V: SOP on Early Warning and Rapid Response',
  title: t('handbook.annexes.v.title'),
  annex: '5',
  color: 'un-orange',
  content: (
    <div className="main-content">
      <div className="text-image">
        <div className="bold">
          <p>United Nations</p>
          <p>
            Multidimensional Integrated Stabilization Mission in the Central African Republic (MINUSCA)
          </p>
          <p>Ref. MINUSCA 2019</p>
        </div>
        <div>
          <img src={UNLogoImage} alt="UN logo" />
        </div>
      </div>
      <div className="centered">
        <p><strong>Standard Operating Procedure</strong></p>
        <p className="bordered">Early Warning<br />and Rapid Response</p>
        <p className="border-bottom">
          Approved by: SRSG Mankeur Ndiaye<br />
          Effective date: 1 May 2019<br />
          Contact: Protection of Civilians Unit<br />
          Review date: 1 May 2020
        </p>
        <p className="bordered full">
          <strong>
            MINUSCA STANDARD OPERATING PROCEDURE ON<br />EARLY WARNING AND RAPID RESPONSE
          </strong>
        </p>
        <div className="contents-table">
          <p><strong>Contents:</strong></p>
          <ol type="A" className="bold">
            <li>Purpose</li>
            <li>Scope</li>
            <li>Rationale</li>
            <li>Procedures</li>
            <li>Roles and Responsibilities</li>
            <li>Terms and definitions</li>
            <li>References</li>
            <li>Contact</li>
            <li>History</li>
            <li>Annexes</li>
          </ol>
        </div>
      </div>

      <h4>A. PURPOSE</h4>
      <ol type="1">
        <li>
          The aim of this Standard Operating Procedure (SOP) is to provide guidance on implementing an effective Early Warning and Rapid Response (EWRR) process that ensures an adequate Mission response to plausible, possible and imminent physical threats to civilians in the Central African Republic (CAR). The SOP aims to facilitate the rapid verification and dissemination of early warning (EW) information and rapid decision-making by relevant actors on responses to prevent and/or respond to protection of civilian (POC) threats.
        </li>
      </ol>

      <ol type="1">
        <h4>B. SCOPE</h4>
        <li>
          This SOP applies to all MINUSCA military, police and civilian personnel, from HQ to Temporary Operating Base (TOB) level. Compliance by all personnel is both mandatory and a priority.
        </li>
        <li>
          POC threats encompass all threats against the physical integrity of civilians, particularly grave violations of international human rights, humanitarian and refugee law, be these related to criminality, inter-communal conflict, non-international armed conflict, and threats from State, non-state or international security forces.
        </li>
        <li>
          This SOP does not apply to any activity outside of the Mission mandate or area of operations.
        </li>
        <h4>C. RATIONALE</h4>
        <li>
          This Standard Operating Procedure (SOP) addresses the steps required for the Mission to maintain an effective Early Warning (EW) system, including information gathering and the issuance of alerts, as a preventive measure and response to serious and credible threats against the civilian population, as mandated by Security Council Resolution 2387 (2017). Additionally, Brigadier General (Rtd) Ferdinand Amoussou&apos;s report (January 2018), whose recommendations were analyzed by MINUSCA&apos;s Senior Management Group on Protection (SMG-P) (February 2018) and an action plan to implement the General&apos;s recommendations was subsequently adopted by the Mission to respond to these recommendations. Actions included improving EW and rapid reaction mechanisms.
        </li>
        <li>
          The EW system is necessary to help the Mission adopt timely and preventive measures and actions required to prevent possible and credible threats of attacks against the physical integrity of civilians in the Central African Republic (CAR). EW contributes to better resource allocation, and provides a more comprehensive, whole-of-mission approach to implementing the POC mandate. Accordingly, EW is thus a critical tool for enhancing the Mission&apos;s capacity to protect civilians from a prevention perspective.
        </li>
        <h4>D. PROCEDURES</h4>
        <p className="bold">
          Early Warning and Rapid Response Procedures
        </p>
        <li>
          The early warning and rapid response process is composed of five (5) phases detailed below (see also graph in Annex 1). Phases A to C apply to the analysis of both non-imminent and imminent POC threats. Phase D is specific to the response to non-imminent POC threats and Phase E to imminent POC threats. Phase F – on review and evaluation – applies to both non-imminent and imminent POC threats.
        </li>
        <h4>PHASE A – BACKGROUND CONFLICT AND POC RISK ASSESSMENT</h4>
        <li>
          The following background conflict and POC risk assessment will facilitate the identification of EW:
          <ol type="1">
            <li>
              <u>Local Peace and Conflict Impact Assessment (PCIA):</u> The Head of Office (HoO) or the POC Focal Point (POC FP) as delegated by the HoO, in collaboration with the sections and components in a Field Office (FO) - including Political Affairs Division (PAD), Civil Affairs Section (CAS), Joint Mission Analysis Centre (JMAC), United Nations Police (UNPOL), and the Military (G2) - must carry out and share a PCIA with all Military, Police and civilian staff involved in Phases A to F of the EWRR process. The PCIA will be shared at the beginning of every fiscal year, and no later than 15 June of each year<sup>1</sup>.
            </li>
            <li>
              <u>Content of local PCIA:</u> PCIAs should include a (i) mapping of social, political and economic tensions and conflicts including those related to gender-based violence, transhumance, and natural resources; (ii) mapping of stakeholders involved or affected by tensions and conflicts, i.e. State actors, non-state armed actors, and other actors, including MINUSCA, UN Country Team (UNCT), international cooperation stakeholders, NGOs, local leaders, youth, protection and women organizations; and iii) the principal POC risks and opportunities linked to the different tensions and conflicts.
            </li>
            <li>
              <u>Community Protection Plans (CPPs)</u><sup>2</sup>: CPPs provide an overview of the security situation and of perceived threats to the civilian population in an FO&apos;s area of responsibility. They identify communities at risk of violence and outline actions and resources required to address identified POC risks. As such, CPPs provide useful background for POC EW. CPPs are drafted by the Civil Affairs Section (CAS) with the contribution of Community Liaison Assistants (CLAs) and inputs from community members, local authorities and the local/sector Commander via the local/sector Senior Management Group on Protection (SMG-P). They are updated biannually and must be adopted by the HoO no later than 1 May for the first semester, and 1 November for the second. The HoO bears the primary responsibility, with support from the POC Unit when requested, for the process leading up to the adoption of CPPs. The CPPs must be shared with the DSRSG-P, the DSRSG-RC/HC, the POC Unit, CAS, PAD, and the Human Rights Division (HRD)<sup>3</sup>.
            </li>
          </ol>
        </li>
        <h4>PHASE B - EW INFORMATION COLLECTION AND ASSESSMENT</h4>
        <li>
          The collection, triangulation and verification of EW information related to threats against the physical integrity of civilians should proceed as follows:
          <ol type="1">
            <li>
              <u>Step 1: Share MINUSCA contact information with POC local stakeholders:</u> To ensure MINUSCA receives EW information in a timely manner, the HoO must update and share a list of MINSUCA contact information with key POC stakeholders<sup>4</sup>. Contact information must be updated every six months. The HoO can delegate these responsibilities to the POC Focal point (POC FP).
            </li>
            <li>
              <u>Step 2: Gather and channel EW information:</u> Each Military, Police and civilian component/section has its own information gathering mechanisms, as per its mandate and capacities. All uniformed or civilian MINUSCA personnel receiving information related to any kind of physical threat against civilians or UN staff must immediately transmit this information to the local/sector JOC/Early Warning and Response Cell (EWC – see paragraph 8.1) for action. The HoO must be copied to ensure follow-up. Uniformed components transmitting the information must in addition copy their hierarchy.
            </li>
            <li>
              <u>Step 3: Assess the reliability of the information source:</u> Upon receiving information, the local/sector JOC/EWC will be responsible for immediately assessing the reliability of the information source. When assessing reliability, the local/sector JOC should use the following terms: totally reliable, usually reliable, rather reliable, rarely reliable, and non-reliable. Definitions of these terms are specified in Annexes 2 and 3.
            </li>
            <li>
              <u>Step 4: Assess the reliability of the EW information:</u> In addition to assessing the reliability of the source, the local/sector JOC/EWC will also be responsible for immediately consolidating, de-conflicting, triangulating, and verifying the information. The local/sector JOC/EWC must also immediately share this assessment with HQ/JOC and JMAC. Upon receipt, JMAC must immediately analyze the information and share the results with the local JOC/EWC.
            </li>
            <li>
              <u>Step 5: Establish an Alert or an Early Warning (EW):</u> If the information is triangulated and validated by the local JOC/EWC, it becomes an early warning (EW). Non-confirmed or non-triangulated information should be called an alert. EWs have priority over Alerts on any resource allocation. The implication of assessing info as EWs or alerts is to allow the receiver to know the type of information that s/he is dealing with.
            </li>
          </ol>
        </li>
        <h4>PHASE C – EW POC THREAT ASSESSMENT</h4>
        <li>
          Each EW or alert will undergo a POC threat assessment conducted as follows:
          <ol type="1">
            <li>
              <u>Step 6: Assess the impact on civilians:</u> The local/sector JOC/EWC, with support of the POC FP and other sections/units/divisions when relevant, will assess the impact that the threat reported in the EW or alert would have on civilians should it materialize. Impact assessment must be based on the scheme presented in Annex 4. Assessment will be shared with JOC/EWC, HRD, PAD, CAS, JMAC and POC unit at Bangui level.
            </li>
            <li>
              <u>Step 7A: Assess impact on children:</u> The Child Protection Unit at HQ level, when relevant, will contribute to FOs in the assessment of the impact that the threat reported in the EW or alert would have on children should it materialize. Assessment must be shared with Impact assessment must be based on the scheme presented in Annex 4. Assessment will be shared with JOC/EWC, HRD, PAD, CAS, JMAC and POC unit at Bangui level.
            </li>
            <li>
              <u>Step 7B: Assess impact on women:</u> The Women Protection Unit at HQ level, when relevant, will contribute to FOs in the assessment of the impact that the threat reported in the EW or alert would have on women and girls should it materialize. Assessment must be based on the scheme presented in Annex 4. Assessment will be shared with JOC/EWC, JOC at Bangui Level, HRD, PAD, CAS, JMAC and POC unit at Bangui level.
            </li>
            <li>
              <u>Step 8: JOC/EWC to assess the likelihood of the threat:</u> The local/sector SMG-P will assess the likelihood of the occurrence of the threat as &apos;high&apos;, &apos;medium&apos;, or &apos;low&apos; following guidance in Annex 5.
            </li>
            <li>
              <u>Step 9: Establish the priority level of the threat:</u> The JOC/EWC will classify alerts and EWs as being, (i) a non-imminent threat against the physical integrity of civilians, or (ii) an imminent threat against the physical integrity of civilians. The local JOC/EWC will assign the priority level to each alert or EW using the scheme presented in Annex 6.
            </li>
          </ol>
          <p>
            Note: Steps 1 to 9 are common for both non-imminent threat response (Phase D), and imminent threat response (Phase E). Each phase has its own additional different steps (10 to 12 for phase D, and steps 10 to 16 for Phase E).
          </p>
        </li>
        <h4>
          PHASE D – RESPONDING TO A NON-IMMINENT THREAT AGAINST THE PHYSICAL INTEGRITY OF CIVILIANS
        </h4>
        <li>
          If an EW alert is classified as a non-imminent threat, the following steps must be implemented:
          <ol type="1">
            <li>
              <u>Step 10: Non-imminent threat: Decision-making on response to threat:</u>
              <ol type="1">
                <li>
                  The sector/local JOC/EWC must share all information pertaining to the POC threat with the HoO and Bangui JOC.
                </li>
                <li>
                  The POC FP must present the threat to the members of the local/sector SMG-P at the next regular meeting as part of the POC Priority Matrix.
                </li>
                <li>
                  Local/sector SMG-P members, under the leadership of the HoO, will decide on the most effective actions to be taken to counter the threat. Action points will designate individual responsibilities and deadlines. The first responses will always be at FO level. Decisions will also be incorporated into the Early Warning Matrix to be followed up at the next local/sector SMG-P.
                </li>
                <li>
                  The POC FP will be responsible for updating the Early Warning Matrix and sharing the alert with JOC who must update SAGE platform. The information uploaded on SAGE must include information on casualties, as well as threats to women and children.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <u>Step 11 Non-imminent threat:</u> Implementation of response to threat: Civilian sections and Military and Police components will implement the agreed actions according to agreed-upon deadlines. Implementation of actions should be reported once the action is completed, or if obstacles arise, to the HoO, POC FPs, the local/sector JOC/EWC, and JMAC. The HoO is responsible for ensuring actions are taken as agreed.
        </li>
        <li>
          <u>Step 12 Non-imminent threat:</u> Continuous assessment of the ongoing threat: The local/sector JOC/EWC will update the local/sector SMG-P on the evolution of the threat, including on the response actions taken. The JOC/EWC will transmit an updated assessment of the threat to the HQ JOC, JMAC, and the POC Unit via daily or weekly reports, as appropriate. Steps 10, 11 and 12 will be continuously implemented until the threat has disappeared.
        </li>
        <h4>
          PHASE E – RESPONDING TO AN IMMINENT THREAT AGAINST THE PHYSICAL INTEGRITY OF CIVILIANS
        </h4>
        <li>
          If an EW or alert is classified as an imminent threat, the following steps must be immediately implemented:
          <ol type="1">
            <li>
              <u>Step 10 Imminent threat:</u> Immediate dissemination of EW or alert: Local/sector JOC/EWC will immediately transmit EW or alerts to the HoO, the local/sector commanders of the Force and UNPOL, the FO POC FPs, the POC Unit and the HQ JOC.
            </li>
            <li>
              <u>Step 11 Imminent threat:</u> Activate emergency meeting of local/sector SMG-P to decide on rapid response: The HoO will convene an emergency local/sector SMG-P. Local authorities, UNCT, ICRC, national/international NGOs, and other relevant stakeholders can be consulted for information gathering, or invited to the SMG-P, as needed. Under the leadership of the HoO, participants will decide on actions<sup>5</sup> to immediately protect civilians/UN personnel from the imminent threat. Decisions on response will be taken at the FO-level, with decisions transmitted immediately to the HQ JOC. Senior Mission Leadership may at all times endorse or modify FO-level decisions. Response decisions will be integrated into the Early Warning Matrix by local/sector JOC/EWC, in coordination with JMAC, if present.
            </li>
            <li>
              <u>Step 12 Imminent threat:</u> Local JOC/EWC to issue an EW Flash Report: After a decision on response is taken by the emergency local/sector SMG-P, the local/sector JOC/EWC must immediately issue an EW flash report containing details on the incident and the response. An EW flash report should be sent to HQ, FCOS, JOC, JMAC, POC Unit, DSRSG-P and DSRSG-RC/HC.
            </li>
            <li>
              <u>Step 13 Imminent threat:</u> Local/sector SMG-P to share EW with external partners where relevant: The local/sector SMG-P may decide to share the EW or alert with relevant partners, stakeholders and other coordination structures as needed to ensure a more effective response to the threat. The local/sector SMG-P may also choose to communicate the EW or alert to the civilian population to allow individuals and communities potentially exposed to threats to take actions to avoid or reduce risks.
            </li>
            <li>
              <u>Step 14 Imminent threat:</u> Updating the Early Warning Matrix: The local/sector JOC/EWC will update the Early Warning Matrix and share it with the POC Unit at HQ. Information relevant to the EW pertaining to women and children will be systematically uploaded and updated in SAGE by JOC.
            </li>
            <li>
              <u>Step 15 Imminent threat:</u> Implementation of rapid responses: The HoO will be responsible for the immediate implementation and coordination of the local/sector SMG-P&apos;s rapid response decisions. Should the response include the use of uniformed personnel, the HoO will work with the local commanders of the Force and UNPOL in the implementation and coordination of the response. Military and police components do not require specific or additional orders, such as FRAGOs, to implement local/sector SMG-P rapid response decisions to protect civilians.<sup>6</sup> Failure by any component or section of the Mission to translate EW into rapid response could lead to investigations by the Conduct and Discipline Unit (CDU).
            </li>
            <li>
              <u>Step 16: POC risk and impact assessment:</u> To prevent any civilian, military and/or police actions from inflicting disproportionate or to negatively impact on the civilian population, the HoO, via local/sector SMG-P, must conduct a rapid POC risk assessment, in consultation with JMAC/U2 where present, highlighting potential negative impacts and/or collateral damage on the civilian population, and must design a corresponding harm mitigation and response strategy before the implementation of any operation. Strategies must be shared with the POC Unit before the start of the operation.
            </li>
          </ol>
        </li>
        <h4>PHASE F – REVIEW AND EVALUATION</h4>
        <li>
          <u>Step 17: Conduct an evaluation of the implementation of the EWRR process:</u> The local/sector SMG-P or the national Protection Working Group (PWG) will decide if an evaluation of the implementation of the EWRR is necessary. Accordingly, the SMG-P will decide on the tool to be used to review the implementation of the EWRR process in the response to a specific threat. These tools include After Action Reviews (AAR), impact evaluations and Joint Assessment Missions (JAMs). Evaluation reports will be shared along the reporting lines defined in the SOPs of each tool, and with PWG members. The AAR will focus, inter alia, on: (i) translating early warning into rapid response; and (ii) actions and decisions of the HoO, local/sector Commander (UNPOL and Force), and the local/sector SMG-P. AAR findings will be taken into consideration by the HoO, Sector Commander and Sector Police Commissioner (SPC) to
        </li>
        <h4>Local Coordination Mechanisms</h4>
        <li>
          <strong>Early Warning and Response Cell: </strong>The HoO is responsible for establishing an Early Warning and Response Cell or &apos;Field JOC&apos;. The local/sector JOC/EWC is responsible for, (i) collecting, consolidating, triangulating and verifying information on POC threats; (ii) disseminating alerts and/or EWs related to POC threats; (iii) updating and uploading the POC Priority Matrix; (iv) sharing the Priority Matrix with the POC Unit; and (v) all the other responsibilities assigned to it in this SOP. The EWC/Field JOC is coordinated by the HoO, who can also delegate tasks to the EWC/Field JOC. The EWC is composed of at least 3 representatives from substantive FO sections and representatives of Military Observers, military, police, security, and Director of Mission Support (DMS).
        </li>
        <li>
          <strong>Prioritization of Resources</strong>
          <ol type="1">
            <li>
              Security Council Resolution 2217 (April 2015) states that POC must be given priority in decisions about deployments and allocation of resources. Only MEDEVAC, CASEVAC and military operations have priority over EW rapid response activities. Accordingly, DMS and all related financial, administrative and support offices/sections/units will prioritize the implementation of rapid responses, including but not limited to:
              <ol type="1">
                <li>
                  Responding to all rapid response related requests with the maximum priority, and in the shortest time frame, fully understanding that the unpredictability and/or urgent nature of rapid responses will often mean that not all administrative deadlines, including the processing of MOPs, will be met in a timely manner.
                </li>
                <li>
                  Assigning/requesting resources, speeding processing times, and facilitating flight assignation.
                </li>
              </ol>
            </li>
            <li>
              The HoO is responsible for coordinating with local/sector SMG-P participants and with the DMS and POC FPs to organise all logistic needs for the implementation of rapid responses. ODMS and UNDSS are instructed to rapidly and effectively facilitate all the necessary permissions, logistics, equipment, material and resources for the implementation of the rapid responses.
            </li>
          </ol>
        </li>
        <h4>F. TERMS AND DEFINITIONS</h4>
        <li>
          <strong>ALERT: </strong>Information related to threats against the physical integrity of civilians that has not been confirmed or has not been totally triangulated.
        </li>
        <li>
          <strong>COMMUNITY ALERT NETWORKS (CANs): </strong>The CANs reinforce the capacities of communities to protect themselves, by enhancing and organizing their means of communication. They aim to provide early warning information related to threats of violence and can help in the triangulation of information and prevention of threats materializing. Community Liaison Assistants (CLAs), EWC/JOC, and all POC FPs must build relationships with CAN focal points to improve information flow and triangulation, including identification of reliable focal points and sources of information within the community, for example community-based groups and civil society organizations.
        </li>
        <li>
          <strong>COMMUNITY LIAISON ASSISTANT (CLA)</strong><sup>7</sup><strong>: </strong>A CLA is a national Civil Affairs staff member collocated with military and police contingents, to support interactions with local authorities, communities and other relevant actors in the field. CLAs perform a range of tasks, including information gathering, threat or needs assessments, contributing to EW activities, local-level protection, planning and coordination of and follow up on field visits, MINUSCA patrols and operations, as well as strengthening the resilience of local communities against protection threats.
        </li>
        <li>
          <strong>EARLY WARNING: </strong>A serious and credible threat from non-state armed actors (including but not limited to self-defense groups, armed groups, and criminal gangs) and/or State authorities that puts at risk the physical integrity of civilians, including human rights violations and IHL infractions. EW differs from simple reporting such as flash reports. These reports generally refer to physical violence, human rights violations and/or IHL infractions which have already taken place. The EW mechanism focuses primarily on gathering, sharing and responding to information on possible incidents that have the potential to directly impact civilians and their physical integrity. Each EW requires a rapid response to be carried out to prevent such violence from occurring.
        </li>
        <li>
          <strong>RAPID RESPONSE: </strong>Rapid response to imminent threats are plans, projects, programs, or actions, agreed upon at the local/sector SMG-P, which must be carried out/activated/implemented, to prevent, preempt, or effectively respond to threats against the physical integrity of civilians, in the area of responsibility at local level. Rapid reaction includes: anticipation, prevention, deterrence, use of Urgent Temporally Measures (UTMs); and/or (ii) effective response, including but not limited to the use of force beyond self-defense, as defined within the MINUSCA POC Strategy 2018. Rapid response is an active obligation of each FO under the joint responsibility of HoO, local/sector Commander and Police Commander.
        </li>
        <h4>G. REFERENCES</h4>
        <li>
          <strong>Normative or superior references</strong>
          <ul>
            <li>
              DPKO and DFS Policy on the Protection of Civilians in UN Peacekeeping, 2015
            </li>
            <li>
              DPKO and DFS Protection of Civilians Military Guidelines, January 2015
            </li>
            <li>DPKO and DFS JOC Policy and Guidelines, May 2014</li>
            <li>DPKO and DFS JMAC Policy and Guidelines, March 2014</li>
            <li>DPKO-DFS Peacekeeping Intelligence Policy, 2017</li>
            <li>
              SG’s Bulletin on Information Sensitivity, Classification and Handling, February 2007
            </li>
            <li>
              Improving Conflict Early Warning Systems for United Nations Peacekeeping
            </li>
          </ul>
        </li>
        <li>
          <strong>Related procedures or guidelines</strong>
          <ul>
            <li>
              MINUSCA SOP for the Collaboration between the Mission uniformed components and Community Liaison Assistants (2018)
            </li>
            <li>MINUSCA Protection of Civilians Strategy, February 2018</li>
            <li>MINUSCA IOM Information Flow to JOC, November 2014</li>
            <li>MINUSCA SOP on Crisis Management, 2014</li>
            <li>
              MINUSCA SOP on Information sharing and follow-up action on human rights violations for MINUSCA Military, Police and Correction components (TBC)
            </li>
            <li>
              MINUSCA Operation of Sector Joint Operations Centres (SJOC), 2017 • MINUSCA SOP ON JOC AND FJOC
            </li>
          </ul>
        </li>
        <h4>H. CONTACT</h4>
        <li>
          For any additional information and/or training on this SOP please contact MINUSCA Senior POC Advisor <a href="mailto:MINUSCA-POC-UNIT@un.org">MINUSCA-POC-UNIT@un.org</a>
        </li>
        <h4>I. HISTORY</h4>
        <li>
          This SOP was drafted by the POC Unit in the period from February to July 2018. Draft versions were shared with all military, police and civilian members of the PWG, including representatives from the UNCT, and with the Strategic Planning Unit (SPU) for inputs and contributions. This version was endorsed by the SMG-P on 20 July 2018. A final version was circulated on Oct 2018, and cleared by DSRSG-P on December 2018.
        </li>
        <li>
          A previous version of this SOP was drafted in 2015 by the POC Unit. It was consulted with the PWG and endorsed by the SMG-P on 5 May 2016 but was never adopted as a Mission document.
        </li>
      </ol>

      <p className="bold">APPROVAL SIGNATURE:</p>
      <p className="bold">APPROVAL SIGNATURE (SRSG):</p>
      <p>
        Mankeur Ndiaye,<br />
        Special Representative of the Secretary-General<br />
        DATE OF APPROVAL
      </p>

      <h4>J. ANNEXES</h4>
      <h5>Annex 1: Diagram of phases of information flow</h5>
      <p>Reference: Paragraph 24.</p>
      <img src={PhasesImage} alt="Diagram of phases" />

      <h5>Annex 2: Source reliability</h5>
      <table className="horizontal">
        <thead>
          <tr>
            <td />
            <td>RELIABILITY OF THE SOURCE</td>
            <td>DEFINITION</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A</td>
            <td>Totally reliable</td>
            <td>
              No doubt. Past information. Source has always been reliable. History of constructive relationship with the source, developed into mutual trust.
            </td>
          </tr>
          <tr>
            <td>B</td>
            <td>Usually reliable</td>
            <td>
              Some doubt but past information of source has proved reliable.
            </td>
          </tr>
          <tr>
            <td>C</td>
            <td>Rather reliable</td>
            <td>
              Usually some doubt on past information. Source is reliable in moderate number of cases.
            </td>
          </tr>
          <tr>
            <td>D</td>
            <td>Rarely reliable</td>
            <td>Real doubt, past information generally not reliable.</td>
          </tr>
          <tr>
            <td>E</td>
            <td>Non-reliable</td>
            <td>
              Great doubt on past information. Source has proved to be unreliable; can still provide credible info.
            </td>
          </tr>
          <tr>
            <td>F</td>
            <td>The reliability cannot be estimated</td>
            <td>Reliability cannot be determined.</td>
          </tr>
        </tbody>
      </table>

      <h5>Annex 3: Information reliability</h5>
      <table className="horizontal">
        <thead>
          <tr>
            <td>RELIABILITY OF THE INFORMATION</td>
            <td>DEFINITION</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Sure accuracy</td>
            <td>
              Substantiated by independent sources &amp; agrees with other information on subject.
            </td>
          </tr>
          <tr>
            <td>Confirmed-coherent</td>
            <td>
              Gives indication of accuracy; agrees with other information.
            </td>
          </tr>
          <tr>
            <td>Probable-relevant</td>
            <td>Neither confirmed nor contradicted; not in disagreement.</td>
          </tr>
          <tr>
            <td>Doubtful</td>
            <td>
              Believed to be unlikely, but possible; not contradicted, not in disagreement.
            </td>
          </tr>
          <tr>
            <td>Non-probable</td>
            <td>
              Contradicted by other data, illogical within itself &amp; in disagreement with body of information on subject.
            </td>
          </tr>
          <tr>
            <td>The truthfulness cannot be estimated</td>
            <td>
              Truth cannot be judged at the time because of lack of knowledge on subject.
            </td>
          </tr>
        </tbody>
      </table>
      <h5>
        Annex 4: Impact of the threat on civilians, women, children and girls
      </h5>
      <table className="horizontal">
        <thead>
          <tr>
            <td>IMPACT OF THE THREAT</td>
            <td>DEFINITION</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>High</td>
            <td>
              If the threat against physical integrity materializes, a significant number of civilians, including women and children, will be directly affected by violations of International Humanitarian Law (IHL) or International Human Rights Law (IHRL), including, but not limited to: direct or indirect loss of lives; impairments to physical integrity; severe internal displacement; elevated food insecurity; gender-based violence; or serious injury; destruction or loss of civilian assets/infrastructure required for civilian survival; or the imposition of conditions that prevent food supply or urgent humanitarian assistance.
            </td>
          </tr>
          <tr>
            <td>Medium</td>
            <td>
              If the threat against physical integrity materializes, some civilians, including women and children will be directly affected by infractions to IHL, or violations to IHL/IHRL, including, but not limited to internal displacement, food insecurity, gender-based violence, or injuries; there may be some repercussion on civilian assets/infrastructure required for civilian survival. Food supply or urgent humanitarian assistance can be disrupted.
            </td>
          </tr>
          <tr>
            <td>Low</td>
            <td>
              If the threat against physical integrity materializes, a few (civilians), (women), (children), (girls) will be directly affected by IHL/IHRL violations. Food security and humanitarian assistance will be able to continue.
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Annex 5: Likelihood of the threat emerging</h5>
      <table className="horizontal">
        <thead>
          <tr>
            <td>LIKELIHOOD OF THE THREAT</td>
            <td>DEFINITION</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>High</td>
            <td>
              The agent generating the physical threat against civilians is highly motivated and sufficiently capable, and actions preventing the threat from being materialized are not used or ineffective.
            </td>
          </tr>
          <tr>
            <td>Medium</td>
            <td>
              The agent generating the physical threat against civilians is motivated and capable, but actions to prevent the threat from being implemented may impede the successful materialization of the threat.
            </td>
          </tr>
          <tr>
            <td>Low</td>
            <td>
              The agent generating the physical threat against civilians lacks motivation or capability; or actions to prevent the threat are in place and/or can impede the threat from being exercised.
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Annex 6: Priority level of the threat</h5>
      <p>
        Priority level of the threat is an assessment conducted by evaluating simultaneously the likelihood of the threat and the impact of the threat, as shown below.
      </p>
      <p>[PRIORITY LEVEL OF THREAT SVG]</p>
      <h5>Annex 7: Community Protection Plan</h5>
      <p>
        Community protection plan template is presented below in French, to facilitate activities at field level.
      </p>
      <p>[Community Protection Plan Document]</p>
      <ol type="1">
        <li>
          PICA methodology is available at <a href="http://www.academia.edu/9491700/Peace_and_Conflict_Impact_Assessment_-">https://www.academia.edu/9491700/Peace_and_Conflict_Impact_Assessment_- Hands_On_PCIA_HANDBOOK_</a> or also at <a href="http://www.managingforimpact.org/sites/default/files/resource/hands_on_pcia_handbook_bush_final_author_version1.pdf">http://www.managingforimpact.org/sites/default/files/resource/hands_on_pcia_handbook_bush_final_author_version1.pdf.</a>
        </li>
        <li>
          See MINUSCA Community Protection Plan template, June 2015.
        </li>
        <li>
          Community Protection Plans must be drafted following the most updated CPP template. This template is produced, updated and shared by Civil Affairs Section. Please see Annex 7 for the template.
        </li>
        <li>
          POC stakeholders are identified by the local/sector SMG-P. They can include State representatives, UNCT, the International Committee of the Red Cross (ICRC), international and local NGOs, religious leaders, traditional leaders, Community Alert Networks (CAN) focal points and other civil society members.
        </li>
        <li>
          Rapid responses include but are not limited to: patrolling, robust patrolling, setting up of UN checkpoints, establishment of buffer zones, political dialogues, negotiations of ceasefires and local peace agreements, as relevant.
        </li>
        <li>
          The UN Security Council authorizes MINUSCA, including the Force, to conduct robust actions, including the use of Urgent Temporary Measures (UTMs), patrolling, and the use of force even beyond self-defense, to effectively protect civilians.
        </li>
        <li>
          See MINUSCA TORs and Aide-Memoire for Community Liaison Assistants, July 2015.
        </li>
      </ol>
    </div>
  )
};
