/**
 * POC Fundamentals Flow (To be kept separate from all).
 *
 * See `src/templates/poc_fundamentals.js`
 */
const common = [
  { section: '0-1' },
  { section: '0-2' },
  { section: '0-2-1-1' },
  { section: '0-2-1-2' },
  { section: '0-3' },
  { section: '0-4' },
  { section: '0-4-3' },
  { section: '0-4-3-1' },
  { section: '0-4-3-2' },
];

/**
 * Caveats:
 * - When a chapter is given instead of a section or subsection, the entire
 *   chapter will be accessible by the user.
 * - No Voices from the fields should be included
 */
/**
 * These definitions are now structured to support focussed control of element visibility.
 *
 * Each definition must include an objection with at least a "section" key. That will make that
 * section visible.
 *
 * If you wish to hide a piece of child content, the "exclude" key will allow you to specify
 * an Array of String's that represent CSS selectors to be hidden. Note, this will apply
 * to the full CSS tree below this section (not just the direct descendant ie. ' > '),
 * so use carefully.
 *
 * Examples:
 *
 *  - { section: '5-1-1', exclude: [ '.voices' ] }
 *  - { section: '5-3', exclude: ['.checklist', '.case-study'] }
 *
 * If you wish to only show a set of child elements (or have very tight display control), the
 * "include" key will allow to specify an Array of Object's that represent elements to be visible.
 * Note, these elements will apply to direct descendants only, ie. ' > '. All other content
 * at this section level should be hidden (except for 'h5' titles).
 *
 * The syntax to include child elements supports three DSL's.
 *
 * 1. You can specify the HTML tag you want to show, and the index of type within that
 * HTML hierarchy. Examples:
 *
 *  - [{ p: [1, 2] }] // This will show the first and second paragraphs only
 *  - [{ p: [1, 4] }, { ul: [1] }] // This will show the first and fourth paragraphs, and the first lists only
 *
 * 2. You can provide an explicit full path CSS selector, how to display it and
 * which indices it applies to. Example:
 *
 *  - [{ selector: 'div.side-by-side', display: 'grid', indices: [1] }] // This will show the first div with the class 'side-by-side' using display: grid
 *
 * 3. You can use the string CSS selector directly. Example:
 *
 *  - ['div.template-container']
 *
 * These definitions are stacked together in an Array - meaning you can combine definitions to
 * show exactly and only what you like.
 *
 * Plenty of examples are included below for reference.
 */

const civilianLeadershipGridContent = [{
  1: true,
  2: true,
  3: false,
  4: true,
  5: false,
  6: false,
  7: false,
  8: false,
  9: false,
  10: false,
  11: true,
  12: false,
  13: false,
  14: true,
  15: true
}];
const policeLeadershipGridContent = [{
  1: true,
  2: true,
  3: false,
  4: true,
  5: false,
  6: false,
  7: false,
  8: false,
  9: true,
  10: false,
  11: true,
  12: false,
  13: true,
  14: true,
  15: true
}];
const militaryLeadershipGridContent = [{
  1: true,
  2: true,
  3: false,
  4: true,
  5: false,
  6: false,
  7: false,
  8: false,
  9: true,
  10: false,
  11: true,
  12: false,
  13: true,
  14: true,
  15: true
}];
const civilianPlannerGridContent = [{
  1: true,
  2: true,
  3: false,
  4: false,
  5: true,
  6: true,
  7: false,
  8: true,
  9: false,
  10: false,
  11: false,
  12: false,
  13: true,
  14: true,
  15: true
}];
const policePlannerGridContent = [{
  1: true,
  2: true,
  3: false,
  4: false,
  5: true,
  6: true,
  7: false,
  8: true,
  9: false,
  10: false,
  11: true,
  12: false,
  13: true,
  14: true,
  15: true
}];
const militaryPlannerGridContent = [{
  1: true,
  2: true,
  3: false,
  4: false,
  5: true,
  6: true,
  7: false,
  8: true,
  9: false,
  10: false,
  11: true,
  12: false,
  13: true,
  14: true,
  15: true
}];
const civilianCommunicationsGridContent = [{
  1: true,
  2: true,
  3: false,
  4: false,
  5: false,
  6: false,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
  12: false,
  13: false,
  14: true,
  15: true
}];
const policeCommunicationsGridContent = [{
  1: true,
  2: true,
  3: false,
  4: false,
  5: false,
  6: false,
  7: true,
  8: true,
  9: true,
  10: true,
  11: false,
  12: false,
  13: false,
  14: true,
  15: true
}];
const militaryCommunicationsGridContent = [{
  1: true,
  2: true,
  3: false,
  4: false,
  5: false,
  6: false,
  7: true,
  8: true,
  9: true,
  10: true,
  11: false,
  12: false,
  13: false,
  14: true,
  15: true
}];
const civilianGeneralGridContent = [{
  1: true,
  2: true,
  3: false,
  4: false,
  5: true,
  6: false,
  7: false,
  8: false,
  9: true,
  10: false,
  11: false,
  12: false,
  13: true,
  14: true,
  15: true
}];
const policeGeneralGridContent = [{
  1: true,
  2: true,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
  8: false,
  9: false,
  10: false,
  11: true,
  12: false,
  13: true,
  14: true,
  15: true
}];
const militaryGeneralGridContent = [{
  1: true,
  2: true,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
  8: false,
  9: false,
  10: false,
  11: true,
  12: false,
  13: true,
  14: true,
  15: true
}];

export const gridContent = {
  'civilian-none': civilianGeneralGridContent,
  'police-none': policeGeneralGridContent,
  'military-none': militaryGeneralGridContent,
  'civilian-leadership': civilianLeadershipGridContent,
  'non-member-leadership': civilianLeadershipGridContent,
  'police-leadership': policeLeadershipGridContent,
  'military-leadership': militaryLeadershipGridContent,
  'civilian-planning': civilianPlannerGridContent,
  'non-member-planning': civilianPlannerGridContent,
  'police-planning': policePlannerGridContent,
  'military-planning': militaryPlannerGridContent,
  'civilian-communications': civilianCommunicationsGridContent,
  'non-member-communications': civilianCommunicationsGridContent,
  'police-communications': policeCommunicationsGridContent,
  'military-communications': militaryCommunicationsGridContent,
};

const civilianLeadershipFlow = [
  ...common,
  { section: '1-3', footnotes: ['1-3'] },
  { section: '3-1', footnotes: ['3-1'] },
  { section: '3-2' },
  { section: '4', exclude: ['.voices'], footnotes: ['4-1-4', '4-4', '4-5'] },
  { section: '5-1' },
  { section: '5-1-1', exclude: ['.voices'] },
  { section: '5-1-2', include: [{ p: [1, 2] }] },
  {
    section: '5-1-3',
    include: [
      'div.template-container',
      'div.template-container > div.template',
    ],
    exclude: ['div.template-container > div'],
  },
  { section: '5-2', include: [{ p: [1, 3] }] },
  { section: '5-3', exclude: ['.checklist'], footnotes: ['5-3'] },
  { section: '6-1', include: [{ div: [4, 5, 6] }] },
  { section: '6-1-1' },
  { section: '6-1-2' },
  { section: '6-1-3' },
  { section: '6-2', include: [{ p: [1, 4] }, { ul: [1] }], exclude: ['h5'] },
  { section: '6-3' },
  { section: '7-1', exclude: ['.voices'] },
  { section: '8-1' },
  { section: '8-2', include: [{ p: [1, 2] }, { div: [1, 5] }] },
  {
    section: '8-2-1',
    include: [
      'div.template-container',
      'div.template-container > div.template',
    ],
    exclude: ['div.template-container > div'],
    footnotes: ['8-2-1']
  },
  { section: '8-2-5' },
  { section: '8-3', include: [{ p: [1, 7] }, { div: [1, 2] }, { table: [1, 2] }] },
  {
    section: '8-4',
    include: [
      { p: [1, 2] },
      'div.template-container',
      'div.template-container > div.template',
    ],
    exclude: ['div.template-container > div'],
  },
  { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'], footnotes: ['8-5'] },
  { section: '9-1' },
  {
    section: '10-1',
    include: [{ p: [1, 2, 4, 5] }, { ul: [1] }],
    exclude: ['h5'],
  },
  { section: '10-2', include: [{ p: [1, 2, 3, 4, 6] }] },
  { section: '11-1' },
  {
    section: '11-2',
    include: [
      { p: [1, 2] },
      { selector: 'div.side-by-side', display: 'grid', indices: [1] },
      'div.template',
    ],
    exclude: ['h5'],
  },
  { section: '11-3', include: [{ p: [3, 4] }] },
  { section: '11-4', include: [{ div: [5] }] },
  {
    section: '11-4-5',
    include: [{ selector: 'div', display: 'block', indices: [3] }],
  },
  { section: '12-1' },
  { section: '12-2' },
  { section: '12-3', include: [{ p: [1, 2] }], exclude: ['h5'] },
  { section: '12-4', footnotes: ['12-4'] },
  { section: '13-2', exclude: ['.voices'] },
  { section: '13-3', include: [{ p: [1] }, { ul: [1] }, { div: [1] }] },
];
const civilianPlanningFlow = [
  ...common,
  { section: '1-3', footnotes: ['1-3'] },
  { section: '3-1', footnotes: ['3-1'] },
  { section: '3-2' },
  { section: '4-1', include: [{ div: [1, 3] }] },
  { section: '4-1-1' },
  {
    section: '4-1-3',
    include: [
      { ol: [1] },
      { selector: 'ol > p', display: 'block', indices: [6] },
      { selector: 'ol > ul', display: 'block', indices: [1] },
    ],
    exclude: ['ol > li', 'ol > p', 'ol > div'],
  },
  { section: '5-1' },
  { section: '5-1-1', exclude: ['.voices'] },
  { section: '5-1-2' },
  {
    section: '5-1-3',
    include: [
      'div.template-container',
      'div.template-container > div.template',
    ],
    exclude: ['div.template-container > div'],
  },
  { section: '5-2', footnotes: ['5-2'] },
  { section: '5-3', footnotes: ['5-3'] },
  { section: '5-4' },
  { section: '6-1', exclude: ['.voices'] },
  { section: '6-1-1' },
  { section: '6-1-2' },
  {
    section: '6-1-3',
    include: [{ p: [1, 2] }, { h5: [1] }, { table: [1] }],
    exclude: ['h5'],
  },
  { section: '6-2', exclude: ['.voices', '.case-study'], footnotes: ['6-2'] },
  { section: '6-3' },
  { section: '7-1', exclude: ['.voices'] },
  { section: '8-1' },
  { section: '8-2', include: [{ p: [1, 2] }, { div: [1, 5] }] },
  { section: '8-2-1', footnotes: ['8-2-1'] },
  { section: '8-2-5' },
  { section: '8-3' },
  { section: '8-4' },
  { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'] },
  { section: '9-1' },
  {
    section: '10-1',
    include: [{ p: [1, 2, 4, 5] }, { ul: [1] }],
    exclude: ['h5'],
  },
  { section: '10-2', include: [{ p: [1, 2, 3, 4, 6] }] },
  { section: '11-1' },
  { section: '11-2' },
  { section: '11-4', include: [{ div: [2, 6] }] },
  { section: '11-4-2', exclude: ['div'] },
  { section: '11-4-6' },
  { section: '12-1' },
  { section: '12-2' },
  { section: '12-4', footnotes: ['12-4'] },
  { section: '12-5', exclude: ['.voices', '.case-study'], footnotes: ['12-5-2'] },
  { section: '13-2', exclude: ['.voices'] },
  { section: '13-3', include: [{ p: [1] }, { ul: [1] }, { div: [1] }] },
  { section: '14-2' },
  { section: '14-3', exclude: ['.voices'] },
  { section: 'annex-3' },
];
const civilianCommunicationFlow = [
  ...common,
  { section: '1-3', footnotes: ['1-3'] },
  { section: '3-1', footnotes: ['3-1'] },
  { section: '4-1', include: [{ div: [1, 3] }] },
  { section: '4-1-1' },
  {
    section: '4-1-3',
    include: [
      { ol: [1] },
      { selector: 'ol > p', display: 'block', indices: [1, 2, 3, 4] },
      { selector: 'ol > li', display: 'block', indices: [1] },
    ],
    exclude: ['ol > p', 'ol > div', 'ol > li', 'ol > ul'],
  },
  {
    section: '4-2',
    include: [
      { ol: [1] },
      { selector: 'ol > p', display: 'block', indices: [4, 5, 6, 7, 8] },
      { selector: 'ol > div', display: 'block', indices: [1] },
      { selector: 'ol > li', display: 'block', indices: [2] },
    ],
    exclude: ['ol > p', 'ol > div', 'ol > li', 'ol > ul', 'ol > table'],
  },
  {
    section: '5-1',
    exclude: ['.voices'],
    include: [{ div: [1] }],
  },
  { section: '5-1-1' },
  { section: '5-3', include: [{ p: [1] }], exclude: ['h5'] },
  { section: '5-6' },
  { section: '6-1', exclude: ['.voices'] },
  { section: '6-1-1' },
  { section: '6-1-2' },
  { section: '6-1-3' },
  { section: '7-1', exclude: ['.voices'] },
  { section: '7-2' },
  { section: '7-3' },
  { section: '7-4', exclude: ['.voices'] },
  { section: '8-4' },
  { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'] },
  { section: '9-3', include: [{ p: [1, 2] }, { ul: [1] }, { table: [1] }] },
  { section: '9-4', include: [{ p: [1, 6] }, { div: [2] }] },
  { section: '10-1', exclude: ['> p:nth-of-type(3)'] },
  { section: '10-2' },
  { section: '12-1' },
  { section: '12-2' },
  { section: '12-3', exclude: ['.voices'], footnotes: ['12-3'] },
  { section: '12-4', footnotes: ['12-4'] },
  { section: '12-5', exclude: ['.voices', '.case-study'], footnotes: ['12-5-2'] },
  { section: '12-6', exclude: ['.voices'] },
  { section: '13-2', exclude: ['.voices'] },
  { section: '13-3', include: [{ p: [1, 2] }, { ul: [1] }, { div: [1] }, { table: [1] }] },
  { section: 'annex-4' },
];

const config = {
  'civilian-leadership': civilianLeadershipFlow,
  'non-member-leadership': civilianLeadershipFlow,
  'police-leadership': [
    ...common,
    { section: '1-3', footnotes: ['1-3'] },
    { section: '3-1', footnotes: ['3-1'] },
    { section: '3-2' },
    { section: '4-1', exclude: ['.voices'], footnotes: ['4-1-4'] },
    { section: '4-2' },
    { section: '4-3' },
    { section: '4-4', footnotes: ['4-4'] },
    { section: '5-1' },
    { section: '5-1-1', exclude: ['.voices'] },
    { section: '5-1-2', include: [{ p: [1, 2] }] },
    {
      section: '5-1-3',
      include: [
        'div.template-container',
        'div.template-container > div.template',
      ],
      exclude: ['div.template-container > div']
    },
    { section: '5-2', include: [{ p: [1, 3] }] },
    { section: '5-3', exclude: ['.checklist'], footnotes: ['5-3'] },
    { section: '6-1', include: [{ div: [4, 5, 6] }] },
    { section: '6-1-1' },
    { section: '6-1-2' },
    { section: '6-1-3' },
    {
      section: '6-2',
      include: [{ p: [1, 2, 3, 4, 17, 18] }, { ul: [1] }, { h5: [4] }],
      exclude: ['h5'],
      footnotes: ['6-2']
    },
    { section: '6-3' },
    { section: '7-1', exclude: ['.voices'] },
    { section: '8-1' },
    { section: '8-2', include: [{ p: [1, 2] }, { div: [1, 5] }] },
    {
      section: '8-2-1',
      include: [
        'div.template-container',
        'div.template-container > div.template',
      ],
      exclude: ['div.template-container > div']
    },
    { section: '8-2-5' },
    { section: '8-3', include: [{ p: [1, 7] }, { div: [1, 2] }, { table: [1, 2] }] },
    {
      section: '8-4',
      include: [
        { p: [1, 2] },
        'div.template-container',
        'div.template-container > div.template',
      ],
      exclude: ['div.template-container > div'],
    },
    { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'] },
    {
      section: '10-1',
      include: [{ p: [1, 2, 4, 5] }, { ul: [1] }],
      exclude: ['h5'],
    },
    { section: '10-2', include: [{ p: [1, 2, 3, 4, 6] }] },
    { section: '10-3', exclude: ['.voices'] },
    { section: '11-1' },
    {
      section: '11-2',
      include: [
        { p: [1, 2, 3] },
        { ul: [1] },
        { selector: 'div.side-by-side', display: 'grid', indices: [1] },
        'div.template',
      ],
      exclude: ['h5'],
    },
    { section: '11-3', include: [{ p: [3, 4] }] },
    { section: '11-4' },
    { section: '11-4-1', footnotes: ['11-4-1-5'] },
    { section: '11-4-2' },
    { section: '11-4-3' },
    { section: '11-4-4' },
    { section: '11-4-5', include: ['.case-study'] },
    { section: '11-4-6', include: [{ p: [1] }] },
    { section: '11-4-7', exclude: ['table'] },
    { section: '12-1' },
    { section: '12-3', include: [{ p: [1, 2] }], exclude: ['h5'] },
    { section: '12-4', footnotes: ['12-4'] },
    { section: '12-6', exclude: ['.voices'] },
    { section: '13-2', exclude: ['.voices'] },
    { section: '13-3', include: [{ p: [1] }, { ul: [1] }, { div: [1] }] },
  ],
  'military-leadership': [
    ...common,
    { section: '1-3', footnotes: ['1-3'] },
    { section: '3-1', footnotes: ['3-1'] },
    { section: '3-2' },
    { section: '4-1', exclude: ['.voices'], footnotes: ['4-1-4'] },
    { section: '4-2' },
    { section: '4-3' },
    { section: '4-4', footnotes: ['4-4'] },
    { section: '5-1' },
    { section: '5-1-1', exclude: ['.voices'] },
    { section: '5-1-2', include: [{ p: [1, 2] }] },
    {
      section: '5-1-3',
      include: [
        'div.template-container',
        'div.template-container > div.template',
      ],
      exclude: ['div.template-container > div'],
    },
    { section: '5-2', include: [{ p: [1, 3] }] },
    { section: '5-3', exclude: ['.checklist'], footnotes: ['5-3'] },
    { section: '6-1', include: [{ div: [4, 5, 6] }] },
    { section: '6-1-1' },
    { section: '6-1-2' },
    { section: '6-1-3' },
    {
      section: '6-2',
      include: [{ p: [1, 2, 3, 4, 17, 18] }, { ul: [1] }, { h5: [4] }],
      exclude: ['h5'],
      footnotes: ['6-2']
    },
    { section: '6-3' },
    { section: '7-1', exclude: ['.voices'] },
    { section: '8-1' },
    { section: '8-2', include: [{ p: [1, 2] }, { div: [1, 5] }] },
    {
      section: '8-2-1',
      include: [
        'div.template-container',
        'div.template-container > div.template',
      ],
      exclude: ['div.template-container > div'],
    },
    { section: '8-2-5' },
    { section: '8-3', include: [{ p: [1, 7] }, { div: [1, 2] }, { table: [1, 2] }] },
    {
      section: '8-4',
      include: [
        { p: [1, 2] },
        'div.template-container',
        'div.template-container > div.template',
      ],
      exclude: ['div.template-container > div'],
    },
    { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'] },
    {
      section: '10-1',
      include: [{ p: [1, 2, 4, 5] }, { ul: [1] }],
      exclude: ['h5'],
    },
    { section: '10-2', include: [{ p: [1, 2, 3, 4, 6] }] },
    { section: '11-1' },
    {
      section: '11-2',
      include: [
        { p: [1, 2, 3] },
        { ul: [1] },
        { selector: 'div.side-by-side', display: 'grid', indices: [1] },
        'div.template',
      ],
      exclude: ['h5'],
    },
    { section: '11-3', include: [{ p: [3, 4] }] },
    { section: '11-4' },
    { section: '11-4-1', footnotes: ['11-4-1-5'] },
    { section: '11-4-2' },
    { section: '11-4-3' },
    { section: '11-4-4' },
    { section: '11-4-5', include: ['.case-study'] },
    { section: '11-4-6', include: [{ p: [1] }] },
    { section: '11-4-7', exclude: ['table'] },
    { section: '12-1' },
    { section: '12-3', include: [{ p: [1, 2] }], exclude: ['h5'] },
    { section: '12-4', footnotes: ['12-4'] },
    { section: '12-6', exclude: ['.voices'] },
    { section: '13-2', exclude: ['.voices'] },
    { section: '13-3', include: [{ p: [1] }, { ul: [1] }, { div: [1] }] },
  ],
  'civilian-planning': civilianPlanningFlow,
  'non-member-planning': civilianPlanningFlow,
  'police-planning': [
    ...common,
    { section: '1-3', footnotes: ['1-3'] },
    { section: '3-1', footnotes: ['3-1'] },
    { section: '3-2' },
    { section: '4-1', include: [{ div: [1, 3] }] },
    { section: '4-1-1' },
    {
      section: '4-1-3',
      include: [
        { ol: [1] },
        {
          selector: 'ol > p',
          display: 'block',
          indices: [7, 8, 9, 10, 11, 12],
        },
        { selector: 'ol > ul', display: 'block', indices: [1] },
        { selector: 'ol > li', display: 'block', indices: [2, 3] },
      ],
      exclude: ['ol > li', 'ol > p', 'ol > div'],
    },
    { section: '5-1', exclude: ['div:nth-of-type(2)'] },
    { section: '5-1-1', exclude: ['.voices'] },
    { section: '5-1-3', include: [{ div: [1] }] },
    { section: '5-2', include: [{ p: [1, 3] }, { table: [1] }] },
    { section: '5-3', footnotes: ['5-3'] },
    { section: '6-1', exclude: ['.voices'] },
    { section: '6-1-1' },
    { section: '6-1-2' },
    {
      section: '6-1-3',
      include: [{ p: [1, 2] }, { h5: [1] }, { ul: [1, 3, 4, 5] }, { table: [1] }],
      exclude: ['h5'],
    },
    {
      section: '6-2',
      include: [{ p: [1, 3, 4, 11, 12, 13] }, { ul: [1] }],
      exclude: ['.voices', '.case-study', 'h5'],
      footnotes: ['6-2']
    },
    { section: '6-3' },
    { section: '7-1', exclude: ['.voices'] },
    { section: '8-1' },
    { section: '8-2', include: [{ p: [1, 2] }, { div: [1, 5] }] },
    { section: '8-2-1', footnotes: ['8-2-1'] },
    { section: '8-2-5' },
    { section: '8-3' },
    { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'] },
    { section: '9-1' },
    {
      section: '10-1',
      include: [{ p: [1, 2, 4, 5] }, { ul: [1] }],
      exclude: ['h5'],
    },
    { section: '10-2' },
    { section: '10-3', exclude: ['.voices'] },
    { section: '11', exclude: ['.voices'], footnotes: ['11-3-1', '11-3-2', '11-4-1-5'] },
    { section: '12-1' },
    { section: '12-2' },
    { section: '12-3', exclude: ['.voices'], footnotes: ['12-3'] },
    { section: '12-4', footnotes: ['12-4'] },
    { section: '12-5', exclude: ['.voices', '.case-study'], footnotes: ['12-5-2'] },
    { section: '12-6', exclude: ['.voices'] },
    { section: '13-2', exclude: ['.voices'] },
    { section: '13-3', include: [{ p: [1] }, { ul: [1] }, { div: [1] }] },
    { section: 'annex-3' },
  ],
  'military-planning': [
    ...common,
    { section: '1-3', footnotes: ['1-3'] },
    { section: '3-1', footnotes: ['3-1'] },
    { section: '3-2' },
    { section: '4-1', include: [{ div: [1, 3] }] },
    { section: '4-1-1' },
    {
      section: '4-1-3',
      include: [
        { ol: [1] },
        {
          selector: 'ol > p',
          display: 'block',
          indices: [7, 8, 9, 10, 11, 12],
        },
        { selector: 'ol > ul', display: 'block', indices: [1] },
        { selector: 'ol > li', display: 'block', indices: [2, 3] },
      ],
      exclude: ['ol > li', 'ol > p', 'ol > div'],
    },
    { section: '5-1', exclude: ['div:nth-of-type(2)'] },
    { section: '5-1-1', exclude: ['.voices'] },
    { section: '5-1-3', include: [{ div: [1] }] },
    { section: '5-2', include: [{ p: [1, 3] }, { table: [1] }] },
    { section: '5-3', footnotes: ['5-3'] },
    { section: '6-1', exclude: ['.voices'] },
    { section: '6-1-1' },
    { section: '6-1-2' },
    {
      section: '6-1-3',
      include: [{ p: [1, 2] }, { h5: [1] }, { ul: [1, 3, 4, 5] }, { table: [1] }],
      exclude: ['h5'],
    },
    { section: '6-2', exclude: ['.voices', '.case-study'], footnotes: ['6-2'] },
    { section: '6-3' },
    { section: '7-1', exclude: ['.voices'] },
    { section: '8-1' },
    { section: '8-2', include: [{ p: [1, 2] }, { div: [1, 5] }] },
    { section: '8-2-1', footnotes: ['8-2-1'] },
    { section: '8-2-5' },
    { section: '8-3' },
    { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'] },
    { section: '9-1' },
    {
      section: '10-1',
      include: [{ p: [1, 2, 4, 5] }, { ul: [1] }],
      exclude: ['h5'],
    },
    { section: '10-2' },
    { section: '11', exclude: ['.voices'], footnotes: ['11-3-1', '11-3-2', '11-4-1-5'] },
    { section: '12-1' },
    { section: '12-2' },
    { section: '12-3', exclude: ['.voices'], footnotes: ['12-3'] },
    { section: '12-4', footnotes: ['12-4'] },
    { section: '12-5', exclude: ['.voices', '.case-study'], footnotes: ['12-5-2'] },
    { section: '12-6', exclude: ['.voices'] },
    { section: '13-2', exclude: ['.voices'] },
    { section: '13-3', include: [{ p: [1] }, { ul: [1] }, { div: [1] }] },
    { section: 'annex-3' },
  ],
  'civilian-communications': civilianCommunicationFlow,
  'non-member-communications': civilianCommunicationFlow,
  'police-communications': [
    ...common,
    { section: '1-3', footnotes: ['1-3'] },
    { section: '3-1', footnotes: ['3-1'] },
    { section: '4-1', include: [{ div: [1, 3] }] },
    { section: '4-1-1' },
    {
      section: '4-1-3',
      include: [
        { ol: [1] },
        { selector: 'ol > p', display: 'block', indices: [1, 2, 3, 4] },
        { selector: 'ol > li', display: 'block', indices: [1] },
      ],
      exclude: ['ol > p', 'ol > div', 'ol > li', 'ol > ul'],
    },
    {
      section: '4-2',
      include: [
        { ol: [1] },
        { selector: 'ol > p', display: 'block', indices: [4, 5, 6, 7, 8] },
        { selector: 'ol > div', display: 'block', indices: [1] },
        { selector: 'ol > li', display: 'block', indices: [2] },
      ],
      exclude: ['ol > p', 'ol > div', 'ol > li', 'ol > ul', 'ol > table'],
    },
    {
      section: '5-1',
      exclude: ['.voices'],
      include: [{ div: [1] }],
    },
    { section: '5-1-1' },
    { section: '5-3', include: [{ p: [1] }], exclude: ['h5'] },
    { section: '6-1', exclude: ['.voices'] },
    { section: '6-1-1', include: [{ p: [1] }] },
    { section: '6-1-2' },
    { section: '6-1-3' },
    { section: '7-1', exclude: ['.voices'] },
    { section: '7-2' },
    { section: '7-3' },
    { section: '7-4', exclude: ['.voices'] },
    { section: '8-4' },
    { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'] },
    { section: '9-3', include: [{ p: [1, 2] }, { ul: [1] }, { table: [1] }] },
    { section: '9-4', include: [{ p: [1, 6] }, { div: [2] }] },
    { section: '10-1', exclude: ['> p:nth-of-type(3)'] },
    { section: '10-2' },
    { section: '10-3', exclude: ['.voices'] },
    { section: '11-4', include: [{ div: [3, 4, 5] }] },
    { section: '11-4-3' },
    { section: '11-4-4' },
    {
      section: '11-4-5',
      include: [{ div: [3] }],
    },
    { section: '12-1' },
    { section: '12-3', exclude: ['.voices'], footnotes: ['12-3'] },
    { section: '12-4', footnotes: ['12-4'] },
    { section: '12-5', exclude: ['.voices', '.case-study'], footnotes: ['12-5-2'] },
    { section: '12-6', exclude: ['.voices'] },
    { section: '13-2', exclude: ['.voices'] },
    { section: '13-3', include: [{ p: [1, 2] }, { ul: [1] }, { div: [1] }, { table: [1] }] },
    { section: 'annex-4' },
  ],
  'military-communications': [
    ...common,
    { section: '1-3', footnotes: ['1-3'] },
    { section: '3-1', footnotes: ['3-1'] },
    { section: '4-1', include: [{ div: [1, 3] }] },
    { section: '4-1-1' },
    {
      section: '4-1-3',
      include: [
        { ol: [1] },
        { selector: 'ol > p', display: 'block', indices: [1, 2, 3, 4] },
        { selector: 'ol > li', display: 'block', indices: [1] },
      ],
      exclude: ['ol > p', 'ol > div', 'ol > li', 'ol > ul'],
    },
    {
      section: '4-2',
      include: [
        { ol: [1] },
        { selector: 'ol > p', display: 'block', indices: [4, 5, 6, 7, 8] },
        { selector: 'ol > div', display: 'block', indices: [1] },
        { selector: 'ol > li', display: 'block', indices: [2] },
      ],
      exclude: ['ol > p', 'ol > div', 'ol > li', 'ol > ul', 'ol > table'],
    },
    {
      section: '5-1',
      exclude: ['.voices'],
      include: [{ div: [1] }],
    },
    { section: '5-1-1' },
    { section: '5-3', include: [{ p: [1] }], exclude: ['h5'] },
    { section: '6-1', exclude: ['.voices'] },
    { section: '6-1-1', include: [{ p: [1] }] },
    { section: '6-1-2' },
    { section: '6-1-3' },
    { section: '7-1', exclude: ['.voices'] },
    { section: '7-2' },
    { section: '7-3' },
    { section: '7-4', exclude: ['.voices'] },
    { section: '8-4' },
    { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'] },
    { section: '9-3', include: [{ p: [1, 2] }, { ul: [1] }, { table: [1] }] },
    { section: '9-4', include: [{ p: [1, 6] }, { div: [2] }] },
    { section: '10-1', exclude: ['> p:nth-of-type(3)'] },
    { section: '10-2' },
    { section: '11-4', include: [{ div: [3, 4, 5] }] },
    { section: '11-4-3' },
    { section: '11-4-4' },
    {
      section: '11-4-5',
      include: [{ div: [3] }],
    },
    { section: '12-1' },
    { section: '12-3', exclude: ['.voices'], footnotes: ['12-3'] },
    { section: '12-4', footnotes: ['12-4'] },
    { section: '12-5', exclude: ['.voices', '.case-study'], footnotes: ['12-5-2'] },
    { section: '12-6', exclude: ['.voices'] },
    { section: '13-2', exclude: ['.voices'] },
    { section: '13-3', include: [{ p: [1, 2] }, { ul: [1] }, { div: [1] }, { table: [1] }] },
    { section: 'annex-4' },
  ],
};

const civilian = [
  ...common,
  { section: '1-3', footnotes: ['1-3'] },
  { section: '3-1', footnotes: ['3-1'] },
  { section: '3-2' },
  { section: '4-1', include: [{ div: [1, 4] }] },
  { section: '4-1-1' },
  { section: '4-1-4', footnotes: ['4-1-4'] },
  {
    section: '4-2',
    include: [
      { ol: [1] },
      { selector: 'ol > p', display: 'block', indices: [13, 14, 15, 16] },
      { selector: 'ol > div', display: 'block', indices: [3, 4] },
      { selector: 'ol > li', display: 'block', indices: [5, 6] },
      { selector: 'ol > ul', display: 'block', indices: [4, 5, 6] },
    ],
    exclude: ['ol > p', 'ol > div', 'ol > li', 'ol > ul', 'ol > table'],
  },
  { section: '5-1', include: [{ div: [1, 3] }, { p: [1] }] },
  { section: '5-1-1', exclude: ['.voices'] },
  {
    section: '5-1-3',
    include: [
      'div.template-container',
      'div.template-container > div.template',
    ],
    exclude: ['div.template-container > div'],
  },
  { section: '5-2', include: [{ p: [1, 3] }] },
  { section: '5-3', include: [{ p: [1] }, { table: [1] }], exclude: ['h5'] },
  { section: '6-1', exclude: ['> p:nth-of-type(1)', '> p:nth-of-type(2)', '.voices', '> div:nth-of-type(3)'] },
  { section: '6-1-1' },
  { section: '6-1-2' },
  { section: '6-1-3' },
  { section: '6-2', include: [{ p: [1, 4] }, { ul: [1] }], exclude: ['h5'] },
  { section: '6-3' },
  { section: '7-1', exclude: ['.voices'] },
  { section: '7-2', include: [{ table: [1] }] },
  { section: '8-1' },
  { section: '8-2' },
  { section: '8-2-1', footnotes: ['8-2-1'] },
  { section: '8-2-2' },
  { section: '8-2-3' },
  { section: '8-2-4' },
  { section: '8-2-5' },
  { section: '8-3', include: [{ p: [1, 7] }, { div: [1, 2] }, { table: [1, 2] }] },
  { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'] },
  { section: '9-1' },
  { section: '9-2', exclude: ['.voices'] },
  { section: '9-3', include: [{ p: [1, 2] }, { ul: [1] }, { table: [1] }] },
  { section: '9-4', exclude: ['.voices', '.case-study:nth-of-type(2)'] },
  { section: '10-1', exclude: ['> p:nth-of-type(3)'] },
  { section: '10-2' },
  { section: '11-1' },
  { section: '11-3', include: [{ p: [1, 3] }], footnotes: ['11-3-1'] },
  { section: '11-4', include: [{ div: [2] }] },
  { section: '11-4-2' },
  { section: '12-1', exclude: ['.case-study'] },
  { section: '12-2' },
  { section: '12-3', include: [{ p: [1, 2] }], exclude: ['h5'] },
  { section: '12-4', footnotes: ['12-4'] },
  { section: '12-5', exclude: ['.voices', '.case-study'], footnotes: ['12-5-2'] },
  { section: '12-6', exclude: ['.voices', '.case-study'] },
  { section: '13-2', exclude: ['.voices'] },
  { section: '13-3' },
  { section: '15' },
];
const police = [
  ...common,
  { section: '1-3', footnotes: ['1-3'] },
  { section: '3-1', footnotes: ['3-1'] },
  { section: '3-2' },
  { section: '4-1', include: [{ div: [1, 3, 4] }] },
  { section: '4-1-1' },
  {
    section: '4-1-3',
    include: [
      { ol: [1] },
      { table: [1] },
      {
        selector: 'ol > p',
        display: 'block',
        indices: [7, 8, 9, 10, 11, 12],
      },
      { selector: 'ol > li', display: 'block', indices: [3] },
    ],
    exclude: ['ol > li', 'ol > p', 'ol > div', 'ol > ul'],
  },
  { section: '4-1-4', footnotes: ['4-1-4'] },
  {
    section: '4-2',
    include: [
      { ol: [1] },
      { selector: 'ol > p', display: 'block', indices: [13, 14, 15, 16] },
      { selector: 'ol > div', display: 'block', indices: [3, 4] },
      { selector: 'ol > li', display: 'block', indices: [5, 6] },
      { selector: 'ol > ul', display: 'block', indices: [4, 5, 6] },
    ],
    exclude: ['ol > p', 'ol > div', 'ol > li', 'ol > ul', 'ol > table'],
  },
  { section: '5-1', include: [{ div: [1, 3] }, { p: [1] }] },
  { section: '5-1-1', exclude: ['.voices'] },
  {
    section: '5-1-3',
    include: [
      'div.template-container',
      'div.template-container > div.template',
    ],
    exclude: ['div.template-container > div'],
  },
  { section: '5-2', include: [{ p: [1, 3] }] },
  { section: '5-3', include: [{ p: [1] }, { table: [1] }], exclude: ['h5'] },
  { section: '6-1', exclude: ['.voices'] },
  { section: '6-1-1' },
  { section: '6-1-2' },
  { section: '6-1-3' },
  { section: '6-2', include: [{ p: [1, 4] }, { ul: [1] }], exclude: ['h5'] },
  { section: '6-3' },
  { section: '7-1', exclude: ['.voices'] },
  { section: '7-2', include: [{ table: [1] }] },
  { section: '8-1' },
  { section: '8-2' },
  { section: '8-2-1', footnotes: ['8-2-1'] },
  { section: '8-2-2' },
  { section: '8-2-3' },
  { section: '8-2-4' },
  { section: '8-2-5' },
  { section: '8-3', include: [{ p: [1, 7] }, { div: [1, 2] }, { table: [1, 2] }] },
  { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'] },
  { section: '9-1' },
  { section: '9-2', exclude: ['.voices'] },
  { section: '9-3', include: [{ p: [1, 2, 3] }, { ul: [1] }, { table: [1] }] },
  { section: '9-4', exclude: ['.voices', '.case-study:nth-of-type(2)'] },
  { section: '10-1', exclude: ['> p:nth-of-type(3)'] },
  { section: '10-2' },
  { section: '10-3', exclude: ['.voices'] },
  { section: '11-1' },
  { section: '11-3', include: [{ p: [1, 3] }], footnotes: ['11-3-1'] },
  { section: '11-4', exclude: ['.voices'], footnotes: ['11-4-1', '11-4-1-5'] },
  { section: '12-1' },
  { section: '12-3', include: [{ p: [1, 2] }], exclude: ['h5'] },
  { section: '12-4', footnotes: ['12-4'] },
  { section: '12-5', exclude: ['.voices', '.case-study'], footnotes: ['12-5-2'] },
  { section: '12-6', exclude: ['.voices', '.case-study'] },
  { section: '13-2', exclude: ['.voices'] },
  { section: '13-3', exclude: ['.case-study'] },
  { section: '15' },
];
const military = [
  ...common,
  { section: '1-3', footnotes: ['1-3'] },
  { section: '3-1', footnotes: ['3-1'] },
  { section: '3-2' },
  { section: '4-1', include: [{ div: [1, 3, 4] }] },
  { section: '4-1-1' },
  {
    section: '4-1-3',
    include: [
      { ol: [1] },
      { table: [1] },
      {
        selector: 'ol > p',
        display: 'block',
        indices: [7, 8, 9, 10, 11, 12],
      },
      { selector: 'ol > li', display: 'block', indices: [3] },
    ],
    exclude: ['ol > li', 'ol > p', 'ol > div', 'ol > ul'],
  },
  { section: '4-1-4', footnotes: ['4-1-4'] },
  { section: '5-1', include: [{ div: [1, 3] }, { p: [1] }] },
  { section: '5-1-1', exclude: ['.voices'] },
  {
    section: '5-1-3',
    include: [
      'div.template-container',
      'div.template-container > div.template',
    ],
    exclude: ['div.template-container > div'],
  },
  { section: '5-2', include: [{ p: [1, 3] }] },
  { section: '5-3', include: [{ p: [1] }, { table: [1] }], exclude: ['h5'] },
  { section: '6-1', exclude: ['.voices'] },
  { section: '6-1-1' },
  { section: '6-1-2' },
  { section: '6-1-3' },
  { section: '6-2', include: [{ p: [1, 4] }, { ul: [1] }], exclude: ['h5'] },
  { section: '6-3' },
  { section: '7-1', exclude: ['.voices'] },
  { section: '7-2', include: [{ table: [1] }] },
  { section: '8-1' },
  { section: '8-2' },
  { section: '8-2-1', footnotes: ['8-2-1'] },
  { section: '8-2-2' },
  { section: '8-2-3' },
  { section: '8-2-4' },
  { section: '8-2-5' },
  { section: '8-3', include: [{ p: [1, 7] }, { div: [1, 2] }, { table: [1, 2] }] },
  { section: '8-5', include: [{ p: [1, 2] }], exclude: ['h5'] },
  { section: '9-1' },
  { section: '9-2', exclude: ['.voices'] },
  { section: '9-3', include: [{ p: [1, 2, 3] }, { ul: [1] }, { table: [1] }] },
  { section: '9-4', exclude: ['.voices', '.case-study:nth-of-type(2)'] },
  { section: '10-1', exclude: ['> p:nth-of-type(3)'] },
  { section: '10-2' },
  { section: '11-1' },
  { section: '11-3', include: [{ p: [1, 3] }], footnotes: ['11-3-1'] },
  { section: '11-4', exclude: ['.voices'], footnotes: ['11-4-1', '11-4-1-5'] },
  { section: '11-5', exclude: ['table'] },
  { section: '12-1' },
  { section: '12-3', include: [{ p: [1, 2] }], exclude: ['h5'] },
  { section: '12-4', footnotes: ['12-4'] },
  { section: '12-5', exclude: ['.voices', '.case-study'], footnotes: ['12-5-2'] },
  { section: '12-6', exclude: ['.voices', '.case-study'] },
  { section: '13-2', exclude: ['.voices'] },
  { section: '13-3', exclude: ['.case-study'] },
  { section: '15' },
];

config['civilian-none'] = civilian;
config['non-member-none'] = civilian;
config['police-none'] = police;
config['military-none'] = military;

export const recommendedChapters = (role, area) => {
  const specifics = config[`${role}-${area}`];
  if (!specifics) return null;

  return specifics.reduce((all, item) => {
    const chapter = item.section.match(/^\d+|annex-\d+/)[0];
    if (all.indexOf(chapter) === -1) all.push(chapter);
    return all;
  }, []);
};

export const isVisible = (role, area, index) => {
  if (!role || !area || !index) return true;
  const specifics = config[`${role}-${area}`];
  if (!specifics) return true;
  return specifics.some((s) => s.section === index.replace('.', '-'));
};

export default config;
