import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Redirect } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import PageWrapper from '../components/wrapper';
import exclamation from '../images/design/exclamation.svg';
import templates from '../templates';
import search from '../images/design/search.svg';
import actions from '../redux/actions';
import { translate } from '../services/i18n';

const keysToEntries = (keys, value) => ({
  all: value,
  ...Object.fromEntries(
    keys.map((key) => [key, value])
  )
});

const Search = ({ updateCache, location }) => {
  const [fromUrl, setFromUrl] = useState(false);
  const [keywords, setKeywords] = useState('');
  const [filter, setFilter] = useState('all');
  const [results, setResults] = useState(null);
  const [timeTaken, setTimeTaken] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [showFilters, setShowFilters] = useState(window.innerWidth > 850);
  const [searched, setSearched] = useState(false);

  // Filter available templates to only search chapters (content that has sections) and exclude
  // searching annexes, the reason for this is search behavior for annexes does not work correctly.
  // For example, highlighting search terms and scrolling down to the first term does not work
  // when searching annexes.
  const searchableTemplates = templates.filter((template) => template.sections);

  const filters = useMemo(() => {
    const options = [
      { label: translate('common.all'), value: 'all' },
      ...searchableTemplates.map((t) => ({
        label: t.name,
        value: t.name
      }))
    ];
    return options;
  }, []);

  useEffect(() => {
    setFilter(
      keysToEntries(
        templates.map((chapter) => chapter.name),
        true
      )
    );
  }, []);

  const addMarks = (text, regex) => text.replace(regex, '<mark>$1</mark>');

  const doSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    setSearched(true);

    // Don't bother searching if the keyword is empty or less than 2 characters
    if (!keywords || keywords.length < 2) {
      return;
    }

    updateCache({ keywords, tab: 'text' });

    const found = [];
    const regex = new RegExp(`(${sanitizeHtml(keywords)})`, 'gi');
    const start = Date.now();

    const checkChildren = (parent, chapter, section = null) => {
      // If the children are an array, loop through and check
      if (parent.props && Array.isArray(parent.props.children)) {
        parent.props.children.forEach((c) => checkChildren(c, chapter, section));
        return;
      }

      // If the child is an object call checkChildren
      if (parent.props && typeof parent.props.children === 'object') {
        checkChildren(parent.props.children, chapter, section);
        return;
      }

      // If the child is a string check it against keywords
      if (
        parent.props
          && typeof parent.props.children === 'string'
          && regex.test(parent.props.children)
      ) {
        found.push({ chapter, section, text: addMarks(parent.props.children, regex) });
        return;
      }

      if (regex.test(parent)) {
        found.push({ chapter, section, text: addMarks(parent, regex) });
      }
    };

    // Filter chapters based on the filters...
    let filtered = searchableTemplates;

    if (!filter.all) {
      filtered = filtered.filter((t) => filter[t.name]);
    }

    // Loop through all of the elements in the templates
    filtered.forEach((chapter) => {
      // Check if the chapter has content

      if (chapter.content) {
        checkChildren(chapter.content, chapter);
      }

      // Loop through the sections to check for results
      if (chapter.sections) {
        chapter.sections.forEach((section) => {
          // TODO: Check the section header

          // Loop through each section's content, until we hit a string
          checkChildren(section.content, chapter, section);
        });
      }
    });
    setTimeTaken(Date.now() - start);
    setResults(found);
  };

  const goTo = (result) => {
    updateCache({ showSidebar: false });

    const params = new URLSearchParams({
      chapter: result.chapter.name
    });

    if (result.section) {
      params.set('section', result.section.index);
    }

    setRedirect(`/?${params.toString()}`);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.has('keywords')) {
      setFromUrl(true);
      setKeywords(query.get('keywords'));
    }
  }, [location]);

  useEffect(() => {
    if (fromUrl) {
      setFromUrl(false);
      doSearch(null);
    }
  }, [keywords]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <PageWrapper page="search">
      <div className="search">
        <h1>{translate('search.page-title')}</h1>
        <div className="input-container">
          <form onSubmit={doSearch}>
            <input
              defaultValue={keywords}
              id="keywords"
              onChange={({ target }) => setKeywords(target.value)}
              placeholder={translate('search.placeholder')}
            />
            <button type="submit" onClick={doSearch}>
              <img src={search} alt="search icon" />
              <span>{translate('search.search-btn')}</span>
            </button>
          </form>
        </div>

        <div className="main">
          <aside>
            <div>
              <h4>{translate('common.filter-by')}</h4>
              <div>
                <h5>{translate('common.chapters')}</h5>
                { Object.keys(filter).some((k) => filter[k]) && (
                  <button
                    type="button"
                    className="clear"
                    onClick={() => {
                      setFilter(
                        keysToEntries(Object.keys(filter), false)
                      );
                    }}
                  >
                    {translate('common.clear-all')}
                  </button>
                ) }
              </div>
              <button
                type="button"
                onClick={() => setShowFilters((f) => !f)}
                className={cn('toggle', { open: showFilters })}
              >
                Filter By
              </button>
            </div>

            <div className={cn('filters', { show: showFilters })}>
              { filters.map((f) => (
                <label
                  key={f.value}
                  htmlFor={f.value}
                  className={cn({ checked: filter[f.value] })}
                >
                  <span>{ f.label }</span>
                  <input
                    type="checkbox"
                    value={f.value}
                    id={f.value}
                    checked={filter[f.value] || false}
                    onChange={({ target }) => {
                      const keys = Object.keys(filter).filter((k) => k !== 'all');
                      if (f.value !== 'all') {
                        const data = {
                          ...filter, [f.value]: target.checked
                        };
                        // If all the chapters are checked, set all to enabled
                        // If they aren't set it to disabled
                        setFilter({
                          ...data,
                          all: keys.every((k) => data[k])
                        });
                      } else {
                        // When all is changed, change everything
                        setFilter(keysToEntries(keys, target.checked));
                      }
                    }}
                  />
                </label>
              ))}
            </div>
          </aside>

          <div className={cn('results', { closed: !showFilters })}>
            { (!results || !results.length) && (
              <div className="empty">
                <img src={exclamation} alt="Exclamation icon" />
                <p>
                  {
                    searched
                      ? translate('search.no-results-msg')
                      : translate('search.msg')
                  }
                </p>
              </div>
            ) }
            { results && !!results.length && (
              <div className="display">
                <h4><b>{ results.length } {translate('search.results')}</b> {translate('search.found-in')} <i>{ timeTaken }ms</i></h4>
                { results.map((result, i) => (
                  <div
                    className="result"
                    key={i}
                    onClick={() => goTo(result)}
                    onKeyDown={() => goTo(result)}
                    role="button"
                    tabIndex={0}
                  >
                    <div dangerouslySetInnerHTML={{ __html: result.text }} />
                    <div className={`chapter-section ${result.chapter.color}`}>
                      <h3>{ result.chapter.name }</h3>
                      { result.section && (
                        <h5>{ result.section.index } { result.section.name }</h5>
                      ) }
                    </div>
                  </div>
                )) }
              </div>
            ) }
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

Search.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  updateCache: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cache }) => ({ cache });
export default connect(mapStateToProps, {
  updateCache: actions.updateCache
})(Search);
