import {
  SET_CACHE,
  UPDATE_CACHE,
  REMOVE_FROM_CACHE,
  CLEAR_CACHE
} from './types';
import templates from '../../templates';

/**
 * Checks if the browser has a localStorage function
 * @returns {Boolean} - true if localStorage is available, false if not
 */
const compatible = () => {
  try {
    localStorage.setItem('unp_cache_test', 'test');
    localStorage.removeItem('unp_cache_test');
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Gets the state from localStorage or blank if none
 * has been saved previously
 * @returns {Object} the previously saved state or blank
 */
const getState = () => {
  // From init allows the location search query to reset this
  // from the /book/components/content overriding the default
  // page shown, when overridden fromInit will be set to false
  const base = { chapter: templates[0].name, progress: {} };
  if (!compatible()) return base;
  const stored = localStorage.getItem('unp_cache');
  if (!stored) return base;
  return JSON.parse(stored);
};

/**
 * Saves the passed state to localStorage
 * @param {Object} state - the state to save to localStorage
 */
const saveState = (state) => {
  if (!compatible()) return;
  localStorage.setItem('unp_cache', JSON.stringify(state));
};

export default (state = getState(), action) => {
  let updated;
  switch (action.type) {
    case SET_CACHE:
      updated = action.data;
      break;
    case UPDATE_CACHE:
      updated = { ...state, ...action.data };
      break;
    case REMOVE_FROM_CACHE: {
      const clone = { ...state };
      delete clone[action.name];
      updated = clone;
      break;
    }
    case CLEAR_CACHE:
      updated = {};
      break;
    default:
      updated = state;
  }

  if (action.cookies) saveState(updated);
  return updated;
};
