import { combineReducers } from 'redux';
import history from './history/reducer';
import cache from './cache/reducer';
import cookies from './cookies/reducer';

export default (state, action) => combineReducers({
  history,
  cache,
  cookies
})(state, action);
