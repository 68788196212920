import PropTypes, { array, oneOfType, string, shape } from 'prop-types';
import { gridContent } from '../../config/access';

/**
 * @returns {boolean|*}
 * @param role
 * @param area
 * @param tab
 * @param index
 */
const isGridSectionVisible = (role, area, tab, index) => {
  if (tab === 'recommended' && !!gridContent[`${role}-${area}`]) {
    return gridContent[`${role}-${area}`].find((item) => item[index]);
  }

  return true;
};
/**
 * @param role
 * @param area
 * @param tab
 * @param chapter
 * @returns {*}
 * @constructor
 */
const GridContent = ({ role, area, tab, chapter }) => {
  const { content = null, index } = chapter;
  const sectionVisible = isGridSectionVisible(role, area, tab, index);

  return sectionVisible ? content : null;
};

GridContent.propTypes = {
  role: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  chapter: PropTypes.shape({
    index: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
    content: oneOfType([array, string, shape({})]),
    sections: oneOfType([array, string, shape({})]),
    footnotes: oneOfType([array, string, shape({})]),
  }).isRequired,
};

export default GridContent;
