import { ADD_TO_HISTORY } from './types';

/**
 * Creates history if it is currently undefined or adds
 * to it if it already exists
 * @param {Object/String} snapshot - an action/event
 */
const addToHistory = (snapshot) => ({ snapshot, type: ADD_TO_HISTORY });

export default { addToHistory };
