import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import './styles/main.scss';
import { I18NService } from './services/i18n/I18nService';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from './store';
import Onboarding from './onboarding';
import About from './about';
import Book from './book';
import Page from './components/page';
import Search from './search';
import Profile from './profile';
import NoMatch from './components/nomatch';

ReactDOM.render(
  <Provider store={store}>
    <I18NService>
      <Router>
        <Switch>
          <Route exact path="/welcome" component={Onboarding} />
          <Page exact path="/about" component={About} />
          <Page exact path="/" component={Book} />
          <Page exact path="/search" component={Search} />
          <Page exact path="/profile" component={Profile} />
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    </I18NService>
  </Provider>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
reportWebVitals();
