import React from 'react';
import { t, setI18nConfig } from '../services/i18n';

setI18nConfig();
export default {
  name: 'Annex II: Guidance on Protection of Civilians Responsive Projects',
  title: t('handbook.annexes.ii.title'),
  annex: '2',
  color: 'un-orange',
  content: (
    <div className="main-content">
      <h5>Background</h5>
      <p>
        Following up on Code Cable XXXX dated XXX which encourages the Mission
        to prioritize the allocation of QiP resources to communities
        experiencing high insecurity and protection of civilians (POC) threats,
        and complementing DPKO/DFS QIPs Guidelines and Policy and the
        Mission&apos;s QIPs SOP and Programmatic Orientations, the Protection
        of Civilians (POC) Unit has identified the need to develop guidance for
        the Mission pillars submitting project proposals for funding through
        other available funding mechanisms.
      </p>
      <p>
        Depending on the type of intervention and scope of activities, some
        projects have clear and direct POC dimension, while others affect POC
        indirectly or to a very limited extent only. The guidance provides a
        means to categorize the Mission&apos;s project outputs based on their
        &quot;POC relevance&quot; and expected contribution to the Mission&apos;s
        protection mandate.
      </p>
      <h5>Intent</h5>
      <p>
        This guidance note is intended to help measure the extent to which
        projects contribute to the Mission&apos;s POC mandate implementation. The
        POC rating enables the Mission to more effectively track and monitor
        the allocation of financial resources to POC activities and therefore,
        the level of integration of POC-related activities into the
        Mission&apos;s projects and programmes.
      </p>
      <p>
        Mission projects intend to support the implementation of the Peace
        Agreement, inclusive dialogue, State authority restoration, support
        to the reconstituted National Defense and Security Forces, and return
        of basic social services among communities most affected by the
        conflict, with a particular view to improving the security situation,
        reinforcing social cohesion, reviving productive activities and
        restoring basic social services. This especially includes the
        following financing mechanisms: (i) the Quick Impact Projects (QIPs),
        (ii) the Trust Fund (TF), (iii) Peacebuilding Fund (PBF), (iv)
        Programmatic Funding as well as projects and activities financed via
        DDR/SSR programmes such as Community Violence Reduction (CVR) projects.
      </p>
      <p>
        With regards to POC, projects need to focus on areas marked by the
        highest levels of threats of physical violence against civilians, as
        identified by the regional POC working groups, co-chaired by the Heads
        of Office and the Sector Commanders and participated by relevant sections
        and the humanitarian community.
      </p>
      <p>
        By addressing threats at their origin, projects shall be implemented across four operational phases: prevention, pre-emption, response and consolidation, as prescribed by the strategic approach to POC<sup>1</sup>:
      </p>
      <ol type="i">
        <li>
          prevention: where no clear threat to civilians has been identified (longer term)
        </li>
        <li>
          pre-emption: where likely threats are identified and attacks against civilians are anticipated (short term)
        </li>
        <li>
          response: where threats to civilians are imminent or occurring (short term), and
        </li>
        <li>
          consolidation: where violence against civilians is subsiding (longer term)
        </li>
      </ol>
      <p>
        The phases do not necessarily occur in sequential order and may be undertaken simultaneously or independently.
      </p>
      <p>
        <u>The prevention and consolidation phases are of particular importance for project planning and implementation:</u> Where the threat to civilians is latent or has been significantly reduced, mission approaches will focus on prevention and consolidation including supporting and building the capacity of host state institutions to enable them to fulfil their responsibilities to protect civilians. Activities may focus on support to the peace process; human rights monitoring, reporting and investigation; supporting resilience and community cohesion through dialogue and reconciliation and addressing root causes of conflict; disarmament, demobilization and re-integration of ex-combatants, institution-building of security, rule and law and criminal justice entities including through security sector reform and enhancing accountability for serious crimes. In areas where violence has ended or reduced, where appropriate it will include securing the return of refugees or durable solutions for IDPs<sup>2</sup>; undertaking community-oriented policing; clearance of explosive ordinance including mines, explosive remnants of war and improvised explosive devices, and enhancing community resilience though support to humanitarian, early-recovery or development assistance, stabilisation and peacebuilding activities, the promotion and protection of human rights and the fight against impunity.
      </p>
      <h5>POC rating</h5>
      <p>
        Assigning a POC rating to project outputs helps ensuring that the respective minimum requirements with regard to POC are fulfilled, and moreover, determining the scope and type of POC mainstreaming activities needed to guarantee that any project is — within its interventions and scope of activities — as POC-responsive as possible.
      </p>
      <p>All projects should:</p>
      <ul>
        <li>
          Address identified threats or underlying sources of conflict, as assessed in regional POC analysis;
        </li>
        <li>
          Enable protection measures undertaken by state actors;
        </li>
        <li>
          Be based on documented evidence, as stated in regional POC analysis and action plans;
        </li>
        <li>
          Be implemented in POC geographical hotspots.
        </li>
      </ul>
      <p>
        Depending on its POC-responsiveness, every project output should therefore be assigned a POC rate scoring as follows (the rating system is qualitative rather than quantitative):
      </p>
      <p>
        2. PoC is the central focus of output or significant attention to POC
      </p>
      <p>1. Some/limited attention to POC</p>
      <p>0. No attention to POC</p>
      <p className="underline">
        2 – POC is the central focus of output or significant attention to POC
      </p>
      <p>In order to receive a &apos;significant&apos; rating, projects must:</p>
      <ul>
        <li>
          be designed to facilitate dialogue with identified perpetrators or potential perpetrators of intercommunity violence;
        </li>
        <li>
          address conflict resolution, reconciliation and mediation between communities in conflict;
        </li>
        <li>strengthen social cohesion</li>
        <li>
          support the participation of women in conflict prevention, conflict resolution and peacebuilding
        </li>
        <li>
          protect civilians through public information, including by increasing access to information.
        </li>
      </ul>
      <p>Or</p>
      <ul>
        <li>
          be designed to help create a protective environment for civilians.
        </li>
      </ul>
      <p>This includes projects directed at:</p>
      <ul className="circle">
        <li>
          supporting the redeployment of the constituted defense and security forces;
        </li>
        <li>
          mitigating tensions over resources by increasing access to resources;
        </li>
        <li>preventing criminality;</li>
        <li>addressing the underlying causes of conflict;</li>
        <li>
          strengthening the rule of law, including through the promotion and protection of human rights and justice;
        </li>
        <li>community infrastructure projects.</li>
      </ul>
      <p>
        Projects being rated as POC significant will be included in the corresponding regional POC action plans as response to an identified threat in a prioritized geographical area.
      </p>
      <p className="underline">1 – Some/limited attention to POC</p>
      <p>
        Some contribution to POC is expected as a result of this output, but not as a primary objective. Activities under this output are primarily focused on other issues but have a small POC component. If possible, one indicator is assigned to this output that includes a POC target and baseline.
      </p>
      <p className="underline">0 – No attention to POC</p>
      <p>
        No contribution to POC is expected as a result from this output. The output is POC-blind.
      </p>
      <h5>Coordination Mechanisms</h5>
      <p>
        In order to assure that a maximum of projects are contributing to POC and that such projects are included in their corresponding regional POC action plan:
      </p>
      <ul>
        <li>
          Assure participation in the regional POC WG meetings where regional POC threats and priorities are discussed under the leadership of the HoO, and action plans including all Mission activities that will address the identified POC threats are designed.
        </li>
        <li>
          In the case that a project already approved/being implemented responds to a threat identified during the regional POC WG meeting, signal it during the meeting, so that the project can be included in the regional response plan.
        </li>
        <li>
          In addition, regional POC WGs should always discuss new projects to address identified threats. Regional POC officers will be asked to raise the question during each regional POC WG meeting.
        </li>
        <li>
          POC Unit participates as an observer in the Trust Fund PRC, regional QIP LPRC and PAC meetings.
        </li>
        <li>
          Projects to be implemented in Mission Headquarters location will be considered as contributing to POC as long as they are signaled in any of the regional POC action plans or recommended by the regional POC working groups as contributing to POC in the field.
        </li>
        <li>
          QIPs and CVR SOPs will be reviewed to reflect all the above measures.
        </li>
      </ul>
      <ol className="references">
        <li>
          DPO Policy on the Protection of Civilians in United Nations Peacekeeping (2019.17).
        </li>
        <li>
          In line with the Guiding Principles on Internal Displacement and the IASC Framework on Durable Solutions for IDPs.
        </li>
      </ol>
    </div>
  )
};
